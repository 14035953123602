import { AppIcon } from '@app/components/atm.app-icon';
import { TeamType } from '@app/data/graphql';
import { Badge } from '@atomic/atm.badge';
import React from 'react';
import { homeStrings } from '../home.strings';
import { AlertBadgeStyled } from './alert-badge.component.style';
const strings = homeStrings;
export const AlertBadge = (props) => {
    if (!props.fullVersion) {
        return React.createElement(AlertBadgeStyled, { alertType: props.alertType }, mapAlertTypeToIcon[props.alertType]);
    }
    return (React.createElement(Badge, { color: props.alertType === TeamType.Operational ? 'pendent' : 'pendent2', text: props.alertType === TeamType.Operational ? strings.alertOperational : strings.alertService, icon: mapAlertTypeToIcon[props.alertType] }));
};
export const mapAlertTypeToIcon = {
    [TeamType.Operational]: React.createElement(AppIcon.DoorHanger, { size: 'large', variant: 'black' }),
    [TeamType.Service]: React.createElement(AppIcon.Phone, { size: 'large', variant: 'black' }),
};
