import { useMutation } from '@app/core/graphql/graphql-mutation.hooks';
import { DeleteBookingItemsDocument, } from '@app/data/graphql';
import { dispatchFlashMessage } from '@atomic/mol.flash-message-container/flash-message-container.component';
export function useDeleteBookingItems(params) {
    const [deleteBookingItems, { error, loading }] = useMutation(DeleteBookingItemsDocument, {
        onCompleted: (deleteBookingItemsResult) => { var _a; return (_a = params.onCompleted) === null || _a === void 0 ? void 0 : _a.call(params, deleteBookingItemsResult); },
        onError: (requestError) => {
            var _a;
            dispatchFlashMessage(requestError.message);
            (_a = params.onError) === null || _a === void 0 ? void 0 : _a.call(params, requestError);
        },
    });
    return {
        deleteBookingItems,
        error,
        loading,
    };
}
