import { gql } from '@apollo/client';
export const BookingItemRecordFragmentDoc = gql `
  fragment BookingItemRecord on BookingItemRecord {
    id
    name
    deadline
    deadlineFriendlyDescription
    shouldTriggerBeforeDeadlineReference
    completedAt
    status
    bookingStepTemplateId
  }
`;
