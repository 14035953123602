import { Color } from '@atomic/obj.constants';
import styled from 'styled-components';
export const PageBackgroundStyled = styled.div `
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: ${Color.PrimaryDark};
`;
