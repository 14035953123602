import React from 'react';
import { AppIcon } from '@app/components/atm.app-icon';
import { Badge } from '@atomic/atm.badge';
import { Body } from '@atomic/atm.typography';
import { TD, TH, THead, TR, Table } from '@atomic/mol.table';
import { Spacing } from '@atomic/obj.constants';
import { Row, Spacer } from '@atomic/obj.grid';
import { AvatarImage } from '@app/components/atm.avatar/avatar.component';
import { Button } from '@atomic/atm.button';
import { usersStrings } from '../users.string';
import { UsersRoutes } from '../users.route';
const strings = usersStrings.table;
export const UsersTable = (props) => {
    return (React.createElement(Table, null,
        React.createElement(THead, null,
            React.createElement(TR, null,
                React.createElement(TH, null,
                    React.createElement(Body, { bold: true, variant: 'black' }, strings.name)),
                React.createElement(TH, null,
                    React.createElement(Body, { bold: true, variant: 'black' }, strings.email)),
                React.createElement(TH, null,
                    React.createElement(Body, { bold: true, variant: 'black' }, strings.role)),
                React.createElement(TH, null))),
        props.users.map((user) => {
            return React.createElement(UsersTableRow, { key: user.id, user: user });
        })));
};
const UsersTableRow = ({ user }) => {
    return (React.createElement(TR, null,
        React.createElement(TD, null,
            React.createElement(Row, { alignItems: 'center' },
                React.createElement(AvatarImage, { src: user.avatarUrl, size: 'xLarge' }),
                React.createElement(Spacer, { "$width": Spacing.Small }),
                React.createElement(Body, { variant: 'black' }, user.name))),
        React.createElement(TD, null,
            React.createElement(Body, { variant: 'black' }, user.email)),
        React.createElement(TD, null,
            React.createElement(Body, { variant: 'black' }, userRoleMapper[user.companyRole])),
        React.createElement(TD, { textAlign: 'center' },
            React.createElement(Button, { variant: 'secondary', to: `${UsersRoutes.Base}/${user.id}` },
                React.createElement(AppIcon.Edit, { variant: 'grayDark', size: 'large' }),
                strings.edit))));
};
const userRoleMapper = {
    OperationalTeam: React.createElement(Badge, { color: 'accent', text: usersStrings.roles.OperationalTeam }),
    ServiceTeam: React.createElement(Badge, { color: 'accessory2', text: usersStrings.roles.ServiceTeam }),
};
