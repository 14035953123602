import { Border, BrandColor, FeedbackColor, Spacing } from '@atomic/obj.constants';
import styled from 'styled-components';
const KanbanColors = [
    BrandColor.Lilac,
    BrandColor.SoftPink,
    BrandColor.SoftOrange,
    BrandColor.SoftYellow,
    FeedbackColor.GreenLight,
];
export const KanbanLaneStyled = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${Spacing.XSmall};
  width: 250px;
  border-radius: ${Border.RadiusLarge};
  padding: ${Spacing.Medium};
  padding-top: ${Spacing.Large};
  background-color: ${({ rowNumber }) => KanbanColors[rowNumber % KanbanColors.length]};
`;
