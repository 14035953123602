import * as React from 'react';
import ptBR from 'date-fns/locale/pt-BR';
import { registerLocale } from 'react-datepicker';
import { DatePickerStyled } from './date-picker.component.style';
import { isValid, parse } from 'date-fns';
export const DatePicker = (props) => {
    var _a, _b, _c, _d;
    const [startDate, setStartDate] = React.useState();
    const [endDate, setEndDate] = React.useState();
    React.useEffect(() => {
        registerLocale('pt-br', ptBR);
    }, []);
    const handleSelect = (date) => {
        var _a;
        if (props.endSelect) {
            if (!props.endDate) {
                setEndDate(date);
            }
        }
        else {
            if (!props.startDate) {
                setStartDate(date);
            }
        }
        (_a = props.onValueChange) === null || _a === void 0 ? void 0 : _a.call(props, date);
    };
    const handleFocusChange = (focused) => () => {
        var _a;
        (_a = props.onFocusChange) === null || _a === void 0 ? void 0 : _a.call(props, focused);
    };
    React.useEffect(() => {
        setEndDate(props.endDate);
    }, [props.endDate]);
    React.useEffect(() => {
        setStartDate(props.startDate);
    }, [props.startDate]);
    const handleChangeRaw = (e) => {
        var _a, _b, _c, _d, _e;
        const parsedDate = parse(e.target.value, 'P', new Date(), { locale: ptBR });
        if (((_b = (_a = e.target.value) === null || _a === void 0 ? void 0 : _a.split('/')) === null || _b === void 0 ? void 0 : _b.length) === 3 &&
            ((_e = (_d = (_c = e.target.value) === null || _c === void 0 ? void 0 : _c.split('/')) === null || _d === void 0 ? void 0 : _d[2]) === null || _e === void 0 ? void 0 : _e.length) === 4 &&
            isValid(parsedDate)) {
            handleSelect(parsedDate);
        }
    };
    const handleChange = (date) => {
        var _a;
        if (!date) {
            if (props.endSelect) {
                setEndDate(date);
            }
            else {
                setStartDate(date);
            }
            (_a = props.onValueChange) === null || _a === void 0 ? void 0 : _a.call(props, date);
        }
    };
    return (React.createElement(DatePickerStyled, { locale: 'pt-br', dateFormat: 'dd/MM/yyyy', onChange: handleChange, onChangeRaw: handleChangeRaw, onSelect: handleSelect, startDate: (_a = props.startDate) !== null && _a !== void 0 ? _a : startDate, endDate: (_b = props.endDate) !== null && _b !== void 0 ? _b : endDate, selected: props.endSelect ? (_c = props.endDate) !== null && _c !== void 0 ? _c : endDate : (_d = props.startDate) !== null && _d !== void 0 ? _d : startDate, onFocus: handleFocusChange(true), onBlur: handleFocusChange(false), placeholderText: props.placeholder, showTimeSelect: props.showTimeSelect, disabled: props.disabled, selectsStart: !props.endSelect, selectsEnd: props.endSelect, isClearable: !props.disabled }));
};
