import React from 'react';
import { ContentWrapper } from '@app/components/atm.content-wrapper/content-wrapper.component';
import { Thumbnail } from '@app/components/atm.thumbnail/thumbnail.component';
import { useUserStore } from '@app/stores';
import { Button } from '@atomic/atm.button';
import { TextField } from '@atomic/atm.text-field';
import { H1, InputCaptionError, InputLabel } from '@atomic/atm.typography';
import { Spacing } from '@atomic/obj.constants';
import { Column, Grid, Row, Spacer } from '@atomic/obj.grid';
import { rolesStringMapper } from '../common/roles.mapper';
import { accountStrings } from './account.strings';
import { useUploadAvatar } from '@app/domain/user/upload-avatar.use-case';
const strings = accountStrings;
export const AccountPage = () => {
    const [user] = useUserStore();
    const { uploadAvatar, loading, error } = useUploadAvatar();
    const fileInputRef = React.useRef(null);
    const handleUpload = () => {
        var _a;
        (_a = fileInputRef.current) === null || _a === void 0 ? void 0 : _a.click();
    };
    const handleFileChange = (event) => {
        var _a, _b;
        const selectedFile = (_b = (_a = event.target) === null || _a === void 0 ? void 0 : _a.files) === null || _b === void 0 ? void 0 : _b[0];
        if (selectedFile) {
            uploadAvatar(selectedFile);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ContentWrapper, null,
            React.createElement(H1, null, strings.myAccount),
            React.createElement(Spacer, { "$height": Spacing.Large })),
        React.createElement(Grid, null,
            React.createElement(Row, { justifyContent: 'center', gap: Spacing.XXLarge },
                React.createElement(Column, { alignItems: 'flex-end', gap: Spacing.Medium },
                    React.createElement(Thumbnail, { image: user === null || user === void 0 ? void 0 : user.avatarUrl, size: 'xxLarge' }),
                    !!error && React.createElement(InputCaptionError, null, error),
                    React.createElement("input", { type: 'file', ref: fileInputRef, style: { display: 'none' }, onChange: handleFileChange }),
                    React.createElement(Button, { variant: 'secondary', onClick: handleUpload, loading: loading }, strings.pickImage)),
                React.createElement(Column, { flex: 1 / 2 },
                    React.createElement(InputLabel, null, strings.fullName),
                    React.createElement(TextField, { value: user === null || user === void 0 ? void 0 : user.name, disabled: true }),
                    React.createElement(Spacer, { "$height": Spacing.Large }),
                    React.createElement(InputLabel, null, strings.email),
                    React.createElement(TextField, { value: user === null || user === void 0 ? void 0 : user.email, disabled: true }),
                    React.createElement(Spacer, { "$height": Spacing.Large }),
                    React.createElement(InputLabel, null, strings.companyRole),
                    React.createElement(TextField, { value: rolesStringMapper[user === null || user === void 0 ? void 0 : user.companyRole], disabled: true }))))));
};
