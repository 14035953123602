import React from 'react';
import { AppIcon } from '@app/components/atm.app-icon';
import { Button } from '@atomic/atm.button';
import { Body } from '@atomic/atm.typography';
import { TD, TH, THead, TR, Table } from '@atomic/mol.table';
import { Spacing } from '@atomic/obj.constants';
import { Row, Spacer } from '@atomic/obj.grid';
import { flowStrings } from '../flow.strings';
const strings = flowStrings.itemsTable;
export const BookingItemsTable = (props) => {
    return (React.createElement(Table, null,
        React.createElement(THead, null,
            React.createElement(TR, null,
                React.createElement(TH, null,
                    React.createElement(Body, { bold: true, variant: 'black' }, strings.itemName)),
                React.createElement(TH, null))),
        props.items.map((item) => {
            return React.createElement(BookingItemsTableRow, { key: item.id, item: item });
        })));
};
const BookingItemsTableRow = ({ item }) => {
    return (React.createElement(TR, null,
        React.createElement(TD, null,
            React.createElement(Body, { variant: 'black', lineLimit: 2 }, item.name)),
        React.createElement(TD, { textAlign: 'center' },
            React.createElement(Button, { variant: 'secondary', to: item.id.toString(), navigationState: { breadcrumbKey: item.name } },
                React.createElement(Row, { alignItems: 'center' },
                    React.createElement(AppIcon.Edit, { variant: 'grayDark', size: 'large' }),
                    React.createElement(Spacer, { "$width": Spacing.XSmall }),
                    strings.edit)))));
};
