import { useQuery } from '@app/core/graphql';
import { SearchHotelsDocument } from '@app/data/graphql';
export const useSearchHotels = (params) => {
    const { data, loading, error } = useQuery(SearchHotelsDocument, {
        query: params.query,
        filters: {
            onlyActive: params.onlyActive,
            onlyInactive: params.onlyInactive,
        },
    }, {
        skip: params.skip,
        onCompleted: (hotelsResult) => { var _a; return (_a = params.onCompleted) === null || _a === void 0 ? void 0 : _a.call(params, hotelsResult); },
    });
    return {
        data: data === null || data === void 0 ? void 0 : data.searchHotels,
        loading,
        error,
    };
};
