export const placeholderStrings = {
    notFound: {
        defaultTitle: 'Página não encontrada',
        defaultDescription: 'Desculpe, página não encontrada. Verifique o endereço e tente novamente.',
    },
    empty: {
        defaultTitle: 'Resultado não encontrado',
        defaultDescription: 'Desculpe, resultado não encontrado.',
    },
};
