import { useMutation } from '@app/core/graphql/graphql-mutation.hooks';
import { ConfigOperationalFlowDocument, } from '@app/data/graphql/generated/config-operational-flow.mutation';
import { dispatchFlashMessage } from '@atomic/mol.flash-message-container/flash-message-container.component';
export function useConfigOperationalFlow(params) {
    const [mutation, { error, loading }] = useMutation(ConfigOperationalFlowDocument, {
        onCompleted: (configOperationalFlowResult) => { var _a; return (_a = params.onCompleted) === null || _a === void 0 ? void 0 : _a.call(params, configOperationalFlowResult); },
        onError: (requestError) => {
            var _a;
            dispatchFlashMessage(requestError.message);
            (_a = params.onError) === null || _a === void 0 ? void 0 : _a.call(params, requestError);
        },
    });
    const configOperationalFlow = (input) => {
        mutation({
            input: {
                bookingItemsTemplateIds: input.bookingItemsTemplateIds,
                bookingStepTemplateId: +input.bookingStepTemplateId,
                hotelId: +input.hotelId,
            },
        });
    };
    return {
        configOperationalFlow,
        error,
        loading,
    };
}
