import React from 'react';
import { SideDrawer } from '@app/components/atm.side-drawer/side-drawer.component';
import { useUpdateGuest } from '@app/domain/guest/update-guest.use-case';
import { H2, H4 } from '@atomic/atm.typography';
import { dispatchFlashMessage } from '@atomic/mol.flash-message-container';
import { Spacing } from '@atomic/obj.constants';
import { Grid, Spacer } from '@atomic/obj.grid';
import { useLocation, useOutletContext, useParams } from 'react-router-dom';
import { GuestForm } from './components/guest-form.component';
import { homeStrings } from './home.strings';
import { Snackbar } from '@atomic/atm.snackbar/snackbar.component';
import { AppIcon } from '@app/components/atm.app-icon';
const strings = homeStrings;
export const BookingGuestDrawerPage = () => {
    var _a;
    const context = useOutletContext();
    const location = useLocation();
    const params = useParams();
    const { guest } = (_a = location === null || location === void 0 ? void 0 : location.state) !== null && _a !== void 0 ? _a : {};
    const { updateGuest, loading } = useUpdateGuest({
        guestId: guest === null || guest === void 0 ? void 0 : guest.id,
        bookingId: params.id,
        onCompleted: () => {
            dispatchFlashMessage(strings.guestDrawer.updatedSuccessfully, 'success');
            context === null || context === void 0 ? void 0 : context.handleClick();
        },
    });
    return (React.createElement(SideDrawer, { level: 2 },
        React.createElement(Grid, null,
            React.createElement(H2, null, guest === null || guest === void 0 ? void 0 :
                guest.name,
                " ", guest === null || guest === void 0 ? void 0 :
                guest.lastName),
            (guest === null || guest === void 0 ? void 0 : guest.hadProblemOnSyncing) && (React.createElement(React.Fragment, null,
                React.createElement(Spacer, { "$height": Spacing.Medium }),
                React.createElement(Snackbar, { message: strings.syncToTotvsFailed, icon: React.createElement(AppIcon.Warning, { variant: 'alertDark', size: 'large' }) }),
                React.createElement(Spacer, { "$height": Spacing.Small }))),
            React.createElement(H4, { variant: 'black' }, (guest === null || guest === void 0 ? void 0 : guest.isMain) ? strings.guestDrawer.mainGuestData : strings.guestDrawer.guestData),
            React.createElement(GuestForm, { loading: loading, onSubmit: updateGuest, guest: guest })),
        React.createElement(Spacer, { "$height": Spacing.Large })));
};
