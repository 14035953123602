import { gql } from '@apollo/client';
import { UserFragmentDoc } from './user.fragment';
export const UserDocument = gql `
  query User($input: UserInput!) {
    user(input: $input) {
      ...User
      isFirstLogin
    }
  }
  ${UserFragmentDoc}
`;
