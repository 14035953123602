var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { uploadFile } from '@app/data/file-storage.datasource';
import { generalStrings } from '@app/utils/general.string';
import { dispatchFlashMessage } from '@atomic/mol.flash-message-container';
import React from 'react';
export const useUploadFile = (params) => {
    const [uploadProgress, setUploadProgress] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const handleUploadFile = (file, url) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        setLoading(true);
        try {
            yield uploadFile(file, url);
            (_a = params === null || params === void 0 ? void 0 : params.onCompleted) === null || _a === void 0 ? void 0 : _a.call(params);
        }
        catch (error) {
            (_b = params === null || params === void 0 ? void 0 : params.onError) === null || _b === void 0 ? void 0 : _b.call(params, error);
        }
        finally {
            setLoading(false);
            setUploadProgress([]);
        }
    });
    const handleUploadMultipleFiles = (files, attachments) => __awaiter(void 0, void 0, void 0, function* () {
        var _c;
        setLoading(true);
        const uploadPromises = files.map((file, i) => __awaiter(void 0, void 0, void 0, function* () {
            return uploadFile(file, attachments.find((attachment) => attachment.name === file.name).signedUrl)
                .then(() => {
                setUploadProgress((prevProgress) => {
                    const newProgress = [...prevProgress];
                    newProgress[i] = true;
                    return newProgress;
                });
            })
                .catch((error) => {
                var _a;
                (_a = params === null || params === void 0 ? void 0 : params.onError) === null || _a === void 0 ? void 0 : _a.call(params, error);
                dispatchFlashMessage(generalStrings.fileUpload.error);
                setUploadProgress([]);
            });
        }));
        yield Promise.allSettled(uploadPromises);
        setLoading(false);
        setUploadProgress([]);
        (_c = params === null || params === void 0 ? void 0 : params.onCompleted) === null || _c === void 0 ? void 0 : _c.call(params);
    });
    return { handleUploadFile, handleUploadMultipleFiles, uploadProgress, loading };
};
