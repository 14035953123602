import { gql } from '@apollo/client';
import { BookingValidationFragmentDoc } from './booking-validation';
import { HotelFragmentDoc } from './hotel.fragment';
import { GuestFragmentDoc } from './guest.fragment';
import { BookingAlertPreviewFragmentDoc } from './booking-alert.fragment';
import { GuestBillingFragmentDoc } from './guest-billing.fragment';
export const BookingFragmentDoc = gql `
  fragment Booking on Booking {
    id
    updatedAt
    createdAt
    comment
    status
    checkIn
    checkOut
    effectiveCheckIn
    effectiveCheckOut
    idHIGS
    idPMS
    idSource
    ticketId
    roomType
    roomNumber
    roomPassword
    childrenCount
    guestCount
    totalAmountBeforeTax
    hadProblemOnSyncing
    hadGlobalProblemOnSyncing
    currentBookingStepTemplateId
    completedValidations {
      ...BookingValidation
    }
    hotel {
      ...Hotel
    }
    guests {
      ...Guest
    }
    alertsPreview {
      ...BookingAlertPreview
    }
    guestBillings {
      ...GuestBilling
    }
  }
  ${BookingValidationFragmentDoc}
  ${HotelFragmentDoc}
  ${GuestFragmentDoc}
  ${BookingAlertPreviewFragmentDoc}
  ${GuestBillingFragmentDoc}
`;
