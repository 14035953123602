import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BreadcrumbLinkTextStyled, BreadcrumbListItemStyled, BreadcrumbListStyled } from './breadcrumb.component.style';
import { BreadcrumbPathToTitle, BreadcrumbPreviousRouteToTitle } from './breadcrumbs.route.names';
import { limitString } from '@app/utils/string.utils';
export const Breadcrumb = () => {
    var _a, _b, _c;
    const location = useLocation();
    const paths = (_b = (_a = location === null || location === void 0 ? void 0 : location.pathname) === null || _a === void 0 ? void 0 : _a.split('/')) === null || _b === void 0 ? void 0 : _b.filter((item) => !!item);
    const breadcrumbKey = (_c = location === null || location === void 0 ? void 0 : location.state) === null || _c === void 0 ? void 0 : _c.breadcrumbKey;
    if ((paths === null || paths === void 0 ? void 0 : paths.length) <= 1) {
        return null;
    }
    const getBreadcrumbPath = (path, previousPath) => {
        var _a, _b, _c;
        return limitString((_c = (_b = (_a = BreadcrumbPathToTitle[path]) !== null && _a !== void 0 ? _a : breadcrumbKey) !== null && _b !== void 0 ? _b : BreadcrumbPreviousRouteToTitle[previousPath]) !== null && _c !== void 0 ? _c : path === null || path === void 0 ? void 0 : path.replace(/-/g, ' '));
    };
    return (React.createElement("nav", { "aria-label": 'Trilha de navega\u00E7\u00E3o', className: 'breadcrumb' },
        React.createElement(BreadcrumbListStyled, null, paths.map((path, index) => {
            var _a, _b;
            const isCurrent = index === (paths === null || paths === void 0 ? void 0 : paths.length) - 1;
            const previousPath = paths[index - 1];
            return (React.createElement(React.Fragment, { key: `${path}-${index}` },
                React.createElement(BreadcrumbListItemStyled, { "aria-current": isCurrent ? 'page' : 'false' },
                    React.createElement(Link, { to: {
                            pathname: (_b = (_a = new Array((paths === null || paths === void 0 ? void 0 : paths.length) - index - 1)) === null || _a === void 0 ? void 0 : _a.fill('..')) === null || _b === void 0 ? void 0 : _b.join('/'),
                        }, relative: 'path' },
                        React.createElement(BreadcrumbLinkTextStyled, { current: isCurrent }, getBreadcrumbPath(path, previousPath)))),
                index !== (paths === null || paths === void 0 ? void 0 : paths.length) - 1 && (React.createElement(BreadcrumbListItemStyled, null,
                    React.createElement(BreadcrumbLinkTextStyled, null, "/")))));
        }))));
};
