import { useMutation } from '@app/core/graphql/graphql-mutation.hooks';
import { UpdateBookingItemDocument, } from '@app/data/graphql/generated/update-booking-item.mutation';
import { dispatchFlashMessage } from '@atomic/mol.flash-message-container/flash-message-container.component';
import { mapBookingItemInput } from './booking-item-input.mapper';
export function useUpdateBookingItem(params = {}) {
    const [mutation, { error, loading }] = useMutation(UpdateBookingItemDocument, {
        onCompleted: (updateBookingItemResult) => { var _a; return (_a = params.onCompleted) === null || _a === void 0 ? void 0 : _a.call(params, updateBookingItemResult); },
        onError: (requestError) => {
            var _a;
            dispatchFlashMessage(requestError.message);
            (_a = params.onError) === null || _a === void 0 ? void 0 : _a.call(params, requestError);
        },
    });
    const updateItem = (input) => {
        mutation({ input: Object.assign(Object.assign({}, mapBookingItemInput(input)), { id: +input.id, hotelId: +input.hotelId }) });
    };
    return {
        loading,
        error,
        updateItem,
    };
}
