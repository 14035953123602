import React from 'react';
import { BodySecondary } from '@atomic/atm.typography';
import { FlashIconTypes } from '@atomic/mol.flash-message';
import { FlashMessageStyled } from '@atomic/mol.flash-message/flash-message.component.style';
import { Spacing } from '@atomic/obj.constants';
import { Row } from '@atomic/obj.grid';
export const Snackbar = (props) => {
    var _a, _b, _c;
    const Icon = FlashIconTypes[(_a = props.type) !== null && _a !== void 0 ? _a : 'alert'];
    return (React.createElement(FlashMessageStyled, { type: (_b = props.type) !== null && _b !== void 0 ? _b : 'alert' },
        React.createElement(Row, { alignItems: 'center', gap: Spacing.Small }, (_c = props.icon) !== null && _c !== void 0 ? _c : React.createElement(Icon, null),
            React.createElement(BodySecondary, null, props.message))));
};
