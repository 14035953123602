import { AppIcon } from '@app/components/atm.app-icon';
import { useDuplicateOperationalFlow } from '@app/domain/hotel/duplicate-operational-flow.use-case';
import { useGetHotels } from '@app/domain/hotel/get-hotels.use-case';
import { Badge } from '@atomic/atm.badge';
import { Button } from '@atomic/atm.button';
import { RadioField } from '@atomic/atm.radio';
import { Body } from '@atomic/atm.typography';
import { TD, TH, THead, TR, Table } from '@atomic/mol.table';
import { Hbox } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { Form, Validators } from '@atomic/obj.form';
import { Row } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import * as _ from 'lodash';
import React from 'react';
import { hotelConfigStrings } from '../hotels-config.strings';
import { ButtonWrapperStyled } from './hotel-table-button.component.style';
const strings = hotelConfigStrings;
export var DuplicateConfigFormFields;
(function (DuplicateConfigFormFields) {
    DuplicateConfigFormFields["targetHotelId"] = "targetHotelId";
})(DuplicateConfigFormFields || (DuplicateConfigFormFields = {}));
export const HotelsConfigTable = (props) => {
    return (React.createElement(Table, null,
        React.createElement(THead, null,
            React.createElement(TR, null,
                React.createElement(TH, null,
                    React.createElement(Body, { bold: true, variant: 'black' }, strings.table.hotelId)),
                React.createElement(TH, null,
                    React.createElement(Body, { bold: true, variant: 'black' }, strings.table.hotelName)),
                React.createElement(TH, null,
                    React.createElement(Body, { bold: true, variant: 'black' }, strings.table.status)),
                React.createElement(TH, null))),
        props.hotels.map((hotel) => {
            return React.createElement(HotelsConfigTableRow, { key: hotel.idHIGS, hotel: hotel });
        })));
};
const HotelsConfigTableRow = ({ hotel }) => {
    var _a, _b;
    const modalRef = React.useRef();
    const formRef = React.useRef();
    const [page, setPage] = React.useState(1);
    const [options, setOptions] = React.useState([]);
    const { data: hotels } = useGetHotels({
        page,
        skipSearch: true,
        onCompleted: (hotelsResult) => {
            var _a, _b;
            if ((_b = (_a = hotelsResult === null || hotelsResult === void 0 ? void 0 : hotelsResult.hotels) === null || _a === void 0 ? void 0 : _a.nodes) === null || _b === void 0 ? void 0 : _b.length) {
                setOptions((prevOptions) => { var _a; return _.uniq([...prevOptions, ...(((_a = hotelsResult === null || hotelsResult === void 0 ? void 0 : hotelsResult.hotels) === null || _a === void 0 ? void 0 : _a.nodes) || [])]); });
            }
        },
    });
    const { duplicateOperationalFlow, loading: loadingDuplicate } = useDuplicateOperationalFlow({
        onCompleted: () => {
            modalRef.current.close();
        },
    });
    const handleSubmit = (formData) => {
        if (formData.hasError) {
            return;
        }
        duplicateOperationalFlow({
            input: {
                baseHotelId: hotel.id,
                targetHotelId: formData.data.targetHotelId,
            },
        });
    };
    const handleLoadMore = () => {
        setPage((prevPage) => prevPage + 1);
    };
    React.useEffect(() => {
        if (hotels === null || hotels === void 0 ? void 0 : hotels.nodes) {
            setOptions((prevOptions) => _.uniq([...prevOptions, ...hotels.nodes]));
        }
    }, [hotels === null || hotels === void 0 ? void 0 : hotels.nodes]);
    const handleDuplicateClick = () => {
        var _a, _b;
        (_b = (_a = formRef.current) === null || _a === void 0 ? void 0 : _a.handleSubmit) === null || _b === void 0 ? void 0 : _b.call(_a);
    };
    const renderButtonContent = () => {
        if (hotel === null || hotel === void 0 ? void 0 : hotel.hasOperationalFlow) {
            return (React.createElement(React.Fragment, null,
                React.createElement(AppIcon.Edit, { variant: 'grayDark', size: 'large' }),
                strings.table.editFlow));
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(AppIcon.Add, { variant: 'white', size: 'large' }),
            strings.table.createFlow));
    };
    return (React.createElement(TR, null,
        React.createElement(TD, null,
            React.createElement(Body, { variant: 'black' }, hotel.idHIGS)),
        React.createElement(TD, null,
            React.createElement(Body, { variant: 'black' }, hotel.name)),
        React.createElement(TD, null,
            React.createElement(Badge, { color: hotel.active ? 'successLight' : 'warningLight', text: hotel.active ? strings.table.active : strings.table.inactive })),
        React.createElement(TD, null,
            React.createElement(Row, { gap: Spacing.XLarge, justifyContent: 'center' },
                React.createElement(ButtonWrapperStyled, { variant: 'secondary', onClick: () => modalRef.current.showModal(), disabled: !(hotel === null || hotel === void 0 ? void 0 : hotel.hasOperationalFlow), expanded: true },
                    React.createElement(AppIcon.Copy, { variant: 'grayDark', size: 'large' }),
                    strings.table.duplicateFlow),
                React.createElement(ButtonWrapperStyled, { variant: (hotel === null || hotel === void 0 ? void 0 : hotel.hasOperationalFlow) ? 'secondary' : 'primary', to: hotel.id ? `${hotel.id}` : undefined, navigationState: { breadcrumbKey: hotel.name }, expanded: true }, renderButtonContent()))),
        React.createElement(Modal, { title: strings.table.duplicateFlow, ref: modalRef, small: true, footer: React.createElement(Hbox, { hAlign: 'flex-end' },
                React.createElement(Hbox.Item, { hAlign: 'flex-end', noGrow: true },
                    React.createElement(Button, { variant: 'link', onClick: () => modalRef.current.close() }, strings.modal.cancel)),
                React.createElement(Hbox.Separator, { size: Spacing.Medium }),
                React.createElement(Hbox.Item, { hAlign: 'flex-end', noGrow: true },
                    React.createElement(Button, { variant: 'primary', onClick: handleDuplicateClick, loading: loadingDuplicate }, strings.modal.duplicate))) },
            React.createElement(Body, { bold: true }, strings.modal.description),
            React.createElement(Form, { ref: formRef, onSubmit: handleSubmit },
                React.createElement(Form.Field, { name: DuplicateConfigFormFields.targetHotelId, validators: [Validators.Required()] }, options === null || options === void 0 ? void 0 : options.map((option) => (React.createElement(RadioField, { key: option === null || option === void 0 ? void 0 : option.id, id: option === null || option === void 0 ? void 0 : option.id, disabled: option === null || option === void 0 ? void 0 : option.hasOperationalFlow, noBorder: true, noBg: true }, option === null || option === void 0 ? void 0 : option.name))))),
            ((_a = hotels === null || hotels === void 0 ? void 0 : hotels.pageInfo) === null || _a === void 0 ? void 0 : _a.hasNextPage) && (React.createElement(Button, { variant: 'link', onClick: handleLoadMore }, (_b = strings.modal) === null || _b === void 0 ? void 0 : _b.loadMore)))));
};
