import { useQuery } from '@app/core/graphql';
import { SearchBookingItemsTemplateByNameDocument, } from '@app/data/graphql';
export const useSearchBookingItemsTemplateByName = (params) => {
    const { data, loading, error } = useQuery(SearchBookingItemsTemplateByNameDocument, {
        name: params.name,
    }, {
        skip: params.skip,
    });
    return {
        data,
        loading,
        error,
    };
};
