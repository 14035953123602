import { gql } from '@apollo/client';
import { BookingStepFragmentDoc } from './booking-step.fragment';
export const UpdateBookingStepsPositionsDocument = gql `
  mutation UpdateBookingStepsPositions($input: [UpdateBookingStepsPositionsInput!]!) {
    updateBookingStepsPositions(input: $input) {
      ...BookingStep
    }
  }
  ${BookingStepFragmentDoc}
`;
