export var GuestFormFields;
(function (GuestFormFields) {
    GuestFormFields["name"] = "name";
    GuestFormFields["lastName"] = "lastName";
    GuestFormFields["birthDate"] = "birthDate";
    GuestFormFields["email"] = "email";
    GuestFormFields["phoneNumber"] = "phoneNumber";
    GuestFormFields["mobileNumber"] = "mobileNumber";
    GuestFormFields["street"] = "street";
    GuestFormFields["addressNumber"] = "addressNumber";
    GuestFormFields["postalCode"] = "postalCode";
    GuestFormFields["district"] = "district";
    GuestFormFields["stateCode"] = "stateCode";
    GuestFormFields["city"] = "city";
    GuestFormFields["complement"] = "complement";
    GuestFormFields["countryName"] = "countryName";
    GuestFormFields["cpf"] = "cpf";
    GuestFormFields["civilIdentification"] = "civilIdentification";
    GuestFormFields["birthCertificate"] = "birthCertificate";
    GuestFormFields["passport"] = "passport";
})(GuestFormFields || (GuestFormFields = {}));
