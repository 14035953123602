import { useQuery } from '@app/core/graphql';
import { HotelsDocument } from '@app/data/graphql/generated/hotels.query';
import { DEFAULT_PAGE_LIMIT } from '@app/utils/constant.utils';
import { useSearchHotels } from './search-hotels.use-case';
export const useGetHotels = (params) => {
    var _a;
    const { data, loading, error } = useQuery(HotelsDocument, {
        filters: {
            onlyActive: params.onlyActive,
            onlyInactive: params.onlyInactive,
        },
        input: {
            limit: DEFAULT_PAGE_LIMIT,
            page: +params.page || 1,
        },
    }, {
        onCompleted: (hotelsResult) => { var _a; return (_a = params.onCompleted) === null || _a === void 0 ? void 0 : _a.call(params, hotelsResult); },
    });
    const { data: searchHotels, loading: loadingSearchHotels, error: errorSearchHotels, } = useSearchHotels({
        query: params.query,
        onlyActive: params.onlyActive,
        onlyInactive: params.onlyInactive,
        skip: params.skipSearch || !((_a = params.query) === null || _a === void 0 ? void 0 : _a.length),
    });
    const paginatedSearchHotels = {
        count: searchHotels === null || searchHotels === void 0 ? void 0 : searchHotels.length,
        nodes: searchHotels,
        pageInfo: {
            page: 1,
            limit: searchHotels === null || searchHotels === void 0 ? void 0 : searchHotels.length,
            totalPages: 1,
            hasNextPage: false,
            hasPreviousPage: false,
        },
    };
    return {
        data: searchHotels ? paginatedSearchHotels : data === null || data === void 0 ? void 0 : data.hotels,
        loading: loading || loadingSearchHotels,
        error: error || errorSearchHotels,
    };
};
