import { ActionType, MessageDestination, TeamType } from '@app/data/graphql';
export const flowStrings = {
    itemsChecklist: 'Checklist de tarefas',
    checklistDescription: 'Defina o checklist de tarefas que serão necessários para conclusão de cada etapa.',
    createItem: 'Criar tarefa',
    editItem: 'Editar tarefa',
    searchItem: 'Buscar tarefa',
    itemsTable: {
        itemName: 'Nome',
        edit: 'Editar tarefa',
    },
    bookingSteps: 'Etapas da reserva',
    bookingStepsDescription: 'Defina abaixo o fluxo padrão de etapas das reservas.',
    createStep: 'Criar etapa',
    stepsTable: {
        stepName: 'Nome da etapa',
        status: 'Status',
    },
    sidebar: {
        flow: 'Fluxo operacional',
        steps: 'Etapas da reserva',
        items: 'Checklist de tarefas',
        settings: 'Configuração de prédio',
    },
    bookingItemForm: {
        name: {
            label: 'Nome da tarefa',
            caption: 'Qual tarefa deverá ser cumprida?',
            placeholder: 'Preencher dados da reserva',
        },
        conditionTitle: 'Condição',
        condition: {
            dontApply: 'Não se aplica',
            label: 'Informação de cadastro associada a esta tarefa',
            caption: 'Qual informação que se não preenchida no prazo limite deve disparar a tarefa?',
        },
        triggerTitle: 'Gatilhos da tarefa',
        trigger: {
            days: 'Dias',
            hours: 'Horas',
            defineDeadline: 'Defina o prazo limite',
            timeField: {
                label: 'Tempo',
                caption: 'Defina quanto tempo em minutos',
                placeholder: '31',
            },
            unitField: {
                label: 'Unidade de tempo',
                caption: 'Defina a unidade de tempo em que alguma ação deverá ser feita nesta tarefa',
            },
            whenField: {
                label: 'Quando',
                before: 'Antes',
                after: 'Depois',
                caption: 'Antes ou depois da data de referência',
            },
            deadlineReference: {
                label: 'Referência para o prazo limite',
                caption: 'Referência utilizada para validar o prazo limite.',
            },
        },
        action: {
            title: 'Ação',
            label: 'Tipo da ação',
            caption: 'Qual ação deve ser executada quando o prazo limite for atingido?',
            actionType: {
                [ActionType.Operational]: 'Enviar alerta',
                [ActionType.TriggerMessage]: 'Enviar mensagem',
            },
            type: {
                [TeamType.Operational]: 'Time operacional',
                [TeamType.Service]: 'Time de atendimento',
            },
            typeLabel: 'Time acionado',
        },
        message: {
            title: 'Mensagem',
            portuguese: 'Mensagem em português',
            english: 'Mensagem em inglês',
            insertData: 'Inserir dado',
            caption: 'Use "Inserir dado" para puxar algum dado do cadastro do hóspede ou informações relevantes do hotel',
            portuguesePlaceholder: 'Ex: Olá {{hospede/nome}}, sua hospedagem no hotel {{hotel/nome}} está confirmada!',
            englishPlaceholder: 'Ex: Hello {{hospede/nome}}, your stay at the hotel {{hotel/nome}} is confirmed!',
            whatsAppTemplate: 'Template do WhatsApp',
            whatsAppTemplateCaption: 'Selecione qual a mensagem template de WhatsApp deverá ser enviada',
            destination: {
                title: 'Destino',
                caption: 'Quem receberá esta mensagem?',
            },
            destinationOptions: {
                [MessageDestination.Guest]: 'Hóspede',
                [MessageDestination.BuildingAdminstration]: 'Administração do prédio',
            },
        },
        cancel: 'Cancelar',
        submit: 'Salvar tarefa',
    },
    flowSettings: {
        add: 'Adicionar',
        atLeastOne: 'Selecione pelo menos um item',
        cancel: 'Cancelar',
        chooseTasks: 'Escolha as tarefas que deseja adicionar',
        confirmDeletion: 'Tem certeza que deseja deletar essa tarefa?',
        delete: 'Deletar',
        edit: 'Editar',
        emptyDescription: 'Seu hotel não possui nenhuma etapa',
        emptyTitle: 'Nenhuma etapa cadastrada',
        hotelCode: 'Código do prédio:',
        insertTask: 'Inserir tarefa',
        loadMore: 'Carregar mais',
        noResults: 'Nenhum resultado encontrado',
        searchTasks: 'Buscar tarefa',
        setupFlow: 'Configurar fluxo operacional',
        somethingWentWrong: 'Algo deu errado',
        taskDeleted: 'Tarefa deletada com sucesso',
        tryAgain: 'Tente atualizar a página',
    },
    insertData: 'Inserir dado',
};
