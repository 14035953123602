import styled, { css, keyframes } from 'styled-components';
import { Border, Color, Spacing } from '@atomic/obj.constants';
const backdropCss = css `
  background-color: ${Color.Black};
  opacity: 0.5;
  @media (prefers-reduced-motion: no-preference) {
    transition: all 0.2s ease-out;
  }
`;
export const DialogStyled = styled.dialog `
  border: none;
  width: 100%;
  height: 100%;

  background: none;

  position: fixed;
  top: 0;
  left: 0;

  &:not([open]) {
    /* hide display for polyfill */
    display: none;
  }

  &::backdrop {
    ${backdropCss}
  }

  & + .backdrop {
    /* for polyfill */
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    ${backdropCss}
  }
`;
const slideDown = keyframes `
  0% {
    transform: translate(-50%, -100%);
  }
`;
const fadeIn = keyframes `
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
export const ModalBoxStyled = styled.div `
  position: absolute;
  top: 80px;
  left: 50%;
  max-width: 85%;
  max-height: 80%;
  overflow: auto;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-name: ${fadeIn};
  transform: translate(-50%, 0);
  background-color: ${Color.White};
  border-radius: ${Border.Radius};
  overscroll-behavior: contain;
  width: ${(props) => (props.small ? '448px' : '1024px')};

  @media (prefers-reduced-motion: no-preference) {
    animation-name: ${fadeIn}, ${slideDown};
  }
`;
const modalPadding = css `
  padding: ${(props) => props.small ? `${Spacing.Medium}` : `${Spacing.XLarge} ${Spacing.Large} ${Spacing.Large}`};
`;
export const ModalHeaderStyled = styled.div `
  ${(props) => !props.noPadding && modalPadding}
`;
export const ModalBodyStyled = styled.div `
  ${(props) => !props.noPadding && modalPadding}
  overflow-y: auto;
  max-height: 50vh;
`;
export const ModalFooterStyled = styled.div `
  ${(props) => !props.noPadding && modalPadding}
`;
