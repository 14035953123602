import { Storages } from '@app/core/storage/storages';
import { BaseStore, IStore } from './base-store';
export class LocalStore extends BaseStore {
    constructor(localStorageKey) {
        super();
        this.localStorageKey = localStorageKey;
        this.setState = (stateOrFn) => {
            if (IStore.isSetter(stateOrFn)) {
                this.state = stateOrFn(this.state);
            }
            else {
                this.state = stateOrFn;
            }
            Storages.local.put(this.localStorageKey, this.state);
            this.publish();
        };
        this.getState = () => {
            if (this.state === undefined) {
                this.state = Storages.local.get(this.localStorageKey);
            }
            return this.state;
        };
        this.refreshState = () => {
            this.state = Storages.local.get(this.localStorageKey);
            this.publish();
        };
        this.remove = () => {
            if (Storages.local.get(this.localStorageKey)) {
                this.state = null;
                Storages.local.remove(this.localStorageKey);
                this.publish();
            }
        };
    }
}
