import { pluralize } from '@app/utils/string.utils';
export const homeStrings = {
    bookingId: 'ID HIGS',
    bookingIdPms: 'ID PMS',
    bookingIdSource: 'ID Source',
    bookings: 'Reservas em progresso',
    refresh: 'Atualizar reservas',
    building: 'Prédio',
    createNewBooking: 'Criar nova reserva',
    noDataDescription: 'Você ainda não possui nenhuma reserva em progresso.',
    noDataTitle: 'Nenhuma reserva em progresso',
    noName: 'Sem nome',
    operational: 'Operacional',
    pendencies: 'Pendências',
    service: 'Atendimento',
    alertOperational: 'Alerta operacional',
    alertService: 'Alerta atendimento',
    syncToTotvsFailed: 'Falha no envio de dados à TOTVS',
    status: {
        Cancelled: 'Cancelado',
        Confirmed: 'Confirmado',
        CheckInAttempt: 'Tentativa de check-in',
        CheckInConfirmed: 'Check-in confirmado',
        CheckOutAttempt: 'Tentativa de check-out',
        CheckInNotAccepted: 'Check-in não aceito',
        CheckOutConfirmed: 'Check-out confirmado',
        CheckOutNotAccepted: 'Check-out não aceito',
        ConfirmedWithChanges: 'Confirmado com alterações',
        NoShow: 'No show',
    },
    newBookingsCount: (count) => ` (${count})`,
    somethingWentWrong: 'Algo deu errado',
    tryAgain: 'Tente novamente mais tarde',
    welcome: 'Bem-vindo',
    searchBarPlaceholder: 'Buscar reserva',
    sidebar: {
        title: 'Filtros',
        selectAll: 'Selecionar tudo',
        loadMore: 'Carregar mais',
        bookinsSteps: 'Etapas da reserva',
        buildings: 'Prédios',
        teams: 'Reservas com alertas',
        operational: 'Operacional',
        service: 'Atendimento',
        status: 'Status da reserva',
        cancelled: 'Cancelada',
        noShow: 'No show',
        checkIn: 'Data de Check-in',
        from: 'De',
        to: 'Para',
        startDate: 'Data inicial',
        endDate: 'Data final',
    },
    drawer: {
        bookingDetails: 'Detalhes da reserva',
        chat: 'Chat',
        tasks: 'Tarefas',
        bookingId: 'Id reserva',
        building: 'Prédio',
        deadlineText: (deadline, deadlineFriendlyDescription, isBefore) => `${deadline} ${pluralize({ count: deadline, singular: 'minuto', plural: 'minutos' })} ${isBefore ? 'antes' : 'depois'} de: ${deadlineFriendlyDescription}`,
        createDate: 'Data de criação',
        guestAmount: 'Quantidade de hóspedes',
        childAmount: 'Quantidade de crianças',
        roomNumber: 'Nro do apartamento',
        roomPassword: 'Senha do quarto',
        totalValue: 'Valor total',
        effectiveCheckIn: 'Data check-in realizado',
        effectiveCheckOut: 'Data check-out realizado',
        generalData: 'Informações gerais',
        checkInLabel: 'Data check-in previsto',
        checkOutLabel: 'Data check-out previsto',
        flaggedCheckInLabel: 'Data check-in realizado',
        flaggedCheckOutLabel: 'Data check-out realizado',
        datePlaceholder: 'dd/mm/aaaa',
        validations: 'Validações',
        validationsTypes: {
            GuestData: 'Validação de check-in',
            Payment: 'Validação de pagamento',
        },
        validationsTable: {
            type: 'Tipo',
            controller: 'Operador',
            doneAt: 'Feito em',
        },
        confirmValidationChange: 'Essa ação não poderá ser revertida. Deseja continuar?',
        guestTable: {
            companion: 'Acompanhante',
            edit: 'Editar',
            details: 'Detalhes',
            lastname: 'Sobrenome',
            main: 'Principal',
            name: 'Nome',
            type: 'Tipo',
        },
        tasksTable: {
            task: 'Tarefas',
            deadline: 'Prazo limite',
        },
        paymentsTable: {
            date: 'Data',
            description: 'Descrição',
            value: 'Valor',
        },
        guests: 'Hóspedes',
        payments: 'Pagamentos',
        comments: 'Observações',
        lastUpdate: (lastUpdate) => `Última atualização: ${lastUpdate}`,
        scheduledCheckIn: 'Check-in',
        scheduledCheckOut: 'Check-out',
        tasksChecklist: 'Checklist das tarefas',
    },
    guestDrawer: {
        guestData: 'Informações do hóspede',
        mainGuestData: 'Informações do hóspede principal',
        updatedSuccessfully: 'Hóspede atualizado com sucesso',
    },
    attachmentsSection: {
        addAttachments: 'Adicionar anexos',
        attachments: 'Anexos',
        attachmentDeletedSuccessfully: 'Anexo removido com sucesso',
        attachmentsUploadedSuccessfully: 'Anexos enviados com sucesso',
    },
    guestForm: {
        name: 'Nome do hóspede',
        lastName: 'Sobrenome do hóspede',
        document: {
            cpf: 'CPF',
            civilIdentification: 'RG',
            passport: 'Passaporte',
            birthCertificate: 'Certidão de nascimento',
        },
        birthDate: 'Data de nascimento',
        contact: {
            title: 'Informações de contato',
            mobileNumber: 'Celular',
            phoneNumber: 'Telefone',
            email: 'E-mail',
        },
        address: {
            title: 'Endereço',
            street: 'Rua',
            addressNumber: 'Número',
            postalCode: 'CEP',
            district: 'Bairro',
            stateCode: 'Estado',
            city: 'Cidade',
            complement: 'Complemento',
            countryName: 'País',
        },
        save: 'Salvar hóspede',
        cancel: 'Cancelar',
    },
};
