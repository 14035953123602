import { gql } from '@apollo/client';
import { WhatsappTemplateOverviewFragmentDoc } from './whatsapp-template-overview.fragment';
export const FieldPointerFragmentDoc = gql `
  fragment FieldPointer on FieldPointer {
    id
    friendlyDescription
  }
`;
export const BookingActionItemFragmentDoc = gql `
  fragment BookingActionItem on BookingActionItem {
    id
    type
    destination
    ptMessage
    enMessage
    teamType
    fieldPointer {
      ...FieldPointer
    }
  }
  ${FieldPointerFragmentDoc}
`;
export const BookingItemFragmentDoc = gql `
  fragment BookingItem on BookingItem {
    id
    active
    name
    deadline
    deadlineReference {
      ...FieldPointer
    }
    shouldTriggerBeforeDeadlineReference
    templateId
    whatsappTemplate {
      ...WhatsappTemplateOverview
    }
    action {
      ...BookingActionItem
    }
  }
  ${FieldPointerFragmentDoc}
  ${WhatsappTemplateOverviewFragmentDoc}
  ${BookingActionItemFragmentDoc}
`;
