import { FIELD_WITH_ICON_LEFT_PADDING } from '@atomic/atm.text-field/text-field.component.style';
import { Border, Color } from '@atomic/obj.constants';
import { fieldBorderCss, fieldCss } from '@atomic/obj.form/field.component.styled';
import styled from 'styled-components';
export const SearchBarStyled = styled.input `
  ${fieldCss}
  ${fieldBorderCss}
  padding-left: ${FIELD_WITH_ICON_LEFT_PADDING};
  border: none;
  border-radius: 0;
  border-bottom: ${Border.Width} solid ${Border.Color};

  &:hover,
  &:focus {
    cursor: initial;
    border-bottom: ${Border.Width} solid;
    border-color: ${Color.GrayDark};
    box-shadow: none;
  }
`;
