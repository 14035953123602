import React from 'react';
import { Card } from '@atomic/atm.card';
import { Divider } from '@atomic/atm.divider';
import { ThumbShimmerBoxStyled, TextShimmerBoxStyled } from '@atomic/mol.shimmer/shimmer.component.style';
import { Spacing } from '@atomic/obj.constants';
import { Spacer } from '@atomic/obj.grid';
export const CardShimmer = () => {
    return (React.createElement(Card, { small: true },
        React.createElement(ThumbShimmerBoxStyled, { height: '100px', width: '100%' }),
        React.createElement(Spacer, { "$height": Spacing.Medium }),
        React.createElement(Divider, { variant: 'grayLight' }),
        React.createElement(Spacer, { "$height": Spacing.Small }),
        React.createElement(TextShimmerBoxStyled, { height: '16px' })));
};
