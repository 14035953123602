import { PageName } from '@app/model';
export const SelectContentAnalytics = (params) => {
    return {
        content_type: params.content_type,
        item_name: params.content_id,
        content_id: params.content_id,
        index: params.index,
    };
};
export const LoginAnalytics = (method) => {
    return {
        method,
    };
};
export const screenNameAnalyticsMapper = {
    [PageName.Login]: 'login_page',
    [PageName.SignUp]: 'signup_page',
    [PageName.Home]: 'home_page',
};
