import { useQuery } from '@app/core/graphql';
import { BookingItemsRecordsDocument, } from '@app/data/graphql';
export const useGetBookingItemsRecord = (params) => {
    const { data, loading, error } = useQuery(BookingItemsRecordsDocument, {
        input: {
            bookingId: params.bookingId,
            bookingStepTemplateId: params.bookingStepTemplateId,
        },
    });
    return {
        data,
        loading,
        error,
    };
};
