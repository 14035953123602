import { createGlobalStyle } from 'styled-components';
import { Border, Color } from '@atomic/obj.constants';
import { fieldBorderCss, fieldCss } from '@atomic/obj.form/field.component.styled';
import { InputPlaceholderCss } from '@atomic/atm.typography/typography.component.style';
export const GlobalStyled = createGlobalStyle `
  * {
    box-sizing: border-box;
  }
  body {
    margin: 0;
  }
  a {
    text-decoration: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0.05);
    color: ${Color.Primary};
  }
  #root {
    isolation: isolate;
  }
  
  input:disabled {
    color: ${Color.Gray};
    -webkit-text-fill-color: ${Color.Gray};
    background-color: ${Color.GrayXLight};

    :hover {
      cursor: initial;
      border: ${Border.Width} solid;
      border-color: ${Color.GrayLight};
      box-shadow: none;
    }
  }

  .PhoneInputInput {
    ${fieldBorderCss}

    ::placeholder {
      ${InputPlaceholderCss}
      opacity: 0.5;
    }

    ${fieldCss}
  }
`;
