import { gql } from '@apollo/client';
import { BookingFragmentDoc } from './booking.fragment';
export const UpdateBookingDocument = gql `
  mutation updateBooking($input: UpdateBookingInput!) {
    updateBooking(input: $input) {
      ...Booking
    }
  }
  ${BookingFragmentDoc}
`;
