var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Fade } from '@atomic/obj.animation/animation.component.style';
import { FlashMessageCloseStyled, FlashMessageContentStyled, FlashMessageStyled, } from './flash-message.component.style';
export const FlashIconTypes = {
    alert: FaIcon.FlashAlert,
    info: FaIcon.FlashInfo,
    success: FaIcon.FlashSuccess,
    warning: FaIcon.FlashWarning,
};
const timeToHide = 3 * 1000;
/**
 * This is an uncontrolled flash message.
 * It starts NOT hidden and after "timeToHide" seconds it is removed from DOM.
 * The user can also close it manually
 */
export class FlashMessage extends React.PureComponent {
    constructor(props) {
        super(props);
        this.timeoutList = [];
        this.handleCloseClick = () => {
            this.startRemoveFromDomAnimation();
        };
        this.startCloseTimer = () => {
            if (this.props.autoClose) {
                const timeout = setTimeout(() => this.startRemoveFromDomAnimation(), timeToHide);
                this.timeoutList.push(timeout);
            }
        };
        this.startRemoveFromDomAnimation = () => {
            this.setState({ hidden: true }, () => {
                const timeout = setTimeout(() => this.removeFromDom(), 300);
                this.timeoutList.push(timeout);
            });
        };
        this.removeFromDom = () => {
            this.setState({ remove: true }, () => {
                var _a, _b;
                this.clearTimeoutList();
                (_b = (_a = this.props).onClose) === null || _b === void 0 ? void 0 : _b.call(_a);
            });
        };
        this.clearTimeoutList = () => {
            this.timeoutList.forEach((element) => clearTimeout(element));
        };
        this.state = {
            hidden: false,
            remove: false,
        };
    }
    componentDidMount() {
        this.startCloseTimer();
    }
    componentWillUnmount() {
        this.clearTimeoutList();
    }
    render() {
        const Icon = FlashIconTypes[this.props.type];
        const _a = this.props, { children, dismissible } = _a, other = __rest(_a, ["children", "dismissible"]);
        return this.state.remove ? null : (React.createElement(Fade, { show: !this.state.hidden },
            React.createElement(FlashMessageStyled, Object.assign({}, other),
                React.createElement(Icon, null),
                React.createElement(FlashMessageContentStyled, null, children),
                dismissible ? (React.createElement(FlashMessageCloseStyled, Object.assign({ onClick: this.handleCloseClick }, other),
                    React.createElement(FaIcon.Close, null))) : null)));
    }
}
FlashMessage.defaultProps = {
    type: 'info',
    dismissible: true,
    outlined: false,
    autoClose: true,
};
