import { gql } from '@apollo/client';
import { SimpleBookingFragmentDoc } from './simple-booking.fragment';
export const BookingsDocument = gql `
  query bookings($filters: BookingsFilters) {
    bookings(filters: $filters) {
      ...SimpleBooking
    }
  }
  ${SimpleBookingFragmentDoc}
`;
