import { gql } from '@apollo/client';
export const BookingStepFragmentDoc = gql `
  fragment BookingStep on BookingStep {
    id
    templateId
    active
    name
    stepPosition
  }
`;
