import { gql } from '@apollo/client';
import { UserFragmentDoc } from './user.fragment';
export const UpdateUserDocument = gql `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      ...User
    }
  }
  ${UserFragmentDoc}
`;
