export const generalStrings = {
    navbar: {
        home: 'Reserva em progresso',
        chat: 'Chat de comunicação',
        hotels: 'Prédios',
        users: 'Usuários',
        flow: 'Fluxo operacional',
    },
    userMenu: {
        myAccount: 'Minha conta',
        changePassword: 'Alterar senha',
        logout: 'Sair',
    },
    insertData: 'Inserir dado',
    autocomplete: {
        resultNotFound: 'Nenhum resultado encontrado',
    },
    fileUpload: {
        error: 'Erro ao fazer upload do arquivo',
    },
};
