import { gql } from '@apollo/client';
import { BookingStepFragmentDoc } from './booking-step.fragment';
export const CreateBookingStepTemplateDocument = gql `
  mutation CreateBookingStepTemplate($input: CreateBookingStepTemplateInput!) {
    createBookingStepTemplate(input: $input) {
      ...BookingStep
    }
  }
  ${BookingStepFragmentDoc}
`;
