import { TeamType } from '@app/data/graphql';
import { BrandColor } from '@atomic/obj.constants';
import styled from 'styled-components';
export const mapAlertTypeToColor = {
    [TeamType.Operational]: BrandColor.Apricot,
    [TeamType.Service]: BrandColor.SalmonPink,
};
export const AlertBadgeStyled = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;

  border-radius: 50%;
  background-color: ${({ alertType }) => mapAlertTypeToColor[alertType]};
`;
