import { gql } from '@apollo/client';
import { PageInfoFragmentDoc } from './page-info.fragment';
import { BookingItemFragmentDoc } from './booking-item.fragment';
export const BookingItemsFragmentDoc = gql `
  fragment BookingItems on BookingItems {
    count
    pageInfo {
      ...PageInfo
    }
    nodes {
      ...BookingItem
    }
  }
  ${PageInfoFragmentDoc}
  ${BookingItemFragmentDoc}
`;
