import { gql } from '@apollo/client';
import { UserFragmentDoc } from './user.fragment';
export const CreateUserDocument = gql `
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      ...User
    }
  }
  ${UserFragmentDoc}
`;
