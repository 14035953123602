import { useQuery } from '@app/core/graphql';
import { AllFieldPointersDocument } from '@app/data/graphql';
export const useGetFieldPointers = () => {
    const { data, loading, error } = useQuery(AllFieldPointersDocument);
    return {
        data,
        loading,
        error,
    };
};
