import { useMutation } from '@app/core/graphql/graphql-mutation.hooks';
import { HotelsDocument } from '@app/data/graphql';
import { DuplicateOperationalFlowDocument, } from '@app/data/graphql/generated/duplicate-operational-flow.mutation';
import { dispatchFlashMessage } from '@atomic/mol.flash-message-container/flash-message-container.component';
export function useDuplicateOperationalFlow(params = {}) {
    const [duplicateOperationalFlow, { error, loading }] = useMutation(DuplicateOperationalFlowDocument, {
        onCompleted: (duplicateOperationalFlowResult) => { var _a; return (_a = params.onCompleted) === null || _a === void 0 ? void 0 : _a.call(params, duplicateOperationalFlowResult); },
        onError: (requestError) => {
            var _a;
            dispatchFlashMessage(requestError.message);
            (_a = params.onError) === null || _a === void 0 ? void 0 : _a.call(params, requestError);
        },
        refetchQueries: [HotelsDocument],
    });
    return {
        duplicateOperationalFlow,
        error,
        loading,
    };
}
