import React from 'react';
import { ActionType, MessageDestination, TeamType, } from '@app/data/graphql';
import { BookingItemFormFields } from '@app/model';
import { SelectField } from '@atomic/atm.select';
import { H3, InputCaption } from '@atomic/atm.typography';
import { Spacing } from '@atomic/obj.constants';
import { FormField, Validators } from '@atomic/obj.form';
import { Spacer } from '@atomic/obj.grid';
import { flowStrings } from '../flow.strings';
import { TextAreaWithInsertOption } from './text-area-with-insert-option.component';
const strings = flowStrings.bookingItemForm;
export const ActionBookingItemFormSection = ({ bookingItemAction, loading, messageDataFieldPointers, whatsappTemplates, initialWhatsappTemplate, }) => {
    var _a;
    const [actionType, setActionType] = React.useState((_a = bookingItemAction === null || bookingItemAction === void 0 ? void 0 : bookingItemAction.type) !== null && _a !== void 0 ? _a : ActionType.TriggerMessage);
    return (React.createElement(React.Fragment, null,
        React.createElement(H3, null, strings.action.title),
        React.createElement(FormField, { label: strings.action.label, name: BookingItemFormFields.type, initialValue: actionType, onValueChange: (value) => setActionType(value) },
            React.createElement(SelectField, null,
                React.createElement("option", { value: ActionType.Operational }, strings.action.actionType.Operational),
                React.createElement("option", { value: ActionType.TriggerMessage }, strings.action.actionType.TriggerMessage)),
            React.createElement(InputCaption, null, strings.action.caption)),
        React.createElement(Spacer, { "$height": Spacing.Medium }),
        React.createElement(ActionTypeSection, { loading: loading, messageDataFieldPointers: messageDataFieldPointers, actionType: actionType, bookingItemAction: bookingItemAction, whatsappTemplates: whatsappTemplates, initialWhatsappTemplate: initialWhatsappTemplate })));
};
const ActionTypeSection = ({ actionType, bookingItemAction, loading, messageDataFieldPointers, whatsappTemplates, initialWhatsappTemplate, }) => {
    var _a, _b, _c, _d;
    const [messageDestination, setMessageDestination] = React.useState((_a = bookingItemAction === null || bookingItemAction === void 0 ? void 0 : bookingItemAction.destination) !== null && _a !== void 0 ? _a : MessageDestination.Guest);
    if (actionType === ActionType.Operational) {
        return (React.createElement(FormField, { label: strings.action.typeLabel, name: BookingItemFormFields.teamType, initialValue: (_b = bookingItemAction === null || bookingItemAction === void 0 ? void 0 : bookingItemAction.teamType) !== null && _b !== void 0 ? _b : TeamType.Operational },
            React.createElement(SelectField, null,
                React.createElement("option", { value: TeamType.Operational }, strings.action.type.Operational),
                React.createElement("option", { value: TeamType.Service }, strings.action.type.Service))));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(H3, null, strings.message.title),
        React.createElement(FormField, { label: strings.message.destination.title, name: BookingItemFormFields.destination, initialValue: messageDestination, onValueChange: (value) => setMessageDestination(value) },
            React.createElement(SelectField, null,
                React.createElement("option", { value: MessageDestination.Guest }, strings.message.destinationOptions.Guest),
                React.createElement("option", { value: MessageDestination.BuildingAdminstration }, strings.message.destinationOptions.BuildingAdminstration)),
            React.createElement(InputCaption, null, strings.message.destination.caption)),
        React.createElement(Spacer, { "$height": Spacing.XLarge }),
        React.createElement(FormField, { initialValue: bookingItemAction === null || bookingItemAction === void 0 ? void 0 : bookingItemAction.ptMessage, label: messageDestination === MessageDestination.Guest ? strings.message.portuguese : strings.message.title, name: BookingItemFormFields.ptMessage, validators: [Validators.Required()] },
            React.createElement(TextAreaWithInsertOption, { expandToContentHeight: true, disabled: loading, options: (_c = messageDataFieldPointers === null || messageDataFieldPointers === void 0 ? void 0 : messageDataFieldPointers.map((field) => { var _a; return (_a = field.tag) !== null && _a !== void 0 ? _a : field.friendlyDescription; })) !== null && _c !== void 0 ? _c : [], placeholder: strings.message.portuguesePlaceholder }),
            React.createElement(InputCaption, null, strings.message.caption)),
        React.createElement(Spacer, { "$height": Spacing.XLarge }),
        messageDestination === MessageDestination.Guest && (React.createElement(FormField, { initialValue: bookingItemAction === null || bookingItemAction === void 0 ? void 0 : bookingItemAction.enMessage, label: strings.message.english, name: BookingItemFormFields.enMessage, validators: [Validators.Required()] },
            React.createElement(TextAreaWithInsertOption, { expandToContentHeight: true, options: (_d = messageDataFieldPointers === null || messageDataFieldPointers === void 0 ? void 0 : messageDataFieldPointers.map((field) => { var _a; return (_a = field.tag) !== null && _a !== void 0 ? _a : field.friendlyDescription; })) !== null && _d !== void 0 ? _d : [], placeholder: strings.message.englishPlaceholder }),
            React.createElement(InputCaption, null, strings.message.caption))),
        !!(whatsappTemplates === null || whatsappTemplates === void 0 ? void 0 : whatsappTemplates.length) && (React.createElement(React.Fragment, null,
            React.createElement(Spacer, { "$height": Spacing.XLarge }),
            React.createElement(FormField, { label: strings.message.whatsAppTemplate, name: BookingItemFormFields.whatsappTemplateId, initialValue: initialWhatsappTemplate === null || initialWhatsappTemplate === void 0 ? void 0 : initialWhatsappTemplate.id },
                React.createElement(SelectField, null,
                    React.createElement("option", { value: null }, strings.condition.dontApply), whatsappTemplates === null || whatsappTemplates === void 0 ? void 0 :
                    whatsappTemplates.map((template) => {
                        return (React.createElement("option", { key: template.id, value: template.id }, template.name));
                    })),
                React.createElement(InputCaption, null, strings.message.whatsAppTemplateCaption)),
            React.createElement(Spacer, { "$height": Spacing.XLarge })))));
};
