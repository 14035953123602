import React from 'react';
import { CompanyRole, Permission } from '@app/data/graphql/base-schema';
import { onlyDigits } from '@app/utils/string.utils';
import { Button } from '@atomic/atm.button';
import { CheckboxField } from '@atomic/atm.checkbox';
import { RadioField } from '@atomic/atm.radio';
import { TextField } from '@atomic/atm.text-field';
import { H2, InputCaption } from '@atomic/atm.typography';
import { Spacing } from '@atomic/obj.constants';
import { Form, FormField, Validators } from '@atomic/obj.form';
import { Column, Row, Spacer } from '@atomic/obj.grid';
import { UsersRoutes } from '../users.route';
import { usersStrings } from '../users.string';
import { useUserStore } from '@app/stores';
export var UserFormFields;
(function (UserFormFields) {
    UserFormFields["companyRole"] = "companyRole";
    UserFormFields["email"] = "email";
    UserFormFields["name"] = "name";
    UserFormFields["whatsapp"] = "whatsapp";
    UserFormFields["permissions"] = "permissions";
})(UserFormFields || (UserFormFields = {}));
const strings = usersStrings.userForm;
export const UserForm = ({ onSubmit, onDelete, user, loading, deleteLoading }) => {
    var _a;
    const [{ id: userId }] = useUserStore();
    const [whatsapp, setWhatsapp] = React.useState(user === null || user === void 0 ? void 0 : user.whatsapp);
    const handleSubmit = (formData) => {
        if (formData.hasError) {
            return;
        }
        onSubmit(Object.assign(Object.assign({}, formData.data), { whatsapp: onlyDigits(whatsapp) }));
    };
    return (React.createElement(Form, { onSubmit: handleSubmit },
        React.createElement(H2, null, strings.collaboratorInfo),
        React.createElement(Spacer, { "$height": Spacing.Medium }),
        React.createElement(Row, { gap: Spacing.XLarge },
            React.createElement(Column, { flex: 1 },
                React.createElement(FormField, { label: strings.formFields.name, name: UserFormFields.name, initialValue: user === null || user === void 0 ? void 0 : user.name, validators: [Validators.Required(), Validators.MaxLength()] },
                    React.createElement(TextField, { placeholder: strings.placeholder.name })),
                React.createElement(Spacer, { "$height": Spacing.XLarge }),
                React.createElement(FormField, { label: strings.formFields.email, name: UserFormFields.email, initialValue: user === null || user === void 0 ? void 0 : user.email, validators: [Validators.Required(), Validators.EmailRegex()] },
                    React.createElement(TextField, { placeholder: strings.placeholder.email }))),
            React.createElement(Column, { flex: 1 },
                React.createElement(FormField, { label: strings.formFields.telephone, name: UserFormFields.whatsapp, initialValue: onlyDigits(user === null || user === void 0 ? void 0 : user.whatsapp), onValueChange: (value) => setWhatsapp(value), validators: [whatsapp && Validators.PhoneRegex()].filter(Boolean) },
                    React.createElement(TextField, { placeholder: strings.placeholder.telephone, variant: 'cel-phone' }),
                    React.createElement(InputCaption, null, strings.formFields.contactViaWhatsApp)))),
        React.createElement(Spacer, { "$height": Spacing.XLarge }),
        React.createElement(H2, null, strings.role),
        React.createElement(Spacer, { "$height": Spacing.Medium }),
        React.createElement(FormField, { name: UserFormFields.companyRole, initialValue: user === null || user === void 0 ? void 0 : user.companyRole, validators: [Validators.Required(strings.chooseARole)] },
            React.createElement(Row, { alignItems: 'center', gap: Spacing.Medium }, Object.values(CompanyRole).map((role) => {
                return (React.createElement(RadioField, { key: role, id: role }, usersStrings.roles[role]));
            }))),
        React.createElement(Spacer, { "$height": Spacing.Large }),
        React.createElement(H2, null, strings.collaboratorPermissions),
        React.createElement(Spacer, { "$height": Spacing.Medium }),
        React.createElement(Row, null,
            React.createElement(Column, null,
                React.createElement(FormField, { name: UserFormFields.permissions, initialValue: (_a = user === null || user === void 0 ? void 0 : user.permissions) !== null && _a !== void 0 ? _a : [] }, Object.values(Permission).map((permission) => {
                    return (React.createElement(CheckboxField, { key: permission, id: permission }, usersStrings.permissions[permission]));
                })))),
        React.createElement(Spacer, { "$height": Spacing.XXLarge }),
        React.createElement(Row, { justifyContent: 'flex-end', gap: Spacing.Medium },
            !!user && user.id !== userId && (React.createElement(Column, { flex: 1, alignItems: 'flex-start' },
                React.createElement(Button, { variant: 'alert', onClick: onDelete, loading: deleteLoading }, strings.delete))),
            React.createElement(Column, null,
                React.createElement(Button, { variant: 'secondary', to: UsersRoutes.Base }, strings.cancel)),
            React.createElement(Column, null,
                React.createElement(Button, { variant: 'callToAction', type: 'submit', loading: loading }, strings.publish)))));
};
