import styled, { css } from 'styled-components';
import { Border, Color, Shadow, Spacing, VerticalNavbarWidth, ZIndex } from '@atomic/obj.constants';
export const NavbarStyled = styled.div `
  display: flex;
  position: fixed;
  flex-direction: column;
  background-color: ${Color.Primary};
  width: ${VerticalNavbarWidth}px;
  top: 0;
  left: 0;
  height: 100vh;
  border-top-right-radius: ${Border.RadiusLarge};
  border-bottom-right-radius: ${Border.RadiusLarge};
  padding: ${Spacing.Medium};
  gap: ${Spacing.Medium};

  box-shadow: ${Shadow.Medium};
  z-index: ${ZIndex.top};
`;
export const NavbarWrapperStyled = styled.nav `
  margin-left: ${VerticalNavbarWidth}px;
`;
export const NavbarTabWrapperStyled = styled.div `
  display: flex;
  justify-content: center;
  border-radius: ${Border.RadiusLarge};
  width: 40px;
  height: 24px;

  ${({ active }) => active &&
    css `
      background-color: ${Color.Accent};
    `}

  transition: background-color 0.5s ease;

  @media (prefers-reduced-motion) {
    transition: none;
  }
`;
export const NavbarTabStyled = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: ${Border.RadiusLarge};
  padding: ${Spacing.XSmall};

  :hover {
    overflow: hidden;
    background-color: ${Color.PrimaryDark};
  }

  transition: background-color 0.3s linear;

  @media (prefers-reduced-motion) {
    transition: none;
  }
`;
