import { useMutation } from '@app/core/graphql/graphql-mutation.hooks';
import { CreateAvatarUrlsDocument, } from '@app/data/graphql';
import { accountStrings } from '@app/modules/account/account.strings';
import { useUserStore } from '@app/stores/user-store';
import { AVATAR_ALLOWED_SIZE_IN_BYTES, AVATAR_ALLOWED_TYPES } from '@app/utils/constant.utils';
import { useUploadFile } from '@app/domain/file-upload.use-case';
import { dispatchFlashMessage } from '@atomic/mol.flash-message-container';
import React from 'react';
const strings = accountStrings;
export function useUploadAvatar() {
    const [user, setUser] = useUserStore();
    const [error, setError] = React.useState();
    const file = React.useRef();
    const filePublicUrl = React.useRef();
    const { handleUploadFile, loading: uploadFileLoading } = useUploadFile({
        onCompleted: () => {
            setUser(Object.assign(Object.assign({}, user), { avatarUrl: filePublicUrl.current }));
            dispatchFlashMessage(strings.pictureSaved, 'success');
        },
        onError: (requestError) => {
            var _a;
            dispatchFlashMessage((_a = requestError === null || requestError === void 0 ? void 0 : requestError.message) !== null && _a !== void 0 ? _a : strings.errorUploadingAvatar);
        },
    });
    const [createAvatarUrl, { loading }] = useMutation(CreateAvatarUrlsDocument, {
        onCompleted: (result) => {
            var _a, _b;
            filePublicUrl.current = (_a = result.createAvatarUrls) === null || _a === void 0 ? void 0 : _a.publicUrl;
            handleUploadFile(file.current, (_b = result.createAvatarUrls) === null || _b === void 0 ? void 0 : _b.signedUrl);
        },
        onError: (requestError) => {
            dispatchFlashMessage(requestError.message);
        },
    });
    const handleUploadAvatar = (inputFile) => {
        if (!AVATAR_ALLOWED_TYPES.includes(inputFile.type)) {
            setError(strings.pictureFormat);
            return;
        }
        if (inputFile.size > AVATAR_ALLOWED_SIZE_IN_BYTES) {
            setError(strings.pictureSize);
            return;
        }
        setError(null);
        file.current = inputFile;
        createAvatarUrl({ filename: inputFile.name });
    };
    return {
        uploadAvatar: handleUploadAvatar,
        error,
        loading: loading || uploadFileLoading,
    };
}
