import * as React from 'react';
import { FormFieldContext } from '@atomic/obj.form';
import { CheckboxCheckedStyled, CheckboxFieldBulletStyled, CheckboxFieldBulletWrapperStyled, CheckboxFieldStyled, CheckboxFieldTextStyled, CheckboxUncheckedStyled, } from './checkbox-field.component.style';
export const CheckboxField = (props) => {
    var _a, _b, _c, _d;
    const [checked, setChecked] = React.useState((_b = (_a = props.initialChecked) !== null && _a !== void 0 ? _a : props.checked) !== null && _b !== void 0 ? _b : false);
    const formFieldConsumer = React.useContext(FormFieldContext);
    const name = (_d = (_c = formFieldConsumer === null || formFieldConsumer === void 0 ? void 0 : formFieldConsumer.name) !== null && _c !== void 0 ? _c : props.name) !== null && _d !== void 0 ? _d : '';
    const fieldId = name + '_' + props.id;
    const isControlled = React.useCallback(() => {
        return props.checked !== undefined;
    }, [props.checked]);
    React.useEffect(() => {
        if (isControlled()) {
            setChecked(props.checked);
        }
    }, [props.checked, isControlled]);
    React.useEffect(() => {
        if (isControlled() && props.initialChecked !== undefined) {
            throw new Error('Use either the initialChecked prop, or the checked prop, but not both');
        }
        if (formFieldConsumer) {
            if (isControlled() || props.initialChecked !== undefined) {
                throw new Error('Please, use value props in <Form.Field> instead of <CheckboxField> component.');
            }
        }
    }, [formFieldConsumer, isControlled, props.initialChecked]);
    const getCurrentValue = React.useCallback(() => {
        var _a, _b;
        if (formFieldConsumer === null || formFieldConsumer === void 0 ? void 0 : formFieldConsumer.value) {
            return ((_b = (_a = formFieldConsumer.value) === null || _a === void 0 ? void 0 : _a.indexOf) === null || _b === void 0 ? void 0 : _b.call(_a, props.id)) > -1;
        }
        return checked;
    }, [checked, formFieldConsumer === null || formFieldConsumer === void 0 ? void 0 : formFieldConsumer.value, props.id]);
    React.useEffect(() => {
        if (formFieldConsumer) {
            const value = getCurrentValue();
            setChecked(value);
        }
    }, [formFieldConsumer, getCurrentValue]);
    const handlePress = () => {
        var _a, _b;
        (_a = props.onClick) === null || _a === void 0 ? void 0 : _a.call(props, props.id);
        const checkToggle = !getCurrentValue();
        (_b = props.onValueChange) === null || _b === void 0 ? void 0 : _b.call(props, props.id, checkToggle);
        if (isControlled()) {
            return;
        }
        formFieldConsumer === null || formFieldConsumer === void 0 ? void 0 : formFieldConsumer.onValueChange([props.id], checkToggle);
        setChecked(checkToggle);
    };
    return (React.createElement(CheckboxFieldStyled, { disabled: props.disabled },
        React.createElement(CheckboxFieldBulletWrapperStyled, { onClick: handlePress },
            React.createElement(CheckboxFieldBulletStyled, { id: fieldId, name: fieldId, type: 'checkbox', checked: checked, value: props.id }),
            React.createElement(CheckboxCheckedStyled, { "aria-hidden": 'true', disabled: props.disabled }),
            React.createElement(CheckboxUncheckedStyled, { "aria-hidden": 'true', disabled: props.disabled })),
        React.createElement(CheckboxFieldTextStyled, { size: props.size, variant: props.variant, strikethrough: props.strikethrough, htmlFor: fieldId }, props.children)));
};
