import React from 'react';
import { useQuery } from '@app/core/graphql';
import { BookingItemsTemplateDocument, } from '@app/data/graphql';
import { DEFAULT_PAGE_LIMIT } from '@app/utils/constant.utils';
export const useGetBookingItemsTemplate = (params) => {
    var _a, _b;
    const [bookingItemsTemplate, setBookingItemsTemplate] = React.useState([]);
    const { data, loading, error } = useQuery(BookingItemsTemplateDocument, {
        input: { limit: DEFAULT_PAGE_LIMIT, hotelId: +((_a = params.input) === null || _a === void 0 ? void 0 : _a.hotelId), page: +((_b = params.input) === null || _b === void 0 ? void 0 : _b.page) || 1 },
        filters: params.filters,
    }, {
        onCompleted: (response) => {
            setBookingItemsTemplate((cur) => [...cur, ...response.bookingItemsTemplate.nodes]);
        },
    });
    return {
        data: data === null || data === void 0 ? void 0 : data.bookingItemsTemplate,
        loading,
        error,
        allBookingItemsTemplate: bookingItemsTemplate,
    };
};
