import * as React from 'react';
import { Ellipsed } from '@atomic/atm.typography';
import { Spacing } from '@atomic/obj.constants';
import { Column, Row } from '@atomic/obj.grid';
import { BadgeStyled, BadgeTextStyled } from './badge.component.style';
export const Badge = (props) => {
    const showText = () => {
        return props.ellipsis ? React.createElement(Ellipsed, null, props.text) : props.text;
    };
    return (React.createElement(BadgeStyled, Object.assign({}, props),
        React.createElement(BadgeTextStyled, Object.assign({}, props), props.icon ? (React.createElement(Row, { alignItems: 'center', gap: Spacing.XSmall },
            React.createElement(Column, null, props.icon),
            showText())) : (showText()))));
};
