import { gql } from '@apollo/client';
import { UserFragmentDoc } from './user.fragment';
import { PageInfoFragmentDoc } from './page-info.fragment';
export const PaginatedUsersFragmentDoc = gql `
  fragment PaginatedUsers on PaginatedUsers {
    nodes {
      ...User
      isFirstLogin
    }
    count
    pageInfo {
      ...PageInfo
    }
  }
  ${UserFragmentDoc}
  ${PageInfoFragmentDoc}
`;
