import { AppIcon } from '@app/components/atm.app-icon';
import { ContentWrapper } from '@app/components/atm.content-wrapper/content-wrapper.component';
import { ErrorPlaceholder } from '@app/components/mol.placeholder/error-placeholder.component';
import { Autocomplete } from '@app/components/org.autocomplete/autocomplete.component';
import { useGetBookingItemsTemplate } from '@app/domain/booking/get-booking-items-template.use-case';
import { useSearchBookingItemsTemplateByName } from '@app/domain/booking/search-booking-items-template-by-name.use-case';
import { PageParams } from '@app/utils/string.utils';
import { Button } from '@atomic/atm.button';
import { PaginationComponent } from '@atomic/atm.pagination';
import { Body, H1 } from '@atomic/atm.typography';
import { Spacing } from '@atomic/obj.constants';
import { Column, Grid, Row, Spacer } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { BookingItemsTable } from './components/items-table.component';
import { FlowRoutes } from './flow.routes';
import { flowStrings } from './flow.strings';
import { ItemsTableShimmer } from './components/items-table.page.shimmer';
const INITIAL_PAGE = '1';
const strings = flowStrings;
const BookingItemsPage = () => {
    var _a, _b, _c, _d, _e;
    const [params, setParams] = useSearchParams({ [PageParams]: INITIAL_PAGE });
    const [term, setTerm] = React.useState('');
    const navigate = useNavigate();
    const { data, loading, error } = useGetBookingItemsTemplate({ input: { page: params.get(PageParams) } });
    const { data: searchData, loading: searchDataLoading } = useSearchBookingItemsTemplateByName({ name: term });
    const handleOptionClick = (autocompleteItem) => {
        navigate(autocompleteItem.id.toString(), { state: { breadcrumbKey: autocompleteItem.name } });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ContentWrapper, null,
            React.createElement(H1, null, strings.itemsChecklist),
            React.createElement(Body, null, strings.checklistDescription),
            React.createElement(Spacer, { "$height": Spacing.XXLarge }),
            React.createElement(Row, { alignItems: 'center', gap: Spacing.Medium },
                React.createElement(Column, { flex: 1, justifyContent: 'flex-start' },
                    React.createElement(Autocomplete, { items: (_a = searchData === null || searchData === void 0 ? void 0 : searchData.searchBookingItemsTemplateByName) === null || _a === void 0 ? void 0 : _a.map((item) => ({ name: item.name, id: item.id })), onTermChange: (value) => setTerm(value), onOptionClick: handleOptionClick, loading: searchDataLoading, placeholder: strings.searchItem })),
                React.createElement(Column, null,
                    React.createElement(Button, { variant: 'primary', to: FlowRoutes.CreateItem, navigationState: { totalPages: (_b = data === null || data === void 0 ? void 0 : data.pageInfo) === null || _b === void 0 ? void 0 : _b.totalPages } },
                        React.createElement(AppIcon.Add, { variant: 'white', size: 'large' }),
                        strings.createItem)))),
        React.createElement(Spacer, { "$height": Spacing.XXLarge }),
        React.createElement(Grid, null,
            React.createElement(LoadingState, { data: !!((_c = data === null || data === void 0 ? void 0 : data.nodes) === null || _c === void 0 ? void 0 : _c.length), loading: loading, error: !!error },
                React.createElement(LoadingState.Shimmer, null,
                    React.createElement(ItemsTableShimmer, null)),
                React.createElement(LoadingState.NoData, null,
                    React.createElement(ErrorPlaceholder, { type: 'empty' })),
                React.createElement(LoadingState.Error, null,
                    React.createElement(ErrorPlaceholder, { type: 'notFound' })),
                (data === null || data === void 0 ? void 0 : data.nodes) && React.createElement(BookingItemsTable, { items: data.nodes }),
                React.createElement(PaginationComponent, { current: (_d = data === null || data === void 0 ? void 0 : data.pageInfo) === null || _d === void 0 ? void 0 : _d.page, onPageChange: (value) => setParams({ page: value === null || value === void 0 ? void 0 : value.toString() }), siblingCount: 2, total: (_e = data === null || data === void 0 ? void 0 : data.pageInfo) === null || _e === void 0 ? void 0 : _e.totalPages }),
                React.createElement(Spacer, { "$height": Spacing.XXLarge })))));
};
export default BookingItemsPage;
