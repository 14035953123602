import { gql } from '@apollo/client';
import { AuthorFragmentDoc } from './author.fragment';
export const BookingValidationFragmentDoc = gql `
  fragment BookingValidation on BookingValidation {
    author {
      ...Author
    }
    completedAt
    id
    type
  }
  ${AuthorFragmentDoc}
`;
