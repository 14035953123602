import { useMutation } from '@app/core/graphql/graphql-mutation.hooks';
import { UpdateGuestDocument } from '@app/data/graphql';
import { dispatchFlashMessage } from '@atomic/mol.flash-message-container/flash-message-container.component';
import { mapGuestInput } from './guest-input.mapper';
export function useUpdateGuest(params) {
    const [updateGuest, { error, loading }] = useMutation(UpdateGuestDocument, {
        onCompleted: (updateGuestResult) => { var _a; return (_a = params.onCompleted) === null || _a === void 0 ? void 0 : _a.call(params, updateGuestResult); },
        onError: (requestError) => {
            var _a;
            dispatchFlashMessage(requestError.message);
            (_a = params.onError) === null || _a === void 0 ? void 0 : _a.call(params, requestError);
        },
    });
    const handleUpdate = (input) => {
        updateGuest({
            input: Object.assign({}, mapGuestInput(input, params.guestId, +params.bookingId)),
        });
    };
    return {
        updateGuest: handleUpdate,
        error,
        loading,
    };
}
