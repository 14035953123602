import * as React from 'react';
import { TabItemStyled, TabItemTextStyled, TabStyled } from './tab.component.style';
const TabItem = (props) => {
    const handleClick = () => {
        var _a;
        (_a = props.onTap) === null || _a === void 0 ? void 0 : _a.call(props);
    };
    return (React.createElement(TabItemStyled, { onClick: handleClick },
        React.createElement(TabItemTextStyled, { active: props.isActiveItem }, props.children)));
};
export const Tab = (props) => {
    const [activeIndex, setActiveIndex] = React.useState(props.initialIndex);
    const handleTap = React.useCallback((index) => {
        var _a;
        if (index !== activeIndex) {
            setActiveIndex(index);
            (_a = props.onIndexChanged) === null || _a === void 0 ? void 0 : _a.call(props, index);
        }
    }, [activeIndex, props]);
    const filteredChildren = React.useMemo(() => React.Children.map(props.children, (child, index) => {
        if (child.type === TabItem) {
            return React.cloneElement(child, {
                isActiveItem: index === activeIndex,
                onTap: () => handleTap(index),
            });
        }
        throw new Error('Childrens type must be the same as the TabItem type');
    }), [props.children, activeIndex, handleTap]);
    return React.createElement(TabStyled, null, filteredChildren);
};
Tab.defaultProps = {
    initialIndex: 0,
};
Tab.Item = TabItem;
