import { gql } from '@apollo/client';
import { BookingFragmentDoc } from './booking.fragment';
export const AddBookingValidationDocument = gql `
  mutation addBookingValidation($input: AddBookingValidationInput!) {
    addBookingValidation(input: $input) {
      ...Booking
    }
  }
  ${BookingFragmentDoc}
`;
