import styled from 'styled-components';
import { FaIcon } from '@atomic/atm.fa-icon';
import { InputValue } from '@atomic/atm.typography';
import { clickableFocusCss } from '@atomic/mol.focus/focus.style';
import { Border, Color, Spacing } from '@atomic/obj.constants';
import { highlightStyle } from '@atomic/obj.mixin';
const radioSize = 24;
export const RadioFieldStyled = styled.span `
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  margin-bottom: ${(props) => (props.mb ? Spacing.Medium : 0)};
  ${(props) => !props.noBorder && `${Border.Width} solid ${props.checked ? Color.GrayDark : Border.Color};`}
  border-radius: ${Border.Radius};
  ${(props) => !props.noBorder && `background-color: ${props.checked ? Color.Accessory : 'transparent'};`}
  padding: ${Spacing.Medium};
  ${(props) => (props.expanded ? 'flex: 1;' : undefined)}

  &:focus-within {
    ${(props) => !props.noBg && clickableFocusCss}
  }

  ${(props) => props.disabled &&
    `
    opacity: 0.5;
    pointer-events: none;
  `}
`;
export const RadioFieldLabelStyled = styled(InputValue) `
  line-height: ${radioSize}px !important;
  padding-left: 36px;
  cursor: pointer;
  ${(props) => (props.expanded ? 'flex: 1;' : undefined)}
`;
export const RadioFieldContentStyled = styled.div `
  margin-left: ${Spacing.XLarge};
  ${highlightStyle};
`;
export const RadioCheckedStyled = styled(FaIcon.DotCircle).attrs({ size: 'lg' }) `
  position: absolute;
  color: ${Color.Accent};
  background-color: ${Color.Black};
  border-radius: 50%;
`;
export const RadioUncheckedStyled = styled(FaIcon.Circle).attrs({ size: 'lg' }) `
  position: absolute;
  color: ${Color.Black};
`;
export const RadioFieldInputStyled = styled.input `
  position: absolute;
  opacity: 0; /* keeping the input available on the accessibility tree */
`;
