import { initializeApp } from 'firebase/app';
// eslint-disable-next-line import/no-unassigned-import
import 'firebase/analytics';
import { Token } from 'typedi';
import { getAnalytics } from 'firebase/analytics';
export const FIREBASE_ANALYTICS = new Token('FIREBASE_ANALYTICS_TOKEN');
export const FIREBASE_USER_ID = new Token('FIREBASE_USER_ID_TOKEN');
export class FirebaseAnalyticsService {
    constructor(config) {
        const firebaseApp = initializeApp(config);
        this.analytics = getAnalytics(firebaseApp);
    }
}
