export var TimeUnit;
(function (TimeUnit) {
    TimeUnit["hours"] = "hours";
    TimeUnit["days"] = "days";
})(TimeUnit || (TimeUnit = {}));
export var MessageLanguage;
(function (MessageLanguage) {
    MessageLanguage["pt"] = "pt";
    MessageLanguage["en"] = "en";
})(MessageLanguage || (MessageLanguage = {}));
export var BookingItemFormFields;
(function (BookingItemFormFields) {
    BookingItemFormFields["deadlineUnit"] = "deadlineUnit";
    BookingItemFormFields["name"] = "name";
    BookingItemFormFields["active"] = "active";
    BookingItemFormFields["deadline"] = "deadline";
    BookingItemFormFields["deadlineReferenceId"] = "deadlineReferenceId";
    BookingItemFormFields["destination"] = "destination";
    BookingItemFormFields["ptMessage"] = "ptMessage";
    BookingItemFormFields["enMessage"] = "enMessage";
    BookingItemFormFields["conditionFieldId"] = "conditionFieldId";
    BookingItemFormFields["shouldTriggerBeforeDeadlineReference"] = "shouldTriggerBeforeDeadlineReference";
    BookingItemFormFields["teamType"] = "teamType";
    BookingItemFormFields["type"] = "type";
    BookingItemFormFields["whatsappTemplateId"] = "whatsappTemplateId";
})(BookingItemFormFields || (BookingItemFormFields = {}));
