import styled, { css } from 'styled-components';
import { Border, Color, MediaQuery, Shadow, Spacing } from '@atomic/obj.constants';
export const CardStyled = styled.div `
  max-width: 480px;
  padding: ${Spacing.XLarge};
  background-color: ${Color.White};
  border-radius: ${Border.RadiusLarge};
  box-shadow: ${Shadow.Small};

  ${(props) => props.vGrow &&
    css `
      min-height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    `}

  ${(props) => props.small
    ? css `
          padding: ${Spacing.Large} ${Spacing.Large} ${Spacing.Medium};
        `
    : css `
          ${MediaQuery.md} {
            padding: ${Spacing.XXLarge} ${Spacing.XXLarge};
          }
        `}

  ${({ onClick }) => !!onClick && `cursor: pointer;`}

  ${MediaQuery.md} {
    max-width: 568px;
  }
`;
