import * as React from 'react';
import { PaginationComponent } from './pagination.component';
export default {
    title: 'Atomic/Atoms/Pagination',
    component: PaginationComponent,
    args: {
        current: 1,
        total: 15,
        siblingCount: 2,
    },
};
export const Pagination = (args) => {
    const [current, setCurrent] = React.useState(args.current);
    return (React.createElement(PaginationComponent, Object.assign({}, args, { current: current, 
        // eslint-disable-next-line react/jsx-no-bind
        onPageChange: (index) => setCurrent(index) })));
};
