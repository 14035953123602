import { gql } from '@apollo/client';
import { UserFragmentDoc } from './user.fragment';
export const RefreshTokenDocument = gql `
  mutation RefreshToken($input: RefreshTokenInput!) {
    refreshToken(input: $input) {
      token
      refreshToken
      user {
        ...User
      }
    }
  }
  ${UserFragmentDoc}
`;
