import { useMutation } from '@app/core/graphql/graphql-mutation.hooks';
import { CreateUserDocument, } from '@app/data/graphql/generated/create-user.mutation';
import { dispatchFlashMessage } from '@atomic/mol.flash-message-container/flash-message-container.component';
export function useCreateUser(params) {
    const [createUser, { error, loading }] = useMutation(CreateUserDocument, {
        onCompleted: (createUserResult) => { var _a; return (_a = params.onCompleted) === null || _a === void 0 ? void 0 : _a.call(params, createUserResult); },
        onError: (requestError) => {
            var _a;
            dispatchFlashMessage(requestError.message);
            (_a = params.onError) === null || _a === void 0 ? void 0 : _a.call(params, requestError);
        },
    });
    return {
        createUser,
        error,
        loading,
    };
}
