import React from 'react';
import { createPortal } from 'react-dom';
import { useOutletContext } from 'react-router-dom';
import { AppIcon } from '../atm.app-icon';
import { SideDrawerContentStyled, SideDrawerLeftIconStyled, SideDrawerStyled } from './side-drawer.component.style';
const portal = document.getElementById('portal');
export const SideDrawer = ({ level = 1, children }) => {
    const { active, handleClick } = useOutletContext();
    return createPortal(React.createElement(SideDrawerStyled, { className: `fadeIn ${!active && 'fadeOut'} level-${level}`, level: level, onClick: (e) => e.stopPropagation() },
        React.createElement(SideDrawerLeftIconStyled, { level: level, onClick: handleClick },
            React.createElement(AppIcon.ChevronRight, { variant: 'grayDark', size: 'large' })),
        React.createElement(SideDrawerContentStyled, null, children)), portal);
};
