const result = {
    possibleTypes: {
        WhatsappTemplateComponent: [
            'WhatsappTemplateComponentBody',
            'WhatsappTemplateComponentButton',
            'WhatsappTemplateComponentHeader',
        ],
    },
};
export default result;
export const namedOperations = {
    Query: {
        BookingAttachments: 'BookingAttachments',
        bookingItemTemplate: 'bookingItemTemplate',
        BookingItemsRecords: 'BookingItemsRecords',
        bookingItemsTemplate: 'bookingItemsTemplate',
        bookingItems: 'bookingItems',
        bookingStepsTemplate: 'bookingStepsTemplate',
        bookingSteps: 'bookingSteps',
        booking: 'booking',
        bookings: 'bookings',
        fieldPointers: 'fieldPointers',
        allFieldPointers: 'allFieldPointers',
        Hotel: 'Hotel',
        Hotels: 'Hotels',
        searchBookingItemsTemplateByName: 'searchBookingItemsTemplateByName',
        SearchHotels: 'SearchHotels',
        User: 'User',
        Users: 'Users',
        whatsappTemplates: 'whatsappTemplates',
    },
    Mutation: {
        addBookingValidation: 'addBookingValidation',
        ChangePassword: 'ChangePassword',
        configOperationalFlow: 'configOperationalFlow',
        createAvatarUrls: 'createAvatarUrls',
        createBookingAttachmentsUrls: 'createBookingAttachmentsUrls',
        createBookingItemTemplate: 'createBookingItemTemplate',
        CreateBookingStepTemplate: 'CreateBookingStepTemplate',
        createBooking: 'createBooking',
        createGuest: 'createGuest',
        CreateHotel: 'CreateHotel',
        CreateUser: 'CreateUser',
        DeleteBookingAttachments: 'DeleteBookingAttachments',
        deleteBookingItems: 'deleteBookingItems',
        deleteGuest: 'deleteGuest',
        DeleteUser: 'DeleteUser',
        duplicateOperationalFlow: 'duplicateOperationalFlow',
        ForgotPassword: 'ForgotPassword',
        Login: 'Login',
        RefreshToken: 'RefreshToken',
        ResetPassword: 'ResetPassword',
        updateBookingItemTemplate: 'updateBookingItemTemplate',
        updateBookingItem: 'updateBookingItem',
        UpdateBookingStepsPositions: 'UpdateBookingStepsPositions',
        UpdateBookingStepTemplate: 'UpdateBookingStepTemplate',
        UpdateBookingStep: 'UpdateBookingStep',
        updateBooking: 'updateBooking',
        updateGuest: 'updateGuest',
        UpdateHotel: 'UpdateHotel',
        UpdateUser: 'UpdateUser',
        ValidatePasswordCode: 'ValidatePasswordCode',
    },
    Fragment: {
        Attachment: 'Attachment',
        Author: 'Author',
        BookingAlertPreview: 'BookingAlertPreview',
        BookingItemRecord: 'BookingItemRecord',
        BookingItem: 'BookingItem',
        BookingActionItem: 'BookingActionItem',
        FieldPointer: 'FieldPointer',
        BookingItems: 'BookingItems',
        BookingStep: 'BookingStep',
        BookingValidation: 'BookingValidation',
        Booking: 'Booking',
        GuestBilling: 'GuestBilling',
        Guest: 'Guest',
        GuestDocument: 'GuestDocument',
        GuestContact: 'GuestContact',
        GuestAddress: 'GuestAddress',
        Hotel: 'Hotel',
        HotelAddress: 'HotelAddress',
        PageInfo: 'PageInfo',
        PaginatedHotels: 'PaginatedHotels',
        PaginatedUsers: 'PaginatedUsers',
        SimpleBooking: 'SimpleBooking',
        User: 'User',
        WhatsappTemplateOverview: 'WhatsappTemplateOverview',
        MessageDataFieldPointer: 'MessageDataFieldPointer',
    },
};
export var ActionType;
(function (ActionType) {
    ActionType["Operational"] = "Operational";
    ActionType["TriggerMessage"] = "TriggerMessage";
})(ActionType || (ActionType = {}));
export var BookingItemRecordStatus;
(function (BookingItemRecordStatus) {
    BookingItemRecordStatus["Completed"] = "Completed";
    BookingItemRecordStatus["Overdue"] = "Overdue";
    BookingItemRecordStatus["Pending"] = "Pending";
})(BookingItemRecordStatus || (BookingItemRecordStatus = {}));
export var BookingReservationStatus;
(function (BookingReservationStatus) {
    BookingReservationStatus["Cancelled"] = "Cancelled";
    BookingReservationStatus["CheckInAttempt"] = "CheckInAttempt";
    BookingReservationStatus["CheckInConfirmed"] = "CheckInConfirmed";
    BookingReservationStatus["CheckInNotAccepted"] = "CheckInNotAccepted";
    BookingReservationStatus["CheckOutAttempt"] = "CheckOutAttempt";
    BookingReservationStatus["CheckOutConfirmed"] = "CheckOutConfirmed";
    BookingReservationStatus["CheckOutNotAccepted"] = "CheckOutNotAccepted";
    BookingReservationStatus["Confirmed"] = "Confirmed";
    BookingReservationStatus["ConfirmedWithChanges"] = "ConfirmedWithChanges";
    BookingReservationStatus["NoShow"] = "NoShow";
})(BookingReservationStatus || (BookingReservationStatus = {}));
export var BookingValidationType;
(function (BookingValidationType) {
    BookingValidationType["GuestData"] = "GuestData";
    BookingValidationType["Payment"] = "Payment";
})(BookingValidationType || (BookingValidationType = {}));
/** ButtonComponentSubType */
export var ButtonComponentSubType;
(function (ButtonComponentSubType) {
    ButtonComponentSubType["copy_code"] = "copy_code";
    ButtonComponentSubType["quick_reply"] = "quick_reply";
    ButtonComponentSubType["url"] = "url";
})(ButtonComponentSubType || (ButtonComponentSubType = {}));
/** User role */
export var CompanyRole;
(function (CompanyRole) {
    CompanyRole["OperationalTeam"] = "OperationalTeam";
    CompanyRole["ServiceTeam"] = "ServiceTeam";
})(CompanyRole || (CompanyRole = {}));
export var FieldPointerType;
(function (FieldPointerType) {
    FieldPointerType["Condition"] = "Condition";
    FieldPointerType["DeadlineReference"] = "DeadlineReference";
    FieldPointerType["MessageData"] = "MessageData";
})(FieldPointerType || (FieldPointerType = {}));
export var FileType;
(function (FileType) {
    FileType["BookingAttachment"] = "BookingAttachment";
    FileType["ProfileAvatar"] = "ProfileAvatar";
})(FileType || (FileType = {}));
export var Language;
(function (Language) {
    Language["en"] = "en";
    Language["pt"] = "pt";
})(Language || (Language = {}));
export var MessageDestination;
(function (MessageDestination) {
    MessageDestination["BuildingAdminstration"] = "BuildingAdminstration";
    MessageDestination["Guest"] = "Guest";
})(MessageDestination || (MessageDestination = {}));
export var OrderBy;
(function (OrderBy) {
    OrderBy["asc"] = "asc";
    OrderBy["desc"] = "desc";
})(OrderBy || (OrderBy = {}));
/** ParameterType */
export var ParameterType;
(function (ParameterType) {
    ParameterType["currency"] = "currency";
    ParameterType["date_time"] = "date_time";
    ParameterType["document"] = "document";
    ParameterType["image"] = "image";
    ParameterType["text"] = "text";
    ParameterType["video"] = "video";
})(ParameterType || (ParameterType = {}));
/** Users permissions */
export var Permission;
(function (Permission) {
    Permission["BookingsModule"] = "BookingsModule";
    Permission["HotelsModule"] = "HotelsModule";
    Permission["OperationalFlowModule"] = "OperationalFlowModule";
    Permission["UsersModule"] = "UsersModule";
})(Permission || (Permission = {}));
export var TeamType;
(function (TeamType) {
    TeamType["Operational"] = "Operational";
    TeamType["Service"] = "Service";
})(TeamType || (TeamType = {}));
