import { gql } from '@apollo/client';
import { BookingItemFragmentDoc } from './booking-item.fragment';
export const CreateBookingItemTemplateDocument = gql `
  mutation createBookingItemTemplate($input: CreateBookingItemTemplateInput!) {
    createBookingItemTemplate(input: $input) {
      ...BookingItem
    }
  }
  ${BookingItemFragmentDoc}
`;
