import React from 'react';
import { AppIcon } from '@app/components/atm.app-icon';
import { Body, BodySecondary } from '@atomic/atm.typography';
import { FieldRulesUnorderedListItemStyled, FieldRulesUnorderedListStyled, } from '@atomic/mol.field-rules/field-rules.component.style';
import { Spacing } from '@atomic/obj.constants';
import { Row, Spacer } from '@atomic/obj.grid';
export const FieldRules = (props) => {
    return (React.createElement(React.Fragment, null,
        !!props.description && (React.createElement(React.Fragment, null,
            React.createElement(Body, null, props.description),
            React.createElement(Spacer, { "$height": Spacing.XLarge }))),
        React.createElement(FieldRulesUnorderedListStyled, null,
            props.validators.map((validator, index) => (React.createElement(React.Fragment, { key: index },
                React.createElement(Row, null,
                    React.createElement(FieldRulesUnorderedListItemStyled, null,
                        React.createElement(BodySecondary, { variant: validator.validationFn(props.value) ? 'success' : 'neutral' },
                            validator.validationFn(props.value) ? (React.createElement(AppIcon.Done, { size: 'large', variant: 'successDark', alt: 'Correto' })) : (React.createElement(AppIcon.Warning, { size: 'large', variant: 'alertDark', alt: 'Incorreto' })),
                            ' ',
                            validator.error.message))),
                React.createElement(Spacer, { "$height": Spacing.Medium })))),
            React.createElement(Spacer, { "$height": Spacing.Medium }))));
};
