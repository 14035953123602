import { gql } from '@apollo/client';
import { BookingItemsFragmentDoc } from './booking-items.fragment';
export const BookingItemsTemplateDocument = gql `
  query bookingItemsTemplate($filters: BookingItemsFilters, $input: BookingItemsTemplateInput!) {
    bookingItemsTemplate(filters: $filters, input: $input) {
      ...BookingItems
    }
  }
  ${BookingItemsFragmentDoc}
`;
