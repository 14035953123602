import { gql } from '@apollo/client';
import { BookingItemRecordFragmentDoc } from './booking-item-record.fragment';
export const BookingItemsRecordsDocument = gql `
  query BookingItemsRecords($input: BookingItemsRecordsInput!) {
    bookingItemsRecords(input: $input) {
      ...BookingItemRecord
    }
  }
  ${BookingItemRecordFragmentDoc}
`;
