var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { AppIcon } from '@app/components/atm.app-icon';
import { AvatarStyled } from '@app/components/atm.avatar/avatar.component.style';
import React from 'react';
import styled from 'styled-components';
export const AvatarImageStackWrapper = styled.div `
  display: flex;
  position: relative;
`;
export const AvatarImageStyled = styled((_a) => {
    var { src, size = 'large' } = _a, props = __rest(_a, ["src", "size"]);
    return src ? React.createElement(AvatarStyled, Object.assign({ src: src, size: size }, props)) : React.createElement(AppIcon.Avatar, Object.assign({ size: size }, props));
}) `
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.2);
  margin-left: -4px;
  border-radius: 50%;
`;
