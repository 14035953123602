import { TextShimmerBoxStyled } from '@atomic/mol.shimmer/shimmer.component.style';
import { Spacing } from '@atomic/obj.constants';
import { Spacer } from '@atomic/obj.grid';
import React from 'react';
import { CardShimmer } from '../mol.card-shimmer';
import { KanbanLane } from '../mol.kanban-lane/kanban-lane.component';
export const KanbanLaneShimmer = ({ rowNumber }) => {
    return (React.createElement(KanbanLane, { rowNumber: rowNumber },
        React.createElement(TextShimmerBoxStyled, { height: '30px' }),
        React.createElement(TextShimmerBoxStyled, { height: '30px' }),
        Array(5)
            .fill('')
            .map((_, index) => (React.createElement(React.Fragment, { key: index },
            React.createElement(CardShimmer, null),
            React.createElement(Spacer, { "$height": Spacing.XSmall }))))));
};
