import * as React from 'react';
import { useLazyQuery as useApolloLazyQuery, useQuery as useApolloQuery, } from '@apollo/client';
import { getQuery } from '@app/data/graphql/graphql-docs.service';
import { mapApolloError } from './graphql-error.mapper';
import { isDocumentNode } from '@apollo/client/utilities';
const DEFAULT_CACHE_POLICY = 'cache-and-network';
export function useQuery(
// When *every* query has been adapted, it will accept only DocumentNode
document, variables, options = {}) {
    if (!options.fetchPolicy) {
        options.fetchPolicy = DEFAULT_CACHE_POLICY;
    }
    const query = isDocumentNode(document) ? document : getQuery(document);
    const res = useApolloQuery(query, {
        variables,
        onCompleted: options.onCompleted,
        onError: (error) => { var _a; return (_a = options.onError) === null || _a === void 0 ? void 0 : _a.call(options, mapApolloError(error)); },
        fetchPolicy: options.fetchPolicy,
        errorPolicy: options.errorPolicy,
        skip: options === null || options === void 0 ? void 0 : options.skip,
        pollInterval: options === null || options === void 0 ? void 0 : options.pollInterval,
    });
    const fetchMore = React.useCallback((vars) => {
        res.fetchMore(vars);
    }, [res]);
    const refetch = React.useCallback((vars) => {
        res.refetch(vars);
    }, [res]);
    const updateQuery = React.useCallback((data) => {
        res.updateQuery(() => data);
    }, [res]);
    return {
        called: res.called,
        loading: res.loading,
        data: res.data,
        error: res.error && mapApolloError(res.error),
        fetchMore,
        refetch,
        updateQuery,
    };
}
export function useLazyQuery(
// When *every* query has been adapted, it will accept only DocumentNode
document, options = {}) {
    if (!options.fetchPolicy) {
        options.fetchPolicy = DEFAULT_CACHE_POLICY;
    }
    const query = isDocumentNode(document) ? document : getQuery(document);
    const [apolloQuery, res] = useApolloLazyQuery(query, {
        onCompleted: options.onCompleted,
        onError: (error) => { var _a; return (_a = options.onError) === null || _a === void 0 ? void 0 : _a.call(options, mapApolloError(error)); },
        fetchPolicy: options.fetchPolicy,
        errorPolicy: options.errorPolicy,
    });
    const fetchMore = React.useCallback((variables) => {
        res.fetchMore(variables);
    }, [res]);
    const refetch = React.useCallback((variables) => {
        res.refetch(variables);
    }, [res]);
    const updateQuery = React.useCallback((data) => {
        res.updateQuery(() => data);
    }, [res]);
    const performQuery = React.useCallback((vars) => {
        apolloQuery({ variables: vars });
    }, [apolloQuery]);
    return [
        performQuery,
        {
            called: res.called,
            loading: res.loading,
            data: res.data,
            error: res.error && mapApolloError(res.error),
            fetchMore,
            refetch,
            updateQuery,
        },
    ];
}
