var _a, _b, _c;
import { HotelsRoutes } from '@app/modules';
import { FlowRoutes } from '@app/modules/flow/flow.routes';
import { UsersRoutes } from '@app/modules/users/users.route';
export const BreadcrumbPathToTitle = {
    [(_a = FlowRoutes.Base) === null || _a === void 0 ? void 0 : _a.replace('/', '')]: 'Fluxo',
    [FlowRoutes.Items]: 'Checklist de tarefas',
    [FlowRoutes.Steps]: 'Etapas da reserva',
    [FlowRoutes.CreateItem]: 'Nova tarefa',
    [FlowRoutes.Settings]: 'Configuração de prédio',
    [(_b = UsersRoutes.Base) === null || _b === void 0 ? void 0 : _b.replace('/', '')]: 'Usuários',
    [UsersRoutes.RegisterUser]: 'Cadastro de novo usuário',
    [(_c = HotelsRoutes.Base) === null || _c === void 0 ? void 0 : _c.replace('/', '')]: 'Prédios',
    [HotelsRoutes.RegisterHotel]: 'Cadastro de novo prédio',
};
export const BreadcrumbPreviousRouteToTitle = {
    [FlowRoutes.Items]: 'Editar tarefa',
};
