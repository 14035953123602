import styled from 'styled-components';
export const AccordionCollapsibleStyled = styled.div `
  max-height: ${(props) => (props.expanded ? `${props.contentHeight}px` : '0px')};
  overflow: hidden;
  visibility: ${(props) => (props.expanded ? `visible` : 'hidden')};

  @media (prefers-reduced-motion: no-preference) {
    transition:
      max-height 0.3s ease-in-out,
      visibility 0.3s ease-in-out;
  }
`;
