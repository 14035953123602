import * as React from 'react';
import { FormFieldContext } from '@atomic/obj.form';
import { RadioCheckedStyled, RadioFieldInputStyled, RadioFieldLabelStyled, RadioFieldStyled, RadioUncheckedStyled, } from './radio-field.component.style';
const DefaultRadioBullet = (props) => props.checked ? React.createElement(RadioCheckedStyled, { "aria-hidden": 'true' }) : React.createElement(RadioUncheckedStyled, { "aria-hidden": 'true' });
export const RadioField = (props) => {
    const formFieldConsumer = React.useContext(FormFieldContext);
    const handlePress = () => {
        var _a;
        (_a = props.onClick) === null || _a === void 0 ? void 0 : _a.call(props, props.id);
        formFieldConsumer.onValueChange(props.id, null);
    };
    if (!formFieldConsumer) {
        throw new Error('<RadioField /> must be wrapped with a <Form.Field> tag');
    }
    const radioFieldId = formFieldConsumer.name + '_' + props.id;
    const selected = formFieldConsumer.value === props.id;
    return (React.createElement(RadioFieldStyled, { checked: selected, mb: props.mb, expanded: props.expanded, onClick: handlePress, noBg: props.noBg, noBorder: props.noBorder, disabled: props.disabled },
        React.createElement(RadioFieldInputStyled, { checked: selected, type: 'radio', name: formFieldConsumer.name, id: radioFieldId, value: props.id }),
        React.createElement(DefaultRadioBullet, { checked: selected }),
        React.createElement(RadioFieldLabelStyled, { htmlFor: radioFieldId, expanded: props.expanded }, props.children)));
};
