import { gql } from '@apollo/client';
import { BookingAlertPreviewFragmentDoc } from './booking-alert.fragment';
import { AuthorFragmentDoc } from './author.fragment';
export const SimpleBookingFragmentDoc = gql `
  fragment SimpleBooking on SimpleBooking {
    id
    status
    idHIGS
    idPMS
    idSource
    ticketId
    checkIn
    checkOut
    guestName
    guestLastName
    buildingName
    buildingAlias
    currentBookingStepTemplateId
    hadGlobalProblemOnSyncing
    alertsPreview {
      ...BookingAlertPreview
    }
    assignees {
      ...Author
    }
  }
  ${BookingAlertPreviewFragmentDoc}
  ${AuthorFragmentDoc}
`;
