import * as React from 'react';
import { Button } from '@atomic/atm.button';
import { Separator } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { AccordionCollapsibleStyled } from './accordion.component.style';
export const Accordion = (props) => {
    const [currentHeight, setCurrentHeight] = React.useState(0);
    const measureElement = (element) => {
        const values = element === null || element === void 0 ? void 0 : element.getBoundingClientRect();
        if ((values === null || values === void 0 ? void 0 : values.height) && (values === null || values === void 0 ? void 0 : values.height) !== currentHeight) {
            setCurrentHeight(values.height);
        }
    };
    const ariaControlsId = `${props.openedTitle}-content`;
    return (React.createElement(React.Fragment, null,
        !props.trailing && (props.closedTitle || props.openedTitle) && (React.createElement(React.Fragment, null,
            React.createElement(Button, { "aria-expanded": props.expanded, "aria-controls": ariaControlsId, variant: 'link', onClick: props.onClick }, props.expanded ? props.openedTitle : props.closedTitle),
            React.createElement(Separator, { size: Spacing.Small }))),
        props.headerComponent,
        React.createElement(AccordionCollapsibleStyled, { expanded: props.expanded, contentHeight: currentHeight, id: ariaControlsId },
            React.createElement("div", { ref: measureElement }, props.children)),
        props.trailing && (React.createElement(React.Fragment, null,
            props.expanded && React.createElement(Separator, { size: Spacing.Small }),
            React.createElement(Button, { "aria-expanded": props.expanded, "aria-controls": ariaControlsId, variant: 'link', onClick: props.onClick }, props.expanded ? props.openedTitle : props.closedTitle)))));
};
