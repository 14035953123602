export const FlowRoutes = {
    Base: '/flow',
    Steps: 'steps',
    Items: 'items',
    Settings: 'settings',
    CreateItem: 'create-item',
    ItemDetails: ':id',
    HotelFlowDetails: ':hotelId',
    HotelBookingItemDetails: ':itemId',
};
