import React from 'react';
import { OverflowBackground } from '@app/components/atm.overflow-bg/overflow-bg.component.style';
import { SideDrawer } from '@app/components/atm.side-drawer/side-drawer.component';
import { ErrorPlaceholder } from '@app/components/mol.placeholder/error-placeholder.component';
import { useGetBookingTasks } from '@app/domain/booking/get-booking-tasks.use-case';
import { useGetBooking } from '@app/domain/booking/get-booking.use-case';
import { Tab } from '@atomic/mol.tab';
import { Spacing } from '@atomic/obj.constants';
import { Grid, Row, Spacer } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { Outlet, useNavigate, useParams } from 'react-router';
import { BookingDetailsTab, Tabs } from './components/booking-details-tab.component';
import { BookingDrawerHeader } from './components/booking-drawer-header.component';
import { BookingDrawerShimmer } from './components/booking-drawer.shimmer';
import { TaskDetailsTab } from './components/task-details-tab.component';
import { homeStrings } from './home.strings';
import { HomeRoutes } from './home.routes';
const strings = homeStrings;
export const BookingDrawerPage = () => {
    var _a;
    const [subDrawerActive, setSubDrawerActive] = React.useState(false);
    const [selectedTab, setSelectedTab] = React.useState(Tabs.BookingData);
    const navigate = useNavigate();
    const params = useParams();
    const { data, loading, error } = useGetBooking({ bookingId: params.id });
    const { data: tasks } = useGetBookingTasks({ bookingId: +params.id });
    const handleSubdrawerClick = () => {
        setSubDrawerActive(false);
        setTimeout(() => {
            navigate(`/${HomeRoutes.Booking}/${params.id}`, { relative: 'route' });
        }, 100);
    };
    const handleOverlayClick = (e) => {
        e.stopPropagation();
        handleSubdrawerClick();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(SideDrawer, null,
            React.createElement(Grid, null,
                React.createElement(BookingDrawerHeader, { booking: data === null || data === void 0 ? void 0 : data.booking }),
                React.createElement(Spacer, { "$height": Spacing.Large }),
                React.createElement(Row, { justifyContent: 'center' },
                    React.createElement(Tab, { initialIndex: selectedTab, onIndexChanged: setSelectedTab },
                        React.createElement(Tab.Item, null, strings.drawer.bookingDetails),
                        React.createElement(Tab.Item, null, strings.drawer.tasks))),
                React.createElement(Spacer, { "$height": Spacing.Medium }),
                React.createElement(LoadingState, { data: !!(data === null || data === void 0 ? void 0 : data.booking), loading: loading, error: !!error },
                    React.createElement(LoadingState.Shimmer, null,
                        React.createElement(BookingDrawerShimmer, null)),
                    React.createElement(LoadingState.NoData, null,
                        React.createElement(ErrorPlaceholder, { type: 'empty' })),
                    React.createElement(LoadingState.Error, null,
                        React.createElement(ErrorPlaceholder, { type: 'notFound', title: strings.somethingWentWrong, description: strings.tryAgain })),
                    selectedTab === Tabs.BookingData && !!(data === null || data === void 0 ? void 0 : data.booking) && (React.createElement(BookingDetailsTab, { booking: data === null || data === void 0 ? void 0 : data.booking, onGuestEdit: () => setSubDrawerActive(true) })),
                    selectedTab === Tabs.Tasks && !!(tasks === null || tasks === void 0 ? void 0 : tasks.length) && (React.createElement(TaskDetailsTab, { tasks: tasks, currentBookingStepId: (_a = data === null || data === void 0 ? void 0 : data.booking) === null || _a === void 0 ? void 0 : _a.currentBookingStepTemplateId }))))),
        React.createElement(OverflowBackground, { removePointerEvents: !subDrawerActive, onClick: handleOverlayClick },
            React.createElement(Outlet, { context: { handleClick: handleSubdrawerClick, active: subDrawerActive } }))));
};
