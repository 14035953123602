import { IconSize } from '@atomic/obj.constants';
import styled from 'styled-components';
export const AvatarSizes = {
    small: IconSize.Small,
    medium: IconSize.Medium,
    large: IconSize.Large,
    xLarge: IconSize.XLarge,
};
export const AvatarStyled = styled.img `
  width: ${({ size }) => { var _a; return (_a = AvatarSizes[size]) !== null && _a !== void 0 ? _a : AvatarSizes.medium; }};
  aspect-ratio: 1;
  border-radius: 50%;
`;
