import * as React from 'react';
import { Body, H4 } from '@atomic/atm.typography';
import { Separator } from '@atomic/obj.box';
import { Row } from '@atomic/obj.grid';
import { PlaceholderColStyled } from './placeholder.component.style';
export const Placeholder = (props) => {
    return (React.createElement(Row, { justifyContent: 'center' },
        React.createElement(PlaceholderColStyled, null,
            React.createElement(Separator, null),
            props.img,
            React.createElement(Separator, null),
            React.createElement(H4, null, props.title),
            !!props.description && React.createElement(Body, null, props.description),
            React.createElement(Separator, null),
            props.children)));
};
