import { Color, Spacing } from '@atomic/obj.constants';
import styled from 'styled-components';
export const PasswordFieldViewButtonStyled = styled.button `
  position: absolute;
  top: 12px;
  right: ${Spacing.Medium};
  padding: 0;
  border: none;
  background-color: transparent;
  color: ${Color.Gray};

  &:hover {
    color: ${Color.GrayDark};
    cursor: pointer;
  }
`;
