import { useQuery } from '@app/core/graphql';
import { BookingItemsDocument, } from '@app/data/graphql';
export const useGetBookingItems = (params) => {
    const { data, loading, error, refetch } = useQuery(BookingItemsDocument, {
        input: params.input,
        filters: params.filters,
    }, {
        fetchPolicy: 'no-cache',
    });
    return {
        data: data === null || data === void 0 ? void 0 : data.bookingItems,
        loading,
        error,
        refetch,
    };
};
