export var EventName;
(function (EventName) {
    EventName["SEARCH"] = "search";
    EventName["SELECT_CONTENT"] = "select_content";
    EventName["LOGIN"] = "login";
    EventName["SIGN_UP"] = "sign_up";
    EventName["EXCEPTION"] = "exception";
    EventName["SCREEN_VIEW"] = "screen_view";
})(EventName || (EventName = {}));
export var ContentType;
(function (ContentType) {
    ContentType["NAV_BAR"] = "nav_bar";
})(ContentType || (ContentType = {}));
export var LoginType;
(function (LoginType) {
    LoginType["STANDARD"] = "standard";
})(LoginType || (LoginType = {}));
