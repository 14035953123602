import { gql } from '@apollo/client';
import { BookingFragmentDoc } from './booking.fragment';
export const BookingDocument = gql `
  query booking($bookingId: Int!) {
    booking(bookingId: $bookingId) {
      ...Booking
    }
  }
  ${BookingFragmentDoc}
`;
