export class FakeStorage {
    constructor() {
        this.storage = {};
    }
    getItem(key) {
        return this.storage[key];
    }
    setItem(key, data) {
        this.storage[key] = data;
    }
}
