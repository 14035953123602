import * as React from 'react';
import { AppImage } from '../atm.app-image/app-image.component';
import { Placeholder } from './placeholder.component';
import { placeholderStrings } from './placeholder.strings';
const placeholderMapper = {
    notFound: Object.assign(Object.assign({}, placeholderStrings.notFound), { img: React.createElement(AppImage.NotFound, null) }),
    empty: Object.assign(Object.assign({}, placeholderStrings.empty), { img: React.createElement(AppImage.NotFound, null) }),
};
export const ErrorPlaceholder = (props) => {
    var _a, _b;
    const placeholder = placeholderMapper[props.type];
    return (React.createElement(Placeholder, { img: placeholder.img, title: (_a = props.title) !== null && _a !== void 0 ? _a : placeholder.defaultTitle, description: (_b = props.description) !== null && _b !== void 0 ? _b : placeholder.defaultDescription }, props.children));
};
