import React from 'react';
import { useUploadFile } from '@app/domain/file-upload.use-case';
import { dispatchFlashMessage } from '@atomic/mol.flash-message-container';
import { homeStrings } from '@app/modules/home/home.strings';
import { useCreateBookingAttachmentsUrls } from '@app/domain/booking/create-booking-attachment-urls.use-case';
const strings = homeStrings.attachmentsSection;
export const useUploadAttachments = (params) => {
    const [attachments, setAttachments] = React.useState([]);
    const { handleUploadMultipleFiles, uploadProgress, loading: uploadLoading, } = useUploadFile({
        onCompleted: () => {
            var _a;
            dispatchFlashMessage(strings.attachmentsUploadedSuccessfully, 'success');
            setAttachments([]);
            (_a = params.onCompleted) === null || _a === void 0 ? void 0 : _a.call(params);
        },
    });
    const { createBookingAttachmentsUrls, loading: createAttachmentsUrlsLoading } = useCreateBookingAttachmentsUrls({
        onCompleted: (result) => {
            handleUploadMultipleFiles(attachments, result.createBookingAttachmentsUrls);
        },
    });
    const handleUploadAttachments = (files) => {
        setAttachments(files);
        createBookingAttachmentsUrls({
            bookingId: params.bookingId,
            attachments: files.map((file) => ({ filename: file.name, mimetype: file.type })),
        });
    };
    return {
        handleUploadAttachments,
        attachments,
        uploadProgress,
        loading: uploadLoading || createAttachmentsUrlsLoading,
    };
};
