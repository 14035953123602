import { gql } from '@apollo/client';
export const AttachmentFragmentDoc = gql `
  fragment Attachment on Attachment {
    id
    name
    type
    signedUrl
    publicUrl
    expiresAt
  }
`;
