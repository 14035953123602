import { gql } from '@apollo/client';
import { BookingItemFragmentDoc } from './booking-item.fragment';
export const UpdateBookingItemTemplateDocument = gql `
  mutation updateBookingItemTemplate($input: UpdateBookingItemTemplateInput!) {
    updateBookingItemTemplate(input: $input) {
      ...BookingItem
    }
  }
  ${BookingItemFragmentDoc}
`;
