import React from 'react';
import { AppIcon } from '@app/components/atm.app-icon';
import { ErrorPlaceholder } from '@app/components/mol.placeholder/error-placeholder.component';
import { useGetUsersUseCase } from '@app/domain/user/get-users.use-case';
import { Button } from '@atomic/atm.button';
import { Pagination } from '@atomic/atm.pagination/pagination.component.stories';
import { H1 } from '@atomic/atm.typography';
import { Spacing } from '@atomic/obj.constants';
import { Column, Grid, Row, Spacer } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { usersStrings } from './users.string';
import { UsersTable } from './components/users-table.component';
import { ContentWrapper } from '@app/components/atm.content-wrapper/content-wrapper.component';
import { UsersRoutes } from './users.route';
import { UserTableShimmer } from './components/users.page.shimmer';
const strings = usersStrings;
const UsersPage = () => {
    var _a, _b;
    const [page, setPage] = React.useState(1);
    const { data, loading, error } = useGetUsersUseCase({ page });
    return (React.createElement(React.Fragment, null,
        React.createElement(ContentWrapper, null,
            React.createElement(Row, { alignItems: 'center' },
                React.createElement(Column, { flex: 1, justifyContent: 'flex-start' },
                    React.createElement(H1, null, strings.usersEditing)),
                React.createElement(Column, null,
                    React.createElement(Button, { variant: 'primary', to: `${UsersRoutes.Base}/${UsersRoutes.RegisterUser}` },
                        React.createElement(AppIcon.Add, { variant: 'white', size: 'large' }),
                        strings.addMember)))),
        React.createElement(Spacer, { "$height": Spacing.Large }),
        React.createElement(Grid, null,
            React.createElement(LoadingState, { data: !!((_a = data === null || data === void 0 ? void 0 : data.nodes) === null || _a === void 0 ? void 0 : _a.length), loading: loading, error: !!error },
                React.createElement(LoadingState.Shimmer, null,
                    React.createElement(UserTableShimmer, null)),
                React.createElement(LoadingState.NoData, null,
                    React.createElement(ErrorPlaceholder, { description: strings.emptyState, type: 'empty' })),
                React.createElement(LoadingState.Error, null,
                    React.createElement(ErrorPlaceholder, { type: 'notFound' })),
                React.createElement(UsersTable, { users: (_b = data === null || data === void 0 ? void 0 : data.nodes) !== null && _b !== void 0 ? _b : [] }),
                React.createElement(Pagination, { siblingCount: 2, onPageChange: setPage, current: data === null || data === void 0 ? void 0 : data.pageInfo.page, total: data === null || data === void 0 ? void 0 : data.pageInfo.totalPages })),
            React.createElement(Spacer, { "$height": Spacing.XXLarge }))));
};
export default UsersPage;
