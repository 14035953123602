import styled from 'styled-components';
import { Border, Color, FontFamily, Spacing } from '@atomic/obj.constants';
export const TableStyled = styled.table `
  border-style: solid;
  border-width: ${Border.Width};
  border-color: ${Border.Color};
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  text-align: center;
  font-family: ${FontFamily.Primary};
  overflow: hidden;
  border-top-right-radius: ${Border.RadiusLarge};
  border-top-left-radius: ${Border.RadiusLarge};
`;
export const TableHeadStyled = styled.thead ``;
export const TableHeaderStyled = styled.th `
  height: 72px;
  color: ${Color.GrayXDark};
  background-color: ${Color.GrayLight};
  padding: ${Spacing.Medium};
  text-align: ${(props) => props.textAlign || 'left'};
`;
export const TableColumnStyled = styled.td `
  padding: ${Spacing.Medium};
  text-align: ${(props) => props.textAlign || 'left'};

  &:last-child {
    padding-right: ${Spacing.XSmall};
    border-right-width: 0;
  }
`;
export const TableRowStyled = styled.tr `
  ${(props) => props.onClick &&
    `
    &:hover > td {
      background-color: ${Color.GrayXLight};
      cursor: pointer;
    }`}

  ${(props) => props.height && `height: ${props.height}px;`}

  &:nth-child(even) {
    background-color: ${Color.GrayXLight + 'a0'};
  }
`;
export const DragHandleWrapperStyled = styled.div `
  cursor: ${({ isDragging }) => (isDragging ? 'grabbing' : 'grab')};
`;
