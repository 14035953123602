var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { FormFieldContext } from '@atomic/obj.form';
import { TextAreaStyled } from './text-area-field.component.style';
export class TextAreaField extends React.Component {
    constructor(props) {
        var _a;
        super(props);
        this.textAreaRef = React.createRef();
        this.focus = (cursorPosition) => {
            var _a, _b;
            (_a = this.textAreaRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            if (cursorPosition) {
                (_b = this.textAreaRef.current) === null || _b === void 0 ? void 0 : _b.setSelectionRange(cursorPosition, cursorPosition);
            }
        };
        this.handleTextChange = (event) => {
            var _a, _b, _c;
            if (this.isControlled()) {
                return;
            }
            const value = event.currentTarget.value;
            if (((_a = this.formFieldConsumer) === null || _a === void 0 ? void 0 : _a.value) === value) {
                return;
            }
            (_c = (_b = this.props).onValueChange) === null || _c === void 0 ? void 0 : _c.call(_b, value);
            if (this.formFieldConsumer) {
                this.formFieldConsumer.onValueChange(value, null);
            }
            else {
                this.setState({ value });
            }
        };
        this.isControlled = () => this.props.value !== undefined;
        this.state = {
            value: (_a = props.initialValue) !== null && _a !== void 0 ? _a : props.value,
        };
    }
    componentDidMount() {
        var _a;
        if (this.props.expandToContentHeight && !!this.textAreaRef.current.style) {
            this.textAreaRef.current.style.height = `${((_a = this.textAreaRef.current) === null || _a === void 0 ? void 0 : _a.scrollHeight) + 20}px`;
        }
        if (this.isControlled() && this.props.initialValue !== undefined) {
            throw new Error('Use either the initialValue prop, or the value prop, but not both');
        }
        if (this.formFieldConsumer) {
            if (this.isControlled() || this.props.initialValue !== undefined) {
                if (this.formFieldConsumer.value) {
                    throw new Error('Please, use either value props in <TextField> or <Form.Field> component.');
                }
                this.formFieldConsumer.onValueChange(this.state.value, null);
            }
        }
    }
    componentDidUpdate(prevProps) {
        var _a, _b, _c;
        if (prevProps.value !== this.props.value && this.props.value !== this.state.value) {
            (_b = (_a = this.props).onValueChange) === null || _b === void 0 ? void 0 : _b.call(_a, this.props.value);
            (_c = this.formFieldConsumer) === null || _c === void 0 ? void 0 : _c.onValueChange(this.props.value, null);
            this.setState({ value: this.props.value });
        }
    }
    render() {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const _a = this.props, { onValueChange, onChange, value, initialValue } = _a, other = __rest(_a, ["onValueChange", "onChange", "value", "initialValue"]);
        return (React.createElement(FormFieldContext.Consumer, null, (formFieldConsumer) => {
            var _a;
            this.formFieldConsumer = formFieldConsumer;
            const val = (_a = formFieldConsumer === null || formFieldConsumer === void 0 ? void 0 : formFieldConsumer.value) !== null && _a !== void 0 ? _a : this.state.value;
            return (React.createElement(TextAreaStyled, Object.assign({ value: val !== null && val !== void 0 ? val : '', onChange: this.handleTextChange }, other, { ref: this.textAreaRef })));
        }));
    }
}
