import React from 'react';
import { BookingItemFormFields } from '@app/model/booking/booking-item-form.model';
import { Button } from '@atomic/atm.button';
import { Divider } from '@atomic/atm.divider';
import { SelectField } from '@atomic/atm.select';
import { TextField } from '@atomic/atm.text-field';
import { H2, H3, InputCaption } from '@atomic/atm.typography';
import { Spacing } from '@atomic/obj.constants';
import { Form, FormField, Validators } from '@atomic/obj.form';
import { Column, Row, Spacer } from '@atomic/obj.grid';
import { flowStrings } from '../flow.strings';
import { useNavigate } from 'react-router';
import { useGetFieldPointers } from '@app/domain/booking/get-field-pointers.use-case';
import { ActionBookingItemFormSection } from './action-booking-item-form-section.component';
import { useGetWhatsappTemplates } from '@app/domain/booking/get-whatsapp-templates.use-case';
const strings = flowStrings.bookingItemForm;
export const BookingItemForm = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    const navigate = useNavigate();
    const bookingItem = Object.assign({}, ((_a = props.bookingItem) !== null && _a !== void 0 ? _a : {}));
    const { data: allFieldPointers, loading } = useGetFieldPointers();
    const { data: whatsappTemplates } = useGetWhatsappTemplates();
    const handleSubmit = (formData) => {
        if (formData.hasError) {
            return;
        }
        props.onSubmit(formData.data);
    };
    return (React.createElement(Form, { onSubmit: handleSubmit },
        React.createElement(FormField, { initialValue: bookingItem.name, name: BookingItemFormFields.name, label: strings.name.label, validators: [Validators.Required()] },
            React.createElement(TextField, { disabled: (_b = props.blockedFields) === null || _b === void 0 ? void 0 : _b.name }),
            React.createElement(Spacer, { "$height": Spacing.XSmall }),
            React.createElement(InputCaption, null, strings.name.caption)),
        React.createElement(Spacer, { "$height": Spacing.Medium }),
        React.createElement(H3, null, strings.conditionTitle),
        React.createElement(FormField, { name: BookingItemFormFields.conditionFieldId, label: strings.condition.label, key: (_c = allFieldPointers === null || allFieldPointers === void 0 ? void 0 : allFieldPointers.conditionFieldPointers) === null || _c === void 0 ? void 0 : _c.length, initialValue: (_e = (_d = bookingItem.action) === null || _d === void 0 ? void 0 : _d.fieldPointer) === null || _e === void 0 ? void 0 : _e.id },
            React.createElement(SelectField, { disabled: loading || ((_f = props.blockedFields) === null || _f === void 0 ? void 0 : _f.conditionFieldId) },
                React.createElement("option", { value: null }, strings.condition.dontApply), (_g = allFieldPointers === null || allFieldPointers === void 0 ? void 0 : allFieldPointers.conditionFieldPointers) === null || _g === void 0 ? void 0 :
                _g.map((fieldPointer) => (React.createElement("option", { key: `${fieldPointer.id}-condition`, value: fieldPointer.id }, fieldPointer.friendlyDescription)))),
            React.createElement(Spacer, { "$height": Spacing.XSmall }),
            React.createElement(InputCaption, null, strings.condition.caption)),
        React.createElement(Spacer, { "$height": Spacing.XXLarge }),
        React.createElement(Divider, { variant: 'grayLight' }),
        React.createElement(Spacer, { "$height": Spacing.Medium }),
        React.createElement(H2, null, strings.triggerTitle),
        React.createElement(H3, null, strings.trigger.defineDeadline),
        React.createElement(Row, null,
            React.createElement(Column, { flex: 1 / 2 },
                React.createElement(FormField, { initialValue: (_h = bookingItem === null || bookingItem === void 0 ? void 0 : bookingItem.deadline) === null || _h === void 0 ? void 0 : _h.toString(), name: BookingItemFormFields.deadline, label: strings.trigger.timeField.label, validators: [Validators.Required()] },
                    React.createElement(TextField, { type: 'number' }),
                    React.createElement(Spacer, { "$height": Spacing.XSmall }),
                    React.createElement(InputCaption, null, strings.trigger.timeField.caption))),
            React.createElement(Spacer, { "$width": Spacing.Medium }),
            React.createElement(Column, { flex: 1 / 2 },
                React.createElement(FormField, { name: BookingItemFormFields.shouldTriggerBeforeDeadlineReference, label: strings.trigger.whenField.label, initialValue: bookingItem.shouldTriggerBeforeDeadlineReference ? 1 : 0 },
                    React.createElement(SelectField, null,
                        React.createElement("option", { value: 0 }, strings.trigger.whenField.after),
                        React.createElement("option", { value: 1 }, strings.trigger.whenField.before)),
                    React.createElement(Spacer, { "$height": Spacing.XSmall }),
                    React.createElement(InputCaption, null, strings.trigger.whenField.caption)))),
        React.createElement(Spacer, { "$height": Spacing.Large }),
        React.createElement(FormField, { name: BookingItemFormFields.deadlineReferenceId, label: strings.trigger.deadlineReference.label, validators: [Validators.Required()], initialValue: (_k = (_j = bookingItem.deadlineReference) === null || _j === void 0 ? void 0 : _j.id) !== null && _k !== void 0 ? _k : (_m = (_l = allFieldPointers === null || allFieldPointers === void 0 ? void 0 : allFieldPointers.deadlineReferenceFieldPointers) === null || _l === void 0 ? void 0 : _l[0]) === null || _m === void 0 ? void 0 : _m.id },
            React.createElement(SelectField, { disabled: loading }, allFieldPointers === null || allFieldPointers === void 0 ? void 0 : allFieldPointers.deadlineReferenceFieldPointers.map((deadlineReference) => (React.createElement("option", { key: `${deadlineReference.id}-deadlineReference`, value: deadlineReference.id }, deadlineReference.friendlyDescription)))),
            React.createElement(Spacer, { "$height": Spacing.XSmall }),
            React.createElement(InputCaption, null, strings.trigger.deadlineReference.caption)),
        React.createElement(Spacer, { "$height": Spacing.XLarge }),
        React.createElement(ActionBookingItemFormSection, { loading: loading, messageDataFieldPointers: allFieldPointers === null || allFieldPointers === void 0 ? void 0 : allFieldPointers.messageDataFieldPointers, whatsappTemplates: whatsappTemplates === null || whatsappTemplates === void 0 ? void 0 : whatsappTemplates.listWhatsappTemplates, bookingItemAction: bookingItem === null || bookingItem === void 0 ? void 0 : bookingItem.action, initialWhatsappTemplate: bookingItem === null || bookingItem === void 0 ? void 0 : bookingItem.whatsappTemplate }),
        React.createElement(Spacer, { "$height": Spacing.XLarge }),
        React.createElement(Row, { justifyContent: 'flex-end' },
            React.createElement(Button, { variant: 'secondary', onClick: () => navigate(-1) }, strings.cancel),
            React.createElement(Spacer, { "$width": Spacing.Medium }),
            React.createElement(Button, { variant: 'callToAction', type: 'submit', loading: props.loading }, strings.submit)),
        React.createElement(Spacer, { "$height": Spacing.XLarge })));
};
