import { gql } from '@apollo/client';
import { WhatsappTemplateOverviewFragmentDoc } from './whatsapp-template-overview.fragment';
export const WhatsappTemplatesDocument = gql `
  query whatsappTemplates {
    listWhatsappTemplates {
      ...WhatsappTemplateOverview
    }
  }
  ${WhatsappTemplateOverviewFragmentDoc}
`;
