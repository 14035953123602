import React from 'react';
import { ContentWrapper } from '@app/components/atm.content-wrapper/content-wrapper.component';
import { ErrorPlaceholder } from '@app/components/mol.placeholder/error-placeholder.component';
import { useCreateHotel } from '@app/domain/hotel/create-hotel.use-case';
import { useUpdateHotel } from '@app/domain/hotel/edit-hotel.use-case';
import { useGetHotelDetails } from '@app/domain/hotel/get-hotel-details.use-case';
import { H1 } from '@atomic/atm.typography';
import { dispatchFlashMessage } from '@atomic/mol.flash-message-container';
import { Spacing } from '@atomic/obj.constants';
import { Grid, Spacer } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { useNavigate, useParams } from 'react-router-dom';
import { HotelForm } from './components/hotel-form.component';
import { HotelsRoutes } from './hotels.routes';
import { hotelStrings } from './hotels.strings';
import { FormShimmer } from '@app/components/mol.form-shimmer/form-shimmer.component';
const HotelFormPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const isUpdatePage = !!id;
    const { data, loading, error } = useGetHotelDetails({ id, skip: !isUpdatePage });
    const { updateHotel, loading: updateLoading } = useUpdateHotel({
        onCompleted: () => {
            dispatchFlashMessage(hotelStrings.updatedSuccessfuly, 'success');
            navigate(-1);
        },
    });
    const { createHotel, loading: createLoading } = useCreateHotel({
        onCompleted: () => {
            dispatchFlashMessage(hotelStrings.createdSuccessfuly, 'success');
            navigate(HotelsRoutes.Base);
        },
    });
    const handleSubmit = (formData) => {
        var _a, _b;
        if (isUpdatePage) {
            updateHotel({ input: Object.assign(Object.assign({ id: data.id }, formData), { active: !!((_a = formData.active) === null || _a === void 0 ? void 0 : _a.length) }) });
            return;
        }
        createHotel({ input: Object.assign(Object.assign({}, formData), { active: !!((_b = formData.active) === null || _b === void 0 ? void 0 : _b.length) }) });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ContentWrapper, null,
            React.createElement(H1, null, isUpdatePage ? hotelStrings.hotelEditing : hotelStrings.newHotelRegistration)),
        React.createElement(Spacer, { "$height": Spacing.Large }),
        React.createElement(LoadingState, { data: !isUpdatePage || !!data, loading: loading, error: !!error },
            React.createElement(LoadingState.Shimmer, null,
                React.createElement(Spacer, { "$height": Spacing.XXLarge }),
                React.createElement(Grid, null,
                    React.createElement(FormShimmer, { columns: 2, rows: 5 }))),
            React.createElement(LoadingState.NoData, null,
                React.createElement(ErrorPlaceholder, { type: 'empty' })),
            React.createElement(LoadingState.Error, null,
                React.createElement(ErrorPlaceholder, { type: 'empty' })),
            React.createElement(Grid, null,
                React.createElement(HotelForm, { hotel: data, onSubmit: handleSubmit, loading: updateLoading || createLoading })))));
};
export default HotelFormPage;
