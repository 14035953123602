import { Border, Color, IconSize, SidebarWidth, VerticalNavbarWidth, ZIndex } from '@atomic/obj.constants';
import styled from 'styled-components';
import { AppIcon } from '../atm.app-icon';
const TAB_SIZE = 48;
const TAB_TOP_POSITION = 15;
export const SidebarStyled = styled.nav `
  display: flex;
  position: fixed;
  height: 100vh;
  width: ${SidebarWidth}px;
  top: 0;
  left: ${({ opened }) => (opened ? VerticalNavbarWidth : 0 - VerticalNavbarWidth)}px;
  z-index: ${ZIndex.third};
  background-color: ${Color.Accessory};
  transition: left 0.3s ease-in-out;

  @media (prefers-reduced-motion) {
    transition: none;
  }
`;
export const TabStyled = styled.button `
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  top: ${TAB_TOP_POSITION}px;
  right: -${TAB_SIZE}px;
  width: ${TAB_SIZE}px;
  aspect-ratio: 1;
  border-top-right-radius: ${Border.RadiusLarge};
  border-bottom-right-radius: ${Border.RadiusLarge};
  border-color: transparent;
  background-color: ${Color.Accessory};
  cursor: pointer;
`;
export const ChevronStyled = styled(AppIcon.ChevronRight) `
  transform: ${({ opened }) => (opened ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.3s ease-in-out;
  width: ${IconSize.Large};
  height: ${IconSize.Large};

  @media (prefers-reduced-motion) {
    transition: none;
  }
`;
export const SidebarBlockStyled = styled.div `
  display: flex;
  position: relative;
  margin: ${({ opened }) => (opened ? SidebarWidth / 2 : 0)}px;
  transition: margin 0.3s ease-in-out;

  @media (prefers-reduced-motion) {
    transition: none;
  }
`;
