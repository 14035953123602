import { Frame } from '@atomic/atm.frame';
import { Spacing } from '@atomic/obj.constants';
import styled from 'styled-components';
export const UserMenuWrapperStyled = styled.div `
  position: absolute;
  top: 20%;
`;
export const UserFrameStyled = styled(Frame) `
  padding: ${Spacing.Small};
  margin: ${Spacing.Small};
`;
