import { ContentWrapper } from '@app/components/atm.content-wrapper/content-wrapper.component';
import { KanbanShimmer } from '@app/components/mol.kanban-shimmer/kanban-shimmer.component';
import { ErrorPlaceholder } from '@app/components/mol.placeholder/error-placeholder.component';
import { useGetBookingStepsTemplate } from '@app/domain/booking/get-booking-steps-template.use-case';
import { useGetHotelDetails } from '@app/domain/hotel/get-hotel-details.use-case';
import { BodySecondary, H1, H2 } from '@atomic/atm.typography';
import { TextShimmerBoxStyled } from '@atomic/mol.shimmer/shimmer.component.style';
import { Spacing } from '@atomic/obj.constants';
import { Column, Row, Spacer } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import React from 'react';
import { useParams } from 'react-router-dom';
import { BookingItemModal } from './components/booking-items-modal.component';
import { BookingStepLane } from './components/booking-step-lane.component';
import { flowStrings } from './flow.strings';
const strings = flowStrings.flowSettings;
const HotelFlowPage = () => {
    var _a;
    const { hotelId } = useParams();
    const [openedStepId, setOpenedStepId] = React.useState(null);
    const [modalKey, setModalKey] = React.useState(`${new Date().getTime()}`);
    const [kanbanCardsKey, setKanbanCardsKey] = React.useState(`${new Date().getTime()}`);
    const modalRef = React.useRef();
    const { data: stepsTemplate, loading: stepsTemplateLoading, error: stepsTemplateError, } = useGetBookingStepsTemplate();
    const { data: hotelData, loading: hotelLoading } = useGetHotelDetails({ id: hotelId });
    const handleInsertTaskClick = (stepId) => {
        var _a;
        setOpenedStepId(stepId);
        (_a = modalRef.current) === null || _a === void 0 ? void 0 : _a.showModal();
    };
    const handleModalClose = () => {
        setModalKey(`${new Date().getTime()}`);
    };
    return (React.createElement(ContentWrapper, null,
        React.createElement(Row, { alignItems: 'center' },
            React.createElement(Column, { flex: 1, justifyContent: 'flex-start' },
                React.createElement(H1, null, strings.setupFlow),
                !hotelLoading && !!hotelData ? (React.createElement(React.Fragment, null,
                    React.createElement(H2, null, hotelData === null || hotelData === void 0 ? void 0 : hotelData.name),
                    React.createElement(BodySecondary, null,
                        strings.hotelCode,
                        " ", hotelData === null || hotelData === void 0 ? void 0 :
                        hotelData.idHIGS))) : (React.createElement(React.Fragment, null,
                    React.createElement(TextShimmerBoxStyled, { width: '30%', height: '30px' }),
                    React.createElement(TextShimmerBoxStyled, { width: '10%', height: '30px' }))))),
        React.createElement(Spacer, { "$height": Spacing.XXLarge }),
        React.createElement(LoadingState, { data: !!(stepsTemplate === null || stepsTemplate === void 0 ? void 0 : stepsTemplate.length), loading: stepsTemplateLoading, error: !!stepsTemplateError },
            React.createElement(LoadingState.Shimmer, null,
                React.createElement(KanbanShimmer, null)),
            React.createElement(LoadingState.NoData, null,
                React.createElement(ErrorPlaceholder, { type: 'empty', description: strings.emptyDescription, title: strings.emptyTitle })),
            React.createElement(LoadingState.Error, null,
                React.createElement(ErrorPlaceholder, { type: 'notFound' })),
            React.createElement(Row, { gap: Spacing.Large }, (_a = stepsTemplate === null || stepsTemplate === void 0 ? void 0 : stepsTemplate.filter((item) => item.active)) === null || _a === void 0 ? void 0 :
                _a.map((bookingStepTemplate, index) => {
                    return (React.createElement(Column, { key: bookingStepTemplate.id + kanbanCardsKey },
                        React.createElement(BookingStepLane, { onItemDeleted: () => setModalKey(`${new Date().getTime()}`), onInsertTaskClick: handleInsertTaskClick, rowNumber: index, bookingStepTemplate: bookingStepTemplate, hotelId: hotelId })));
                }),
                React.createElement(Spacer, { "$height": Spacing.XXLarge })),
            React.createElement(Spacer, { "$height": Spacing.XXLarge }),
            React.createElement(BookingItemModal, { onConfigOperationalFlowCompleted: () => setKanbanCardsKey(`${new Date().getTime()}`), key: modalKey, onModalClose: handleModalClose, hotelId: hotelId, modalRef: modalRef, openedStepId: openedStepId }))));
};
export default HotelFlowPage;
