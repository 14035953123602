import { useQuery } from '@app/core/graphql';
import { BookingStepsTemplateDocument, } from '@app/data/graphql/generated/booking-steps-template.query';
export const useGetBookingStepsTemplate = () => {
    const { data, loading, error, refetch } = useQuery(BookingStepsTemplateDocument);
    return {
        data: data === null || data === void 0 ? void 0 : data.bookingStepsTemplate,
        loading,
        error,
        refetch,
    };
};
