export const accountStrings = {
    myAccount: 'MINHA CONTA',
    editProfile: 'Editar perfil',
    fullName: 'Nome completo',
    companyRole: 'Função',
    email: 'E-mail',
    errorUploadingAvatar: 'Erro ao enviar foto',
    choosePicture: 'Escolha sua foto',
    pictureFormat: 'Deve estar no formato PNG, JPG ou JPEG',
    pictureSize: 'O tamanho máximo permitido é de 2MB',
    pictureSaved: 'Foto salva com sucesso',
    pickImage: 'Escolher foto',
    remove: 'Remover',
    save: 'Salvar alterações',
};
