import styled from 'styled-components';
import { Color, IconSize } from '@atomic/obj.constants';
const IcDefaultProps = { variant: 'primary', size: 'medium' };
export const IcColors = {
    primary: Color.Primary,
    primaryDark: Color.PrimaryDark,
    secondary: Color.Secondary,
    callToAction: Color.CallToAction,
    white: Color.White,
    warning: Color.Warning,
    successDark: Color.SuccessDark,
    alertDark: Color.AlertDark,
    grayDark: Color.GrayDark,
    grayXDark: Color.GrayXDark,
    black: Color.Black,
    transparent: 'transparent',
};
export const IcSizes = {
    small: IconSize.Small,
    medium: IconSize.Medium,
    large: IconSize.Large,
    xLarge: IconSize.XLarge,
};
export const IcSvgStyled = styled.svg.attrs((props) => ({
    width: IcSizes[props.size || IcDefaultProps.size],
    height: IcSizes[props.size || IcDefaultProps.size],
    fill: 'transparent',
    stroke: 'transparent',
})) `
  color: ${(props) => IcColors[props.variant || IcDefaultProps.variant]};
  vertical-align: middle;
`;
