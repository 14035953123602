import * as React from 'react';
import { DatePicker } from '@atomic/atm.date-picker';
import { Hbox } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { Column, Spacer } from '@atomic/obj.grid';
import { InputLabel } from '../atm.typography';
export const DatePickerRange = (props) => {
    const [startDate, setStartDate] = React.useState(props.startDate);
    const [endDate, setEndDate] = React.useState(props.endDate);
    React.useEffect(() => {
        var _a;
        if (props.startDate || props.endDate) {
            (_a = props.onDateChange) === null || _a === void 0 ? void 0 : _a.call(props, { startDate, endDate });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleStartValueChange = (selectedStartDate) => {
        var _a;
        (_a = props.onDateChange) === null || _a === void 0 ? void 0 : _a.call(props, { startDate: selectedStartDate, endDate });
        setStartDate(selectedStartDate);
    };
    const handleEndValueChange = (selectedEndDate) => {
        var _a;
        (_a = props.onDateChange) === null || _a === void 0 ? void 0 : _a.call(props, { startDate, endDate: selectedEndDate });
        setEndDate(selectedEndDate);
    };
    if (props.vertical) {
        return (React.createElement(Column, null,
            !!props.startDateLabel && React.createElement(InputLabel, null, props.startDateLabel),
            React.createElement(DatePicker, { disabled: props.startDateDisabled, startDate: startDate, onValueChange: handleStartValueChange, placeholder: props.startDatePlaceholder }),
            React.createElement(Spacer, { "$height": Spacing.Small }),
            !!props.endDateLabel && React.createElement(InputLabel, null, props.endDateLabel),
            React.createElement(DatePicker, { disabled: props.endDateDisabled, endDate: endDate, onValueChange: handleEndValueChange, placeholder: props.endDatePlaceholder, endSelect: true })));
    }
    return (React.createElement(Hbox, null,
        React.createElement(Hbox.Item, { noGrow: true },
            !!props.startDateLabel && React.createElement(InputLabel, null, props.startDateLabel),
            React.createElement(DatePicker, { disabled: props.startDateDisabled, startDate: startDate, onValueChange: handleStartValueChange, placeholder: props.startDatePlaceholder })),
        React.createElement(Hbox.Separator, null),
        React.createElement(Hbox.Item, { noGrow: true },
            !!props.endDateLabel && React.createElement(InputLabel, null, props.endDateLabel),
            React.createElement(DatePicker, { disabled: props.endDateDisabled, endDate: endDate, onValueChange: handleEndValueChange, placeholder: props.endDatePlaceholder, endSelect: true }))));
};
