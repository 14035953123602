import { ThumbnailSize } from '@atomic/obj.constants';
import styled from 'styled-components';
export const ThumbnailSizes = {
    small: ThumbnailSize.Small,
    medium: ThumbnailSize.Medium,
    large: ThumbnailSize.Large,
    xLarge: ThumbnailSize.XLarge,
    xxLarge: ThumbnailSize.XXLarge,
};
export const ThumbnailStyled = styled.img `
  height: ${({ size }) => (size ? ThumbnailSizes[size] : ThumbnailSizes.medium)};
  aspect-ratio: 1;
`;
