import styled from 'styled-components';
import { Color, Spacing } from '@atomic/obj.constants';
import { Label } from '@atomic/atm.typography';
export const BreadcrumbListStyled = styled.div `
  display: flex;
  gap: ${Spacing.Small};
  padding-left: ${Spacing.XLarge};
  padding-bottom: ${Spacing.Medium};
`;
export const BreadcrumbListItemStyled = styled.div `
  display: inline-block;
`;
export const BreadcrumbLinkTextStyled = styled(Label) `
  color: ${(props) => (props.current ? Color.Secondary : Color.GrayDark)};
`;
