import React from 'react';
import { SearchBar } from '@app/components/atm.search-bar';
import { Body } from '@atomic/atm.typography';
import { AutocompleteContainer, AutocompleteList, AutocompleteListItem } from './autocomplete.component.style';
import { generalStrings } from '@app/utils/general.string';
import { LoadingCentered } from '@atomic/mol.loading';
import { LoadingState } from '@atomic/obj.loading-state';
const MIN_KEYS_TO_SEARCH = 3;
const strings = generalStrings.autocomplete;
export const Autocomplete = (props) => {
    var _a, _b;
    const [searchOpened, setSearchOpened] = React.useState(false);
    const term = React.useRef('');
    const handleSearchType = (event) => {
        const value = event.currentTarget.value;
        if ((value === null || value === void 0 ? void 0 : value.length) >= MIN_KEYS_TO_SEARCH) {
            props.onTermChange(value);
            setSearchOpened(true);
        }
        else {
            setSearchOpened(false);
        }
    };
    const handleOptionClick = (item) => {
        props.onOptionClick(item);
    };
    const handleFocus = () => {
        if (term.current.length >= MIN_KEYS_TO_SEARCH) {
            setSearchOpened(true);
        }
    };
    return (React.createElement(AutocompleteContainer, { onFocus: () => handleFocus(), onBlur: () => setSearchOpened(false) },
        React.createElement(SearchBar, { placeholder: props.placeholder, onChange: handleSearchType }),
        searchOpened && (React.createElement(AutocompleteList, null,
            React.createElement(LoadingState, { data: !!((_a = props.items) === null || _a === void 0 ? void 0 : _a.length), loading: props.loading },
                React.createElement(LoadingState.Shimmer, null,
                    React.createElement(AutocompleteListItem, null,
                        React.createElement(LoadingCentered, null))),
                React.createElement(LoadingState.NoData, null,
                    React.createElement(AutocompleteListItem, null,
                        React.createElement(Body, null, strings.resultNotFound))), (_b = props.items) === null || _b === void 0 ? void 0 :
                _b.map((item) => (React.createElement(AutocompleteListItem, { key: item.id, value: item.id, onMouseDown: () => handleOptionClick({ id: item.id, name: item.name }) },
                    React.createElement(Body, null, item.name)))))))));
};
