import React from 'react';
import { createPortal } from 'react-dom';
import { Outlet, useNavigate } from 'react-router-dom';
import { OverflowBackground } from '../atm.overflow-bg/overflow-bg.component.style';
import { HomeRoutes } from '@app/modules';
const portal = document.getElementById('portal');
export const SideDrawerLayout = () => {
    const [active, setActive] = React.useState(true);
    const navigate = useNavigate();
    const handleClick = () => {
        setActive(false);
        setTimeout(() => {
            navigate(HomeRoutes.Base);
        }, 100);
    };
    return createPortal(React.createElement(OverflowBackground, { bgColor: true, onClick: handleClick },
        React.createElement(Outlet, { context: { handleClick, active } })), portal);
};
