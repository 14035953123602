var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Container, { Service } from 'typedi';
import { FIREBASE_ANALYTICS } from './firebase-analytics.service';
import { logEvent, setUserId } from 'firebase/analytics';
import { EventName } from './analytics-event.model';
let AnalyticsService = class AnalyticsService {
    constructor() {
        this.analytics = Container.get(FIREBASE_ANALYTICS).analytics;
    }
    send(event, params) {
        logEvent(this.analytics, event, params);
    }
    setScreen(screenName) {
        logEvent(this.analytics, EventName.SCREEN_VIEW, {
            firebase_screen: screenName,
            firebase_screen_class: screenName,
        });
    }
    setUserId(userId) {
        setUserId(this.analytics, userId);
    }
};
AnalyticsService = __decorate([
    Service()
], AnalyticsService);
export { AnalyticsService };
