import React from 'react';
import { Badge } from '@atomic/atm.badge';
import { homeStrings } from '../home.strings';
const strings = homeStrings.status;
export const StatusBadge = (props) => {
    var _a;
    return (React.createElement(Badge, { ellipsis: true, color: mapStatusToColor[props.status], size: (_a = props.size) !== null && _a !== void 0 ? _a : 'small', text: strings[props.status] }));
};
const mapStatusToColor = {
    Cancelled: 'alertLight',
    CheckInAttempt: 'warningLight',
    CheckInConfirmed: 'warningLight',
    CheckInNotAccepted: 'alertLight',
    CheckOutAttempt: 'warningLight',
    CheckOutConfirmed: 'warningLight',
    CheckOutNotAccepted: 'alertLight',
    Confirmed: 'warningLight',
    ConfirmedWithChanges: 'warningLight',
    NoShow: 'alertLight',
};
