import { useMutation } from '@app/core/graphql/graphql-mutation.hooks';
import { DeleteUserDocument, } from '@app/data/graphql/generated/delete-user.mutation';
import { dispatchFlashMessage } from '@atomic/mol.flash-message-container/flash-message-container.component';
import React from 'react';
export function useDeleteUser(params) {
    const [deleteUserMutation, { error, loading }] = useMutation(DeleteUserDocument, {
        onCompleted: (DeleteUserResult) => { var _a; return (_a = params.onCompleted) === null || _a === void 0 ? void 0 : _a.call(params, DeleteUserResult); },
        onError: (requestError) => {
            var _a;
            dispatchFlashMessage(requestError.message);
            (_a = params.onError) === null || _a === void 0 ? void 0 : _a.call(params, requestError);
        },
    });
    const deleteUser = React.useCallback((userId) => {
        deleteUserMutation({ input: { userId: +userId } });
    }, [deleteUserMutation]);
    return {
        deleteUser,
        error,
        loading,
    };
}
