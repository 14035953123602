import { GlobalStyled } from '@atomic/obj.globals';
import React from 'react';
import { FlashMessageContainer } from '@atomic/mol.flash-message-container/flash-message-container.component';
import { RootRouter } from './root.router';
function App() {
    return (React.createElement(React.Fragment, null,
        React.createElement(GlobalStyled, null),
        React.createElement(RootRouter, null),
        React.createElement(FlashMessageContainer, { renderPortal: true })));
}
export default App;
