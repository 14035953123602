import { AppIcon } from '@app/components/atm.app-icon';
import { CardShimmer } from '@app/components/mol.card-shimmer';
import { KanbanLane } from '@app/components/mol.kanban-lane';
import { useDeleteBookingItems } from '@app/domain/booking/delete-booking-items.use-case';
import { useGetBookingItems } from '@app/domain/booking/get-booking-items.use-case';
import { Button } from '@atomic/atm.button';
import { Card } from '@atomic/atm.card';
import { Divider } from '@atomic/atm.divider';
import { Body, BodySecondary, H4 } from '@atomic/atm.typography';
import { dispatchFlashMessage } from '@atomic/mol.flash-message-container';
import { ClickableIcon } from '@atomic/obj.clickable';
import { Spacing } from '@atomic/obj.constants';
import { Column, Row, Spacer } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import React from 'react';
import { useNavigate } from 'react-router';
import { flowStrings } from '../flow.strings';
const strings = flowStrings.flowSettings;
export const BookingStepLane = (props) => {
    const navigate = useNavigate();
    const { data, loading, error, refetch } = useGetBookingItems({
        input: { hotelId: +props.hotelId, bookingStepTemplateId: props.bookingStepTemplate.id },
    });
    const { deleteBookingItems, loading: deleteLoading } = useDeleteBookingItems({
        onCompleted: () => {
            dispatchFlashMessage(strings.taskDeleted, 'success');
            props.onItemDeleted();
            refetch();
        },
    });
    const handleDeleteBookingItem = (itemId) => {
        if (confirm(strings.confirmDeletion)) {
            deleteBookingItems({ itemIds: [itemId] });
        }
    };
    return (React.createElement(KanbanLane, { rowNumber: props.rowNumber },
        React.createElement(H4, { noMargin: true, variant: 'black' }, props.bookingStepTemplate.name),
        React.createElement(Spacer, { "$height": Spacing.XSmall }),
        React.createElement(Row, { justifyContent: 'center' },
            React.createElement(Button, { variant: 'secondary', onClick: () => props.onInsertTaskClick(props.bookingStepTemplate.id) },
                React.createElement(AppIcon.Add, { variant: 'grayDark' }),
                strings.insertTask)),
        React.createElement(LoadingState, { data: !!(data === null || data === void 0 ? void 0 : data.length), loading: loading || deleteLoading, error: !!error },
            React.createElement(LoadingState.Shimmer, null,
                React.createElement(Column, { gap: Spacing.Medium }, Array(5)
                    .fill('')
                    .map((_, index) => (React.createElement(CardShimmer, { key: index }))))),
            React.createElement(LoadingState.Error, null,
                React.createElement(Body, null, strings.somethingWentWrong),
                React.createElement(BodySecondary, null, strings.tryAgain)),
            React.createElement(Column, { gap: Spacing.Small },
                React.createElement(Spacer, { "$height": Spacing.XSmall }), data === null || data === void 0 ? void 0 :
                data.map((bookingItem) => {
                    return (React.createElement(Card, { small: true, key: bookingItem.id },
                        React.createElement(Spacer, { "$height": Spacing.Small }),
                        React.createElement(Row, null,
                            React.createElement(Body, { lineLimit: 4 }, bookingItem.name)),
                        React.createElement(Spacer, { "$height": Spacing.Medium }),
                        React.createElement(Divider, { variant: 'grayLight' }),
                        React.createElement(Spacer, { "$height": Spacing.Small }),
                        React.createElement(Row, { justifyContent: 'space-around' },
                            React.createElement(ClickableIcon, { onClick: () => navigate(bookingItem.id.toString(), { state: { bookingItem, breadcrumbKey: bookingItem.name } }) },
                                React.createElement(AppIcon.Edit, { alt: strings.edit, variant: 'grayDark', size: 'large' })),
                            React.createElement(ClickableIcon, { onClick: () => handleDeleteBookingItem(bookingItem.id) },
                                React.createElement(AppIcon.Trash, { alt: strings.delete, variant: 'alertDark', size: 'large' })))));
                })))));
};
