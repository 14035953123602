import { AppError, ErrorType } from '@app/model';
export function mapApolloError(error) {
    // eslint-disable-next-line no-console
    console.warn('[GraphQLError] Error:', JSON.stringify(error, null, 2));
    if (error.graphQLErrors || error.networkError) {
        return handleApolloError(error);
    }
    else {
        // eslint-disable-next-line no-console
        console.warn('[GraphQLError] Invalid error format.');
        return new AppError(ErrorType.Internal);
    }
}
function handleApolloError({ graphQLErrors, networkError: apolloNetworkError, message }) {
    const networkError = apolloNetworkError;
    if (isTimeoutError(message)) {
        return new AppError(ErrorType.Timeout);
    }
    if (isConnectionError(message)) {
        return new AppError(ErrorType.Connection);
    }
    if (networkError) {
        return handleNetworkError(networkError, message);
    }
    else if (graphQLErrors.length > 0) {
        return handleGraphQLError(graphQLErrors);
    }
    else {
        return new AppError(ErrorType.Internal, message);
    }
}
export function handleNetworkError(networkError, fallbackMessage) {
    var _a, _b, _c, _d, _e;
    let message;
    let type = ErrorType.Internal;
    if (((_b = (_a = networkError.result) === null || _a === void 0 ? void 0 : _a.errors) === null || _b === void 0 ? void 0 : _b.length) > 0) {
        const [error] = networkError.result.errors;
        message = (_c = error.message) !== null && _c !== void 0 ? _c : fallbackMessage;
    }
    else if (((_d = networkError.response) === null || _d === void 0 ? void 0 : _d.status) === 401) {
        type = ErrorType.Authorization;
    }
    else {
        // eslint-disable-next-line no-console
        console.warn('[NetworkError] Unexpected network error.');
        const errorMessage = (_e = networkError.bodyText) !== null && _e !== void 0 ? _e : fallbackMessage;
        message = errorMessage;
    }
    return new AppError(type, message);
}
function handleGraphQLError([graphQLError]) {
    const type = graphQLErrorCodeTypeMap[graphQLError.code];
    return new AppError(type, graphQLError.message);
}
const graphQLErrorCodeTypeMap = {
    401: ErrorType.Authorization,
    404: ErrorType.NotFound,
    default: ErrorType.Internal,
};
function isTimeoutError(message) {
    const TIMEOUT_ERROR_MESSAGE = 'timeout';
    return message.includes(TIMEOUT_ERROR_MESSAGE);
}
function isConnectionError(message) {
    const CONNECTION_ERROR_MESSAGE = 'Network request failed';
    return message.includes(CONNECTION_ERROR_MESSAGE);
}
