var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { Service } from 'typedi';
import { authLink } from '@app/core/graphql/auth.link';
let ApolloClientBuilderService = class ApolloClientBuilderService {
    build(url) {
        const link = authLink.concat(createUploadLink({ uri: url, headers: { 'Apollo-Require-Preflight': 'true' } }));
        const client = new ApolloClient({
            link,
            cache: new InMemoryCache(),
        });
        return client;
    }
};
ApolloClientBuilderService = __decorate([
    Service()
], ApolloClientBuilderService);
export { ApolloClientBuilderService };
