import { gql } from '@apollo/client';
import { UserFragmentDoc } from './user.fragment';
export const LoginDocument = gql `
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      token
      refreshToken
      user {
        ...User
        isFirstLogin
      }
    }
  }
  ${UserFragmentDoc}
`;
