import React from 'react';
import { useMutation } from '@app/core/graphql/graphql-mutation.hooks';
import { DeleteBookingAttachmentsDocument, } from '@app/data/graphql';
import { ONE_SECONDE_IN_MS } from '@app/utils/constant.utils';
import { dispatchFlashMessage } from '@atomic/mol.flash-message-container';
import { debounce } from 'lodash';
import { homeStrings } from '@app/modules/home/home.strings';
const strings = homeStrings.attachmentsSection;
export function useDeleteBookingAttachments(params) {
    const [deletingAttachments, setDeletingAttachments] = React.useState([]);
    const [deleteBookingAttachments] = useMutation(DeleteBookingAttachmentsDocument, {
        onCompleted: (deleteBookingAttachmentsResult) => {
            var _a;
            dispatchFlashMessage(strings.attachmentDeletedSuccessfully, 'success');
            (_a = params === null || params === void 0 ? void 0 : params.onCompleted) === null || _a === void 0 ? void 0 : _a.call(params, deleteBookingAttachmentsResult);
        },
        onError: (requestError) => {
            var _a;
            dispatchFlashMessage(requestError === null || requestError === void 0 ? void 0 : requestError.message);
            (_a = params === null || params === void 0 ? void 0 : params.onError) === null || _a === void 0 ? void 0 : _a.call(params, requestError);
        },
    });
    const handleDeleteAttachment = React.useRef(debounce((attachmentsToDelete) => {
        deleteBookingAttachments({
            input: {
                attachmentsIds: attachmentsToDelete,
            },
        });
        setDeletingAttachments([]);
    }, ONE_SECONDE_IN_MS)).current;
    const handleSelectFileForDeletion = (attachmentId) => {
        setDeletingAttachments((prev) => [...prev, attachmentId]);
        handleDeleteAttachment([...deletingAttachments, attachmentId]);
    };
    return {
        deleteAttachment: handleSelectFileForDeletion,
        deletingAttachments,
    };
}
