var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { SelectIconStyled, SelectStyled, SelectWrapperStyled, TextAreaMenuStyled, TextAreaStyled, } from './text-area-with-insert-option.component.style';
import { FormFieldContext } from '@atomic/obj.form';
import { generalStrings } from '@app/utils/general.string';
import { debounce } from 'lodash';
const strings = generalStrings;
export const TextAreaWithInsertOption = (_a) => {
    var { options } = _a, fieldProps = __rest(_a, ["options"]);
    const [option, setOption] = React.useState();
    const [cursorPosition, setCursorPosition] = React.useState(null);
    const formFieldConsumer = React.useContext(FormFieldContext);
    const textAreaRef = React.useRef();
    const handleInsertOption = (event) => {
        var _a, _b, _c;
        const data = (_a = event.currentTarget) === null || _a === void 0 ? void 0 : _a.value;
        if (data === strings.insertData) {
            return;
        }
        const optionToInsert = ` {{${data}}} `;
        if (cursorPosition === ((_b = formFieldConsumer === null || formFieldConsumer === void 0 ? void 0 : formFieldConsumer.value) === null || _b === void 0 ? void 0 : _b.length) || cursorPosition === null) {
            formFieldConsumer.onValueChange((formFieldConsumer === null || formFieldConsumer === void 0 ? void 0 : formFieldConsumer.value) + ' ' + optionToInsert, null);
            setOption(strings.insertData);
            textAreaRef.current.focus(cursorPosition !== null && cursorPosition !== void 0 ? cursorPosition : ((_c = formFieldConsumer === null || formFieldConsumer === void 0 ? void 0 : formFieldConsumer.value) === null || _c === void 0 ? void 0 : _c.length) + optionToInsert.length);
            return;
        }
        const textBeforeCursorPosition = formFieldConsumer === null || formFieldConsumer === void 0 ? void 0 : formFieldConsumer.value.substring(0, cursorPosition);
        const textAfterCursorPosition = formFieldConsumer === null || formFieldConsumer === void 0 ? void 0 : formFieldConsumer.value.substring(cursorPosition, formFieldConsumer === null || formFieldConsumer === void 0 ? void 0 : formFieldConsumer.value.length);
        formFieldConsumer.onValueChange(textBeforeCursorPosition + optionToInsert + textAfterCursorPosition, null);
        setOption(strings.insertData);
        const debounceSelectPosition = debounce((position) => {
            textAreaRef.current.focus(position);
        }, 100);
        debounceSelectPosition(cursorPosition + optionToInsert.length);
    };
    const handleBlur = (event) => {
        setCursorPosition(event.currentTarget.selectionStart);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(TextAreaMenuStyled, null,
            React.createElement(SelectWrapperStyled, null,
                React.createElement(SelectStyled, { onChange: handleInsertOption, value: option },
                    React.createElement("option", null, strings.insertData),
                    options.map((item) => {
                        return React.createElement("option", { key: item }, item);
                    })),
                React.createElement(SelectIconStyled, null))),
        React.createElement(TextAreaStyled, Object.assign({}, fieldProps, { expandToContentHeight: true, ref: textAreaRef, onBlur: handleBlur }))));
};
