import { useQuery } from '@app/core/graphql';
import { UsersDocument } from '@app/data/graphql/generated/users.query';
import { DEFAULT_PAGE_LIMIT } from '@app/utils/constant.utils';
export const useGetUsersUseCase = (params) => {
    var _a;
    const { data, loading, error } = useQuery(UsersDocument, {
        input: {
            limit: DEFAULT_PAGE_LIMIT,
            page: (_a = params.page) !== null && _a !== void 0 ? _a : 1,
        },
    });
    return {
        data: data === null || data === void 0 ? void 0 : data.users,
        loading,
        error,
    };
};
