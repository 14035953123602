import { useSyncTabLocalStorage } from '@app/core/storage';
import { useValidatePasswordCode } from '@app/domain/auth/validate-password-code.use-case';
import { useUserStore, userStoreInstance } from '@app/stores';
import { isTokenExpired } from '@app/utils/token.utils';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
export const useAuthorized = () => {
    const [user] = useUserStore();
    const isAuthenticated = !isTokenExpired(user === null || user === void 0 ? void 0 : user.token) || (user === null || user === void 0 ? void 0 : user.isRefreshingToken);
    useSyncTabLocalStorage({
        storageKey: localStorage.User,
        onChange: () => userStoreInstance.refreshState(),
    });
    return {
        authorized: isAuthenticated && !!(user === null || user === void 0 ? void 0 : user.email),
    };
};
export const useNotAuthorized = () => {
    return !useAuthorized();
};
export const useValidLink = () => {
    const [params] = useSearchParams();
    const { validateCode, data, error, loading, called } = useValidatePasswordCode();
    React.useEffect(() => {
        validateCode({
            input: {
                code: params.get('code'),
                email: params.get('email'),
            },
        });
    }, [params, validateCode]);
    return {
        authorized: !called || loading || (called && data && !loading) || (called && !error && !loading),
        params: {
            email: params.get('email'),
        },
    };
};
