import * as React from 'react';
import { useMutation as useApolloMutation } from '@apollo/client';
import { getMutation } from '@app/data/graphql/graphql-docs.service';
import { mapApolloError } from './graphql-error.mapper';
import { isDocumentNode } from '@apollo/client/utilities';
export function useMutation(
// When *every* mutation has been adapted, it will accept only DocumentNode
document, options = {}) {
    const query = isDocumentNode(document) ? document : getMutation(document);
    const [apolloMutate, res] = useApolloMutation(query, {
        onCompleted: options.onCompleted,
        onError: (error) => { var _a; return (_a = options.onError) === null || _a === void 0 ? void 0 : _a.call(options, mapApolloError(error)); },
        refetchQueries: options.refetchQueries,
    });
    const mutate = React.useCallback((variables) => {
        apolloMutate({ variables });
    }, [apolloMutate]);
    return [
        mutate,
        {
            called: res.called,
            loading: res.loading,
            data: res.data,
            error: res.error ? mapApolloError(res.error) : undefined,
        },
    ];
}
