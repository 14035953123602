import styled from 'styled-components';
import { Spacing } from '@atomic/obj.constants';
export const Hbox = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.hAlign ? props.hAlign : 'flex-start')};
`;
Hbox.displayName = 'Hbox';
Hbox.Item = styled.div `
  flex-direction: column;
  display: flex;
  ${(props) => (!props.noGrow ? 'flex: 1;' : null)}
  justify-content: ${(props) => (props.vAlign ? props.vAlign : 'flex-start')};
  align-items: ${(props) => (props.hAlign ? props.hAlign : 'stretch')};
`;
Hbox.Item.displayName = 'Hbox.Item';
Hbox.Separator = styled.div `
  width: ${(props) => { var _a; return (_a = props.size) !== null && _a !== void 0 ? _a : Spacing.Small; }};
`;
Hbox.Separator.displayName = 'Hbox.Separator';
export const Separator = styled.div `
  margin-bottom: ${(props) => { var _a; return (_a = props.size) !== null && _a !== void 0 ? _a : Spacing.Large; }};
`;
Separator.displayName = 'Separator';
