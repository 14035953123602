import { useQuery } from '@app/core/graphql';
import { BookingItemTemplateDocument, } from '@app/data/graphql';
export const useGetBookingItemTemplate = (params) => {
    const { data, loading, error } = useQuery(BookingItemTemplateDocument, {
        templateId: +params.templateId,
    }, {
        skip: params.skip,
    });
    return {
        data: data === null || data === void 0 ? void 0 : data.bookingItemTemplate,
        loading,
        error,
    };
};
