import { gql } from '@apollo/client';
import { GuestFragmentDoc } from './guest.fragment';
export const UpdateGuestDocument = gql `
  mutation updateGuest($input: UpdateGuestInput!) {
    updateGuest(input: $input) {
      ...Guest
    }
  }
  ${GuestFragmentDoc}
`;
