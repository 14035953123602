import * as React from 'react';
import { ActivityIndicator } from '@atomic/atm.activity-indicator';
import { LoadingStateBlockStyled, LoadingStateStyled, LoadingStyled } from './loading-state.style';
const StateShimmer = (props) => React.createElement(React.Fragment, null, props.children);
StateShimmer.displayName = 'StateShimmer';
const StateError = (props) => React.createElement(React.Fragment, null, props.children);
StateError.displayName = 'StateError';
const StateNoData = (props) => React.createElement(React.Fragment, null, props.children);
StateNoData.displayName = 'StateNoData';
export class LoadingState extends React.Component {
    render() {
        const { shimmer, error, noData, data } = this.getTypedChildren();
        return (React.createElement(LoadingStateStyled, null,
            React.createElement(LoadingStateBlockStyled, { visible: this.props.error && !this.props.loading && !this.props.data }, error),
            React.createElement(LoadingStateBlockStyled, { visible: !this.props.data && this.props.loading }, shimmer),
            React.createElement(LoadingStateBlockStyled, { visible: !this.props.error && !this.props.data && !this.props.loading }, noData),
            React.createElement(LoadingStateBlockStyled, { visible: this.props.data }, this.props.data && data),
            React.createElement(LoadingStyled, { visible: this.props.data && this.props.loading && this.props.enableActivityIndicator, topMost: this.props.topMost },
                React.createElement(ActivityIndicator, { type: 'spinner' }))));
    }
    getTypedChildren() {
        let shimmer;
        let error;
        let noData;
        const data = [];
        React.Children.map(this.props.children, (child) => {
            if (child === null || child === void 0 ? void 0 : child.type) {
                switch (child.type.displayName) {
                    case StateShimmer.displayName:
                        shimmer = child;
                        break;
                    case StateError.displayName:
                        error = child;
                        break;
                    case StateNoData.displayName:
                        noData = child;
                        break;
                    default:
                        data.push(child);
                }
            }
            else {
                data.push(child);
            }
        });
        return { shimmer, error, noData, data };
    }
}
LoadingState.defaultProps = {
    data: true,
    enableActivityIndicator: true,
    topMost: false,
};
LoadingState.Shimmer = StateShimmer;
LoadingState.Error = StateError;
LoadingState.NoData = StateNoData;
