var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { ActivityIndicator } from '@atomic/atm.activity-indicator';
import { AnchorButtonStyled, ButtonContentStyled, ButtonSpinnerStyled, ButtonStyled, LinkButtonStyled, } from './button.component.style';
export class Button extends React.Component {
    render() {
        let ButtonComponent = ButtonStyled;
        if (this.props.to || this.props.navigationState) {
            ButtonComponent = LinkButtonStyled;
        }
        if (this.props.href) {
            ButtonComponent = AnchorButtonStyled;
        }
        return getButton(ButtonComponent, this.props);
    }
}
Button.defaultProps = {
    disabled: false,
    expanded: false,
    loading: false,
    outlined: false,
    kind: 'primary',
    type: 'button',
};
const getButton = (Component, props) => {
    const { loading, disabled, type, outlined, target, expanded, navigationState, to } = props, others = __rest(props, ["loading", "disabled", "type", "outlined", "target", "expanded", "navigationState", "to"]);
    return (React.createElement(Component, Object.assign({ disabled: loading || disabled, type: !props.to && !props.href ? type : undefined, outlined: outlined ? 1 : 0, expanded: expanded ? 1 : 0, target: target, 
        // https://developers.google.com/web/tools/lighthouse/audits/noopener
        rel: target === '_blank' ? 'noopener' : undefined, state: navigationState, to: !disabled && to }, others),
        React.createElement(ButtonContentStyled, { processing: loading }, props.children),
        React.createElement(ButtonSpinnerStyled, { processing: loading, circular: others.circular },
            React.createElement(ActivityIndicator, { type: 'spinner' }))));
};
