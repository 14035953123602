import { css } from 'styled-components';
import { Spacing } from '@atomic/obj.constants';
// https://stackoverflow.com/a/13924997/3670829
export const textMaxLines = (maxLines, lineHeight, adjustment = 0) => `
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${maxLines};
  line-height: ${lineHeight}px;
  max-height: ${lineHeight * maxLines + adjustment}px;
  margin: 0;
`;
// https://css-tricks.com/snippets/css/remove-gray-highlight-when-tapping-links-in-mobile-safari/
export const highlightStyle = css `
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.05);
`;
export const clickableStyle = css `
  font-size: ${Spacing.Large};
  ${highlightStyle}
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.7;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
