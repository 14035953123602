import { Breadcrumb } from '@app/components/mol.breadcrumb/breadcrumb.component';
import { HorizontalNavbar } from '@app/components/mol.horizontal-navbar/horizontal-navbar.component';
import { VerticalNavbar } from '@app/components/mol.vertical-navbar/vertical-navbar.component';
import { useUserStore } from '@app/stores/user-store';
import React from 'react';
import { Outlet } from 'react-router-dom';
export const NavbarGlobal = ({ showBredcrumb }) => {
    const [user] = useUserStore();
    return (React.createElement(VerticalNavbar, null,
        React.createElement(HorizontalNavbar, { user: user }),
        showBredcrumb && React.createElement(Breadcrumb, null),
        React.createElement(Outlet, null)));
};
