import * as React from 'react';
import { handleSharedRef } from '@app/utils/ref.utils';
import { H2 } from '@atomic/atm.typography';
import { FlashMessageContainer } from '@atomic/mol.flash-message-container/flash-message-container.component';
import { Hbox } from '@atomic/obj.box';
import { ClickableIcon } from '@atomic/obj.clickable';
import { DialogPolyfillWrapper, useDialog } from '@atomic/obj.dialog/dialog.hook';
import { DialogStyled, ModalBodyStyled, ModalBoxStyled, ModalFooterStyled, ModalHeaderStyled, } from './modal.component.style';
import { AppIcon } from '@app/components/atm.app-icon';
import { Divider } from '@atomic/atm.divider';
export const Modal = React.forwardRef((props, forwardedRef) => {
    const { handleDismissEvent, handleRef, isPolyfill, dialogRef, isVisible } = useDialog();
    return (React.createElement(DialogPolyfillWrapper, { isPolyfill: isPolyfill },
        React.createElement(BaseModal, Object.assign({ handleDismissEvent: (e) => {
                var _a;
                (_a = props.onClose) === null || _a === void 0 ? void 0 : _a.call(props);
                handleDismissEvent(e);
            }, dialogRef: dialogRef, ref: (innerRef) => {
                handleSharedRef(innerRef, forwardedRef, handleRef);
            }, isVisible: isVisible }, props))));
});
Modal.displayName = 'Modal';
const BaseModal = React.forwardRef((props, ref) => {
    return (React.createElement(DialogStyled, { onClose: props.handleDismissEvent, onCancel: props.handleDismissEvent, ref: ref },
        React.createElement(ModalBoxStyled, { small: props.small, noPadding: props.noPadding },
            React.createElement(ModalHeaderStyled, { small: props.small },
                React.createElement(Hbox, null,
                    !!props.title && (React.createElement(Hbox.Item, { hAlign: 'flex-start' },
                        React.createElement(H2, { noMargin: true }, props.title))),
                    !props.noIcon && (React.createElement(Hbox.Item, { noGrow: true, vAlign: 'center', hAlign: 'center' },
                        React.createElement(ClickableIcon, { type: 'button', onClick: () => { var _a; return (_a = props.dialogRef) === null || _a === void 0 ? void 0 : _a.current.close(); } },
                            React.createElement(AppIcon.Close, { size: 'large', variant: 'grayDark', "aria-label": 'Fechar' })))))),
            React.createElement(Divider, { variant: 'grayLight' }),
            React.createElement(ModalBodyStyled, { small: props.small }, props.children),
            React.createElement(Divider, { variant: 'grayLight' }),
            React.createElement(ModalFooterStyled, { small: props.small }, props.footer)),
        props.isVisible && React.createElement(FlashMessageContainer, null)));
});
BaseModal.displayName = 'BaseModal';
