import { AppIcon } from '@app/components/atm.app-icon';
import { ContentWrapper } from '@app/components/atm.content-wrapper/content-wrapper.component';
import { ErrorPlaceholder } from '@app/components/mol.placeholder/error-placeholder.component';
import { useGetBookingStepsTemplate } from '@app/domain/booking/get-booking-steps-template.use-case';
import { Button } from '@atomic/atm.button';
import { Body, H1 } from '@atomic/atm.typography';
import { Spacing } from '@atomic/obj.constants';
import { Column, Grid, Row, Spacer } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import React from 'react';
import { ItemsTableShimmer } from './components/items-table.page.shimmer';
import { BookingStepsTable } from './components/steps-table.component';
import { flowStrings } from './flow.strings';
const strings = flowStrings;
const BookingStepsPage = () => {
    const bookingStepsTableRef = React.useRef();
    const { data, loading, error } = useGetBookingStepsTemplate();
    const handleCreateBookingStep = () => {
        var _a;
        (_a = bookingStepsTableRef === null || bookingStepsTableRef === void 0 ? void 0 : bookingStepsTableRef.current) === null || _a === void 0 ? void 0 : _a.addStep();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ContentWrapper, null,
            React.createElement(Row, { alignItems: 'center' },
                React.createElement(Column, { flex: 1, justifyContent: 'flex-start' },
                    React.createElement(H1, null, strings.bookingSteps),
                    React.createElement(Body, null, strings.bookingStepsDescription)),
                React.createElement(Column, null,
                    React.createElement(Button, { variant: 'primary', onClick: handleCreateBookingStep },
                        React.createElement(AppIcon.Add, { variant: 'white', size: 'large' }),
                        strings.createStep)))),
        React.createElement(Spacer, { "$height": Spacing.XXLarge }),
        React.createElement(Grid, null,
            React.createElement(LoadingState, { data: !loading && !error, loading: loading, error: !!error },
                React.createElement(LoadingState.Shimmer, null,
                    React.createElement(Row, { justifyContent: 'center', flex: 1 },
                        React.createElement(Column, { flex: 1 / 2 },
                            React.createElement(ItemsTableShimmer, null)))),
                React.createElement(LoadingState.NoData, null,
                    React.createElement(ErrorPlaceholder, { type: 'empty' })),
                React.createElement(LoadingState.Error, null,
                    React.createElement(ErrorPlaceholder, { type: 'notFound' })),
                React.createElement(Row, { justifyContent: 'center', flex: 1 },
                    React.createElement(Column, { flex: 1 / 2 },
                        React.createElement(BookingStepsTable, { ref: bookingStepsTableRef, steps: data }))),
                React.createElement(Spacer, { "$height": Spacing.XXLarge })))));
};
export default BookingStepsPage;
