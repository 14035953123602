import * as React from 'react';
import * as Scroll from 'react-scroll';
import { InputLabel } from '@atomic/atm.typography';
import { FormFieldCaption } from './form-field-caption.component';
import { FormContext, FormFieldContext } from './form.context';
/**
 * This component must wrap each field. It validates a field, get its value on
 * submission, show an error message (if needed).
 */
export class FormField extends React.Component {
    constructor(props) {
        var _a;
        super(props);
        this.required = (_a = this.props.validators) === null || _a === void 0 ? void 0 : _a.some((validator) => validator.error.name === 'Required');
        this.handleValueChange = (value, other) => {
            let val = value;
            let oth = other;
            // Its a checkbox, must verify if checked/unchecked from the "other" parameter
            if (this.isCheckboxValue(val)) {
                val = (this.state.value || []).slice();
                oth = Object.assign({}, this.state.other);
                const checkboxValue = value[0];
                const index = val.indexOf(checkboxValue, 0);
                if (other && index < 0) {
                    val.push(checkboxValue);
                }
                if (!other && index > -1) {
                    val.splice(index, 1);
                }
                oth[checkboxValue] = other;
            }
            if (this.isControlled()) {
                this.fireValueChange(val, oth);
                return;
            }
            // (other?.touched) -> validate on onValueChange for <Select />
            if (this.state.touched || (other === null || other === void 0 ? void 0 : other.touched)) {
                this.validate(val);
            }
            this.setState({ value: val, other: oth, dirty: true });
            this.fireValueChange(val, oth);
        };
        this.handleFocusChange = (focus) => {
            this.setState({ focus });
            if (!focus) {
                this.validate(this.state.value);
                this.setState({ touched: true });
            }
        };
        this.handleClear = () => {
            var _a, _b;
            this.setState({ value: null, other: null, dirty: true });
            (_b = (_a = this.props).onValueChange) === null || _b === void 0 ? void 0 : _b.call(_a, null, null);
        };
        this.isControlled = () => this.props.value !== undefined;
        this.isCheckboxValue = (value) => Array.isArray(value);
        this.state = {
            other: undefined,
            name: props.name,
            value: props.initialValue !== undefined ? props.initialValue : props.value,
            errors: [],
            touched: false,
            dirty: false,
            focus: false,
            onValueChange: this.handleValueChange,
            onFocusChange: this.handleFocusChange,
            onClear: this.handleClear,
        };
    }
    componentDidMount() {
        var _a;
        if (this.isControlled() && this.props.initialValue !== undefined) {
            throw new Error('Use either the initialValue prop, or the value prop, but not both');
        }
        return (_a = this.formContext) === null || _a === void 0 ? void 0 : _a.register(this);
    }
    componentDidUpdate(prevProps) {
        var _a, _b;
        if (prevProps.value !== this.props.value && this.props.value !== this.state.value) {
            if (this.state.touched) {
                this.validate(this.props.value);
            }
            let other = null;
            if (this.isCheckboxValue(this.props.value)) {
                other = this.state.other || {};
                Object.getOwnPropertyNames(other).forEach((id) => {
                    other[id] = this.props.value.findIndex((checkedId) => String(checkedId) === id) !== -1;
                });
                this.props.value.forEach((id) => {
                    if (!Object.hasOwn(other, id)) {
                        return (other = Object.assign(Object.assign({}, other), { [id]: true }));
                    }
                });
            }
            this.setState({ value: this.props.value, other, dirty: true });
            (_b = (_a = this.props).onValueChange) === null || _b === void 0 ? void 0 : _b.call(_a, this.props.value, other);
        }
    }
    componentWillUnmount() {
        var _a;
        return (_a = this.formContext) === null || _a === void 0 ? void 0 : _a.unregister(this);
    }
    render() {
        return (React.createElement(FormContext.Consumer, null, (formContext) => {
            this.formContext = formContext;
            return (React.createElement(FormFieldContext.Provider, { value: this.state },
                React.createElement(Scroll.Element, { name: this.props.name }),
                !!this.props.label && (React.createElement(InputLabel, { hasError: this.state.errors.length > 0 },
                    this.props.label,
                    this.required && '*')),
                this.props.children,
                React.createElement(FormFieldCaption, { errors: this.state.errors })));
        }));
    }
    validate(value) {
        var _a;
        const errors = [];
        // Pass it to each validator
        if (((_a = this.props.validators) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            for (const validator of this.props.validators) {
                // Add to validation array if errors
                if (!validator.validationFn(value)) {
                    errors.push(validator.error);
                }
            }
        }
        return this.setState({ errors });
    }
    fireValueChange(val, oth) {
        var _a, _b;
        (_b = (_a = this.props).onValueChange) === null || _b === void 0 ? void 0 : _b.call(_a, val, oth);
    }
}
