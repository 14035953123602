import React from 'react';
import { TextShimmerBoxStyled } from '@atomic/mol.shimmer/shimmer.component.style';
import { DEFAULT_PAGE_LIMIT } from '@app/utils/constant.utils';
export const ListShimmer = (props) => {
    var _a;
    return (React.createElement(React.Fragment, null, Array((_a = props.count) !== null && _a !== void 0 ? _a : DEFAULT_PAGE_LIMIT)
        .fill('')
        .map((_, index) => {
        var _a;
        return (React.createElement(TextShimmerBoxStyled, { key: index, height: (_a = props.height) !== null && _a !== void 0 ? _a : '40px' }));
    })));
};
