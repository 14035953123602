import React from 'react';
import { useMutation } from '@app/core/graphql/graphql-mutation.hooks';
import { CreateBookingItemTemplateDocument, } from '@app/data/graphql';
import { dispatchFlashMessage } from '@atomic/mol.flash-message-container/flash-message-container.component';
import { mapBookingItemInput } from './booking-item-input.mapper';
export function useCreateBookingItemTemplate(params = {}) {
    const [mutation, { error, loading }] = useMutation(CreateBookingItemTemplateDocument, {
        onCompleted: (createBookingItemTemplateResult) => { var _a; return (_a = params.onCompleted) === null || _a === void 0 ? void 0 : _a.call(params, createBookingItemTemplateResult); },
        onError: (requestError) => {
            var _a;
            dispatchFlashMessage(requestError.message);
            (_a = params.onError) === null || _a === void 0 ? void 0 : _a.call(params, requestError);
        },
    });
    const createItem = React.useCallback((input) => {
        mutation({ input: mapBookingItemInput(input) });
    }, [mutation]);
    return {
        createItem,
        error,
        loading,
    };
}
