import { AppIcon } from '@app/components/atm.app-icon';
import { ProgressBar } from '@app/components/mol.progress-bar/progress-bar.component';
import { BookingItemRecordStatus } from '@app/data/graphql';
import { Body, H4 } from '@atomic/atm.typography';
import { Spacing } from '@atomic/obj.constants';
import { Column, Row, Spacer } from '@atomic/obj.grid';
import React from 'react';
import { homeStrings } from '../home.strings';
import { BookingItemRecord } from './booking-item-record.component';
import { CustomBadge, CustomBadgeDivider } from './stepper-custom-badge.style';
const strings = homeStrings.drawer;
export const TaskDetailsTab = (props) => {
    var _a, _b;
    const [selectedStepId, setSelectedStepId] = React.useState();
    React.useEffect(() => {
        var _a, _b;
        setSelectedStepId(props.currentBookingStepId || ((_b = (_a = props.tasks[0]) === null || _a === void 0 ? void 0 : _a.step) === null || _b === void 0 ? void 0 : _b.id));
    }, [props.tasks, props.currentBookingStepId]);
    const selectedRecord = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = props.tasks) === null || _a === void 0 ? void 0 : _a.find((task) => task.step.id === selectedStepId)) === null || _b === void 0 ? void 0 : _b.records;
    }, [props.tasks, selectedStepId]);
    const tasksInActiveSteps = (_a = props.tasks) === null || _a === void 0 ? void 0 : _a.filter((task) => !!task.step.active);
    return (React.createElement(React.Fragment, null,
        React.createElement(Column, { flex: 1 },
            React.createElement(Row, { alignItems: 'center', style: { overflow: 'scroll' } }, tasksInActiveSteps.map((task, index) => {
                var _a, _b, _c, _d, _e;
                const isSelected = ((_a = task.step) === null || _a === void 0 ? void 0 : _a.id) === selectedStepId;
                const isAllTasksCompleted = !!((_b = task === null || task === void 0 ? void 0 : task.records) === null || _b === void 0 ? void 0 : _b.length) &&
                    ((_c = task.records) === null || _c === void 0 ? void 0 : _c.every((record) => record.status === BookingItemRecordStatus.Completed));
                const badgeColor = isSelected ? 'primary' : isAllTasksCompleted ? 'successLight' : 'accessory';
                return (React.createElement(React.Fragment, { key: (_d = task.step) === null || _d === void 0 ? void 0 : _d.id },
                    React.createElement(Column, { flex: 1, justifyContent: 'center', style: { height: '50px' } },
                        React.createElement(CustomBadge, { color: badgeColor, onClick: () => setSelectedStepId(task.step.id) },
                            React.createElement(Row, { alignItems: 'center', justifyContent: 'center', flex: 1 },
                                isAllTasksCompleted && (React.createElement(Column, null,
                                    React.createElement(AppIcon.Done, { variant: isSelected ? 'white' : 'grayDark' }))),
                                React.createElement(Spacer, { "$width": Spacing.Small }),
                                React.createElement(Column, null,
                                    React.createElement(Body, { variant: isSelected ? 'white' : 'black', lineLimit: 2 }, (_e = task.step) === null || _e === void 0 ? void 0 : _e.name))))),
                    index !== tasksInActiveSteps.length - 1 && (React.createElement(Column, { flex: 1 },
                        React.createElement(CustomBadgeDivider, null)))));
            }))),
        React.createElement(H4, { variant: 'black' }, strings.tasksChecklist),
        React.createElement(ProgressBar, { currentStep: (_b = selectedRecord === null || selectedRecord === void 0 ? void 0 : selectedRecord.filter((record) => record.status === BookingItemRecordStatus.Completed)) === null || _b === void 0 ? void 0 : _b.length, totalSteps: selectedRecord === null || selectedRecord === void 0 ? void 0 : selectedRecord.length }),
        React.createElement(Spacer, { "$height": Spacing.Large }),
        React.createElement(Row, { gap: Spacing.XXLarge },
            React.createElement(Column, { flex: 1 },
                React.createElement(Body, { variant: 'neutral' }, strings.tasksTable.task)),
            React.createElement(Column, { flex: 1 },
                React.createElement(Body, { variant: 'neutral' }, strings.tasksTable.deadline))),
        React.createElement(Spacer, { "$height": Spacing.Medium }), selectedRecord === null || selectedRecord === void 0 ? void 0 :
        selectedRecord.map((record) => (React.createElement(React.Fragment, null,
            React.createElement(BookingItemRecord, { key: record === null || record === void 0 ? void 0 : record.id, bookingItemRecord: record }),
            React.createElement(Spacer, { "$height": Spacing.Medium })))),
        React.createElement(Spacer, { "$height": Spacing.Large })));
};
