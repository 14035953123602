import { useQuery } from '@app/core/graphql';
import { UserDocument } from '@app/data/graphql/generated/user.query';
export const useGetUserUseCase = (params) => {
    const { data, loading, error } = useQuery(UserDocument, {
        input: {
            id: +params.id,
        },
    }, { skip: params.skip });
    return {
        data: data === null || data === void 0 ? void 0 : data.user,
        loading,
        error,
    };
};
