import * as React from 'react';
import { PageDisabledStyled, PageStyled, PaginationStyled } from './pagination.component.style';
import { AppIcon } from '@app/components/atm.app-icon';
import { ClickableIcon } from '@atomic/obj.clickable';
const FIRST_PAGE = 1;
const BOUNDARY_COUNT = 2; // Edge item plus possible separator
const SEPARATOR = '...';
const START_PAGE = 1;
const PaginationItem = (props) => {
    if (props.page === SEPARATOR) {
        return React.createElement(PageDisabledStyled, null, props.page);
    }
    const { first, last, active } = props;
    const handleClick = () => {
        props.onClick(parseInt(props.page));
    };
    return (React.createElement(PageStyled, { variant: 'neutral', outlined: true, onClick: handleClick, first: first, last: last, active: active }, props.page));
};
export const PaginationComponent = (props) => {
    const constructPaginationArray = () => {
        const paginationItems = [];
        const window = props.siblingCount * 2 + 1;
        const totalItems = window + BOUNDARY_COUNT * 2;
        if (totalItems >= props.total) {
            return [...new Array(props.total)].map((_value, index) => (index + 1).toString());
        }
        const untiedFirstSibling = props.current - props.siblingCount;
        const canTieWindowAndLeftBoundary = untiedFirstSibling <= START_PAGE + BOUNDARY_COUNT;
        const canTieWindowAndRightBoundary = untiedFirstSibling >= props.total - BOUNDARY_COUNT - window + 1;
        const firstSibling = canTieWindowAndLeftBoundary
            ? START_PAGE + BOUNDARY_COUNT
            : canTieWindowAndRightBoundary
                ? props.total - BOUNDARY_COUNT - window + 1
                : untiedFirstSibling;
        paginationItems.push(START_PAGE.toString());
        if (canTieWindowAndLeftBoundary) {
            paginationItems.push((START_PAGE + 1).toString());
        }
        else {
            paginationItems.push(SEPARATOR);
        }
        for (let page = firstSibling; page < firstSibling + window; page++) {
            paginationItems.push(page.toString());
        }
        if (canTieWindowAndRightBoundary) {
            paginationItems.push((props.total - 1).toString());
        }
        else {
            paginationItems.push(SEPARATOR);
        }
        paginationItems.push(props.total.toString());
        return paginationItems;
    };
    const constructedPaginationArray = constructPaginationArray();
    return (React.createElement(PaginationStyled, null,
        props.current > FIRST_PAGE && (React.createElement(ClickableIcon, { onClick: () => props.onPageChange(props.current - 1) },
            React.createElement(AppIcon.ChevronLeft, { size: 'large', variant: 'grayDark' }))),
        constructedPaginationArray.map((val, index) => (React.createElement(PaginationItem, { first: index === 0 || constructedPaginationArray[index - 1] === SEPARATOR, last: val === props.total.toString() || constructedPaginationArray[index + 1] === SEPARATOR, key: 'name' + index, active: val === props.current.toString(), onClick: props.onPageChange, page: val }))),
        props.current < props.total && (React.createElement(ClickableIcon, { onClick: () => props.onPageChange(props.current + 1) },
            React.createElement(AppIcon.ChevronRight, { size: 'large', variant: 'grayDark' })))));
};
