import React from 'react';
import { Breadcrumb } from '@app/components/mol.breadcrumb/breadcrumb.component';
import { HorizontalNavbar } from '@app/components/mol.horizontal-navbar/horizontal-navbar.component';
import { VerticalNavbar } from '@app/components/mol.vertical-navbar/vertical-navbar.component';
import { Sidebar } from '@app/components/org.sidebar';
import { FlowMenu } from '@app/modules/flow/components/flow-menu.component';
import { useUserStore } from '@app/stores/user-store';
import { Row } from '@atomic/obj.grid/grid.component';
import { Outlet } from 'react-router-dom';
import { SidebarPageWrapper } from './flow-sidebar-element.component.style';
export const FlowSidebar = () => {
    const [user] = useUserStore();
    return (React.createElement(VerticalNavbar, null,
        React.createElement(HorizontalNavbar, { user: user }),
        React.createElement(Row, null,
            React.createElement(Sidebar, { collapsable: true },
                React.createElement(FlowMenu, null)),
            React.createElement(SidebarPageWrapper, null,
                React.createElement(Breadcrumb, null),
                React.createElement(Outlet, null)))));
};
