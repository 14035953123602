import { gql } from '@apollo/client';
import { HotelFragmentDoc } from './hotel.fragment';
export const UpdateHotelDocument = gql `
  mutation UpdateHotel($input: UpdateHotelInput!) {
    updateHotel(input: $input) {
      ...Hotel
    }
  }
  ${HotelFragmentDoc}
`;
