import styled, { css } from 'styled-components';
import { FaIcon } from '@atomic/atm.fa-icon/fa-icon.component';
import { clickableFocusCss } from '@atomic/mol.focus/focus.style';
import { Spacing, Color, Border, Breakpoint } from '@atomic/obj.constants';
import { fieldBorderCss, fieldCss } from '@atomic/obj.form/field.component.styled';
export const SelectWrapperStyled = styled.div `
  ${fieldBorderCss}
  position: relative;
  padding: 0;
  display: flex;
  align-items: center;

  @media all and (min-width: ${Breakpoint.md}px) {
    :hover {
      box-shadow: none;
      border-color: ${Color.Gray};
    }
  }

  ${(props) => props.disabled &&
    css `
      color: ${Color.Gray};
      -webkit-text-fill-color: ${Color.Gray};
      background-color: ${Color.GrayXLight};

      :hover {
        cursor: initial;
        border: ${Border.Width} solid;
        border-color: ${Color.GrayLight};
        box-shadow: none;
      }
    `}

  &:focus-within {
    ${clickableFocusCss}
  }
`;
export const SelectStyled = styled.select `
  appearance: none;
  border: 0;
  min-width: 100px;
  outline: none;
  ${fieldCss}
  padding: ${Spacing.Small} ${Spacing.Medium};

  ${(props) => props.disabled &&
    css `
      color: ${Color.Gray};
      -webkit-text-fill-color: ${Color.Gray};
      background-color: ${Color.GrayXLight};
    `}
`;
export const SelectIconStyled = styled(FaIcon.ChevronDown) `
  padding: 0 ${Spacing.Small};
  color: ${Color.GrayDark};
  pointer-events: none;
`;
