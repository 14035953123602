import React, { Suspense } from 'react';
import { GuardElement } from '@app/core/route/guard-element.component';
import { LazyFirstLoginPage, LazyForgotPasswordPage, LazyLoginPage, LazyResetPasswordPage } from '@app/modules/auth';
import AuthPage from '@app/modules/auth/auth.page';
import { AuthRoutes } from '@app/modules/auth/auth.routes';
import HomePage from '@app/modules/home/home.page';
import { HomeRoutes } from '@app/modules/home/home.routes';
import { useAuthorized, useValidLink } from '@app/route-guard/useAuthGuard.hook';
import { LoadingCentered } from '@atomic/mol.loading';
import { Route, Routes } from 'react-router-dom';
import { HotelsRoutes } from '@app/modules/hotels/hotels.routes';
import HotelsPage from '@app/modules/hotels/hotels.page';
import HotelFormPage from '@app/modules/hotels/hotel-form.page';
import { NavbarGlobal } from '@app/core/route/navbar-global-element.component';
import { AccountRoutes } from '@app/modules/account/account.routes';
import { AccountPage } from '@app/modules/account/account.page';
import { UsersRoutes } from '@app/modules/users/users.route';
import UsersPage from '@app/modules/users/users.page';
import UserFormPage from '@app/modules/users/user-form.page';
import { Permission } from '@app/data/graphql/base-schema';
import { LazyNotFoundPage } from '@app/modules/common/404';
import { FlowRoutes } from '@app/modules/flow/flow.routes';
import ChangePasswordPage from '@app/modules/auth/change-password.page';
import BookingItemsPage from '@app/modules/flow/booking-items.page';
import BookingStepsPage from '@app/modules/flow/booking-steps.page';
import { FlowSidebar } from '@app/core/route/flow-sidebar-element.component';
import { FlowRedirect } from '@app/core/route/flow-redirect.component';
import BookingItemTemplateFormPage from '@app/modules/flow/booking-item-template-form.page';
import HotelsConfigPage from '@app/modules/flow/hotels-config.page';
import HotelFlowPage from '@app/modules/flow/hotel-flow.page';
import HotelBookingItemPage from '@app/modules/flow/hotel-booking-item-details.page';
import { LazyExpiredLinkPage } from '@app/modules/common/expired-link';
import { BookingDrawerPage } from '@app/modules/home/booking-drawer.page';
import { SideDrawerLayout } from '@app/components/atm.side-drawer/side-drawer-layout.component';
import { BookingGuestDrawerPage } from '@app/modules/home/booking-guest-drawer.page';
import { PageViewAnalytics } from '@app/modules/page-view-listener';
export const RootRouter = () => (React.createElement(PageViewAnalytics, null,
    React.createElement(Suspense, { fallback: React.createElement(LoadingCentered, null) },
        React.createElement(Routes, null,
            React.createElement(Route, { path: AuthRoutes.Base, element: React.createElement(AuthPage, null) },
                React.createElement(Route, { index: true, element: React.createElement(LazyLoginPage, null) }),
                React.createElement(Route, { path: AuthRoutes.ForgotPassword, element: React.createElement(LazyForgotPasswordPage, null) }),
                React.createElement(Route, { path: AuthRoutes.FirstLogin, element: React.createElement(LazyFirstLoginPage, null) }),
                React.createElement(Route, { path: '*', element: React.createElement(LazyNotFoundPage, null) })),
            React.createElement(Route, { path: AuthRoutes.Base, element: React.createElement(AuthPage, null) },
                React.createElement(Route, { element: React.createElement(GuardElement, { sendPreviousPath: true, redirectPath: AuthRoutes.ExpiredLink, useGuard: useValidLink }) },
                    React.createElement(Route, { path: AuthRoutes.ResetForgotPassword, element: React.createElement(LazyResetPasswordPage, null) })),
                React.createElement(Route, { path: '*', element: React.createElement(LazyNotFoundPage, null) })),
            React.createElement(Route, { path: AuthRoutes.ExpiredLink, element: React.createElement(LazyExpiredLinkPage, null) }),
            React.createElement(Route, { path: AccountRoutes.Base, element: React.createElement(GuardElement, { sendPreviousPath: true, redirectPath: AuthRoutes.Base, useGuard: useAuthorized }) },
                React.createElement(Route, { path: AccountRoutes.Base, element: React.createElement(NavbarGlobal, { showBredcrumb: true }) },
                    React.createElement(Route, { index: true, element: React.createElement(AccountPage, null) }),
                    React.createElement(Route, { path: AccountRoutes.ResetPassword, element: React.createElement(ChangePasswordPage, null) })),
                React.createElement(Route, { path: '*', element: React.createElement(LazyNotFoundPage, null) })),
            React.createElement(Route, { path: UsersRoutes.Base, element: React.createElement(GuardElement, { sendPreviousPath: true, redirectPath: AuthRoutes.Base, useGuard: useAuthorized, permissions: [Permission.UsersModule] }) },
                React.createElement(Route, { path: UsersRoutes.Base, element: React.createElement(NavbarGlobal, { showBredcrumb: true }) },
                    React.createElement(Route, { index: true, element: React.createElement(UsersPage, null) }),
                    React.createElement(Route, { path: UsersRoutes.Details, element: React.createElement(UserFormPage, null) }),
                    React.createElement(Route, { path: UsersRoutes.RegisterUser, element: React.createElement(UserFormPage, null) })),
                React.createElement(Route, { path: '*', element: React.createElement(LazyNotFoundPage, null) })),
            React.createElement(Route, { path: HotelsRoutes.Base, element: React.createElement(GuardElement, { sendPreviousPath: true, redirectPath: AuthRoutes.Base, useGuard: useAuthorized, permissions: [Permission.HotelsModule] }) },
                React.createElement(Route, { path: HotelsRoutes.Base, element: React.createElement(NavbarGlobal, { showBredcrumb: true }) },
                    React.createElement(Route, { index: true, element: React.createElement(HotelsPage, null) }),
                    React.createElement(Route, { path: HotelsRoutes.Details, element: React.createElement(HotelFormPage, null) }),
                    React.createElement(Route, { path: HotelsRoutes.RegisterHotel, element: React.createElement(HotelFormPage, null) })),
                React.createElement(Route, { path: '*', element: React.createElement(LazyNotFoundPage, null) })),
            React.createElement(Route, { path: FlowRoutes.Base, element: React.createElement(GuardElement, { sendPreviousPath: true, redirectPath: AuthRoutes.Base, useGuard: useAuthorized, permissions: [Permission.OperationalFlowModule] }) },
                React.createElement(Route, { path: FlowRoutes.Base, element: React.createElement(FlowSidebar, null) },
                    React.createElement(Route, { index: true, element: React.createElement(FlowRedirect, null) }),
                    React.createElement(Route, { path: FlowRoutes.Steps, element: React.createElement(BookingStepsPage, null) }),
                    React.createElement(Route, { path: FlowRoutes.Items },
                        React.createElement(Route, { index: true, element: React.createElement(BookingItemsPage, null) }),
                        React.createElement(Route, { path: FlowRoutes.ItemDetails, element: React.createElement(BookingItemTemplateFormPage, null) }),
                        React.createElement(Route, { path: FlowRoutes.CreateItem, element: React.createElement(BookingItemTemplateFormPage, null) })),
                    React.createElement(Route, { path: FlowRoutes.Settings },
                        React.createElement(Route, { index: true, element: React.createElement(HotelsConfigPage, null) }),
                        React.createElement(Route, { path: FlowRoutes.HotelFlowDetails },
                            React.createElement(Route, { index: true, element: React.createElement(HotelFlowPage, null) }),
                            React.createElement(Route, { path: FlowRoutes.HotelBookingItemDetails, element: React.createElement(HotelBookingItemPage, null) })),
                        React.createElement(Route, { path: '*', element: React.createElement(LazyNotFoundPage, null) }))),
                React.createElement(Route, { path: '*', element: React.createElement(LazyNotFoundPage, null) })),
            React.createElement(Route, { path: HomeRoutes.Base, element: React.createElement(GuardElement, { sendPreviousPath: true, redirectPath: AuthRoutes.Base, useGuard: useAuthorized, permissions: [Permission.BookingsModule] }) },
                React.createElement(Route, { path: HomeRoutes.Base, element: React.createElement(NavbarGlobal, null) },
                    React.createElement(Route, { path: HomeRoutes.Base, element: React.createElement(HomePage, null) },
                        React.createElement(Route, { path: HomeRoutes.Booking, element: React.createElement(SideDrawerLayout, null) },
                            React.createElement(Route, { path: HomeRoutes.BookingId, element: React.createElement(BookingDrawerPage, null) },
                                React.createElement(Route, { path: HomeRoutes.BookingEditGuest, element: React.createElement(BookingGuestDrawerPage, null) }))))),
                React.createElement(Route, { path: '*', element: React.createElement(LazyNotFoundPage, null) })),
            React.createElement(Route, { path: '*', element: React.createElement(LazyNotFoundPage, null) })))));
