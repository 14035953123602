var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { ContentWrapper } from '@app/components/atm.content-wrapper/content-wrapper.component';
import { useChangePassword } from '@app/domain/auth/change-password.use-case';
import { Body, H1 } from '@atomic/atm.typography';
import { dispatchFlashMessage } from '@atomic/mol.flash-message-container';
import { Spacing } from '@atomic/obj.constants';
import { Column, Grid, Row, Spacer } from '@atomic/obj.grid';
import { authStrings } from './auth.strings';
import { Form, Validators } from '@atomic/obj.form';
import { FieldRules } from '@atomic/mol.field-rules/field-rules.component';
import { PasswordField } from '@atomic/mol.password-field';
import { Button } from '@atomic/atm.button';
import { useNavigate } from 'react-router-dom';
const strings = authStrings;
var ResetPasswordFormFields;
(function (ResetPasswordFormFields) {
    ResetPasswordFormFields["oldPassword"] = "oldPassword";
    ResetPasswordFormFields["newPassword"] = "newPassword";
    ResetPasswordFormFields["confirmPassword"] = "confirmPassword";
})(ResetPasswordFormFields || (ResetPasswordFormFields = {}));
const passwordValidators = [
    Validators.MinLength(6, authStrings.passwordRules.minLength),
    Validators.HasNumber(authStrings.passwordRules.hasNumber),
    Validators.HasLetter(authStrings.passwordRules.hasLetter),
];
export const ChangePasswordPage = () => {
    const [password, setPassword] = React.useState('');
    const navigate = useNavigate();
    const { changePassword, loading } = useChangePassword({
        onCompleted: (response) => {
            dispatchFlashMessage(response.changePassword.message, 'success');
            navigate(-1);
        },
    });
    const handleSubmit = (formData) => {
        if (formData.hasError) {
            return;
        }
        const _a = formData.data, { confirmPassword } = _a, data = __rest(_a, ["confirmPassword"]);
        changePassword({ input: data });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ContentWrapper, null,
            React.createElement(H1, null, strings.changePassword),
            React.createElement(Spacer, { "$height": Spacing.Large })),
        React.createElement(Grid, null,
            React.createElement(Row, { justifyContent: 'center', gap: Spacing.XXLarge },
                React.createElement(Column, { flex: 1 / 4, gap: Spacing.Large },
                    React.createElement(Body, null, strings.chooseNewPassword),
                    React.createElement(FieldRules, { value: password, validators: passwordValidators })),
                React.createElement(Column, { flex: 1 / 2 },
                    React.createElement(Form, { onSubmit: handleSubmit },
                        React.createElement(Form.Field, { label: authStrings.currentPassword, name: ResetPasswordFormFields.oldPassword, validators: [Validators.Required()] },
                            React.createElement(PasswordField, { inputLabel: true })),
                        React.createElement(Spacer, { "$height": Spacing.Large }),
                        React.createElement(Form.Field, { label: authStrings.newPassword, name: ResetPasswordFormFields.newPassword, validators: [Validators.Required()], onValueChange: (value) => setPassword(value) },
                            React.createElement(PasswordField, { inputLabel: true })),
                        React.createElement(Spacer, { "$height": Spacing.Large }),
                        React.createElement(Form.Field, { label: authStrings.confirmPassword, name: ResetPasswordFormFields.confirmPassword, validators: [
                                Validators.Required(),
                                Validators.IsNotEqualToField(password, authStrings.passwordsMustMatch),
                            ] },
                            React.createElement(PasswordField, { inputLabel: true })),
                        React.createElement(Spacer, { "$height": Spacing.Large }),
                        React.createElement(Row, { justifyContent: 'flex-end' },
                            React.createElement(Button, { variant: 'callToAction', type: 'submit', loading: loading }, authStrings.changePassword))))))));
};
export default ChangePasswordPage;
