export const hotelStrings = {
    table: {
        hotelName: 'Nome do prédio',
        hotelId: 'ID HIGS',
        status: 'Status',
        active: 'Ativo',
        inactive: 'Inativo',
        stepFlow: 'Fluxo de etapas',
        updateHotel: 'Editar prédio',
        filters: {
            placeholder: 'Status',
            active: 'Ativo',
            inactive: 'Inativo',
        },
    },
    hotelForm: {
        atLeastOneOf: 'Informe um email e/ou número de WhatsApp',
        publish: 'Salvar prédio',
        cancel: 'Cancelar',
        contactViaChat: 'Para comunicação via WhatsApp',
        idHIGS: 'Código do prédio',
        email: 'E-mail',
        name: 'Nome do prédio',
        alias: 'Apelido do prédio',
        aliasDescription: 'Sigla que identifica o prédio',
        telephone: 'Telefone da portaria',
        active: 'Prédio ativo',
        addressFields: {
            addressNumber: 'Número',
            city: 'Cidade',
            complement: 'Complemento',
            district: 'Bairro',
            postalCode: 'CEP',
            stateCode: 'Estado',
            street: 'Logradouro',
        },
        placeholder: {
            idHIGS: 'Código',
            email: 'exemplo@predio.com.br',
            name: 'Nome do empreendimento',
            telephone: '(ddd) 90000-0000',
            alias: 'RVM',
            addressNumber: 'Número do local',
            city: 'Nome da cidade',
            complement: 'Apartamento, bloco...',
            district: 'Bairro/Distrito',
            postalCode: '00000-000',
            stateCode: '',
            street: 'Rua/Avenida',
        },
    },
    hotelsEditing: 'PRÉDIOS',
    registerNewHotel: 'Cadastrar novo prédio',
    newHotelRegistration: 'CADASTRO DE NOVO PRÉDIO',
    hotelEditing: 'EDITAR PRÉDIO',
    updatedSuccessfuly: 'Prédio atualizado com sucesso!',
    createdSuccessfuly: 'Prédio criado com sucesso!',
    searchBarPlaceholder: 'Buscar prédio',
};
