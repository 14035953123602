var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { setContext } from '@apollo/client/link/context';
import { isTokenExpired } from '@app/utils/token.utils';
import { userStoreInstance } from '@app/stores';
import { ApolloClient } from '@apollo/client';
import Container from 'typedi';
import { RefreshTokenDocument } from '@app/data/graphql';
const publicOperations = ['Login', 'ForgotPassword', 'ValidatePasswordCode', 'ResetPassword'];
export const authLink = setContext((request, { headers }) => __awaiter(void 0, void 0, void 0, function* () {
    const user = userStoreInstance.getState();
    const setUser = userStoreInstance.setState;
    const invokeRefreshToken = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c;
        setUser(Object.assign(Object.assign({}, user), { isRefreshingToken: true }));
        const client = Container.get(ApolloClient);
        try {
            const { data } = yield client.mutate({
                mutation: RefreshTokenDocument,
                variables: {
                    input: {
                        token: user === null || user === void 0 ? void 0 : user.token,
                        refreshToken: user === null || user === void 0 ? void 0 : user.refreshToken,
                        userId: +(user === null || user === void 0 ? void 0 : user.id),
                    },
                },
            });
            setUser(Object.assign(Object.assign({}, (_a = data === null || data === void 0 ? void 0 : data.refreshToken) === null || _a === void 0 ? void 0 : _a.user), { refreshToken: (_b = data === null || data === void 0 ? void 0 : data.refreshToken) === null || _b === void 0 ? void 0 : _b.refreshToken, token: (_c = data === null || data === void 0 ? void 0 : data.refreshToken) === null || _c === void 0 ? void 0 : _c.token, isRefreshingToken: false }));
        }
        catch (error) {
            setUser(Object.assign(Object.assign({}, user), { isRefreshingToken: false }));
            userStoreInstance.remove();
        }
    });
    if (!publicOperations.includes(request.operationName) && isTokenExpired(user === null || user === void 0 ? void 0 : user.token) && !(user === null || user === void 0 ? void 0 : user.isRefreshingToken)) {
        yield invokeRefreshToken();
    }
    return {
        headers: Object.assign(Object.assign({}, headers), { authorization: user === null || user === void 0 ? void 0 : user.token }),
    };
}));
