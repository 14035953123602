export var BrandColor;
(function (BrandColor) {
    BrandColor["Pink"] = "#FF388B";
    BrandColor["Purple"] = "#663AE7";
    BrandColor["BlueLight"] = "#66ADE0";
    BrandColor["Blue"] = "#0077CB";
    BrandColor["BlueDark"] = "#005FA2";
    BrandColor["YellowLight"] = "#E9EBA9";
    BrandColor["Yellow"] = "#DBDE70";
    BrandColor["YellowDark"] = "#AFB25A";
    BrandColor["AzureBreeze"] = "#F0F9FF";
    BrandColor["PastelBlue"] = "#CCEDF0";
    BrandColor["SoftPink"] = "#F5EBF9";
    BrandColor["Lilac"] = "#F0F4FF";
    BrandColor["SoftOrange"] = "#FFEFE5";
    BrandColor["SoftYellow"] = "#FAF8EA";
    BrandColor["Apricot"] = "#FED8B4";
    BrandColor["SalmonPink"] = "#FEBDB4";
})(BrandColor || (BrandColor = {}));
export var FeedbackColor;
(function (FeedbackColor) {
    FeedbackColor["GreenLight"] = "#D6F5E6";
    FeedbackColor["Green"] = "#29A567";
    FeedbackColor["GreenDark"] = "#145233";
    FeedbackColor["BrownLight"] = "#FEEDB4";
    FeedbackColor["Brown"] = "#B2911E";
    FeedbackColor["RedLight"] = "#FADBDF";
    FeedbackColor["Red"] = "#E54D5D";
    FeedbackColor["RedDark"] = "#892E38";
})(FeedbackColor || (FeedbackColor = {}));
// We used class rather than enums since enums don't support computed values
export class Color {
}
Color.Black = '#222222';
Color.White = 'white';
Color.GrayXLight = '#F2F4F4';
Color.GrayLight = '#E5E9EA';
Color.Gray = '#99A6AB';
Color.GrayDark = '#646C6F';
Color.GrayXDark = '#2E3233';
Color.Primary = BrandColor.Blue;
Color.PrimaryDark = BrandColor.BlueDark;
Color.Secondary = Color.Black;
Color.Neutral = Color.GrayDark;
Color.CallToAction = BrandColor.Yellow;
Color.Accessory = BrandColor.AzureBreeze;
Color.Accent = BrandColor.PastelBlue;
Color.Accessory2 = BrandColor.SoftPink;
Color.AlertLight = FeedbackColor.RedLight;
Color.Alert = FeedbackColor.Red;
Color.AlertDark = FeedbackColor.RedDark;
Color.WarningLight = FeedbackColor.BrownLight;
Color.Warning = FeedbackColor.Brown;
Color.SuccessLight = FeedbackColor.GreenLight;
Color.Success = FeedbackColor.Green;
Color.SuccessDark = FeedbackColor.GreenDark;
export var FontFamily;
(function (FontFamily) {
    FontFamily["Primary"] = "Corsa Grotesk, Arial";
})(FontFamily || (FontFamily = {}));
export var FontWeight;
(function (FontWeight) {
    FontWeight[FontWeight["Medium"] = 500] = "Medium";
    FontWeight["Bold"] = "bold";
    FontWeight["Normal"] = "normal";
    FontWeight[FontWeight["Lighter"] = 300] = "Lighter";
})(FontWeight || (FontWeight = {}));
export var FontSize;
(function (FontSize) {
    FontSize["XSmall"] = "12px";
    FontSize["Small"] = "16px";
    FontSize["Medium"] = "20px";
    FontSize["Large"] = "24px";
    FontSize["XLarge"] = "32px";
})(FontSize || (FontSize = {}));
export var Spacing;
(function (Spacing) {
    Spacing["XSmall"] = "4px";
    Spacing["Small"] = "8px";
    Spacing["Medium"] = "16px";
    Spacing["Large"] = "24px";
    Spacing["XLarge"] = "32px";
    Spacing["XXLarge"] = "48px";
})(Spacing || (Spacing = {}));
export const HeaderHeight = {
    Mobile: '64px',
    Desk: '80px',
};
export var IconSize;
(function (IconSize) {
    IconSize["Small"] = "12px";
    IconSize["Medium"] = "16px";
    IconSize["Large"] = "24px";
    IconSize["XLarge"] = "40px";
})(IconSize || (IconSize = {}));
export var ThumbnailSize;
(function (ThumbnailSize) {
    ThumbnailSize["Small"] = "24px";
    ThumbnailSize["Medium"] = "48px";
    ThumbnailSize["Large"] = "80px";
    ThumbnailSize["XLarge"] = "160px";
    ThumbnailSize["XXLarge"] = "228px";
})(ThumbnailSize || (ThumbnailSize = {}));
export var FaIconSize;
(function (FaIconSize) {
    FaIconSize["XSmall"] = "xs";
    FaIconSize["Large"] = "lg";
    FaIconSize["Small"] = "sm";
    FaIconSize["X1"] = "1x";
    FaIconSize["X2"] = "2x";
    FaIconSize["X3"] = "3x";
    FaIconSize["X4"] = "4x";
    FaIconSize["X5"] = "5x";
    FaIconSize["X6"] = "6x";
    FaIconSize["X7"] = "7x";
    FaIconSize["X8"] = "8x";
    FaIconSize["X9"] = "9x";
    FaIconSize["X10"] = "10x";
})(FaIconSize || (FaIconSize = {}));
export const DrawerWidth = '400px';
export const Navbar = {
    a: '1',
};
export const VerticalNavbarWidth = 125;
export const HorizontalNavbarHeight = 72;
export const SidebarWidth = 260;
export const FieldHeight = '48px';
export const TransitionDuration = '.3s';
export const Border = {
    Color: Color.GrayLight,
    Width: '1px',
    Radius: '8px',
    RadiusLarge: '16px',
};
export const BaseBorder = `${Border.Width} solid ${Border.Color}`;
// Security recommendation: Failing to enforce passwords of at least 7 characters, a complexity
// of at least alpha and numeric characters increases the risk of a brute force attack.
export var PasswordLength;
(function (PasswordLength) {
    PasswordLength[PasswordLength["Max"] = 18] = "Max";
    PasswordLength[PasswordLength["Min"] = 7] = "Min";
})(PasswordLength || (PasswordLength = {}));
export var AspectRatio;
(function (AspectRatio) {
    AspectRatio[AspectRatio["Square"] = 1] = "Square";
})(AspectRatio || (AspectRatio = {}));
export const MaxContainerWidth = {
    xs: '540px',
    sm: '540px',
    md: '720px',
    lg: '960px',
    xl: '1140px',
    xxl: '1420px',
};
export const Breakpoint = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1600,
};
export const MediaQuery = {
    xs: '@media all and (min-width: 0)',
    sm: `@media all and (min-width: ${Breakpoint.sm - 0.2}px)`,
    md: `@media all and (min-width: ${Breakpoint.md - 0.2}px)`,
    lg: `@media all and (min-width: ${Breakpoint.lg - 0.2}px)`,
    xl: `@media all and (min-width: ${Breakpoint.xl - 0.2}px)`,
    xxl: `@media all and (min-width: ${Breakpoint.xxl - 0.2}px)`,
};
export var ZIndex;
(function (ZIndex) {
    ZIndex[ZIndex["ground"] = 0] = "ground";
    ZIndex[ZIndex["first"] = 1] = "first";
    ZIndex[ZIndex["second"] = 2] = "second";
    ZIndex[ZIndex["third"] = 3] = "third";
    ZIndex[ZIndex["top"] = 4] = "top";
})(ZIndex || (ZIndex = {}));
export const Shadow = {
    Small: '0px 2px 2px 0px rgba(0,0,0,0.2)',
    Medium: '1px 1px 8px 0px rgba(0,0,0,0.2)',
};
