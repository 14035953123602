import { useQuery } from '@app/core/graphql';
import { WhatsappTemplatesDocument } from '@app/data/graphql';
export const useGetWhatsappTemplates = (params) => {
    const { data, loading, error } = useQuery(WhatsappTemplatesDocument, {}, {
        onCompleted: (response) => { var _a; return (_a = params === null || params === void 0 ? void 0 : params.onCompleted) === null || _a === void 0 ? void 0 : _a.call(params, response); },
        onError: (err) => { var _a; return (_a = params === null || params === void 0 ? void 0 : params.onError) === null || _a === void 0 ? void 0 : _a.call(params, err); },
    });
    return {
        data,
        loading,
        error,
    };
};
