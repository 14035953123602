/* eslint-disable react/display-name */
import * as React from 'react';
import { IcSvgStyled } from './app-icon.component.style';
import LogoImage from '@assets/icons/apple-touch-icon.png';
import LoadingTooltipArrowImage from '@assets/img/img_tooltip_arrow.png';
import IcWarning from '@assets/icons/as-component/ic_warning.svg';
import IcAdd from '@assets/icons/as-component/ic_add.svg';
import IcAttachment from '@assets/icons/as-component/ic_attachment.svg';
import IcAvatar from '@assets/icons/as-component/ic_avatar.svg';
import IcClose from '@assets/icons/as-component/ic_close.svg';
import IcCopy from '@assets/icons/as-component/ic_copy.svg';
import IcDone from '@assets/icons/as-component/ic_done.svg';
import IcDoneCircular from '@assets/icons/as-component/ic_done_circular.svg';
import IcDragIndicator from '@assets/icons/as-component/ic_drag_indicator.svg';
import IcLogo from '@assets/icons/as-component/ic_logo.svg';
import IcEyeOpened from '@assets/icons/as-component/ic_eye_opened.svg';
import IcEyeClosed from '@assets/icons/as-component/ic_eye_closed.svg';
import IcHomeFilled from '@assets/icons/as-component/ic_home_filled.svg';
import IcHome from '@assets/icons/as-component/ic_home.svg';
import IcHotelFilled from '@assets/icons/as-component/ic_hotel_filled.svg';
import IcHotel from '@assets/icons/as-component/ic_hotel.svg';
import IcFlowFilled from '@assets/icons/as-component/ic_flow_filled.svg';
import IcFlow from '@assets/icons/as-component/ic_flow.svg';
import IcFilter from '@assets/icons/as-component/ic_filter.svg';
import IcChatFilled from '@assets/icons/as-component/ic_chat_filled.svg';
import IcChat from '@assets/icons/as-component/ic_chat.svg';
import IcChevronLeft from '@assets/icons/as-component/ic_chevron_left.svg';
import IcChevronRight from '@assets/icons/as-component/ic_chevron_right.svg';
import IcEdit from '@assets/icons/as-component/ic_edit.svg';
import IcChevronUp from '@assets/icons/as-component/ic_chevron_up.svg';
import IcChevronDown from '@assets/icons/as-component/ic_chevron_down.svg';
import IcUserCheckFilled from '@assets/icons/as-component/ic_user_check_filled.svg';
import IcUserCheck from '@assets/icons/as-component/ic_user_check.svg';
import IcKanbanFilled from '@assets/icons/as-component/ic_kanban_filled.svg';
import IcKanban from '@assets/icons/as-component/ic_kanban.svg';
import IcListFilled from '@assets/icons/as-component/ic_list_filled.svg';
import IcList from '@assets/icons/as-component/ic_list.svg';
import IcSettingsFilled from '@assets/icons/as-component/ic_settings_filled.svg';
import IcSettings from '@assets/icons/as-component/ic_settings.svg';
import IcSearch from '@assets/icons/as-component/ic_search.svg';
import IcCancelOutlined from '@assets/icons/as-component/ic_cancel_outlined.svg';
import IcTrash from '@assets/icons/as-component/ic_trash.svg';
import IcBuilding from '@assets/icons/as-component/ic_sm_building.svg';
import IcCalendar from '@assets/icons/as-component/ic_sm_calendar.svg';
import IcTicket from '@assets/icons/as-component/ic_sm_ticket.svg';
import IcClock from '@assets/icons/as-component/ic_clock.svg';
import IcPhone from '@assets/icons/as-component/ic_phone.svg';
import IcDoorHanger from '@assets/icons/as-component/ic_door_hanger.svg';
import IcSource from '@assets/icons/as-component/ic_source.svg';
import IcShield from '@assets/icons/as-component/ic_shield.svg';
import IcHigs from '@assets/icons/as-component/ic_higs.svg';
import IcRefresh from '@assets/icons/as-component/ic_refresh.svg';
import IcCheckin from '@assets/icons/as-component/ic_checkin.svg';
import IcCheckout from '@assets/icons/as-component/ic_checkout.svg';
export var AppIcon;
(function (AppIcon) {
    AppIcon.LoadingTooltipArrow = () => React.createElement("img", { alt: '', src: LoadingTooltipArrowImage, width: '40px' });
    AppIcon.Logo = () => React.createElement("img", { "data-cy": 'logo', alt: 'Logo do site', src: LogoImage, width: '70px' });
    AppIcon.Avatar = (props) => React.createElement(IcSvgStyled, Object.assign({ as: IcAvatar }, props));
    AppIcon.Add = (props) => React.createElement(IcSvgStyled, Object.assign({ as: IcAdd }, props));
    AppIcon.Attachment = (props) => React.createElement(IcSvgStyled, Object.assign({ as: IcAttachment }, props));
    AppIcon.Warning = (props) => React.createElement(IcSvgStyled, Object.assign({ as: IcWarning }, props));
    AppIcon.Done = (props) => React.createElement(IcSvgStyled, Object.assign({ as: IcDone }, props));
    AppIcon.ChevronLeft = (props) => React.createElement(IcSvgStyled, Object.assign({ as: IcChevronLeft }, props));
    AppIcon.ChevronRight = (props) => React.createElement(IcSvgStyled, Object.assign({ as: IcChevronRight }, props));
    AppIcon.LogoSvg = (props) => React.createElement(IcSvgStyled, Object.assign({ as: IcLogo }, props));
    AppIcon.EyeOpened = (props) => React.createElement(IcSvgStyled, Object.assign({ as: IcEyeOpened }, props));
    AppIcon.EyeClosed = (props) => React.createElement(IcSvgStyled, Object.assign({ as: IcEyeClosed }, props));
    AppIcon.HomeFilled = (props) => React.createElement(IcSvgStyled, Object.assign({ as: IcHomeFilled }, props));
    AppIcon.Home = (props) => React.createElement(IcSvgStyled, Object.assign({ as: IcHome }, props));
    AppIcon.HotelFilled = (props) => React.createElement(IcSvgStyled, Object.assign({ as: IcHotelFilled }, props));
    AppIcon.Hotel = (props) => React.createElement(IcSvgStyled, Object.assign({ as: IcHotel }, props));
    AppIcon.ChatFilled = (props) => React.createElement(IcSvgStyled, Object.assign({ as: IcChatFilled }, props));
    AppIcon.Chat = (props) => React.createElement(IcSvgStyled, Object.assign({ as: IcChat }, props));
    AppIcon.FlowFilled = (props) => React.createElement(IcSvgStyled, Object.assign({ as: IcFlowFilled }, props));
    AppIcon.Flow = (props) => React.createElement(IcSvgStyled, Object.assign({ as: IcFlow }, props));
    AppIcon.Filter = (props) => React.createElement(IcSvgStyled, Object.assign({ as: IcFilter }, props));
    AppIcon.Edit = (props) => React.createElement(IcSvgStyled, Object.assign({ as: IcEdit }, props));
    AppIcon.ChevronUp = (props) => React.createElement(IcSvgStyled, Object.assign({ as: IcChevronUp }, props));
    AppIcon.ChevronDown = (props) => React.createElement(IcSvgStyled, Object.assign({ as: IcChevronDown }, props));
    AppIcon.UserCheck = (props) => React.createElement(IcSvgStyled, Object.assign({ as: IcUserCheck }, props));
    AppIcon.UserCheckFilled = (props) => React.createElement(IcSvgStyled, Object.assign({ as: IcUserCheckFilled }, props));
    AppIcon.Kanban = (props) => React.createElement(IcSvgStyled, Object.assign({ as: IcKanban }, props));
    AppIcon.KanbanFilled = (props) => React.createElement(IcSvgStyled, Object.assign({ as: IcKanbanFilled }, props));
    AppIcon.Search = (props) => React.createElement(IcSvgStyled, Object.assign({ as: IcSearch }, props));
    AppIcon.Settings = (props) => React.createElement(IcSvgStyled, Object.assign({ as: IcSettings }, props));
    AppIcon.SettingsFilled = (props) => React.createElement(IcSvgStyled, Object.assign({ as: IcSettingsFilled }, props));
    AppIcon.List = (props) => React.createElement(IcSvgStyled, Object.assign({ as: IcList }, props));
    AppIcon.ListFilled = (props) => React.createElement(IcSvgStyled, Object.assign({ as: IcListFilled }, props));
    AppIcon.DragIndicator = (props) => React.createElement(IcSvgStyled, Object.assign({ as: IcDragIndicator }, props));
    AppIcon.CancelOutlined = (props) => React.createElement(IcSvgStyled, Object.assign({ as: IcCancelOutlined }, props));
    AppIcon.Close = (props) => React.createElement(IcSvgStyled, Object.assign({ as: IcClose }, props));
    AppIcon.Copy = (props) => React.createElement(IcSvgStyled, Object.assign({ as: IcCopy }, props));
    AppIcon.DoneCircular = (props) => React.createElement(IcSvgStyled, Object.assign({ as: IcDoneCircular }, props));
    AppIcon.Trash = (props) => React.createElement(IcSvgStyled, Object.assign({ as: IcTrash }, props));
    AppIcon.Building = (props) => React.createElement(IcSvgStyled, Object.assign({ as: IcBuilding }, props));
    AppIcon.Calendar = (props) => React.createElement(IcSvgStyled, Object.assign({ as: IcCalendar }, props));
    AppIcon.Ticket = (props) => React.createElement(IcSvgStyled, Object.assign({ as: IcTicket }, props));
    AppIcon.Clock = (props) => React.createElement(IcSvgStyled, Object.assign({ as: IcClock }, props));
    AppIcon.Phone = (props) => React.createElement(IcSvgStyled, Object.assign({ as: IcPhone }, props));
    AppIcon.DoorHanger = (props) => React.createElement(IcSvgStyled, Object.assign({ as: IcDoorHanger }, props));
    AppIcon.Higs = (props) => React.createElement(IcSvgStyled, Object.assign({ as: IcHigs }, props));
    AppIcon.Source = (props) => React.createElement(IcSvgStyled, Object.assign({ as: IcSource }, props));
    AppIcon.Shield = (props) => React.createElement(IcSvgStyled, Object.assign({ as: IcShield }, props));
    AppIcon.Refresh = (props) => React.createElement(IcSvgStyled, Object.assign({ as: IcRefresh }, props));
    AppIcon.Checkin = (props) => React.createElement(IcSvgStyled, Object.assign({ as: IcCheckin }, props));
    AppIcon.Checkout = (props) => React.createElement(IcSvgStyled, Object.assign({ as: IcCheckout }, props));
})(AppIcon || (AppIcon = {}));
