import React from 'react';
import { useUpdateBookingItem } from '@app/domain/booking/update-booking-item.use-case';
import { H1 } from '@atomic/atm.typography';
import { Spacing } from '@atomic/obj.constants';
import { Column, Grid, Row, Spacer } from '@atomic/obj.grid';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { BookingItemForm } from './components/booking-item-form.component';
import { flowStrings } from './flow.strings';
const strings = flowStrings;
const HotelBookingItemPage = () => {
    var _a, _b;
    const navigate = useNavigate();
    const { bookingItem } = (_b = (_a = useLocation()) === null || _a === void 0 ? void 0 : _a.state) !== null && _b !== void 0 ? _b : {};
    const { itemId, hotelId } = useParams();
    const { updateItem, loading } = useUpdateBookingItem({
        onCompleted: () => navigate(-1),
    });
    const handleSubmit = (data) => {
        updateItem(Object.assign(Object.assign({}, data), { id: itemId, hotelId }));
    };
    return (React.createElement(Grid, null,
        React.createElement(Row, { justifyContent: 'center' },
            React.createElement(Column, null,
                React.createElement(H1, null, strings.editItem),
                React.createElement(Spacer, { "$height": Spacing.Large }),
                React.createElement(BookingItemForm, { onSubmit: handleSubmit, loading: loading, bookingItem: bookingItem, blockedFields: { name: true, conditionFieldId: true } })))));
};
export default HotelBookingItemPage;
