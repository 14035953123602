import { Label } from '@atomic/atm.typography';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { AppIcon } from '../atm.app-icon';
import { NavbarStyled, NavbarTabStyled, NavbarTabWrapperStyled, NavbarWrapperStyled, } from './vertical-navbar.component.style';
import { HotelsRoutes } from '@app/modules/hotels/hotels.routes';
import { HomeRoutes } from '@app/modules/home/home.routes';
import { Divider } from '@atomic/atm.divider';
import { Spacer } from '@atomic/obj.grid';
import { Spacing } from '@atomic/obj.constants';
import { generalStrings } from '@app/utils/general.string';
import { UsersRoutes } from '@app/modules/users/users.route';
import { useUserStore } from '@app/stores';
import { Permission } from '@app/data/graphql/base-schema';
import { FlowRoutes } from '@app/modules/flow/flow.routes';
import Container from 'typedi';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { ContentType, EventName } from '@app/core/analytics/analytics-event.model';
import { SelectContentAnalytics } from '@app/core/analytics/analytics-params.mapper';
const strings = generalStrings.navbar;
const NavTab = (props) => {
    const handleClick = () => {
        Container.get(AnalyticsService).send(EventName.SELECT_CONTENT, SelectContentAnalytics({
            content_type: ContentType.NAV_BAR,
            item_name: props.label,
        }));
    };
    return (React.createElement(NavLink, { to: props.to, onClick: handleClick }, ({ isActive }) => (React.createElement(NavbarTabStyled, { active: isActive },
        React.createElement(NavbarTabWrapperStyled, { active: isActive }, isActive ? props.activeIcon : props.icon),
        React.createElement(Spacer, { "$height": Spacing.XSmall }),
        React.createElement(Label, { variant: 'white', centered: true }, props.label)))));
};
export const VerticalNavbar = (props) => {
    var _a, _b;
    const [user] = useUserStore();
    const usersPermissionsWithoutBookings = (_a = user.permissions) === null || _a === void 0 ? void 0 : _a.filter((permission) => permission !== Permission.BookingsModule);
    return (React.createElement(NavbarWrapperStyled, null,
        React.createElement(NavbarStyled, null,
            React.createElement(NavLink, { to: `${HomeRoutes.Base}` },
                React.createElement(NavbarTabStyled, null,
                    React.createElement(AppIcon.Logo, null))),
            React.createElement(Divider, { variant: 'primaryDark' }),
            ((_b = user.permissions) === null || _b === void 0 ? void 0 : _b.includes(Permission.BookingsModule)) && (React.createElement(React.Fragment, null,
                React.createElement(NavTab, { to: `${HomeRoutes.Base}`, label: strings.home, activeIcon: React.createElement(AppIcon.HomeFilled, { variant: 'grayDark', size: 'large' }), icon: React.createElement(AppIcon.Home, { variant: 'white', size: 'large' }) }),
                React.createElement(Divider, { variant: 'primaryDark' }))), usersPermissionsWithoutBookings === null || usersPermissionsWithoutBookings === void 0 ? void 0 :
            usersPermissionsWithoutBookings.map((permission, index) => {
                return (React.createElement(React.Fragment, { key: index + permission },
                    NavbarMapper[permission],
                    index !== usersPermissionsWithoutBookings.length - 1 && React.createElement(Divider, { variant: 'primaryDark' })));
            })),
        props.children));
};
const NavbarMapper = {
    HotelsModule: (React.createElement(NavTab, { to: `${HotelsRoutes.Base}`, label: strings.hotels, activeIcon: React.createElement(AppIcon.HotelFilled, { variant: 'grayDark', size: 'large' }), icon: React.createElement(AppIcon.Hotel, { variant: 'white', size: 'large' }) })),
    UsersModule: (React.createElement(NavTab, { to: `${UsersRoutes.Base}`, label: strings.users, activeIcon: React.createElement(AppIcon.UserCheckFilled, { variant: 'grayDark', size: 'large' }), icon: React.createElement(AppIcon.UserCheck, { variant: 'white', size: 'large' }) })),
    OperationalFlowModule: (React.createElement(NavTab, { to: `${FlowRoutes.Base}`, label: strings.flow, activeIcon: React.createElement(AppIcon.FlowFilled, { variant: 'grayDark', size: 'large' }), icon: React.createElement(AppIcon.Flow, { variant: 'white', size: 'large' }) })),
    BookingsModule: null,
};
