import { badgeColors } from '@atomic/atm.badge';
import { Border, Color, Spacing } from '@atomic/obj.constants';
import styled from 'styled-components';
export const CustomBadge = styled.div `
  display: flex;
  flex: 1;
  min-width: 120px;
  max-width: 150px;
  height: 50px;

  padding: ${Spacing.Small} ${Spacing.Medium} ${Spacing.Small} ${Spacing.Small};
  border-radius: ${Border.RadiusLarge};
  border-color: ${(props) => (props.color ? badgeColors[props.color] : Color.Primary)};
  background-color: ${(props) => (props.color ? badgeColors[props.color] : Color.Primary)};

  justify-content: center;
  align-items: center;

  ${(props) => !!props.onClick && `cursor: pointer;`}

  text-align: center;
`;
export const CustomBadgeDivider = styled.div `
  height: ${Border.Width};
  min-width: 10px;
  background-color: black;
`;
