export const hotelConfigStrings = {
    table: {
        hotelName: 'Nome do prédio',
        hotelId: 'Cód. Prédio',
        status: 'Status',
        active: 'Ativo',
        inactive: 'Inativo',
        duplicateFlow: 'Duplicar fluxo',
        createFlow: 'Configurar fluxo',
        editFlow: 'Editar fluxo',
    },
    modal: {
        description: 'Escolha onde deseja duplicar o fluxo selecionado',
        cancel: 'Cancelar',
        duplicate: 'Duplicar',
        loadMore: 'Carregar mais',
    },
    hotelConfigEditing: 'CONFIGURAÇÃO DO PRÉDIO',
};
