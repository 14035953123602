import { gql } from '@apollo/client';
export const PageInfoFragmentDoc = gql `
  fragment PageInfo on PageInfo {
    page
    limit
    totalPages
    hasNextPage
    hasPreviousPage
  }
`;
