import { ChangePasswordDocument } from './generated/change-password.mutation';
import { CreateHotelDocument } from './generated/create-hotel.mutation';
import { CreateUserDocument } from './generated/create-user.mutation';
import { DeleteUserDocument } from './generated/delete-user.mutation';
import { ForgotPasswordDocument } from './generated/forgot-password.mutation';
import { HotelDocument } from './generated/hotel.query';
import { HotelsDocument } from './generated/hotels.query';
import { LoginDocument } from './generated/login.mutation';
import { UpdateHotelDocument } from './generated/update-hotel.mutation';
import { UpdateUserDocument } from './generated/update-user.mutation';
import { UserDocument } from './generated/user.query';
import { UsersDocument } from './generated/users.query';
import { ValidatePasswordCodeDocument } from './generated/validate-password-code.mutation';
export const MutationDocuments = {
    LoginDocument,
    ChangePasswordDocument,
    ForgotPasswordDocument,
    CreateHotelDocument,
    CreateUserDocument,
    DeleteUserDocument,
    UpdateHotelDocument,
    UpdateUserDocument,
    ValidatePasswordCodeDocument,
};
export const QueryDocuments = {
    UserDocument,
    HotelsDocument,
    HotelDocument,
    UsersDocument,
};
