export class BaseStore {
    constructor() {
        // designed to use with useSyncExternalStore
        this.subscribe = (listener) => {
            this.listeners.push(listener);
            return () => {
                this.listeners = this.listeners.filter((l) => l !== listener);
            };
        };
        this.publish = () => {
            this.listeners.forEach((listener) => listener());
        };
        this.listeners = [];
    }
}
// eslint-disable-next-line @typescript-eslint/no-namespace
export var IStore;
(function (IStore) {
    function isSetter(value) {
        return typeof value === 'function';
    }
    IStore.isSetter = isSetter;
})(IStore || (IStore = {}));
