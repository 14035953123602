import { useQuery } from '@app/core/graphql';
import { HotelDocument } from '@app/data/graphql/generated/hotel.query';
export const useGetHotelDetails = (params) => {
    const { data, loading, error } = useQuery(HotelDocument, {
        input: {
            id: +params.id,
        },
    }, {
        skip: params.skip,
    });
    return {
        data: data === null || data === void 0 ? void 0 : data.hotel,
        loading,
        error,
    };
};
