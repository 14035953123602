import React from 'react';
import { AppIcon } from '@app/components/atm.app-icon';
import { FlowRoutes } from '@app/modules/flow/flow.routes';
import { H2, Label } from '@atomic/atm.typography';
import { Spacing } from '@atomic/obj.constants';
import { Spacer } from '@atomic/obj.grid';
import { NavLink } from 'react-router-dom';
import { SidebarTabStyled, SidebarWrapperStyled } from './flow-menu.component.style';
import { flowStrings } from '../flow.strings';
const strings = flowStrings.sidebar;
const NavTab = (props) => {
    return (React.createElement(NavLink, { to: props.to }, ({ isActive }) => (React.createElement(SidebarTabStyled, { active: isActive },
        isActive ? props.activeIcon : props.icon,
        React.createElement(Spacer, { "$height": Spacing.XSmall }),
        React.createElement(Label, { centered: true }, props.label)))));
};
export const FlowMenu = () => {
    return (React.createElement(SidebarWrapperStyled, null,
        React.createElement(H2, null, strings.flow),
        React.createElement(NavTab, { to: FlowRoutes.Steps, label: strings.steps, activeIcon: React.createElement(AppIcon.KanbanFilled, { variant: 'grayDark', size: 'large' }), icon: React.createElement(AppIcon.Kanban, { variant: 'grayDark', size: 'large' }) }),
        React.createElement(NavTab, { to: FlowRoutes.Items, label: strings.items, activeIcon: React.createElement(AppIcon.ListFilled, { variant: 'grayDark', size: 'large' }), icon: React.createElement(AppIcon.List, { variant: 'grayDark', size: 'large' }) }),
        React.createElement(NavTab, { to: FlowRoutes.Settings, label: strings.settings, activeIcon: React.createElement(AppIcon.SettingsFilled, { variant: 'grayDark', size: 'large' }), icon: React.createElement(AppIcon.Settings, { variant: 'grayDark', size: 'large' }) })));
};
