import ColorFunc from 'color';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
// todo: import { Link } from '@app/core/route/link.component';
import { clickableFocusCss } from '@atomic/mol.focus/focus.style';
import { Border, Color, Spacing, FontFamily, FontSize, FontWeight } from '@atomic/obj.constants';
import { highlightStyle } from '@atomic/obj.mixin';
const btnColors = (variant, outlined) => {
    if (outlined) {
        const outlinedColors = {
            primary: { bg: Color.White, border: Color.Primary, text: Color.Secondary },
            secondary: { bg: Color.White, border: Color.Secondary, text: Color.Secondary },
            neutral: { bg: Color.White, border: Color.Neutral, text: Color.Neutral },
            callToAction: { bg: Color.White, border: Color.CallToAction, text: Color.CallToAction },
            accessory: { bg: Color.White, border: Color.Accessory, text: Color.Accessory },
            alert: { bg: Color.White, border: Color.AlertLight, text: Color.AlertLight },
            link: { bg: 'transparent', border: 'transparent', text: Color.GrayDark },
            destructiveLink: { bg: 'transparent', border: 'transparent', text: Color.AlertDark },
        };
        return outlinedColors[variant];
    }
    const solidColors = {
        primary: { bg: Color.Primary, border: Color.Primary, text: Color.White },
        secondary: { bg: 'transparent', border: 'transparent', text: Color.Black },
        neutral: { bg: Color.Neutral, border: Color.Neutral, text: Color.Accessory },
        callToAction: { bg: Color.CallToAction, border: Color.CallToAction, text: Color.Secondary },
        accessory: { bg: Color.Accessory, border: Color.Accessory, text: Color.White },
        alert: { bg: Color.AlertLight, border: Color.AlertLight, text: Color.Black },
        link: { bg: 'transparent', border: 'transparent', text: Color.Primary },
        destructiveLink: { bg: 'transparent', border: 'transparent', text: Color.AlertDark },
    };
    return solidColors[variant];
};
const hoverButton = (props) => {
    if (!props.disabled) {
        return css `
      &:hover {
        color: ${ColorFunc(btnColors(props.variant, props.outlined).text).darken(0.2).hsl().string()};
        background-color: ${props.variant === 'secondary'
            ? Color.GrayLight
            : ColorFunc(btnColors(props.variant, props.outlined).bg).darken(0.1).hsl().string()};
      }

      &:active {
        color: ${ColorFunc(btnColors(props.variant, props.outlined).text).darken(0.3).hsl().string()};
        background-color: ${props.variant === 'secondary'
            ? ColorFunc(Color.GrayXLight).darken(0.1).hsl().string()
            : ColorFunc(btnColors(props.variant, props.outlined).bg).darken(0.2).hsl().string()};
      }
    `;
    }
};
const disabledButton = (props) => {
    if (props.disabled) {
        return css `
      opacity: 0.5;
      ${props.variant === 'secondary' && `background-color: ${Color.GrayLight};`}

      &:hover {
        cursor: not-allowed;
      }
    `;
    }
};
export const buttonHeight = '44px';
export const ButtonStyledCss = css `
  position: relative;
  width: ${(props) => (props.expanded ? '100%' : 'auto')};
  display: inline-block;
  min-height: ${buttonHeight};
  line-height: ${buttonHeight};
  padding: 0 ${Spacing.Large};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Medium};
  font-size: ${FontSize.Small};
  text-decoration: ${({ variant }) => (variant === 'link' || variant === 'destructiveLink' ? 'underline' : 'none')};
  white-space: nowrap;
  text-align: ${(props) => (props.expanded ? 'center' : 'left')};
  ${highlightStyle}
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  opacity: ${(props) => (props.disabled || props.loading ? 0.5 : 1)};

  /* this is useful when its father is pointer-events: none */
  pointer-events: auto;
  color: ${(props) => btnColors(props.variant, props.outlined).text};
  background-color: ${(props) => btnColors(props.variant, props.outlined).bg};
  border-color: ${(props) => btnColors(props.variant, props.outlined).border};
  border-style: solid;
  border-width: ${Border.Width};
  border-radius: ${Border.RadiusLarge};

  &:focus-visible {
    ${clickableFocusCss}
  }

  ${(props) => hoverButton(props)}
  ${(props) => disabledButton(props)}

  ${(props) => (props.variant === 'link' || props.variant === 'destructiveLink') &&
    `
    padding: 0;
    min-height: ${FontSize.Medium};
  `}

  ${(props) => props.circular &&
    `
    width: ${buttonHeight};
    height: ${buttonHeight};
    padding: 0;
    padding-left: ${Spacing.Small};
    padding-right: ${Spacing.Small};
  `}

  ${(props) => props.iconButton &&
    `
    text-align: center;
    line-height: 0;
  `}
`;
export const LinkButtonStyled = styled(Link) `
  ${ButtonStyledCss}
`;
export const AnchorButtonStyled = styled.a `
  ${ButtonStyledCss}
`;
export const ButtonStyled = styled.button `
  ${ButtonStyledCss}
`;
export const ButtonContentStyled = styled.div `
  display: flex;
  gap: ${Spacing.XSmall};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  opacity: ${(props) => (props.processing ? 0 : 1)};
  font-stretch: 100%;
`;
export const ButtonSpinnerStyled = styled.span `
  display: ${(props) => (props.processing ? 'inline-block' : 'none')} !important;
  position: absolute;
  right: calc(50% - 7px);
  ${(props) => (props.circular ? 'top: calc(50% - 7px)' : 'top: 0;')}
`;
