import { useQuery } from '@app/core/graphql';
import { BookingAttachmentsDocument, } from '@app/data/graphql';
export const useGetBookingAttachments = (params) => {
    const { data, loading, error, refetch } = useQuery(BookingAttachmentsDocument, {
        bookingId: params === null || params === void 0 ? void 0 : params.bookingId,
    }, {
        onCompleted: params === null || params === void 0 ? void 0 : params.onCompleted,
        skip: !(params === null || params === void 0 ? void 0 : params.bookingId),
    });
    return {
        data,
        loading,
        error,
        refetch,
    };
};
