import React from 'react';
import { AppIcon } from '@app/components/atm.app-icon';
import { Badge } from '@atomic/atm.badge';
import { Body } from '@atomic/atm.typography';
import { TD, TH, THead, TR, Table } from '@atomic/mol.table';
import { hotelStrings, HotelsRoutes } from '@app/modules/hotels';
import { Button } from '@atomic/atm.button';
const strings = hotelStrings.table;
export const HotelsTable = (props) => {
    return (React.createElement(Table, null,
        React.createElement(THead, null,
            React.createElement(TR, null,
                React.createElement(TH, null,
                    React.createElement(Body, { bold: true, variant: 'black' }, strings.hotelName)),
                React.createElement(TH, null,
                    React.createElement(Body, { bold: true, variant: 'black' }, strings.hotelId)),
                React.createElement(TH, null,
                    React.createElement(Body, { bold: true, variant: 'black' }, strings.status)),
                React.createElement(TH, null))),
        props.hotels.map((hotel) => {
            return React.createElement(HotelsTableRow, { key: hotel.idHIGS, hotel: hotel });
        })));
};
const HotelsTableRow = ({ hotel }) => {
    return (React.createElement(TR, null,
        React.createElement(TD, null,
            React.createElement(Body, { variant: 'black' }, hotel.name)),
        React.createElement(TD, null,
            React.createElement(Body, { variant: 'black' }, hotel.idHIGS)),
        React.createElement(TD, null,
            React.createElement(Badge, { color: hotel.active ? 'successLight' : 'warningLight', text: hotel.active ? strings.active : strings.inactive })),
        React.createElement(TD, { textAlign: 'center' },
            React.createElement(Button, { variant: 'secondary', to: `${HotelsRoutes.Base}/${hotel.id}` },
                React.createElement(AppIcon.Edit, { variant: 'grayDark', size: 'large' }),
                strings.updateHotel))));
};
