import { useGetBookings } from './get-bookings.use-case';
import { useGetBookingStepsTemplate } from './get-booking-steps-template.use-case';
import { mapBookingsToKanban } from './bookings.mapper';
const POLL_INTERVAL_IN_MS = 1000 * 30;
export const useGetBookingsKanban = (params) => {
    const { data: bookingsData, loading: bookingsLoading, error: bookingsError, refetch: refetchBookings, } = useGetBookings({ filters: params === null || params === void 0 ? void 0 : params.filters, pollInterval: POLL_INTERVAL_IN_MS });
    const { data: bookingStepsTemplate, loading: bookingStepsTemplateLoading, error: bookingStepsTemplateError, refetch: refetchBookingStepsTemplate, } = useGetBookingStepsTemplate();
    const refetch = () => {
        refetchBookings();
        refetchBookingStepsTemplate();
    };
    return {
        data: mapBookingsToKanban(bookingsData === null || bookingsData === void 0 ? void 0 : bookingsData.bookings, bookingStepsTemplate),
        loading: bookingStepsTemplateLoading || bookingsLoading,
        error: bookingStepsTemplateError || bookingsError,
        refetch,
    };
};
