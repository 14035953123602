import { useSyncExternalStore } from 'react';
import { Store } from './store';
export function createStore(initialValue) {
    const store = new Store(initialValue);
    const useHook = () => useStore(store);
    return [store, useHook];
}
export function useStore(store) {
    const state = useSyncExternalStore(store.subscribe, store.getState);
    return [state, store.setState];
}
