import { useMutation } from '@app/core/graphql/graphql-mutation.hooks';
import { BookingStepsTemplateDocument, UpdateBookingStepsPositionsDocument, } from '@app/data/graphql';
import { dispatchFlashMessage } from '@atomic/mol.flash-message-container/flash-message-container.component';
export function useUpdateBookingStepPosition(params = {}) {
    const [updateBookingStepsPositions, { error, loading }] = useMutation(UpdateBookingStepsPositionsDocument, {
        onCompleted: (updateBookingStepResult) => { var _a; return (_a = params.onCompleted) === null || _a === void 0 ? void 0 : _a.call(params, updateBookingStepResult); },
        onError: (requestError) => {
            var _a;
            dispatchFlashMessage(requestError.message);
            (_a = params.onError) === null || _a === void 0 ? void 0 : _a.call(params, requestError);
        },
        refetchQueries: [BookingStepsTemplateDocument],
    });
    return {
        updateBookingStepsPositions,
        error,
        loading,
    };
}
