import { useQuery } from '@app/core/graphql';
import { BookingsDocument } from '@app/data/graphql/generated/bookings.query';
export const useGetBookings = (params) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
    const { data, loading, error, refetch } = useQuery(BookingsDocument, {
        filters: {
            bookingStepTemplateId: ((_b = (_a = params === null || params === void 0 ? void 0 : params.filters) === null || _a === void 0 ? void 0 : _a.bookingStepTemplateId) === null || _b === void 0 ? void 0 : _b.length)
                ? (_c = params === null || params === void 0 ? void 0 : params.filters) === null || _c === void 0 ? void 0 : _c.bookingStepTemplateId
                : null,
            hotelsIds: ((_e = (_d = params === null || params === void 0 ? void 0 : params.filters) === null || _d === void 0 ? void 0 : _d.hotelsIds) === null || _e === void 0 ? void 0 : _e.length) ? (_f = params === null || params === void 0 ? void 0 : params.filters) === null || _f === void 0 ? void 0 : _f.hotelsIds : null,
            query: ((_h = (_g = params === null || params === void 0 ? void 0 : params.filters) === null || _g === void 0 ? void 0 : _g.query) === null || _h === void 0 ? void 0 : _h.length) ? (_j = params === null || params === void 0 ? void 0 : params.filters) === null || _j === void 0 ? void 0 : _j.query : null,
            overdueItemsTeamTypes: ((_l = (_k = params === null || params === void 0 ? void 0 : params.filters) === null || _k === void 0 ? void 0 : _k.overdueItemsTeamTypes) === null || _l === void 0 ? void 0 : _l.length)
                ? (_m = params === null || params === void 0 ? void 0 : params.filters) === null || _m === void 0 ? void 0 : _m.overdueItemsTeamTypes
                : null,
            checkIn: (_o = params.filters) === null || _o === void 0 ? void 0 : _o.checkIn,
            status: ((_q = (_p = params === null || params === void 0 ? void 0 : params.filters) === null || _p === void 0 ? void 0 : _p.status) === null || _q === void 0 ? void 0 : _q.length) ? (_r = params === null || params === void 0 ? void 0 : params.filters) === null || _r === void 0 ? void 0 : _r.status : null,
        },
    }, {
        pollInterval: params === null || params === void 0 ? void 0 : params.pollInterval,
    });
    return {
        data,
        loading,
        error,
        refetch,
    };
};
