import { gql } from '@apollo/client';
import { BookingItemFragmentDoc } from './booking-item.fragment';
export const BookingItemTemplateDocument = gql `
  query bookingItemTemplate($templateId: Int!) {
    bookingItemTemplate(templateId: $templateId) {
      ...BookingItem
    }
  }
  ${BookingItemFragmentDoc}
`;
