import { gql } from '@apollo/client';
export const UserFragmentDoc = gql `
  fragment User on User {
    id
    name
    email
    permissions
    companyRole
    avatarUrl
    whatsapp
  }
`;
