import { Color } from '@atomic/obj.constants';
import styled from 'styled-components';
export const ProgressBarBackgroundStyled = styled.div `
  background-color: ${Color.GrayXLight};
  width: 100%;
  height: 4px;
`;
export const ProgressBarFillStyled = styled.div `
  background-color: ${Color.Primary};
  width: ${(props) => (!props.currentStep ? 0 : props.currentStep / props.totalSteps) * 100}%;
  height: 100%;

  transition: width 0.5s ease-in-out;
`;
