import React from 'react';
import { useLocation } from 'react-router';
import Container from 'typedi';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AuthRoutes } from '@app/modules/auth/auth.routes';
import { PageName } from '@app/model';
import { HomeRoutes } from '@app/modules/home';
import { screenNameAnalyticsMapper } from '@app/core/analytics/analytics-params.mapper';
const mapRouteToPageName = {
    [AuthRoutes.Base]: PageName.Login,
    [`${AuthRoutes.Base}/${AuthRoutes.FirstLogin}`]: PageName.SignUp,
    [HomeRoutes.Base]: PageName.Home,
};
export const PageViewAnalytics = (props) => {
    const location = useLocation();
    React.useEffect(() => {
        Container.get(AnalyticsService).setScreen(screenNameAnalyticsMapper[mapRouteToPageName[location.pathname]]);
    }, [location.pathname]);
    return React.createElement(React.Fragment, null, props.children);
};
