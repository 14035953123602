import React from 'react';
import { H1 } from '@atomic/atm.typography';
import { Column, Grid, Row, Spacer } from '@atomic/obj.grid';
import { BookingItemForm } from './components/booking-item-form.component';
import { Spacing } from '@atomic/obj.constants';
import { useCreateBookingItemTemplate } from '@app/domain/booking/create-booking-item-template.use-case';
import { flowStrings } from './flow.strings';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FlowRoutes } from './flow.routes';
import { useGetBookingItemTemplate } from '@app/domain/booking/get-booking-item-template.use-case';
import { LoadingState } from '@atomic/obj.loading-state';
import { FormShimmer } from '@app/components/mol.form-shimmer/form-shimmer.component';
import { ErrorPlaceholder } from '@app/components/mol.placeholder/error-placeholder.component';
import { useUpdateBookingItemTemplate } from '@app/domain/booking/update-booking-item-template.use-case';
import { ContentWrapper } from '@app/components/atm.content-wrapper/content-wrapper.component';
const strings = flowStrings;
const BookingItemTemplateFormPage = () => {
    var _a;
    const navigate = useNavigate();
    const { id } = useParams();
    const locationState = (_a = useLocation()) === null || _a === void 0 ? void 0 : _a.state;
    const isUpdatePage = !!id;
    const { data: bookingItemTemplate, loading, error, } = useGetBookingItemTemplate({ skip: !isUpdatePage, templateId: id });
    const { createItem, loading: createLoading } = useCreateBookingItemTemplate({
        onCompleted: () => {
            if (locationState === null || locationState === void 0 ? void 0 : locationState.totalPages) {
                return navigate(`${FlowRoutes.Base}/${FlowRoutes.Items}?page=${locationState === null || locationState === void 0 ? void 0 : locationState.totalPages}`);
            }
            navigate(`${FlowRoutes.Base}/${FlowRoutes.Items}`);
        },
    });
    const { updateItem, loading: updateLoading } = useUpdateBookingItemTemplate({
        onCompleted: () => navigate(-1),
    });
    const handleSubmit = (data) => {
        if (isUpdatePage) {
            updateItem(Object.assign(Object.assign({}, data), { id }));
            return;
        }
        createItem(data);
    };
    return (React.createElement(Grid, null,
        React.createElement(LoadingState, { data: !isUpdatePage || !!bookingItemTemplate, loading: loading, error: !!error },
            React.createElement(LoadingState.Shimmer, null,
                React.createElement(ContentWrapper, null,
                    React.createElement(FormShimmer, { rows: 9 }))),
            React.createElement(LoadingState.NoData, null,
                React.createElement(ErrorPlaceholder, { type: 'empty' })),
            React.createElement(LoadingState.Error, null,
                React.createElement(ErrorPlaceholder, { type: 'empty' })),
            React.createElement(Row, { justifyContent: 'center' },
                React.createElement(Column, null,
                    React.createElement(H1, null, isUpdatePage ? strings.editItem : strings.createItem),
                    React.createElement(Spacer, { "$height": Spacing.Large }),
                    React.createElement(BookingItemForm, { onSubmit: handleSubmit, loading: createLoading || updateLoading, bookingItem: bookingItemTemplate }))))));
};
export default BookingItemTemplateFormPage;
