export const authStrings = {
    welcome: 'BEM VINDO À PLATAFORMA ROOMO',
    loginAccount: 'Entrar na conta',
    chooseNewPassword: 'Para a sua segurança, crie uma nova senha única e forte com:',
    email: 'E-mail',
    password: 'Senha',
    passwordRules: {
        minLength: 'no mínimo 6 caracteres',
        hasNumber: 'no mínimo 1 número',
        hasLetter: 'no mínimo 1 letra',
    },
    currentPassword: 'Digite a senha atual',
    newPassword: 'Digite sua nova senha',
    confirmPassword: 'Confirmar senha',
    passwordsMustMatch: 'As senhas devem ser iguais',
    resetPassword: 'Alterar senha',
    forgotPassword: 'Esqueci minha senha',
    login: 'Entrar',
    changePassword: 'Alterar senha',
    recoverPassword: 'Recuperar senha',
    cancel: 'Cancelar',
};
