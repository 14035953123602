import { Permission } from '@app/data/graphql/base-schema';
import { HotelsRoutes } from '@app/modules';
import { FlowRoutes } from '@app/modules/flow/flow.routes';
import { HomeRoutes } from '@app/modules/home/home.routes';
import { UsersRoutes } from '@app/modules/users/users.route';
import { usePermissionsGuard } from '@app/route-guard/usePermissionsGuard.hook';
import { useUserStore } from '@app/stores';
import * as React from 'react';
import { Navigate, Outlet } from 'react-router';
import { useLocation } from 'react-router-dom';
export const GuardElement = (props) => {
    var _a, _b, _c;
    const [user] = useUserStore();
    const location = useLocation();
    const { authorized, params } = props.useGuard();
    const hasPermission = usePermissionsGuard((_a = props.permissions) !== null && _a !== void 0 ? _a : []);
    const newParams = new URLSearchParams();
    const oldParams = new URLSearchParams(location.search);
    let targetUrl = oldParams.get('nextPath');
    if (!targetUrl) {
        targetUrl = props.redirectPath;
        if (props.sendPreviousPath) {
            newParams.append('nextPath', location.pathname + location.search);
        }
    }
    if (params) {
        Object.keys(params).forEach((key) => {
            newParams.append(key, params[key]);
        });
    }
    if (newParams.size > 0) {
        targetUrl += '?' + newParams.toString();
    }
    if (!hasPermission && authorized) {
        return (React.createElement(Navigate, { to: ((_b = user === null || user === void 0 ? void 0 : user.permissions) === null || _b === void 0 ? void 0 : _b.includes(Permission.BookingsModule))
                ? HomeRoutes.Base
                : MapRedirectPermission[(_c = user.permissions) === null || _c === void 0 ? void 0 : _c[0]] }));
    }
    return authorized && hasPermission ? React.createElement(Outlet, null) : React.createElement(Navigate, { to: targetUrl });
};
const MapRedirectPermission = {
    BookingsModule: HomeRoutes.Base,
    HotelsModule: HotelsRoutes.Base,
    OperationalFlowModule: FlowRoutes.Base,
    UsersModule: UsersRoutes.Base,
};
