import { AppImage } from '@app/components/atm.app-image/app-image.component';
import { Column, Row } from '@atomic/obj.grid';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { AuthCard } from './components/login-card.component';
import { PageBackground } from './components/page-background.component';
export const AuthPage = () => {
    return (React.createElement(PageBackground, null,
        React.createElement(Row, { responsive: true },
            React.createElement(Column, { flex: 1, alignItems: 'center', justifyContent: 'center' },
                React.createElement(AppImage.RoomoLogo, null)),
            React.createElement(Column, { justifyContent: 'center', alignItems: { xs: 'center', lg: 'flex-end' } },
                React.createElement(AuthCard, null,
                    React.createElement(Outlet, null))))));
};
export default AuthPage;
