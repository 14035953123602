import { SelectIconStyled as SelectIcon } from '@atomic/atm.select/select.component.style';
import { TextAreaField } from '@atomic/atm.text-field';
import { Border, Breakpoint, Color, FieldHeight, Spacing } from '@atomic/obj.constants';
import { fieldBorderCss, fieldCss } from '@atomic/obj.form/field.component.styled';
import styled from 'styled-components';
export const TextAreaStyled = styled(TextAreaField) `
  ${fieldBorderCss}
  ${fieldCss}
  min-height: 300px;
  resize: vertical;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-top: 1px solid ${Color.GrayXLight};

  transition: resize 0s;
`;
export const TextAreaMenuStyled = styled.div `
  display: flex;
  flex-direction: row;
  padding: ${Spacing.Small} ${Spacing.Medium};
  min-height: ${FieldHeight};
  border: ${Border.Width} solid ${Border.Color};
  border-radius: ${Border.Radius};
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom: none;
`;
export const SelectStyled = styled.select `
  ${fieldCss}
  appearance: none;
  border: none;
  min-width: 100px;
  outline: none;
  padding: ${Spacing.Small} ${Spacing.Medium};

  width: 125px;
`;
export const SelectWrapperStyled = styled.div `
  ${fieldBorderCss}
  position: relative;
  padding: 0;
  display: flex;
  align-items: center;

  @media all and (min-width: ${Breakpoint.md}px) {
    :hover {
      box-shadow: none;
      border-color: ${Color.Gray};
    }
  }
`;
export const SelectIconStyled = styled(SelectIcon) `
  padding: 0 ${Spacing.Small};
  color: ${Color.GrayDark};
  pointer-events: none;
`;
