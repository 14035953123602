import React from 'react';
import { FormFieldContext } from '@atomic/obj.form';
import ReactPhoneInput from 'react-phone-number-input';
import pt from 'react-phone-number-input/locale/pt-BR.json';
// eslint-disable-next-line import/no-unassigned-import
import 'react-phone-number-input/style.css';
export const PhoneInput = (props) => {
    var _a, _b, _c;
    const [value, setValue] = React.useState((_b = (_a = props.initialValue) !== null && _a !== void 0 ? _a : props.value) !== null && _b !== void 0 ? _b : null);
    const formFieldConsumer = React.useContext(FormFieldContext);
    const isControlled = React.useCallback(() => {
        return props.value !== undefined;
    }, [props.value]);
    React.useEffect(() => {
        if (isControlled()) {
            setValue(props.value);
        }
    }, [props.value, isControlled]);
    React.useEffect(() => {
        if (isControlled() && props.initialValue !== undefined) {
            throw new Error('Use either the initialChecked prop, or the checked prop, but not both');
        }
        if (formFieldConsumer) {
            if (isControlled() || props.initialValue !== undefined) {
                throw new Error('Please, use value props in <Form.Field> instead of <CheckboxField> component.');
            }
        }
    }, [formFieldConsumer, isControlled, props.initialValue]);
    const getCurrentValue = React.useCallback(() => {
        var _a;
        if (isControlled()) {
            return props.value;
        }
        return (_a = formFieldConsumer.value) !== null && _a !== void 0 ? _a : value;
    }, [formFieldConsumer.value, isControlled, props.value, value]);
    React.useEffect(() => {
        if (formFieldConsumer) {
            const currentValue = getCurrentValue();
            setValue(currentValue);
        }
    }, [formFieldConsumer, getCurrentValue]);
    const handleFocus = (event) => {
        var _a;
        formFieldConsumer === null || formFieldConsumer === void 0 ? void 0 : formFieldConsumer.onFocusChange(true);
        (_a = props.onFocus) === null || _a === void 0 ? void 0 : _a.call(props, event);
    };
    const handleBlur = (event) => {
        var _a;
        formFieldConsumer === null || formFieldConsumer === void 0 ? void 0 : formFieldConsumer.onFocusChange(false);
        (_a = props.onBlur) === null || _a === void 0 ? void 0 : _a.call(props, event);
    };
    const handleChangeText = (eventOrText) => {
        var _a, _b;
        let newValue;
        if (typeof eventOrText === 'string') {
            newValue = eventOrText;
        }
        else {
            newValue = (_a = eventOrText === null || eventOrText === void 0 ? void 0 : eventOrText.currentTarget) === null || _a === void 0 ? void 0 : _a.value;
        }
        if (value === newValue) {
            return;
        }
        (_b = props === null || props === void 0 ? void 0 : props.onValueChange) === null || _b === void 0 ? void 0 : _b.call(props, newValue);
        if (isControlled()) {
            return;
        }
        if (formFieldConsumer) {
            formFieldConsumer.onValueChange(newValue, null);
        }
        else {
            setValue(newValue);
        }
    };
    return (React.createElement(ReactPhoneInput, { placeholder: props.placeholder, value: value, onBlur: handleBlur, onFocus: handleFocus, onChange: handleChangeText, defaultCountry: 'BR', limitMaxLength: (_c = props.limitMaxLength) !== null && _c !== void 0 ? _c : true, disabled: props.disabled, labels: pt }));
};
