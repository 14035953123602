import styled from 'styled-components';
import { Spacing, ZIndex } from '@atomic/obj.constants';
export const FlashMessageContainerDivStyled = styled.div `
  background: transparent;
  border: none;
  position: fixed;
  left: 0;
  right: 0;
  bottom: ${Spacing.Large};
  z-index: ${ZIndex.top};
`;
