import { gql } from '@apollo/client';
import { HotelFragmentDoc } from './hotel.fragment';
export const CreateHotelDocument = gql `
  mutation CreateHotel($input: CreateHotelInput!) {
    createHotel(input: $input) {
      ...Hotel
    }
  }
  ${HotelFragmentDoc}
`;
