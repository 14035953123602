/* eslint-disable react/display-name */
import React from 'react';
import { faCircle, faEye, faEyeSlash, faSquare } from '@fortawesome/free-regular-svg-icons';
import { faBan, faBars, faBus, faCheck, faCheckCircle, faCheckSquare, faChevronDown, faChevronLeft, faChevronRight, faChevronUp, faDotCircle, faExclamationCircle, faExclamationTriangle, faHeart, faImage, faInfoCircle, faMicrophone, faMinus, faPlus, faSearch, faStar, faStarHalf, faTimes, faTimesCircle, faTrash, faUpload, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export var FaIcon;
(function (FaIcon) {
    FaIcon.Ban = (props) => React.createElement(FontAwesomeIcon, Object.assign({}, props, { icon: faBan }));
    FaIcon.Bus = (props) => React.createElement(FontAwesomeIcon, Object.assign({}, props, { icon: faBus }));
    FaIcon.Circle = (props) => React.createElement(FontAwesomeIcon, Object.assign({}, props, { icon: faCircle }));
    FaIcon.Check = (props) => React.createElement(FontAwesomeIcon, Object.assign({}, props, { icon: faCheck }));
    FaIcon.CheckSquare = (props) => React.createElement(FontAwesomeIcon, Object.assign({}, props, { icon: faCheckSquare }));
    FaIcon.ChevronDown = (props) => React.createElement(FontAwesomeIcon, Object.assign({}, props, { icon: faChevronDown }));
    FaIcon.ChevronLeft = (props) => React.createElement(FontAwesomeIcon, Object.assign({}, props, { icon: faChevronLeft }));
    FaIcon.ChevronRight = (props) => React.createElement(FontAwesomeIcon, Object.assign({}, props, { icon: faChevronRight }));
    FaIcon.ChevronUp = (props) => React.createElement(FontAwesomeIcon, Object.assign({}, props, { icon: faChevronUp }));
    FaIcon.Close = (props) => React.createElement(FontAwesomeIcon, Object.assign({}, props, { icon: faTimes }));
    FaIcon.DotCircle = (props) => React.createElement(FontAwesomeIcon, Object.assign({}, props, { icon: faDotCircle }));
    FaIcon.PasswordHide = (props) => React.createElement(FontAwesomeIcon, Object.assign({}, props, { icon: faEyeSlash }));
    FaIcon.PasswordShow = (props) => React.createElement(FontAwesomeIcon, Object.assign({}, props, { icon: faEye }));
    FaIcon.FileUpload = (props) => React.createElement(FontAwesomeIcon, Object.assign({}, props, { icon: faUpload }));
    FaIcon.FlashAlert = (props) => React.createElement(FontAwesomeIcon, Object.assign({}, props, { icon: faTimesCircle }));
    FaIcon.FlashInfo = (props) => React.createElement(FontAwesomeIcon, Object.assign({}, props, { icon: faInfoCircle }));
    FaIcon.FlashSuccess = (props) => React.createElement(FontAwesomeIcon, Object.assign({}, props, { icon: faCheckCircle }));
    FaIcon.FlashWarning = (props) => (React.createElement(FontAwesomeIcon, Object.assign({}, props, { icon: faExclamationCircle })));
    FaIcon.Heart = (props) => React.createElement(FontAwesomeIcon, Object.assign({}, props, { icon: faHeart }));
    FaIcon.Image = (props) => React.createElement(FontAwesomeIcon, Object.assign({}, props, { icon: faImage }));
    FaIcon.Menu = (props) => React.createElement(FontAwesomeIcon, Object.assign({}, props, { icon: faBars }));
    FaIcon.Microphone = (props) => React.createElement(FontAwesomeIcon, Object.assign({}, props, { icon: faMicrophone }));
    FaIcon.Search = (props) => React.createElement(FontAwesomeIcon, Object.assign({}, props, { icon: faSearch }));
    FaIcon.Square = (props) => React.createElement(FontAwesomeIcon, Object.assign({}, props, { icon: faSquare }));
    FaIcon.Star = (props) => React.createElement(FontAwesomeIcon, Object.assign({}, props, { icon: faStar }));
    FaIcon.StarHalf = (props) => React.createElement(FontAwesomeIcon, Object.assign({}, props, { icon: faStarHalf }));
    FaIcon.StepperMinus = (props) => React.createElement(FontAwesomeIcon, Object.assign({}, props, { icon: faMinus }));
    FaIcon.StepperPlus = (props) => React.createElement(FontAwesomeIcon, Object.assign({}, props, { icon: faPlus }));
    FaIcon.Trash = (props) => React.createElement(FontAwesomeIcon, Object.assign({}, props, { icon: faTrash }));
    FaIcon.Warning = (props) => React.createElement(FontAwesomeIcon, Object.assign({}, props, { icon: faExclamationTriangle }));
})(FaIcon || (FaIcon = {}));
