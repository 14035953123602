import React from 'react';
import { AppIcon } from '@app/components/atm.app-icon';
import { DATE_FORMATS } from '@app/utils/constant.utils';
import { createFormattedAliasWithHotelName, formatDate } from '@app/utils/string.utils';
import { Card } from '@atomic/atm.card';
import { Divider } from '@atomic/atm.divider';
import { Body, BodySecondary } from '@atomic/atm.typography';
import { Spacing } from '@atomic/obj.constants';
import { Column, Row, Spacer } from '@atomic/obj.grid';
import { homeStrings } from '../home.strings';
import { AlertBadge } from './alert-badge.component';
import { AvatarImageStack } from './avatar-image-stack.component';
import { StatusBadge } from './status-badge.component';
const strings = homeStrings;
export const HomeKanbanCard = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const getGuestFullName = () => {
        var _a;
        if (!props.booking.guestName) {
            return strings.noName;
        }
        return `${props.booking.guestName} ${(_a = props.booking.guestLastName) !== null && _a !== void 0 ? _a : ''}`;
    };
    return (React.createElement(Card, { small: true, onClick: props.onClick },
        React.createElement(Column, null,
            React.createElement(Row, { gap: Spacing.Small, alignItems: 'center' },
                !!((_a = props.booking) === null || _a === void 0 ? void 0 : _a.hadGlobalProblemOnSyncing) && React.createElement(AppIcon.Warning, { size: 'large', variant: 'alertDark' }),
                React.createElement(Column, { flex: 1 },
                    React.createElement(Body, { lineLimit: 2, bold: true, variant: ((_b = props.booking) === null || _b === void 0 ? void 0 : _b.hadGlobalProblemOnSyncing) ? 'alert' : 'black' }, getGuestFullName()))),
            React.createElement(Spacer, { "$height": Spacing.Medium }),
            React.createElement(Row, { gap: Spacing.Small, alignItems: 'center' },
                React.createElement(Column, null,
                    React.createElement(AppIcon.Building, { variant: 'grayDark' })),
                React.createElement(BodySecondary, null, createFormattedAliasWithHotelName(props.booking.buildingAlias, props.booking.buildingName))),
            React.createElement(Spacer, { "$height": Spacing.Small }),
            React.createElement(Row, { gap: Spacing.Small, alignItems: 'center' },
                React.createElement(AppIcon.Checkin, { variant: 'grayDark' }),
                React.createElement(BodySecondary, null, formatDate(props.booking.checkIn, DATE_FORMATS.FULL_DATE))),
            React.createElement(Spacer, { "$height": Spacing.Small }),
            React.createElement(Row, { gap: Spacing.Small, alignItems: 'center' },
                React.createElement(AppIcon.Checkout, { variant: 'grayDark' }),
                React.createElement(BodySecondary, null, formatDate(props.booking.checkOut, DATE_FORMATS.FULL_DATE))),
            React.createElement(Spacer, { "$height": Spacing.Small }),
            !!props.booking.status && (React.createElement(React.Fragment, null,
                React.createElement(Divider, { variant: 'grayLight' }),
                React.createElement(Spacer, { "$height": Spacing.Medium }),
                React.createElement(Row, { justifyContent: 'center' },
                    React.createElement(StatusBadge, { status: props.booking.status })))),
            (!!((_c = props.booking.alertsPreview) === null || _c === void 0 ? void 0 : _c.length) || !!((_e = (_d = props.booking) === null || _d === void 0 ? void 0 : _d.assignees) === null || _e === void 0 ? void 0 : _e.length)) && (React.createElement(React.Fragment, null,
                React.createElement(Spacer, { "$height": Spacing.Medium }),
                React.createElement(Divider, { variant: 'grayLight' }),
                React.createElement(Spacer, { "$height": Spacing.Small }))),
            React.createElement(Row, { alignItems: 'center', justifyContent: 'space-between' },
                !!((_f = props.booking.alertsPreview) === null || _f === void 0 ? void 0 : _f.length) && (React.createElement(Row, { gap: Spacing.Small }, props.booking.alertsPreview.map((alert) => {
                    return React.createElement(AlertBadge, { key: alert.type, alertType: alert.type });
                }))),
                React.createElement(Column, { flex: 1, alignItems: 'flex-end' },
                    React.createElement(AvatarImageStack, { authors: (_g = props.booking) === null || _g === void 0 ? void 0 : _g.assignees, bookingId: (_h = props.booking) === null || _h === void 0 ? void 0 : _h.id }))))));
};
