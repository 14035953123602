var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { TextField } from '@atomic/atm.text-field';
import { PasswordFieldViewButtonStyled } from './password-field.component.style';
import { AppIcon } from '@app/components/atm.app-icon';
export const PasswordField = (_a) => {
    var _b;
    var { onSecureChange } = _a, props = __rest(_a, ["onSecureChange"]);
    const [secure, setSecure] = React.useState((_b = props.isSecure) !== null && _b !== void 0 ? _b : true);
    const Icon = secure ? AppIcon.EyeOpened : AppIcon.EyeClosed;
    const handleIconTap = () => {
        setSecure((cur) => !cur);
        onSecureChange === null || onSecureChange === void 0 ? void 0 : onSecureChange(!secure);
    };
    return (React.createElement(TextField, Object.assign({}, props, { type: secure ? 'password' : 'text', autoCapitalize: 'none' }),
        React.createElement(PasswordFieldViewButtonStyled, { onClick: handleIconTap, type: 'button', role: 'switch', "aria-checked": !secure },
            React.createElement(Icon, { size: 'large', variant: 'grayDark' }))));
};
