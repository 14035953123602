import { ContentWrapper } from '@app/components/atm.content-wrapper/content-wrapper.component';
import { ErrorPlaceholder } from '@app/components/mol.placeholder/error-placeholder.component';
import { useCreateUser } from '@app/domain/user/create-user.use-case';
import { useGetUserUseCase } from '@app/domain/user/get-user.use-case';
import { useUpdateUser } from '@app/domain/user/update-user.use-case';
import { H1 } from '@atomic/atm.typography';
import { dispatchFlashMessage } from '@atomic/mol.flash-message-container';
import { Spacing } from '@atomic/obj.constants';
import { Grid, Spacer } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UserForm } from './components/user-form.component';
import { UsersRoutes } from './users.route';
import { usersStrings } from './users.string';
import { useDeleteUser } from '@app/domain/user/delete-user.use-case';
import { FormShimmer } from '@app/components/mol.form-shimmer/form-shimmer.component';
const UserFormPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const isUpdatePage = !!id;
    const { data, loading, error } = useGetUserUseCase({ id, skip: !isUpdatePage });
    const { updateUser, loading: updateLoading } = useUpdateUser({
        onCompleted: () => {
            dispatchFlashMessage(usersStrings.updatedSuccessfuly, 'success');
            navigate(UsersRoutes.Base);
        },
    });
    const { createUser, loading: createLoading } = useCreateUser({
        onCompleted: () => {
            dispatchFlashMessage(usersStrings.createdSuccessfuly, 'success');
            navigate(UsersRoutes.Base);
        },
    });
    const { deleteUser, loading: deleteLoading } = useDeleteUser({
        onCompleted: () => navigate(UsersRoutes.Base),
    });
    const handleSubmit = (formData) => {
        if (isUpdatePage && (data === null || data === void 0 ? void 0 : data.id)) {
            updateUser({ input: Object.assign({ id: +data.id }, formData) });
            return;
        }
        createUser({ input: formData });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ContentWrapper, null,
            React.createElement(H1, null, isUpdatePage ? usersStrings.userEditing : usersStrings.newUserRegistration)),
        React.createElement(Spacer, { "$height": Spacing.Medium }),
        React.createElement(LoadingState, { data: !isUpdatePage || !!data, loading: loading, error: !!error },
            React.createElement(LoadingState.Shimmer, null,
                React.createElement(Grid, null,
                    React.createElement(FormShimmer, { columns: 2, rows: 5 }))),
            React.createElement(LoadingState.NoData, null,
                React.createElement(ErrorPlaceholder, { type: 'empty' })),
            React.createElement(LoadingState.Error, null,
                React.createElement(ErrorPlaceholder, { type: 'empty' })),
            React.createElement(Grid, null,
                React.createElement(UserForm, { user: data, onSubmit: handleSubmit, onDelete: () => deleteUser(data === null || data === void 0 ? void 0 : data.id), loading: updateLoading || createLoading, deleteLoading: deleteLoading })))));
};
export default UserFormPage;
