import { gql } from '@apollo/client';
import { BookingItemFragmentDoc } from './booking-item.fragment';
export const BookingItemsDocument = gql `
  query bookingItems($filters: BookingItemsFilters, $input: BookingItemsInput!) {
    bookingItems(filters: $filters, input: $input) {
      ...BookingItem
    }
  }
  ${BookingItemFragmentDoc}
`;
