import styled from 'styled-components';
import { Border, BrandColor, Color, FontFamily, FontSize, FontWeight, Spacing } from '@atomic/obj.constants';
import { limitLineNumber } from '@atomic/atm.typography';
export const badgeColors = {
    primary: Color.Primary,
    secondary: Color.Secondary,
    accessory: Color.Accessory,
    successLight: Color.SuccessLight,
    warningLight: Color.WarningLight,
    accent: Color.Accent,
    accessory2: Color.Accessory2,
    alertLight: Color.AlertLight,
    pendent: BrandColor.Apricot,
    pendent2: BrandColor.SalmonPink,
};
const badgeTextColors = {
    primary: Color.White,
    secondary: Color.White,
    accessory: Color.GrayXDark,
    successLight: Color.GrayXDark,
    warningLight: Color.GrayXDark,
    accent: Color.GrayXDark,
    accessory2: Color.GrayXDark,
    alertLight: Color.GrayXDark,
    pendent: Color.GrayXDark,
    pendent2: Color.GrayXDark,
};
const badgeSizes = {
    small: FontSize.XSmall,
    medium: FontSize.Small,
};
export const BadgeStyled = styled.span `
  padding: ${Spacing.Small} ${Spacing.Medium};
  border-radius: ${Border.RadiusLarge};
  border-color: ${(props) => (props.color ? badgeColors[props.color] : Color.Primary)};
  background-color: ${(props) => (props.color ? badgeColors[props.color] : Color.Primary)};
  justify-content: center;
  ${(props) => !!props.onClick && `cursor: pointer;`}

  ${(props) => !!props.icon && `padding-left: ${Spacing.Small};`}
  text-align: center;
`;
export const BadgeTextStyled = styled.span `
  background-color: ${(props) => { var _a; return (_a = props.color) !== null && _a !== void 0 ? _a : Color.Primary; }};
  color: ${(props) => (props.color ? badgeTextColors[props.color] : Color.Primary)};
  font-size: ${(props) => (props.size ? badgeSizes[props.size] : FontSize.Small)};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Medium};

  ${({ lineLimit }) => limitLineNumber(lineLimit)};
`;
