import styled, { keyframes } from 'styled-components';
import { TransitionDuration } from '@atomic/obj.constants/constants';
export const fadeIn = keyframes `
  from {
    opacity: 0;
    transform: translate(100%, 0);
  }

  to {
    opacity: 1;
    transform: translate(0%, 0px);
  }
`;
export const fadeOut = keyframes `
  from {
    opacity: 1;
    transform: translate(0%, 0);
  }

  to {
    opacity: 0;
    transform: translate(100%, 0);
  }
`;
export const Fade = styled.div `
  border: 1px solid transparent;
  animation: ${(props) => (props.show ? fadeIn : fadeOut)} ${TransitionDuration} linear forwards;
`;
