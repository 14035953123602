import React, { useCallback, useState } from 'react';
import { AppIcon } from '@app/components/atm.app-icon';
import { Button } from '@atomic/atm.button';
import { Body } from '@atomic/atm.typography';
import { TD, TH, THead, TR, Table } from '@atomic/mol.table';
import { Row } from '@atomic/obj.grid';
import { flowStrings } from '../flow.strings';
import { TextField } from '@atomic/atm.text-field';
import { Switch } from '@atomic/atm.switch';
import { useUpdateBookingStepTemplate } from '@app/domain/booking/update-booking-step-template.use-case';
import { DndContext, closestCenter, TouchSensor, useSensor, useSensors, MouseSensor, KeyboardSensor, } from '@dnd-kit/core';
import { SortableContext, arrayMove, verticalListSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { useUpdateBookingStepPosition } from '@app/domain/booking/update-booking-step-position.use-case';
import { DragHandleWrapperStyled } from '@atomic/mol.table/table.component.style';
import { Spacing } from '@atomic/obj.constants';
import { useCreateBookingStepTemplate } from '@app/domain/booking/create-booking-step-template.use-case';
import { ErrorPlaceholder } from '@app/components/mol.placeholder/error-placeholder.component';
const strings = flowStrings.stepsTable;
export const BookingStepsTable = React.forwardRef((props, ref) => {
    const [steps, setSteps] = useState(props.steps);
    const sensors = useSensors(useSensor(MouseSensor, {}), useSensor(TouchSensor, {}), useSensor(KeyboardSensor, {}));
    const { updateBookingStepsPositions } = useUpdateBookingStepPosition();
    React.useImperativeHandle(ref, () => ({
        addStep: () => {
            const alreadyAddedNewStepRow = steps === null || steps === void 0 ? void 0 : steps.some((step) => !step.id);
            if (!alreadyAddedNewStepRow) {
                setSteps((prevSteps) => [...prevSteps, { name: '', active: false }]);
            }
        },
    }));
    const removeStep = () => {
        setSteps((prevSteps) => prevSteps.filter((step) => !!step.id));
    };
    const handleDragEnd = (event) => {
        var _a;
        const { active, over } = event;
        if ((active === null || active === void 0 ? void 0 : active.id) && (over === null || over === void 0 ? void 0 : over.id) && active.id !== over.id) {
            const oldIndex = steps === null || steps === void 0 ? void 0 : steps.findIndex((step) => step.id === Number(active.id));
            const newIndex = steps === null || steps === void 0 ? void 0 : steps.findIndex((step) => step.id === Number(over.id));
            if (oldIndex === -1 || newIndex === -1)
                return;
            const newSteps = arrayMove(steps, oldIndex, newIndex);
            setSteps(newSteps);
            updateBookingStepsPositions({
                input: (_a = newSteps
                    .filter) === null || _a === void 0 ? void 0 : _a.call(newSteps, (step) => step === null || step === void 0 ? void 0 : step.id).map((step, index) => ({
                    stepId: Number(step.id),
                    newPosition: index + 1,
                })),
            });
        }
    };
    React.useEffect(() => {
        setSteps(props.steps);
    }, [props.steps]);
    if (!(steps === null || steps === void 0 ? void 0 : steps.length)) {
        return React.createElement(ErrorPlaceholder, { type: 'empty' });
    }
    return (React.createElement(DndContext, { sensors: sensors, onDragEnd: handleDragEnd, collisionDetection: closestCenter, modifiers: [restrictToVerticalAxis] },
        React.createElement(Table, null,
            React.createElement(THead, null,
                React.createElement(TR, null,
                    React.createElement(TH, null),
                    React.createElement(TH, null,
                        React.createElement(Body, { bold: true, variant: 'black' }, strings.stepName)),
                    React.createElement(TH, null,
                        React.createElement(Body, { variant: 'black' }, strings.status)))),
            React.createElement(SortableContext, { items: (steps === null || steps === void 0 ? void 0 : steps.map((step) => (Object.assign(Object.assign({}, step), { id: (step === null || step === void 0 ? void 0 : step.id) || (step === null || step === void 0 ? void 0 : step.name) })))) || [], strategy: verticalListSortingStrategy }, steps === null || steps === void 0 ? void 0 : steps.map((step) => {
                return React.createElement(BookingStepsTableRow, { key: step.id, step: step, removeStep: removeStep });
            })))));
});
BookingStepsTable.displayName = 'BookingStepsTable';
const BookingStepsTableRow = ({ step, removeStep }) => {
    const { attributes, listeners, setNodeRef, isDragging, transform, transition } = useSortable({
        id: (step === null || step === void 0 ? void 0 : step.id) || (step === null || step === void 0 ? void 0 : step.name),
    });
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };
    const isNewStep = !(step === null || step === void 0 ? void 0 : step.id);
    const [isEditing, setIsEditing] = React.useState(isNewStep);
    const [name, setName] = React.useState(step.name);
    const { updateBookingStepTemplate, loading: loadingUpdate } = useUpdateBookingStepTemplate({
        onCompleted: () => {
            setIsEditing(false);
        },
    });
    const { createBookingStepTemplate, loading: loadingCreate } = useCreateBookingStepTemplate({
        onCompleted: () => {
            setIsEditing(false);
        },
    });
    const loading = loadingCreate || loadingUpdate;
    const toggleEditing = () => setIsEditing((prevIsEditing) => !prevIsEditing);
    const handleChange = useCallback((params) => {
        updateBookingStepTemplate({ input: params });
    }, [updateBookingStepTemplate]);
    React.useEffect(() => {
        if (!isEditing) {
            setName(step.name);
        }
    }, [isEditing, step]);
    const handleSave = () => {
        if (isNewStep) {
            createBookingStepTemplate({ input: { name, active: false } });
        }
        else {
            if (step.name !== name) {
                handleChange({
                    id: step === null || step === void 0 ? void 0 : step.id,
                    name,
                });
            }
            else {
                toggleEditing();
            }
        }
    };
    const handleStatusChange = (checked) => {
        handleChange({
            id: step === null || step === void 0 ? void 0 : step.id,
            active: checked,
        });
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSave();
        }
    };
    return (React.createElement(TR, { style: style, ref: setNodeRef },
        !isEditing && (React.createElement(TD, Object.assign({}, listeners, attributes),
            React.createElement(DragHandleWrapperStyled, { isDragging: isDragging },
                React.createElement(AppIcon.DragIndicator, { variant: 'secondary', size: 'large' })))),
        React.createElement(TD, { colSpan: isEditing ? 2 : 1, height: 80 },
            React.createElement(Row, { alignItems: 'center', gap: Spacing.Medium },
                React.createElement(Row, { flex: 1 }, isEditing ? (React.createElement(TextField, { autoFocus: true, value: name, onValueChange: (newName) => setName(newName), onKeyDown: handleKeyDown })) : (React.createElement(Body, { variant: 'black' }, step.name))),
                isEditing ? (React.createElement(Button, { variant: 'link', circular: true, iconButton: true, onClick: isNewStep ? removeStep : toggleEditing, disabled: loading },
                    React.createElement(AppIcon.Close, { variant: 'secondary', size: 'large' }))) : (React.createElement(Button, { variant: 'link', circular: true, iconButton: true, onClick: toggleEditing },
                    React.createElement(AppIcon.Edit, { variant: 'secondary', size: 'large' }))))),
        React.createElement(TD, { textAlign: 'center' },
            React.createElement(Row, { alignItems: 'center' }, isEditing ? (React.createElement(Button, { circular: true, iconButton: true, variant: 'callToAction', onClick: handleSave, loading: loading },
                React.createElement(AppIcon.DoneCircular, { variant: 'secondary', size: 'large' }))) : (React.createElement(Switch, { checked: step.active, onChange: handleStatusChange }))))));
};
