import * as React from 'react';
import { TableColumnStyled, TableHeaderStyled, TableHeadStyled, TableRowStyled, TableStyled, } from './table.component.style';
export const TR = TableRowStyled;
export const TD = TableColumnStyled;
export const THead = TableHeadStyled;
export const TH = TableHeaderStyled;
export class Table extends React.Component {
    render() {
        const { header, body } = this.getTypedChildren();
        return (React.createElement(TableStyled, null,
            header,
            React.createElement("tbody", null, body)));
    }
    getTypedChildren() {
        let header;
        const body = [];
        React.Children.forEach(this.props.children, (child) => {
            if (child === null || child === void 0 ? void 0 : child.type) {
                switch (child.type) {
                    case THead:
                        header = child;
                        break;
                    default:
                        body.push(child);
                }
            }
            else {
                body.push(child);
            }
        });
        return { header, body };
    }
}
