import { AuthRoutes } from '@app/modules/auth/auth.routes';
import { userStoreInstance } from '@app/stores/user-store';
import { useNavigate } from 'react-router-dom';
export const useLogout = () => {
    const navigate = useNavigate();
    const logout = () => {
        userStoreInstance.remove();
        navigate(AuthRoutes.Base);
    };
    return logout;
};
