import { useEffect } from 'react';
export const useSyncTabLocalStorage = ({ storageKey, onChange }) => {
    useEffect(() => {
        // tab storage sync
        function handleStorageEvent(event) {
            if (event.key === storageKey || event.key === null) {
                // token was changed in another tab or local storage was cleared
                onChange();
            }
        }
        window === null || window === void 0 ? void 0 : window.addEventListener('storage', handleStorageEvent);
        return () => {
            window === null || window === void 0 ? void 0 : window.removeEventListener('storage', handleStorageEvent);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
