import React from 'react';
import { AppIcon } from '@app/components/atm.app-icon';
import { Button } from '@atomic/atm.button';
import { Body } from '@atomic/atm.typography';
import { TD, TH, THead, TR, Table } from '@atomic/mol.table';
import { Row } from '@atomic/obj.grid';
import { HomeRoutes } from '../home.routes';
import { homeStrings } from '../home.strings';
import { Spacing } from '@atomic/obj.constants';
const strings = homeStrings.drawer.guestTable;
export const GuestsTable = (props) => {
    var _a;
    return (React.createElement(Table, null,
        React.createElement(THead, null,
            React.createElement(TR, null,
                React.createElement(TH, null,
                    React.createElement(Body, { bold: true }, strings.type)),
                React.createElement(TH, null,
                    React.createElement(Body, { bold: true }, strings.name)),
                React.createElement(TH, null,
                    React.createElement(Body, { bold: true }, strings.lastname)),
                React.createElement(TH, null))), (_a = props.guests) === null || _a === void 0 ? void 0 :
        _a.map((guest) => {
            return (React.createElement(GuestsTableRow, { key: guest.id, guest: guest, onGuestEdit: props.onGuestEdit, bookingId: props.bookingId }));
        })));
};
const GuestsTableRow = (props) => {
    var _a, _b;
    return (React.createElement(TR, null,
        React.createElement(TD, null,
            React.createElement(Row, { alignItems: 'center' },
                React.createElement(Body, null, props.guest.isMain ? strings.main : strings.companion))),
        React.createElement(TD, null,
            React.createElement(Row, { gap: Spacing.Small, alignItems: 'center' },
                ((_a = props.guest) === null || _a === void 0 ? void 0 : _a.hadProblemOnSyncing) && React.createElement(AppIcon.Warning, { variant: 'alertDark', size: 'large' }),
                React.createElement(Body, { variant: ((_b = props.guest) === null || _b === void 0 ? void 0 : _b.hadProblemOnSyncing) ? 'alert' : 'black' }, props.guest.name))),
        React.createElement(TD, null,
            React.createElement(Body, null, props.guest.lastName)),
        React.createElement(TD, { textAlign: 'center' },
            React.createElement(Button, { variant: 'secondary', onClick: props.onGuestEdit, to: HomeRoutes.BookingEditGuest, navigationState: { guest: props.guest, bookingId: props.bookingId } },
                React.createElement(AppIcon.Edit, { size: 'large', variant: 'grayDark' }),
                strings.edit))));
};
