import { gql } from '@apollo/client';
export const GuestAddressFragmentDoc = gql `
  fragment GuestAddress on GuestAddress {
    id
    createdAt
    updatedAt
    street
    addressNumber
    postalCode
    complement
    district
    stateCode
    city
    countryName
  }
`;
export const GuestContactFragmentDoc = gql `
  fragment GuestContact on GuestContact {
    id
    createdAt
    updatedAt
    email
    phoneNumber
    mobileNumber
  }
`;
export const GuestDocumentFragmentDoc = gql `
  fragment GuestDocument on GuestDocument {
    id
    createdAt
    updatedAt
    cpf
    civilIdentification
    birthCertificate
    passport
  }
`;
export const GuestFragmentDoc = gql `
  fragment Guest on Guest {
    id
    createdAt
    updatedAt
    name
    lastName
    isMain
    code
    birthDate
    hadProblemOnSyncing
    address {
      ...GuestAddress
    }
    contact {
      ...GuestContact
    }
    document {
      ...GuestDocument
    }
  }
  ${GuestAddressFragmentDoc}
  ${GuestContactFragmentDoc}
  ${GuestDocumentFragmentDoc}
`;
