import { Color } from '@atomic/obj.constants';
import styled from 'styled-components';
const ALPHA = 80;
export const OverflowBackground = styled.div `
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ bgColor }) => (bgColor ? Color.Black + ALPHA : 'transparent')};
  ${({ removePointerEvents }) => removePointerEvents && 'pointer-events: none;'}
`;
