import { gql } from '@apollo/client';
import { PaginatedUsersFragmentDoc } from './paginated-users.fragment';
export const UsersDocument = gql `
  query Users($input: UsersInput!) {
    users(input: $input) {
      ...PaginatedUsers
    }
  }
  ${PaginatedUsersFragmentDoc}
`;
