import React from 'react';
import { UserMenu } from '@app/components/mol.user-menu/user-menu.component';
import { Column } from '@atomic/obj.grid/grid.component';
import { HorizontalNavbarStyled, HorizontalNavbarWrapperStyled } from './horizontal-navbar.component.style';
export const HorizontalNavbar = (props) => {
    return (React.createElement(HorizontalNavbarWrapperStyled, null,
        React.createElement(HorizontalNavbarStyled, null,
            React.createElement(Column, { flex: 1, alignItems: 'flex-end' },
                React.createElement(UserMenu, { user: props.user })))));
};
