import React from 'react';
import { Body } from '@atomic/atm.typography';
import { Row } from '@atomic/obj.grid';
import { ProgressBarBackgroundStyled, ProgressBarFillStyled } from './progress-bar.component.style';
import { Spacing } from '@atomic/obj.constants';
export const ProgressBar = (props) => {
    const { totalSteps = 0, currentStep = 0 } = props;
    return (React.createElement(Row, { alignItems: 'center', gap: Spacing.Medium },
        React.createElement(Body, { variant: 'black' },
            currentStep,
            "/",
            totalSteps),
        React.createElement(ProgressBarBackgroundStyled, null,
            React.createElement(ProgressBarFillStyled, { currentStep: currentStep, totalSteps: totalSteps }))));
};
