import { useGetBookingStepsTemplate } from './get-booking-steps-template.use-case';
import { useGetBookingItemsRecord } from './get-booking-items-record.use-case';
import { mapBookingTasks } from './booking-tasks.mapper';
export const useGetBookingTasks = (params) => {
    const { data: tasks, loading: tasksLoading, error: tasksError, } = useGetBookingItemsRecord({
        bookingId: params === null || params === void 0 ? void 0 : params.bookingId,
        bookingStepTemplateId: params === null || params === void 0 ? void 0 : params.bookingStepTemplateId,
    });
    const { data: bookingStepsTemplate, loading: bookingStepsTemplateLoading, error: bookingStepsTemplateError, } = useGetBookingStepsTemplate();
    return {
        data: mapBookingTasks(tasks === null || tasks === void 0 ? void 0 : tasks.bookingItemsRecords, bookingStepsTemplate),
        loading: bookingStepsTemplateLoading || tasksLoading,
        error: bookingStepsTemplateError || tasksError,
    };
};
