var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { FormFieldContext } from '@atomic/obj.form';
import { TextFieldDismissButtonStyled, TextFieldIconStyled, TextFieldIconWrapperStyled, TextFieldMaskedStyled, TextFieldStyled, TextFieldWrapperStyled, } from './text-field.component.style';
export class TextField extends React.Component {
    constructor(props) {
        super(props);
        this.handleChangeText = (eventOrText) => {
            var _a, _b;
            let value;
            if (typeof eventOrText === 'string') {
                value = eventOrText;
            }
            else {
                value = eventOrText.currentTarget.value;
            }
            if (((_a = this.state) === null || _a === void 0 ? void 0 : _a.value) === value) {
                return;
            }
            if (this.props.onValueChange) {
                (_b = this.props) === null || _b === void 0 ? void 0 : _b.onValueChange(value);
            }
            if (this.isControlled()) {
                return;
            }
            if (this.formFieldConsumer) {
                this.formFieldConsumer.onValueChange(value, null);
            }
            else {
                this.setState({ value });
            }
        };
        this.handleInput = (event) => {
            const nextValue = event.currentTarget.value;
            const currentValue = this.getCurrentValue();
            // HACK: "not fired by keyboard" means that who is calling onInput
            // is not the user by typing letters on the keyboard.
            // For instance, it can be fired by pasting some value or
            // by using form auto-complete.
            // Why is this necessary? auto-complete doesn't fire onChange event
            // but it fires onInput.
            // If you don't handle onInput, some bugs may appear if you use
            // auto-complete on Chrome iOS
            const notFiredByKeyboardTyping = (nextValue.length ? nextValue.length : 0) - (currentValue ? currentValue.length : 0) > 1;
            if (notFiredByKeyboardTyping) {
                event.preventDefault();
                this.handleChangeText(nextValue);
            }
        };
        this.handleFocus = (event) => {
            var _a, _b, _c;
            (_a = this.formFieldConsumer) === null || _a === void 0 ? void 0 : _a.onFocusChange(true);
            (_c = (_b = this.props).onFocus) === null || _c === void 0 ? void 0 : _c.call(_b, event);
        };
        this.handleBlur = (event) => {
            var _a, _b, _c;
            (_a = this.formFieldConsumer) === null || _a === void 0 ? void 0 : _a.onFocusChange(false);
            (_c = (_b = this.props).onBlur) === null || _c === void 0 ? void 0 : _c.call(_b, event);
        };
        this.handleDismiss = () => {
            this.handleChangeText('');
        };
        this.isControlled = () => {
            return this.props.value !== undefined;
        };
        this.getCurrentValue = () => {
            var _a;
            if (this.isControlled()) {
                return this.props.value;
            }
            return ((_a = this.formFieldConsumer) === null || _a === void 0 ? void 0 : _a.value) ? this.formFieldConsumer.value : this.state.value;
        };
        this.state = {
            value: props.initialValue || props.value,
        };
    }
    render() {
        /* eslint-disable @typescript-eslint/no-unused-vars */
        const _a = this.props, { autoComplete, onValueChange, variant, icon, onChange, value, options, dismissable, initialValue, onBlur, onFocus, inputLabel, children } = _a, other = __rest(_a, ["autoComplete", "onValueChange", "variant", "icon", "onChange", "value", "options", "dismissable", "initialValue", "onBlur", "onFocus", "inputLabel", "children"]);
        /* eslint-enable @typescript-eslint/no-unused-vars */
        return (React.createElement(FormFieldContext.Consumer, null, (formFieldConsumer) => {
            this.formFieldConsumer = formFieldConsumer;
            const val = this.getCurrentValue();
            return (React.createElement(React.Fragment, null,
                icon && (React.createElement(TextFieldIconWrapperStyled, null,
                    React.createElement(TextFieldIconStyled, null))),
                variant === 'normal' ? (React.createElement(TextFieldWrapperStyled, null,
                    React.createElement(TextFieldStyled, Object.assign({ value: val !== null && val !== void 0 ? val : '', onChange: this.handleChangeText, icon: icon, autoComplete: autoComplete, onInput: this.handleInput, onBlur: this.handleBlur, onFocus: this.handleFocus, inputLabel: inputLabel }, other)),
                    children,
                    dismissable && (val === null || val === void 0 ? void 0 : val.length) > 0 && React.createElement(TextFieldDismissButtonStyled, { onClick: this.handleDismiss }))) : (React.createElement(TextFieldWrapperStyled, null,
                    React.createElement(TextFieldMaskedStyled, Object.assign({ kind: variant, options: options, value: val !== null && val !== void 0 ? val : '', onChangeText: this.handleChangeText, icon: icon, autoComplete: autoComplete, onInput: this.handleInput, onBlur: this.handleBlur, onFocus: this.handleFocus, inputLabel: inputLabel }, other)),
                    children,
                    dismissable && val && val.length > 0 && (React.createElement(TextFieldDismissButtonStyled, { onClick: this.handleDismiss }))))));
        }));
    }
    componentDidMount() {
        if (this.isControlled() && this.props.initialValue !== undefined) {
            throw new Error('Use either the initialValue prop, or the value prop, but not both');
        }
        if (this.formFieldConsumer) {
            if (this.isControlled() || this.props.initialValue !== undefined) {
                if (this.formFieldConsumer.value) {
                    throw new Error('Please, use either value props in <TextField> or <Form.Field> component.');
                }
                this.formFieldConsumer.onValueChange(this.state.value, null);
            }
        }
    }
    componentDidUpdate(prevProps) {
        var _a, _b, _c;
        if (prevProps.value !== this.props.value && this.props.value !== this.state.value) {
            (_b = (_a = this.props).onValueChange) === null || _b === void 0 ? void 0 : _b.call(_a, this.props.value);
            (_c = this.formFieldConsumer) === null || _c === void 0 ? void 0 : _c.onValueChange(this.props.value, null);
            this.setState({ value: this.props.value });
        }
    }
}
TextField.defaultProps = {
    variant: 'normal',
};
