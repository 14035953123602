import React from 'react';
import { GuestFormFields } from '@app/model/booking/booking-guest.model';
import { TextField } from '@atomic/atm.text-field';
import { Spacing } from '@atomic/obj.constants';
import { FormField } from '@atomic/obj.form';
import { Column, Row, Spacer } from '@atomic/obj.grid';
import { homeStrings } from '../home.strings';
const strings = homeStrings.guestForm;
export const GuestAddressForm = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, { gap: Spacing.Medium },
            React.createElement(Column, { flex: 1 },
                React.createElement(FormField, { name: `address.${GuestFormFields.street}`, initialValue: (_a = props.address) === null || _a === void 0 ? void 0 : _a.street, label: strings.address.street },
                    React.createElement(TextField, null))),
            React.createElement(Column, { flex: 1 },
                React.createElement(FormField, { name: `address.${GuestFormFields.addressNumber}`, initialValue: (_b = props.address) === null || _b === void 0 ? void 0 : _b.addressNumber, label: strings.address.addressNumber },
                    React.createElement(TextField, null)))),
        React.createElement(Spacer, { "$height": Spacing.Medium }),
        React.createElement(Row, { gap: Spacing.Medium },
            React.createElement(Column, { flex: 1 },
                React.createElement(FormField, { name: `address.${GuestFormFields.postalCode}`, initialValue: (_c = props.address) === null || _c === void 0 ? void 0 : _c.postalCode, label: strings.address.postalCode },
                    React.createElement(TextField, null))),
            React.createElement(Column, { flex: 1 },
                React.createElement(FormField, { name: `address.${GuestFormFields.district}`, initialValue: (_d = props.address) === null || _d === void 0 ? void 0 : _d.district, label: strings.address.district },
                    React.createElement(TextField, null)))),
        React.createElement(Spacer, { "$height": Spacing.Medium }),
        React.createElement(Row, { gap: Spacing.Medium },
            React.createElement(Column, { flex: 1 },
                React.createElement(FormField, { name: `address.${GuestFormFields.stateCode}`, initialValue: (_e = props.address) === null || _e === void 0 ? void 0 : _e.stateCode, label: strings.address.stateCode },
                    React.createElement(TextField, null))),
            React.createElement(Column, { flex: 1 },
                React.createElement(FormField, { name: `address.${GuestFormFields.city}`, initialValue: (_f = props.address) === null || _f === void 0 ? void 0 : _f.city, label: strings.address.city },
                    React.createElement(TextField, null)))),
        React.createElement(Spacer, { "$height": Spacing.Medium }),
        React.createElement(Row, { gap: Spacing.Medium },
            React.createElement(Column, { flex: 1 },
                React.createElement(FormField, { name: `address.${GuestFormFields.complement}`, initialValue: (_g = props.address) === null || _g === void 0 ? void 0 : _g.complement, label: strings.address.complement },
                    React.createElement(TextField, null))),
            React.createElement(Column, { flex: 1 },
                React.createElement(FormField, { name: `address.${GuestFormFields.countryName}`, initialValue: (_h = props.address) === null || _h === void 0 ? void 0 : _h.countryName, label: strings.address.countryName },
                    React.createElement(TextField, null))))));
};
