import { Color, Spacing } from '@atomic/obj.constants';
import styled from 'styled-components';
export const SidebarWrapperStyled = styled.nav `
  width: 100%;

  > h2 {
    margin: ${Spacing.Medium};
  }
`;
export const SidebarTabStyled = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: ${Spacing.Small} ${Spacing.Medium};
  background-color: ${({ active }) => (active ? Color.Accent : 'transparent')};

  :hover {
    overflow: hidden;
    background-color: ${Color.Accent};
  }

  transition: background-color 0.3s linear;

  @media (prefers-reduced-motion) {
    transition: none;
  }
`;
