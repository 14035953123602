import { gql } from '@apollo/client';
import { BookingItemFragmentDoc } from './booking-item.fragment';
export const SearchBookingItemsTemplateByNameDocument = gql `
  query searchBookingItemsTemplateByName($name: String!) {
    searchBookingItemsTemplateByName(name: $name) {
      ...BookingItem
    }
  }
  ${BookingItemFragmentDoc}
`;
