import { TextShimmerBoxStyled } from '@atomic/mol.shimmer/shimmer.component.style';
import { TD, TH, THead, TR, Table } from '@atomic/mol.table';
import { Spacing } from '@atomic/obj.constants';
import { Row, Spacer } from '@atomic/obj.grid';
import React from 'react';
export const BookingDrawerShimmer = () => {
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, { alignItems: 'center', justifyContent: 'space-between' },
            React.createElement(TextShimmerBoxStyled, { height: '60px', width: '50%' }),
            React.createElement(TextShimmerBoxStyled, { height: '30px', width: '30%' })),
        React.createElement(Spacer, { "$height": Spacing.Medium }),
        React.createElement(Row, { gap: Spacing.Medium },
            React.createElement(TextShimmerBoxStyled, { height: '30px', width: '20%' }),
            React.createElement(TextShimmerBoxStyled, { height: '30px', width: '20%' })),
        React.createElement(Row, { gap: Spacing.Medium },
            React.createElement(TextShimmerBoxStyled, { height: '30px', width: '20%' }),
            React.createElement(TextShimmerBoxStyled, { height: '30px', width: '20%' })),
        React.createElement(Spacer, { "$height": Spacing.XXLarge }),
        React.createElement(TextShimmerBoxStyled, { height: '50px', width: '70%' }),
        React.createElement(Spacer, { "$height": Spacing.Medium }),
        React.createElement(Row, { gap: Spacing.Medium },
            React.createElement(TextShimmerBoxStyled, { height: '50px', width: '30%' }),
            React.createElement(TextShimmerBoxStyled, { height: '50px', width: '30%' })),
        React.createElement(Spacer, { "$height": Spacing.XXLarge }),
        React.createElement(TextShimmerBoxStyled, { height: '50px' }),
        React.createElement(Spacer, { "$height": Spacing.Large }),
        React.createElement(Table, null,
            React.createElement(THead, null,
                React.createElement(TR, null,
                    React.createElement(TH, null,
                        React.createElement(TextShimmerBoxStyled, { height: '40px' })),
                    React.createElement(TH, null,
                        React.createElement(TextShimmerBoxStyled, { height: '40px' })),
                    React.createElement(TH, null,
                        React.createElement(TextShimmerBoxStyled, { height: '40px' })),
                    React.createElement(TH, null))),
            Array(3)
                .fill('')
                .map((_, index) => (React.createElement(TR, { key: index },
                React.createElement(TD, null,
                    React.createElement(TextShimmerBoxStyled, { height: '40px' })),
                React.createElement(TD, null,
                    React.createElement(TextShimmerBoxStyled, { height: '40px', width: '70%' })),
                React.createElement(TD, null,
                    React.createElement(TextShimmerBoxStyled, { height: '40px' })),
                React.createElement(TD, null,
                    React.createElement(TextShimmerBoxStyled, { height: '40px', width: '70%' }))))))));
};
