import * as React from 'react';
import { InputCaption, InputCaptionError } from '@atomic/atm.typography';
import { FieldErrorIconStyled } from './field-caption.component.style';
export const FormFieldCaption = (props) => {
    var _a;
    const wrap = props.errors.map((error) => (React.createElement(InputCaptionError, { key: error.name + error.message }, error.message)));
    return props.showAll ? (React.createElement("div", null, wrap)) : (React.createElement(React.Fragment, null, ((_a = props.errors) === null || _a === void 0 ? void 0 : _a.length) > 0 ? (React.createElement(InputCaptionError, { key: props.errors[0].name },
        React.createElement(FieldErrorIconStyled, null),
        " ",
        props.errors[0].message)) : (props.validationPlaceholder && React.createElement(InputCaption, null, props.validationPlaceholder))));
};
