import { gql } from '@apollo/client';
import { FieldPointerFragmentDoc } from './booking-item.fragment';
export const MessageDataFieldPointerFragmentDoc = gql `
  fragment MessageDataFieldPointer on FieldPointer {
    ...FieldPointer
    tag
  }
  ${FieldPointerFragmentDoc}
`;
export const FieldPointersDocument = gql `
  query fieldPointers($type: FieldPointerType!) {
    fieldPointers(type: $type) {
      ...FieldPointer
    }
  }
  ${FieldPointerFragmentDoc}
`;
export const AllFieldPointersDocument = gql `
  query allFieldPointers {
    conditionFieldPointers: fieldPointers(type: Condition) {
      ...FieldPointer
    }
    deadlineReferenceFieldPointers: fieldPointers(type: DeadlineReference) {
      ...FieldPointer
    }
    messageDataFieldPointers: fieldPointers(type: MessageData) {
      ...MessageDataFieldPointer
    }
  }
  ${FieldPointerFragmentDoc}
  ${MessageDataFieldPointerFragmentDoc}
`;
