import { AppIcon } from '@app/components/atm.app-icon';
import { BookingItemRecordStatus } from '@app/data/graphql';
import { BodySecondary } from '@atomic/atm.typography';
import { Spacing } from '@atomic/obj.constants';
import { Column, Row } from '@atomic/obj.grid';
import React from 'react';
import { homeStrings } from '../home.strings';
const strings = homeStrings.drawer;
export const BookingItemRecord = (props) => {
    const { name, deadline, status, shouldTriggerBeforeDeadlineReference, deadlineFriendlyDescription } = props.bookingItemRecord;
    const getDeadlineText = () => {
        if (!deadline || !deadlineFriendlyDescription || shouldTriggerBeforeDeadlineReference === undefined) {
            return '-';
        }
        return strings.deadlineText(deadline, deadlineFriendlyDescription, shouldTriggerBeforeDeadlineReference);
    };
    return (React.createElement(Row, { gap: Spacing.Large },
        React.createElement(Column, { flex: 1 },
            React.createElement(BodySecondary, { variant: status === BookingItemRecordStatus.Overdue ? 'warning' : undefined, strikethrough: status === BookingItemRecordStatus.Completed }, name)),
        React.createElement(Column, { flex: 1 },
            React.createElement(Row, { gap: Spacing.Small, alignItems: 'center' },
                React.createElement(Column, null, status === BookingItemRecordStatus.Overdue ? (React.createElement(AppIcon.Warning, { variant: 'warning', size: 'large' })) : (React.createElement(AppIcon.Clock, { variant: 'grayDark', size: 'large' }))),
                React.createElement(BodySecondary, { variant: status === BookingItemRecordStatus.Overdue ? 'warning' : undefined }, getDeadlineText())))));
};
