import styled from 'styled-components';
import { ButtonStyled } from '@atomic/atm.button/button.component.style';
import { Border, Color, Spacing } from '@atomic/obj.constants';
const PAGINATION_BORDER_RADIUS = '4px';
export const PaginationStyled = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: ${Border.Width} solid ${Border.Color};
  border-radius: 0 0 ${PAGINATION_BORDER_RADIUS} ${PAGINATION_BORDER_RADIUS};
`;
export const PageStyled = styled(ButtonStyled) `
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${Spacing.Large};
  border-width: 0;
  border-radius: ${PAGINATION_BORDER_RADIUS};
  background-color: ${(props) => (props.active ? Color.Accent : 'transparent')};
  color: ${(props) => (props.active ? Color.Black : Color.Neutral)};
  content: ${(props) => (props.first ? 'true' : 'false')};
  margin: ${Spacing.Small} ${Spacing.XSmall};
`;
export const PageDisabledStyled = styled.label `
  padding: ${Spacing.Medium};
`;
