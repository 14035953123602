import React from 'react';
import { Button } from '@atomic/atm.button';
import { H4 } from '@atomic/atm.typography';
import { Spacing } from '@atomic/obj.constants';
import { Form } from '@atomic/obj.form';
import { Column, Row, Spacer } from '@atomic/obj.grid';
import { homeStrings } from '../home.strings';
import { GuestAddressForm } from './guest-address-form.component';
import { GuestContactForm } from './guest-contact-form.component';
import { GuestGeneralDataForm } from './guest-general-data-form.component';
const strings = homeStrings.guestForm;
export const GuestForm = (props) => {
    var _a, _b;
    const handleSubmit = (formData) => {
        if (formData.hasError) {
            return;
        }
        props.onSubmit(formData.data);
    };
    return (React.createElement(Form, { onSubmit: handleSubmit },
        React.createElement(Column, null,
            React.createElement(GuestGeneralDataForm, { guest: props.guest }),
            React.createElement(Spacer, { "$height": Spacing.Medium }),
            React.createElement(H4, { variant: 'black' }, strings.contact.title),
            React.createElement(GuestContactForm, { contact: (_a = props.guest) === null || _a === void 0 ? void 0 : _a.contact }),
            React.createElement(Spacer, { "$height": Spacing.Medium }),
            React.createElement(H4, { variant: 'black' }, strings.address.title),
            React.createElement(GuestAddressForm, { address: (_b = props.guest) === null || _b === void 0 ? void 0 : _b.address }),
            React.createElement(Spacer, { "$height": Spacing.Large }),
            React.createElement(Row, { justifyContent: 'flex-end' },
                React.createElement(Button, { type: 'submit', loading: props.loading, variant: 'callToAction' }, strings.save)))));
};
