import { gql } from '@apollo/client';
export const GuestBillingFragmentDoc = gql `
  fragment GuestBilling on GuestBilling {
    id
    createdAt
    updatedAt
    expenseDate
    valueInBRL
    description
    guestId
    ticketId
  }
`;
