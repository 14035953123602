export const mapBookingsToKanban = (bookings, steps) => {
    if (!(bookings === null || bookings === void 0 ? void 0 : bookings.length) && !(steps === null || steps === void 0 ? void 0 : steps.length)) {
        return [];
    }
    if (!(bookings === null || bookings === void 0 ? void 0 : bookings.length) && !!(steps === null || steps === void 0 ? void 0 : steps.length)) {
        return steps.map((step) => ({ step, bookings: [] }));
    }
    const bookingsByStepId = new Map();
    steps.forEach((step) => {
        bookingsByStepId.set(step.id, { step, bookings: [] });
    });
    bookings.forEach((booking) => {
        var _a;
        let bookingsForStep = bookingsByStepId.get(booking.currentBookingStepTemplateId);
        if (bookingsForStep) {
            bookingsForStep.bookings.push(booking);
        }
        else {
            bookingsForStep = bookingsByStepId.get((_a = steps[0]) === null || _a === void 0 ? void 0 : _a.id);
            bookingsForStep === null || bookingsForStep === void 0 ? void 0 : bookingsForStep.bookings.push(booking);
        }
    });
    return Array.from(bookingsByStepId.values());
};
