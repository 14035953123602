import { gql } from '@apollo/client';
export const HotelAddressFragmentDoc = gql `
  fragment HotelAddress on HotelAddress {
    id
    hotelId
    street
    addressNumber
    complement
    postalCode
    district
    stateCode
    city
  }
`;
export const HotelFragmentDoc = gql `
  fragment Hotel on Hotel {
    id
    idHIGS
    name
    email
    telephone
    active
    hasOperationalFlow
    alias
    address {
      ...HotelAddress
    }
  }
  ${HotelAddressFragmentDoc}
`;
