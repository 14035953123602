import styled from 'styled-components';
import { BrandColor, Color } from '@atomic/obj.constants';
const HANDLE_DIAMETER = 24;
const SWITCH_HEIGHT = 28;
const SWITCH_WIDTH = 56;
const HANDLE_OFFSET = Math.max(0, (SWITCH_HEIGHT - HANDLE_DIAMETER) / 2);
export const SwitchStyled = styled.button `
  position: relative;
  height: ${SWITCH_HEIGHT}px;
  width: ${SWITCH_WIDTH}px;
  padding: 0;
  border: 0;
  border-radius: ${SWITCH_HEIGHT / 2}px;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  background-color: ${(props) => (props.$checked ? BrandColor.PastelBlue : Color.GrayXLight)};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  @media (prefers-reduced-motion: no-preference) {
    transition:
      background-color 0.25s,
      opacity 0.25s;
  }
`;
export const SwitchHandleStyled = styled.div `
  position: absolute;
  top: 0;
  margin: ${HANDLE_OFFSET}px;
  height: ${HANDLE_DIAMETER}px;
  width: ${HANDLE_DIAMETER}px;
  background-color: ${Color.Black};
  border-radius: 50%;
  transform: translate(${(props) => (props.$checked ? SWITCH_WIDTH / 2 : 0)}px);
  @media (prefers-reduced-motion: no-preference) {
    transition:
      background-color 0.25s,
      transform 0.25s,
      box-shadow 0.15s;
  }
`;
