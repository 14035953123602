export const usersStrings = {
    addMember: 'Adicionar membro',
    createdSuccessfuly: 'Usuário criado com sucesso!',
    deletedSuccessfuly: 'Usuário deletado com sucesso!',
    edit: 'Editar usuário',
    usersEditing: 'USUÁRIOS',
    teamMembers: 'Membros do time',
    updatedSuccessfuly: 'Usuário atualizado com sucesso!',
    userEditing: 'EDITAR USUÁRIO',
    emptyState: 'Não há usuários adicionados ao seu time ainda.',
    newUserRegistration: 'CADASTRO DE NOVO USUÁRIO',
    permissions: {
        OperationalFlowModule: 'Fluxo operacional',
        HotelsModule: 'Prédios',
        UsersModule: 'Usuários',
        BookingsModule: 'Reservas',
    },
    roles: {
        OperationalTeam: 'Time operacional',
        ServiceTeam: 'Time de atendimento',
    },
    table: {
        name: 'Nome',
        role: 'Função',
        email: 'E-mail',
        edit: 'Editar usuário',
    },
    userForm: {
        chooseARole: 'Escolha a função do colaborador',
        collaboratorInfo: 'Dados do colaborador',
        collaboratorPermissions: 'Permissões de acesso',
        role: 'Função',
        cancel: 'Cancelar',
        delete: 'Deletar usuário',
        publish: 'Salvar usuário',
        formFields: {
            companyRole: 'Função',
            email: 'E-mail',
            name: 'Nome completo',
            permissions: 'Acessos',
            telephone: 'Número de telefone',
            contactViaWhatsApp: 'Para contato via WhatsApp',
        },
        placeholder: {
            email: 'exemplo@ahi.com.br',
            name: 'Nome do usuário',
            telephone: '(ddd) 99999-9999',
        },
    },
};
