import { gql } from '@apollo/client';
import { BookingStepFragmentDoc } from './booking-step.fragment';
export const UpdateBookingStepTemplateDocument = gql `
  mutation UpdateBookingStepTemplate($input: UpdateBookingStepTemplateInput!) {
    updateBookingStepTemplate(input: $input) {
      ...BookingStep
    }
  }
  ${BookingStepFragmentDoc}
`;
