import { AppIcon } from '@app/components/atm.app-icon';
import { Spacing } from '@atomic/obj.constants';
import styled from 'styled-components';
export const SidebarFilterWrapperStyled = styled.nav `
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: ${Spacing.XLarge} ${Spacing.Medium};

  > h2 {
    margin: ${Spacing.XSmall} 0;
  }
`;
export const HeaderWrapper = styled.button `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  width: 100%;
`;
export const ChevronStyled = styled(AppIcon.ChevronDown) `
  transform: ${({ opened }) => (opened ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.3s ease-in-out;

  @media (prefers-reduced-motion) {
    transition: none;
  }
`;
export const FilterItemWrapperStyled = styled.nav `
  width: 100%;
  margin: ${Spacing.Medium} 0;
`;
export const AccordionItemWrapperStyled = styled.nav `
  width: 100%;
  margin: ${Spacing.Medium} 0;
`;
