import { gql } from '@apollo/client';
import { AttachmentFragmentDoc } from './attachment.fragment';
export const CreateBookingAttachmentsUrlsDocument = gql `
  mutation createBookingAttachmentsUrls($input: CreateBookingAttachmentsUrlInput!) {
    createBookingAttachmentsUrls(input: $input) {
      ...Attachment
    }
  }
  ${AttachmentFragmentDoc}
`;
