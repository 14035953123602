import React from 'react';
import { Sidebar } from '@app/components/org.sidebar';
import { Row } from '@atomic/obj.grid/grid.component';
import { SidebarPageWrapper } from './flow-sidebar-element.component.style';
import { BookingsMenu } from '@app/modules/home/components/home-menu.component';
export const HomeSidebar = (props) => {
    return (React.createElement(Row, null,
        React.createElement(Sidebar, { collapsable: true },
            React.createElement(BookingsMenu, { onFilterChange: props.onFilterChange })),
        React.createElement(SidebarPageWrapper, { style: { overflow: 'hidden' } }, props.children)));
};
