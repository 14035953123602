import { ApolloClient } from '@apollo/client';
import { Container } from 'typedi';
import { ApolloClientBuilderService } from '@app/core/graphql';
import { FIREBASE_ANALYTICS, FIREBASE_USER_ID, FirebaseAnalyticsService, } from '@app/core/analytics/firebase-analytics.service';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function configure(frontendEnvironmentVariables) {
    const baseUrl = frontendEnvironmentVariables.BASE_URL;
    configureFirebase(frontendEnvironmentVariables);
    configApolloClient(baseUrl);
}
function configureFirebase(frontendEnvironmentVariables) {
    Container.set(FIREBASE_ANALYTICS, new FirebaseAnalyticsService({
        apiKey: frontendEnvironmentVariables.FIREBASE_API_KEY,
        authDomain: frontendEnvironmentVariables.FIREBASE_AUTH_DOMAIN,
        projectId: frontendEnvironmentVariables.FIREBASE_PROJECT_ID,
        storageBucket: frontendEnvironmentVariables.FIREBASE_STORAGE_BUCKET,
        messagingSenderId: frontendEnvironmentVariables.FIREBASE_MESSAGING_SENDER_ID,
        appId: frontendEnvironmentVariables.FIREBASE_APP_ID,
        measurementId: frontendEnvironmentVariables.FIREBASE_MEASUREMENT_ID,
    }));
    Container.set(FIREBASE_USER_ID, '');
}
function configApolloClient(url) {
    const client = Container.get(ApolloClientBuilderService).build(url);
    Container.set(ApolloClient, client);
}
