import styled, { css, keyframes } from 'styled-components';
import { Border, Spacing } from '@atomic/obj.constants';
const shimmerAnimation = keyframes `
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`;
export const BaseShimmerBoxStyled = styled.div `
  background: linear-gradient(90deg, #f5f5f5 8%, #eaeaea 38%, #f5f5f5 60%);
  background-size: 1000px;
  position: relative;
  border-radius: ${Border.Radius};
  @media (prefers-reduced-motion: no-preference) {
    animation: ${shimmerAnimation} 3s linear infinite;
    animation-fill-mode: forwards;
  }
`;
export const textShimmerType = (width, height, margin) => css `
  height: ${height};
  width: ${width};
  margin: ${margin || `${Spacing.Small} 0`};
`;
export const TextShimmerBoxStyled = styled(BaseShimmerBoxStyled) `
  ${(props) => textShimmerType(props.width ? props.width : '100%', props.height, props.margin)};
`;
export const thumbShimmerType = (x, y, margin) => css `
  width: ${x};
  height: ${y || x};
  margin: ${margin || 'auto'};
`;
export const ThumbShimmerBoxStyled = styled(BaseShimmerBoxStyled) `
  ${(props) => thumbShimmerType(props.width ? props.width : '100%', props.height, props.margin)};
`;
export const ShimmerAspectRatioBoxStyled = styled(BaseShimmerBoxStyled) `
  width: 100%;
  padding-bottom: ${(props) => props.ratioPercentage}%;
`;
export const ShimmerCircleStyled = styled(BaseShimmerBoxStyled) `
  height: ${(props) => 2 * props.radius}px;
  width: ${(props) => 2 * props.radius}px;
  border-radius: ${(props) => props.radius}px;
`;
