import React from 'react';
import { UserButton } from '@app/components/mol.user-button/user-button.component';
import { useLogout } from '@app/domain/auth/logout.use-case';
import { AccountRoutes } from '@app/modules/account';
import { Button } from '@atomic/atm.button';
import { Accordion } from '@atomic/mol.accordion/accordion.component';
import { Spacing } from '@atomic/obj.constants';
import { Spacer } from '@atomic/obj.grid';
import { UserFrameStyled, UserMenuWrapperStyled } from './user-menu.component.style';
import { generalStrings } from '@app/utils/general.string';
const strings = generalStrings.userMenu;
export const UserMenu = ({ user }) => {
    const [menuOpened, setMenuOpened] = React.useState(false);
    const ignoreCloseModal = React.useRef(false);
    const logout = useLogout();
    return (React.createElement(UserMenuWrapperStyled, { onBlur: () => {
            if (!ignoreCloseModal.current) {
                setMenuOpened(false);
            }
        } },
        React.createElement(Accordion, { headerComponent: React.createElement(UserButton, { userName: user === null || user === void 0 ? void 0 : user.name, avatarUrl: user === null || user === void 0 ? void 0 : user.avatarUrl, onClick: () => setMenuOpened(!menuOpened) }), expanded: menuOpened, onClick: () => setMenuOpened(!menuOpened) },
            React.createElement(UserFrameStyled, { onMouseDown: () => (ignoreCloseModal.current = true), onMouseUp: () => (ignoreCloseModal.current = false) },
                React.createElement(Button, { variant: 'link', onClick: () => setMenuOpened(false), to: AccountRoutes.Base }, strings.myAccount),
                React.createElement(Spacer, { "$height": Spacing.Small }),
                React.createElement(Button, { variant: 'link', onClick: () => setMenuOpened(false), to: `${AccountRoutes.Base}/${AccountRoutes.ResetPassword}` }, strings.changePassword),
                React.createElement(Spacer, { "$height": Spacing.Small }),
                React.createElement(Button, { variant: 'destructiveLink', onClick: logout }, strings.logout)),
            React.createElement(Spacer, { "$height": Spacing.Small }))));
};
