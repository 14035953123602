import { useMutation } from '@app/core/graphql/graphql-mutation.hooks';
import { CreateBookingAttachmentsUrlsDocument, } from '@app/data/graphql';
import { dispatchFlashMessage } from '@atomic/mol.flash-message-container';
export function useCreateBookingAttachmentsUrls(params) {
    const [createBookingAttachmentsUrls, { error, loading }] = useMutation(CreateBookingAttachmentsUrlsDocument, {
        onCompleted: (createBookingAttachmentsUrlsResult) => { var _a; return (_a = params === null || params === void 0 ? void 0 : params.onCompleted) === null || _a === void 0 ? void 0 : _a.call(params, createBookingAttachmentsUrlsResult); },
        onError: (requestError) => {
            var _a;
            dispatchFlashMessage(requestError.message);
            (_a = params === null || params === void 0 ? void 0 : params.onError) === null || _a === void 0 ? void 0 : _a.call(params, requestError);
        },
    });
    const handleCreate = (input) => {
        createBookingAttachmentsUrls({
            input,
        });
    };
    return {
        createBookingAttachmentsUrls: handleCreate,
        error,
        loading,
    };
}
