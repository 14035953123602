import { gql } from '@apollo/client';
import { HotelFragmentDoc } from './hotel.fragment';
export const HotelDocument = gql `
  query Hotel($input: HotelInput!) {
    hotel(input: $input) {
      ...Hotel
    }
  }
  ${HotelFragmentDoc}
`;
