var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from 'react';
import set from 'lodash.set';
import * as Scroll from 'react-scroll';
import { FormField } from './form-field.component';
import { FormContext } from './form.context';
/**
 * A component for form creation. If you follow its convention it has some out of the box behaviours.
 * When the user submits a form, it iterates over all inner "Form.Field"
 * elements and call their validation. After that, it returns a "formData"
 * object with all found errors and the final data
 *
 * The goal is to enable more declarative forms (no need to have refs, call
 * validation and collect data manually, build the submission data manually)
 *
 */
export class Form extends React.Component {
    constructor(props) {
        super(props);
        this.fields = {};
        this.handleRegister = (field) => {
            if (field.props.name !== undefined) {
                this.fields[field.props.name] = field;
            }
        };
        this.handleUnregister = (field) => {
            if (field.props.name !== undefined) {
                delete this.fields[field.props.name];
            }
        };
        this.handleSubmit = (e) => __awaiter(this, void 0, void 0, function* () {
            var _a, _b;
            e === null || e === void 0 ? void 0 : e.preventDefault();
            const formData = yield this.mapFormData();
            if (Object.keys(formData.error).length > 0) {
                formData.hasError = true;
                const data = formData.error;
                const key = Object.keys(data)[0];
                const fieldName = this.getFieldName(key, data);
                this.scrollToField(fieldName);
            }
            (_b = (_a = this.props).onSubmit) === null || _b === void 0 ? void 0 : _b.call(_a, formData);
        });
        this.scrollToField = (name) => {
            const scroller = Scroll.scroller;
            scroller.scrollTo(name, {
                smooth: true,
                offset: -30,
            });
        };
        this.state = {
            register: this.handleRegister,
            unregister: this.handleUnregister,
        };
    }
    render() {
        return (React.createElement(FormContext.Provider, { value: this.state },
            React.createElement("form", { onSubmit: this.handleSubmit, name: this.props.name, noValidate: true, autoComplete: this.props.autoComplete }, this.props.children)));
    }
    getFieldName(key, obj) {
        const nextObj = obj[key];
        if (typeof nextObj !== 'object') {
            return '';
        }
        const nextKey = Object.keys(nextObj)[0];
        const suffix = this.getFieldName(nextKey, nextObj);
        return `${key}${suffix.length ? '.' : ''}${suffix}`;
    }
    mapFormData() {
        const formData = { data: {}, other: {}, error: {}, hasError: false };
        return Promise.all(
        // Trigger validation on all the fields
        Object.keys(this.fields)
            .filter((fieldName) => this.fields[fieldName].validate)
            .map((fieldName) => this.fields[fieldName])
            .map((field) => field.validate(field.state.value))).then(() => {
            Object.keys(this.fields)
                .map((fieldName) => this.fields[fieldName])
                .forEach((field) => {
                var _a;
                set(formData.data, field.props.name, field.state.value);
                set(formData.other, field.props.name, field.state.other);
                (_a = field.state.errors) === null || _a === void 0 ? void 0 : _a.forEach((error) => {
                    set(formData.error, field.props.name, {
                        name: error.name,
                        message: error.message,
                    });
                });
            });
            return formData;
        });
    }
}
Form.Field = FormField;
