import React, { useMemo } from 'react';
import { Body, H2 } from '@atomic/atm.typography';
import { Row } from '@atomic/obj.grid';
import { AccordionItemWrapperStyled, ChevronStyled, FilterItemWrapperStyled, HeaderWrapper, SidebarFilterWrapperStyled, } from './home-menu.component.style';
import { homeStrings } from '../home.strings';
import { CheckboxField } from '@atomic/atm.checkbox';
import { Accordion } from '@atomic/mol.accordion/accordion.component';
import { Button } from '@atomic/atm.button';
import { useGetBookingStepsTemplate } from '@app/domain/booking/get-booking-steps-template.use-case';
import { useGetHotels } from '@app/domain/hotel/get-hotels.use-case';
import { BookingReservationStatus, TeamType } from '@app/data/graphql';
import { DatePickerRange } from '@atomic/atm.date-picker-range';
import _ from 'lodash';
const strings = homeStrings.sidebar;
export const BookingsMenu = ({ onFilterChange }) => {
    const [selectAll, setSelectAll] = React.useState(false);
    const [openedMenus, setOpenedMenus] = React.useState([strings.checkIn]);
    const [selectedValues, setSelectedValues] = React.useState({});
    const [dates, setDates] = React.useState({});
    const [page, setPage] = React.useState(1);
    const [hotelsOptions, setHotelsOptions] = React.useState([]);
    const { data: steps } = useGetBookingStepsTemplate();
    const { data: hotels } = useGetHotels({
        page,
        onlyActive: true,
        skipSearch: true,
        onCompleted: (data) => setHotelsOptions((prevHotels) => { var _a; return [...prevHotels, ...(((_a = data === null || data === void 0 ? void 0 : data.hotels) === null || _a === void 0 ? void 0 : _a.nodes) || [])]; }),
    });
    const filters = useMemo(() => {
        return [
            {
                label: strings.bookinsSteps,
                value: 'bookingStepTemplateId',
                options: steps === null || steps === void 0 ? void 0 : steps.map((step) => ({
                    type: 'checkbox',
                    label: step.name,
                    value: step.id,
                })),
            },
            {
                label: strings.buildings,
                value: 'hotelsIds',
                options: hotelsOptions === null || hotelsOptions === void 0 ? void 0 : hotelsOptions.map((hotel) => ({
                    type: 'checkbox',
                    label: hotel.name,
                    value: hotel.id,
                })),
            },
            {
                label: strings.teams,
                value: 'overdueItemsTeamTypes',
                options: [
                    {
                        type: 'checkbox',
                        label: strings.operational,
                        value: TeamType.Operational,
                    },
                    {
                        type: 'checkbox',
                        label: strings.service,
                        value: TeamType.Service,
                    },
                ],
            },
            {
                label: strings.status,
                value: 'status',
                options: [
                    {
                        type: 'checkbox',
                        label: strings.cancelled,
                        value: BookingReservationStatus.Cancelled,
                    },
                    {
                        type: 'checkbox',
                        label: strings.noShow,
                        value: BookingReservationStatus.NoShow,
                    },
                ],
            },
            {
                label: strings.checkIn,
                value: 'checkIn',
                options: [
                    {
                        label: strings.from,
                        type: 'dateRange',
                        value: undefined,
                    },
                ],
            },
        ];
    }, [steps, hotelsOptions]);
    const handleValueChange = (value) => (option, checked) => {
        var _a, _b;
        const newSelectedOptions = checked
            ? [...((_a = selectedValues === null || selectedValues === void 0 ? void 0 : selectedValues[value]) !== null && _a !== void 0 ? _a : []), option]
            : (_b = selectedValues === null || selectedValues === void 0 ? void 0 : selectedValues[value]) === null || _b === void 0 ? void 0 : _b.filter((filterValue) => filterValue !== option);
        setSelectedValues((prevValues) => {
            return Object.assign({}, _.set(prevValues, value, newSelectedOptions));
        });
    };
    const handleDateChange = (value) => (params) => {
        setDates((prevDates) => (Object.assign(Object.assign({}, prevDates), { [value]: {
                from: params.startDate,
                to: params.endDate,
            } })));
    };
    React.useEffect(() => {
        if (selectAll) {
            setOpenedMenus(filters === null || filters === void 0 ? void 0 : filters.map((filter) => filter === null || filter === void 0 ? void 0 : filter.label));
            setSelectedValues(filters === null || filters === void 0 ? void 0 : filters.reduce((prevValue, currentValue) => {
                var _a;
                return (Object.assign(Object.assign({}, prevValue), { [currentValue === null || currentValue === void 0 ? void 0 : currentValue.value]: (_a = currentValue === null || currentValue === void 0 ? void 0 : currentValue.options) === null || _a === void 0 ? void 0 : _a.map((option) => option.value) }));
            }, {}));
        }
    }, [selectAll, filters]);
    React.useEffect(() => {
        var _a;
        const isAllItemsChecked = ((_a = Object.values(selectedValues)) === null || _a === void 0 ? void 0 : _a.reduce((prev, curr) => prev + (curr === null || curr === void 0 ? void 0 : curr.length), 0)) ===
            (filters === null || filters === void 0 ? void 0 : filters.reduce((prev, curr) => { var _a; return prev + ((_a = curr === null || curr === void 0 ? void 0 : curr.options) === null || _a === void 0 ? void 0 : _a.length); }, 0));
        setSelectAll(isAllItemsChecked);
        onFilterChange === null || onFilterChange === void 0 ? void 0 : onFilterChange(Object.assign(Object.assign({}, selectedValues), dates));
    }, [selectedValues, filters, dates, onFilterChange]);
    const toggleMenu = (menuIndex) => () => {
        var _a;
        const selectedMenu = (_a = filters[menuIndex]) === null || _a === void 0 ? void 0 : _a.label;
        if (openedMenus === null || openedMenus === void 0 ? void 0 : openedMenus.includes(selectedMenu)) {
            setOpenedMenus(openedMenus.filter((label) => label !== selectedMenu));
        }
        else {
            setOpenedMenus([...openedMenus, selectedMenu]);
        }
    };
    const handleLoadMore = () => {
        setPage((prevPage) => prevPage + 1);
    };
    return (React.createElement(SidebarFilterWrapperStyled, null,
        React.createElement(H2, null, strings.title), filters === null || filters === void 0 ? void 0 :
        filters.map((filter, index) => {
            var _a, _b;
            const isOpen = openedMenus === null || openedMenus === void 0 ? void 0 : openedMenus.includes(filter === null || filter === void 0 ? void 0 : filter.label);
            return (React.createElement(FilterItemWrapperStyled, { key: `${filter.label}-${index}`.replaceAll(' ', '') },
                React.createElement(Accordion, { headerComponent: React.createElement(HeaderWrapper, { onClick: toggleMenu(index) },
                        React.createElement(Body, { bold: true }, filter.label),
                        React.createElement(Button, { variant: 'link', circular: true, iconButton: true, onClick: toggleMenu(index) },
                            React.createElement(ChevronStyled, { opened: isOpen, variant: 'grayDark', size: 'large' }))), expanded: isOpen, onClick: toggleMenu(index) }, (_a = filter.options) === null || _a === void 0 ? void 0 :
                    _a.map((option) => {
                        var _a;
                        if (option.type === 'dateRange') {
                            return (React.createElement(DatePickerRange, { key: `${option.label}`, endDatePlaceholder: strings.endDate, startDatePlaceholder: strings.startDate, vertical: true, onDateChange: handleDateChange(filter.value) }));
                        }
                        if (option.type === 'checkbox') {
                            return (React.createElement(AccordionItemWrapperStyled, { key: `${option.label}-${option.value}` },
                                React.createElement(CheckboxField, { name: `${option.label}-${option.value}`, id: option.value, checked: (_a = selectedValues === null || selectedValues === void 0 ? void 0 : selectedValues[filter.value]) === null || _a === void 0 ? void 0 : _a.includes(option.value), onValueChange: handleValueChange(filter.value) }, option.label)));
                        }
                    }),
                    React.createElement(Row, { justifyContent: 'center' }, ((_b = hotels === null || hotels === void 0 ? void 0 : hotels.pageInfo) === null || _b === void 0 ? void 0 : _b.hasNextPage) && (filter === null || filter === void 0 ? void 0 : filter.label) === strings.buildings && (React.createElement(Button, { variant: 'link', onClick: handleLoadMore }, strings.loadMore))))));
        })));
};
