import { TextShimmerBoxStyled } from '@atomic/mol.shimmer/shimmer.component.style';
import { Spacing } from '@atomic/obj.constants';
import { Column, Row, Spacer } from '@atomic/obj.grid';
import React from 'react';
const DEFAULT_ROWS = 7;
const DEFAULT_COLUMNS = 1;
export const FormShimmer = (props) => {
    var _a;
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, { gap: Spacing.Large }, Array((_a = props.columns) !== null && _a !== void 0 ? _a : DEFAULT_COLUMNS)
            .fill('')
            .map((_, index) => {
            var _a;
            return (React.createElement(Column, { flex: 1, gap: Spacing.Large, key: `column-${index}` }, Array((_a = props.rows) !== null && _a !== void 0 ? _a : DEFAULT_ROWS)
                .fill('')
                .map((__, rowIndex) => (React.createElement(TextShimmerBoxStyled, { key: `row-${rowIndex}`, height: '60px' })))));
        })),
        React.createElement(Spacer, { "$height": Spacing.XXLarge }),
        React.createElement(Row, { justifyContent: 'flex-end' },
            React.createElement(TextShimmerBoxStyled, { height: '60px', width: '240px' }))));
};
