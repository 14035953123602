import { gql } from '@apollo/client';
import { HotelFragmentDoc } from './hotel.fragment';
export const SearchHotelsDocument = gql `
  query SearchHotels($query: String, $filters: HotelsFilters) {
    searchHotels(query: $query, filters: $filters) {
      ...Hotel
    }
  }
  ${HotelFragmentDoc}
`;
