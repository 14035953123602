import React, { useRef, useState } from 'react';
import { createPortal } from 'react-dom';
export const useDialog = () => {
    const dialogRef = React.useRef(null);
    const [isVisible, setIsVisible] = useState(false);
    const receivedCancelEventFromAnotherElement = useRef(false);
    const [isPolyfill, setIsPolyfill] = useState(false);
    const dialogObserverRef = useRef(new MutationObserver((events) => {
        const hasOpenAttribute = events.some((item) => item.attributeName === 'open');
        setIsVisible(hasOpenAttribute);
    }));
    const initializePolyfill = React.useCallback(() => {
        if (typeof window !== 'undefined' && dialogRef.current && !dialogRef.current.showModal) {
            import('dialog-polyfill').then(({ default: dialogPolyfill }) => dialogPolyfill.registerDialog(dialogRef.current));
            setIsPolyfill(true);
        }
    }, []);
    const handleRef = React.useCallback((ref) => {
        dialogRef.current = ref;
        if (dialogRef.current) {
            dialogObserverRef.current.observe(dialogRef.current, {
                attributeFilter: ['open'],
                attributes: true,
            });
        }
        else {
            dialogObserverRef.current.disconnect();
        }
        initializePolyfill();
    }, [initializePolyfill]);
    const handleDismissEvent = React.useCallback((event) => {
        var _a, _b;
        event.stopPropagation();
        if (event.target === dialogRef.current) {
            if (event.type === 'close' && receivedCancelEventFromAnotherElement.current) {
                // workaround for chromium bug https://bugs.chromium.org/p/chromium/issues/detail?id=1449848
                (_b = (_a = dialogRef.current) === null || _a === void 0 ? void 0 : _a.showModal) === null || _b === void 0 ? void 0 : _b.call(_a);
            }
            receivedCancelEventFromAnotherElement.current = false;
        }
        else if (event.type === 'cancel') {
            receivedCancelEventFromAnotherElement.current = true;
        }
        else {
            receivedCancelEventFromAnotherElement.current = false;
        }
    }, []);
    return {
        handleDismissEvent,
        handleRef,
        isPolyfill,
        dialogRef,
        isVisible,
    };
};
export const DialogPolyfillWrapper = (props) => {
    return props.isPolyfill ? (createPortal(props.children, document === null || document === void 0 ? void 0 : document.getElementById('dialog-polyfill'))) : (React.createElement(React.Fragment, null, props.children));
};
