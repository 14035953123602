import styled from 'styled-components';
import { Border, Color } from '@atomic/obj.constants';
const variantMapper = {
    primaryDark: Color.PrimaryDark,
    white: Color.White,
    grayLight: Color.GrayLight,
};
export const Divider = styled.div `
  height: ${Border.Width};
  width: 100%;
  background-color: ${({ variant }) => (variant ? variantMapper[variant] : Color.PrimaryDark)};
`;
