import styled, { css } from 'styled-components';
import { Color, Spacing, Border } from '@atomic/obj.constants';
import { fadeIn, fadeOut } from '@atomic/obj.animation/animation.component.style';
const getDrawerTopDistance = (level) => {
    return `${(level - 1) * parseInt(Spacing.XXLarge) + DRAWER_TOP_BASE_DISTANCE}px`;
};
const DRAWER_TOP_BASE_DISTANCE = 4;
const DRAWER_LEFT_BASE_DISTANCE = 20;
const DRAWER_SHADOW = '-4px 0px 40px -4px rgba(0, 0, 0, 0.5);';
export const SideDrawerStyled = styled.div `
  position: fixed;
  height: ${({ level }) => css `calc(100% - ${getDrawerTopDistance(level)})`};
  right: 0;
  border-radius: ${Border.Radius} 0 0 0;

  ${(props) => {
    return [...Array(props.level)].map(() => css `
        &.level-${props.level} {
          top: ${getDrawerTopDistance(props.level)};
          left: calc(40% + ${props.level * DRAWER_LEFT_BASE_DISTANCE}px);
          z-index: ${props.level + 1};
          box-shadow: ${DRAWER_SHADOW};
        }
      `);
}}

  @media (prefers-reduced-motion: no-preference) {
    &.fadeIn {
      transition-timing-function: ease-in-out;
      animation: 0.4s ${fadeIn} forwards;
    }

    &.fadeOut {
      animation: 0.2s ${fadeOut} forwards;
    }
  }
`;
export const SideDrawerContentStyled = styled.div `
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${Color.White};
  overflow-y: auto;
  border-radius: ${Border.Radius} 0 0 0;
  padding: ${Spacing.Medium} ${Spacing.XLarge};
`;
export const SideDrawerLeftIconStyled = styled.button `
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  top: 30px;
  left: -48px;
  width: 48px;
  aspect-ratio: 1;
  border-top-left-radius: ${Border.Radius};
  border-bottom-left-radius: ${Border.Radius};
  border-color: transparent;
  background-color: ${Color.White};
  z-index: ${(props) => props.level + 2};
  cursor: pointer;
`;
