import styled, { css } from 'styled-components';
import { Color, FontFamily, FontSize, FontWeight, Spacing } from '@atomic/obj.constants';
export const variantMapper = {
    neutral: Color.GrayDark,
    success: Color.SuccessDark,
    alert: Color.AlertDark,
    warning: Color.Warning,
    white: Color.White,
    black: Color.Black,
};
export const HDisplay = styled.label `
  color: ${Color.PrimaryDark};
  font-size: ${FontSize.XLarge};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Lighter};
  text-transform: uppercase;
`;
HDisplay.displayName = 'HDisplay';
export const H1 = styled.h1 `
  color: ${Color.PrimaryDark};
  font-size: ${FontSize.Large};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Normal};
  margin-bottom: ${(props) => (props.noMargin ? 0 : Spacing.Medium)};
  margin-top: ${(props) => (props.noMargin ? 0 : Spacing.Large)};
  ${(props) => !!props.center && `text-align: center;`}
  text-transform: uppercase;
`;
export const H2 = styled.h2 `
  color: ${Color.PrimaryDark};
  font-size: ${FontSize.Large};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Medium};
  margin-bottom: ${(props) => (props.noMargin ? 0 : Spacing.Small)};
  margin-top: ${(props) => (props.noMargin ? 0 : Spacing.Large)};
`;
H2.displayName = 'H2';
export const H3 = styled.h3 `
  color: ${Color.GrayXDark};
  font-size: ${FontSize.Medium};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Normal};
`;
H3.displayName = 'H3';
export const H4 = styled.h4 `
  color: ${({ variant }) => (variant ? variantMapper[variant] : Color.PrimaryDark)};
  font-size: ${FontSize.Medium};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Bold};
  ${({ noMargin }) => noMargin &&
    css `
      margin: 0;
    `}
`;
H4.displayName = 'H4';
export const limitLineNumber = (lineNumber) => {
    if (lineNumber) {
        return css `
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: ${lineNumber};
      -webkit-box-orient: vertical;
    `;
    }
};
export const Body = styled.p `
  color: ${({ variant }) => (variant ? variantMapper[variant] : Color.GrayXDark)};
  margin: 0;
  font-size: ${FontSize.Small};
  font-family: ${FontFamily.Primary};
  font-weight: ${({ bold }) => (bold ? FontWeight.Bold : FontWeight.Normal)};

  ${({ lineLimit }) => limitLineNumber(lineLimit)};
  ${({ strikethrough }) => strikethrough && `text-decoration: line-through;`}
`;
Body.displayName = 'Body';
export const BodySecondary = styled.p `
  color: ${({ variant }) => (variant ? variantMapper[variant] : Color.GrayDark)};
  font-size: ${FontSize.XSmall};
  font-family: ${FontFamily.Primary};
  font-weight: ${({ bold }) => (bold ? FontWeight.Bold : FontWeight.Medium)};
  line-height: 1.5;
  margin: 0;

  ${({ strikethrough }) => strikethrough && `text-decoration: line-through;`}
  ${({ lineLimit }) => limitLineNumber(lineLimit)};
`;
export const BodySecondaryCentered = styled(BodySecondary) `
  text-align: center;
`;
export const Label = styled(BodySecondary) `
  font-size: ${FontSize.XSmall};
  ${({ centered }) => centered && 'text-align: center'};
`;
export const InputLabel = styled.label `
  color: ${(props) => (props.hasError ? Color.Alert : Color.GrayXDark)};
  display: block;
  font-size: ${FontSize.Small};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Medium};
  margin-bottom: ${Spacing.XSmall};
`;
export const InputValueCss = css `
  color: ${Color.Black};
  font-size: ${FontSize.Small};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Normal};
`;
export const InputValue = styled.label `
  ${InputValueCss};
`;
export const InputDisabledCss = css `
  color: ${Color.Gray};
  font-size: ${FontSize.Small};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Medium};

  /* https://stackoverflow.com/a/45209441/3670829 */
  -webkit-text-fill-color: ${Color.GrayLight};
  opacity: 1;
  -webkit-opacity: 1;
`;
export const InputDisabled = styled.label `
  ${InputDisabledCss};
`;
export const InputCaption = styled.label `
  color: ${Color.GrayXDark};
  font-size: ${FontSize.XSmall};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Medium};
  margin-top: ${Spacing.XSmall};
  display: block;
`;
export const InputCaptionError = styled(InputCaption) `
  color: ${Color.Alert};
`;
export const InputPlaceholderCss = css `
  color: ${Color.GrayDark};
  font-size: ${FontSize.Small};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Medium};
`;
export const InputPlaceholder = styled.label `
  pointer-events: none;
  ${InputPlaceholderCss};
`;
export const ProductPricePrefix = styled.span `
  color: ${Color.GrayDark};
  font-weight: ${FontWeight.Bold};
`;
export const ProductPrice = styled.label `
  color: ${(props) => (props.isFeatured ? Color.Alert : Color.Primary)};
  font-size: ${FontSize.Small};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Medium};
`;
export const ProductOldPrice = styled.label `
  color: ${Color.GrayXDark};
  text-decoration: line-through;
  font-size: ${FontSize.XSmall};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Lighter};
`;
export const DT = styled.dt `
  display: inline;
  color: ${Color.GrayXDark};
  font-size: calc(${FontSize.XSmall} + '2px');
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Bold};
`;
export const DD = styled.dd `
  display: inline;
  color: ${Color.GrayXDark};
  font-size: calc(${FontSize.XSmall} + '2px');
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Normal};
  margin: 0;
`;
// Utilities
export const Ellipsed = styled.span `
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${({ lineLimit }) => limitLineNumber(lineLimit)};
`;
export const Bold = styled.span `
  font-weight: ${FontWeight.Bold};
`;
