import styled from 'styled-components';
import { Color, HorizontalNavbarHeight, Spacing, VerticalNavbarWidth, ZIndex } from '@atomic/obj.constants';
export const HorizontalNavbarStyled = styled.div `
  position: fixed;
  height: ${HorizontalNavbarHeight}px;
  top: 0;
  right: 0;
  left: ${VerticalNavbarWidth}px;
  padding: ${Spacing.Small} ${Spacing.XLarge};
  z-index: ${ZIndex.third};
  background-color: ${Color.White};
`;
export const HorizontalNavbarWrapperStyled = styled.nav `
  margin-bottom: ${HorizontalNavbarHeight}px;
`;
