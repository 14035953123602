import React from 'react';
import { GuestFormFields } from '@app/model/booking/booking-guest.model';
import { PhoneInput } from '@atomic/atm.phone-field/phone-field.component';
import { TextField } from '@atomic/atm.text-field';
import { Spacing } from '@atomic/obj.constants';
import { Form, FormField } from '@atomic/obj.form';
import { Column, Row, Spacer } from '@atomic/obj.grid';
import { homeStrings } from '../home.strings';
import { addPlusToPhoneNumber } from '@app/utils/string.utils';
const strings = homeStrings.guestForm;
export const GuestContactForm = (props) => {
    var _a, _b, _c;
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, { gap: Spacing.Medium },
            React.createElement(Column, { flex: 1 },
                React.createElement(Form.Field, { name: `contact.${GuestFormFields.mobileNumber}`, initialValue: addPlusToPhoneNumber((_a = props.contact) === null || _a === void 0 ? void 0 : _a.mobileNumber), label: strings.contact.mobileNumber },
                    React.createElement(PhoneInput, null))),
            React.createElement(Column, { flex: 1 },
                React.createElement(Form.Field, { name: `contact.${GuestFormFields.phoneNumber}`, initialValue: addPlusToPhoneNumber((_b = props.contact) === null || _b === void 0 ? void 0 : _b.phoneNumber), label: strings.contact.phoneNumber },
                    React.createElement(PhoneInput, null)))),
        React.createElement(Spacer, { "$height": Spacing.Medium }),
        React.createElement(FormField, { name: `contact.${GuestFormFields.email}`, initialValue: (_c = props.contact) === null || _c === void 0 ? void 0 : _c.email, label: strings.contact.email },
            React.createElement(TextField, null))));
};
