import React from 'react';
import { ContentWrapper } from '@app/components/atm.content-wrapper/content-wrapper.component';
import { ErrorPlaceholder } from '@app/components/mol.placeholder/error-placeholder.component';
import { H1 } from '@atomic/atm.typography';
import { Spacing } from '@atomic/obj.constants';
import { Grid, Spacer } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { HotelTableShimmer } from '@app/modules/hotels/components/hotels.page.shimmer';
import { useSearchParams } from 'react-router-dom';
import { useGetHotels } from '@app/domain/hotel/get-hotels.use-case';
import { PageParams } from '@app/utils/string.utils';
import { PaginationComponent } from '@atomic/atm.pagination';
import { HotelsConfigTable } from './components/hotels-config-table.component';
import { hotelConfigStrings } from './hotels-config.strings';
const INITIAL_PAGE = '1';
const HotelsConfigPage = () => {
    var _a;
    const [params, setParams] = useSearchParams({ [PageParams]: INITIAL_PAGE });
    const { data, loading, error } = useGetHotels({ page: params.get(PageParams), skipSearch: true });
    return (React.createElement(React.Fragment, null,
        React.createElement(ContentWrapper, null,
            React.createElement(H1, null, hotelConfigStrings.hotelConfigEditing)),
        React.createElement(Spacer, { "$height": Spacing.Large }),
        React.createElement(Grid, null,
            React.createElement(LoadingState, { data: !!((_a = data === null || data === void 0 ? void 0 : data.nodes) === null || _a === void 0 ? void 0 : _a.length), loading: loading, error: !!error },
                React.createElement(LoadingState.Shimmer, null,
                    React.createElement(HotelTableShimmer, null)),
                React.createElement(LoadingState.NoData, null,
                    React.createElement(ErrorPlaceholder, { type: 'empty' })),
                React.createElement(LoadingState.Error, null,
                    React.createElement(ErrorPlaceholder, { type: 'notFound' })),
                (data === null || data === void 0 ? void 0 : data.nodes) && React.createElement(HotelsConfigTable, { hotels: data.nodes }),
                React.createElement(PaginationComponent, { current: data === null || data === void 0 ? void 0 : data.pageInfo.page, onPageChange: (page) => setParams({ page: page.toString() }), siblingCount: 2, total: data === null || data === void 0 ? void 0 : data.pageInfo.totalPages }),
                React.createElement(Spacer, { "$height": Spacing.XXLarge })))));
};
export default HotelsConfigPage;
