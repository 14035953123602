import { gql } from '@apollo/client';
import { BookingItemFragmentDoc } from './booking-item.fragment';
export const UpdateBookingItemDocument = gql `
  mutation updateBookingItem($input: UpdateBookingItemInput!) {
    updateBookingItem(input: $input) {
      ...BookingItem
    }
  }
  ${BookingItemFragmentDoc}
`;
