// eslint-disable-next-line import/no-unassigned-import
import 'reflect-metadata';
import React from 'react';
// eslint-disable-next-line import/no-unassigned-import
import '@assets/fonts/fonts.css';
import { ApolloClient, ApolloProvider } from '@apollo/client';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import Container from 'typedi';
import { configure } from '@app/configure-app';
import reportWebVitals from 'reportWebVitals';
import App from './App';
configure(__serializedVariables);
const container = document.getElementById('root');
const isSSR = container.hasChildNodes();
const app = (React.createElement(React.StrictMode, null,
    React.createElement(BrowserRouter, null,
        React.createElement(ApolloProvider, { client: Container.get(ApolloClient) },
            React.createElement(App, null)))));
if (isSSR) {
    hydrateRoot(container, app);
}
else {
    const root = createRoot(container);
    root.render(app);
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
