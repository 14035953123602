import { useMutation } from '@app/core/graphql/graphql-mutation.hooks';
import { ValidatePasswordCodeDocument, } from '@app/data/graphql';
import { dispatchFlashMessage } from '@atomic/mol.flash-message-container/flash-message-container.component';
export function useValidatePasswordCode(params = {}) {
    const [validateCode, { error, data, loading, called }] = useMutation(ValidatePasswordCodeDocument, {
        onCompleted: (validatePasswordCodeResult) => { var _a; return (_a = params.onCompleted) === null || _a === void 0 ? void 0 : _a.call(params, validatePasswordCodeResult); },
        onError: (requestError) => {
            var _a;
            dispatchFlashMessage(requestError.message);
            (_a = params.onError) === null || _a === void 0 ? void 0 : _a.call(params, requestError);
        },
    });
    return {
        validateCode,
        called,
        data,
        error,
        loading,
    };
}
