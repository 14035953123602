import { gql } from '@apollo/client';
import { HotelFragmentDoc } from './hotel.fragment';
import { PageInfoFragmentDoc } from './page-info.fragment';
export const PaginatedHotelsFragmentDoc = gql `
  fragment PaginatedHotels on PaginatedHotels {
    nodes {
      ...Hotel
    }
    count
    pageInfo {
      ...PageInfo
    }
  }
  ${HotelFragmentDoc}
  ${PageInfoFragmentDoc}
`;
