import { useMutation } from '@app/core/graphql/graphql-mutation.hooks';
import { dispatchFlashMessage } from '@atomic/mol.flash-message-container/flash-message-container.component';
export function useChangePassword(props) {
    const [changePassword, { data, error, loading }] = useMutation('ChangePasswordDocument', {
        onCompleted: (changePasswordResult) => { var _a; return (_a = props.onCompleted) === null || _a === void 0 ? void 0 : _a.call(props, changePasswordResult); },
        onError: (requestError) => {
            var _a;
            dispatchFlashMessage(requestError.message);
            (_a = props.onError) === null || _a === void 0 ? void 0 : _a.call(props, requestError);
        },
    });
    return {
        changePassword,
        data,
        error,
        loading,
    };
}
