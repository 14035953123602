import { AppIcon } from '@app/components/atm.app-icon';
import { DATE_FORMATS, ONE_SECONDE_IN_MS } from '@app/utils/constant.utils';
import { formatDate } from '@app/utils/string.utils';
import { Snackbar } from '@atomic/atm.snackbar/snackbar.component';
import { BodySecondary, DD, DT, H2 } from '@atomic/atm.typography';
import { ClickableIcon } from '@atomic/obj.clickable';
import { Spacing } from '@atomic/obj.constants';
import { Column, Row, Spacer } from '@atomic/obj.grid';
import React from 'react';
import { homeStrings } from '../home.strings';
import { AlertBadge } from './alert-badge.component';
import { BookingTD, BookingTR } from './booking-data-table.component.style';
import { StatusBadge } from './status-badge.component';
const strings = homeStrings;
export const BookingDrawerHeader = ({ booking }) => {
    var _a, _b, _c, _d, _e;
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, { alignItems: 'center', justifyContent: 'space-between' },
            React.createElement(Column, null,
                React.createElement(H2, null, [(_b = (_a = booking === null || booking === void 0 ? void 0 : booking.guests) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.name, (_d = (_c = booking === null || booking === void 0 ? void 0 : booking.guests) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.lastName].filter(Boolean).join(' ')),
                React.createElement(BodySecondary, null, strings.drawer.lastUpdate((booking === null || booking === void 0 ? void 0 : booking.updatedAt) ? formatDate(booking === null || booking === void 0 ? void 0 : booking.updatedAt, DATE_FORMATS.FULL_DATE_WITH_TIME) : ''))),
            !!(booking === null || booking === void 0 ? void 0 : booking.status) && React.createElement(StatusBadge, { status: booking === null || booking === void 0 ? void 0 : booking.status, size: 'medium' })),
        React.createElement(Spacer, { "$height": Spacing.Large }),
        (booking === null || booking === void 0 ? void 0 : booking.hadGlobalProblemOnSyncing) && (React.createElement(React.Fragment, null,
            React.createElement(Snackbar, { message: strings.syncToTotvsFailed, icon: React.createElement(AppIcon.Warning, { variant: 'alertDark', size: 'large' }) }),
            React.createElement(Spacer, { "$height": Spacing.Large }))),
        React.createElement(BookingDetailsTable, { booking: booking }),
        React.createElement(Spacer, { "$height": Spacing.Large }),
        ((_e = booking === null || booking === void 0 ? void 0 : booking.alertsPreview) === null || _e === void 0 ? void 0 : _e.length) > 0 && (React.createElement(Row, { gap: Spacing.Small }, booking === null || booking === void 0 ? void 0 : booking.alertsPreview.map((alert) => React.createElement(AlertBadge, { fullVersion: true, key: alert.type, alertType: alert.type }))))));
};
const BookingDetailsTable = ({ booking }) => {
    var _a, _b;
    return (React.createElement(Row, { justifyContent: 'space-between', alignItems: 'flex-start' },
        React.createElement("table", null,
            React.createElement("tbody", null,
                React.createElement(BookingTR, null,
                    React.createElement(BookingTD, null,
                        React.createElement(Row, { gap: Spacing.Small, alignItems: 'center' },
                            React.createElement(AppIcon.Building, { variant: 'grayDark' }),
                            React.createElement(DT, null, strings.building))),
                    React.createElement(BookingTD, null,
                        React.createElement(DD, null, (_a = booking === null || booking === void 0 ? void 0 : booking.hotel) === null || _a === void 0 ? void 0 :
                            _a.alias,
                            " \u30FB ", (_b = booking === null || booking === void 0 ? void 0 : booking.hotel) === null || _b === void 0 ? void 0 :
                            _b.name))),
                React.createElement(BookingTR, null,
                    React.createElement(BookingTD, null,
                        React.createElement(Row, { gap: Spacing.Small, alignItems: 'center' },
                            React.createElement(AppIcon.Higs, { variant: 'grayDark' }),
                            React.createElement(DT, null, strings.bookingId))),
                    React.createElement(BookingTD, null,
                        React.createElement(IdSection, { id: booking === null || booking === void 0 ? void 0 : booking.idHIGS }))),
                React.createElement(BookingTR, null,
                    React.createElement(BookingTD, null,
                        React.createElement(Row, { gap: Spacing.Small, alignItems: 'center' },
                            React.createElement(AppIcon.Shield, { variant: 'grayDark' }),
                            React.createElement(DT, null, strings.bookingIdPms))),
                    React.createElement(BookingTD, null,
                        React.createElement(IdSection, { id: booking === null || booking === void 0 ? void 0 : booking.idPMS }))),
                React.createElement(BookingTR, null,
                    React.createElement(BookingTD, null,
                        React.createElement(Row, { gap: Spacing.Small, alignItems: 'center' },
                            React.createElement(AppIcon.Source, { variant: 'white' }),
                            React.createElement(DT, null, strings.bookingIdSource))),
                    React.createElement(BookingTD, null,
                        React.createElement(IdSection, { id: booking === null || booking === void 0 ? void 0 : booking.idSource }))))),
        React.createElement("table", null,
            React.createElement("tbody", null,
                React.createElement(BookingTR, null,
                    React.createElement(BookingTD, null,
                        React.createElement(Row, { gap: Spacing.Small, alignItems: 'center' },
                            React.createElement(AppIcon.Checkin, { variant: 'grayDark' }),
                            React.createElement(DT, null, strings.drawer.scheduledCheckIn))),
                    React.createElement(BookingTD, null,
                        React.createElement(DD, null, formatDate(booking === null || booking === void 0 ? void 0 : booking.checkIn, DATE_FORMATS.FULL_DATE)))),
                React.createElement(BookingTR, null,
                    React.createElement(BookingTD, null,
                        React.createElement(Row, { gap: Spacing.Small, alignItems: 'center' },
                            React.createElement(AppIcon.Checkout, { variant: 'grayDark' }),
                            React.createElement(DT, null, strings.drawer.scheduledCheckOut))),
                    React.createElement(BookingTD, null,
                        React.createElement(DD, null, formatDate(booking === null || booking === void 0 ? void 0 : booking.checkOut, DATE_FORMATS.FULL_DATE))))))));
};
const IdSection = ({ id }) => {
    const [copied, setCopied] = React.useState(false);
    const handleCopyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, ONE_SECONDE_IN_MS);
    };
    return (React.createElement(Row, { gap: Spacing.XSmall, alignItems: 'center' }, id ? (React.createElement(React.Fragment, null,
        React.createElement(DD, null, id),
        React.createElement(ClickableIcon, { onClick: () => handleCopyToClipboard(id) }, copied ? (React.createElement(AppIcon.Done, { variant: 'successDark', size: 'large' })) : (React.createElement(AppIcon.Copy, { variant: 'grayDark', size: 'large' }))))) : (React.createElement(DD, null, "-"))));
};
