import React from 'react';
import { SearchBar } from '@app/components/atm.search-bar';
import { useGetBookingItemsTemplate } from '@app/domain/booking/get-booking-items-template.use-case';
import { useSearchBookingItemsTemplateByName } from '@app/domain/booking/search-booking-items-template-by-name.use-case';
import { useConfigOperationalFlow } from '@app/domain/hotel/config-operational-flow.use-case';
import { Button } from '@atomic/atm.button';
import { CheckboxField } from '@atomic/atm.checkbox';
import { Body } from '@atomic/atm.typography';
import { Spacing } from '@atomic/obj.constants';
import { Form, FormField, Validators } from '@atomic/obj.form';
import { Column, Row, Spacer } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { Modal } from '@atomic/obj.modal';
import { flowStrings } from '../flow.strings';
import { ListShimmer } from '@atomic/mol.list-shimmer';
import { limitString } from '@app/utils/string.utils';
const strings = flowStrings.flowSettings;
export const BookingItemModal = ({ hotelId, modalRef, openedStepId, onModalClose, onConfigOperationalFlowCompleted, }) => {
    var _a, _b;
    const [page, setPage] = React.useState(1);
    const [term, setTerm] = React.useState('');
    const { configOperationalFlow, loading: configOperationalFlowLoading } = useConfigOperationalFlow({
        onCompleted: () => {
            var _a;
            (_a = modalRef.current) === null || _a === void 0 ? void 0 : _a.close();
            onConfigOperationalFlowCompleted();
        },
    });
    const { allBookingItemsTemplate: notSelectedBookingItems, loading: notSelectedBookingItemsLoading, data: notSelectedBookingItemsData, error: notSelectedBookingItemsError, } = useGetBookingItemsTemplate({
        input: { hotelId, page, limit: 1 },
    });
    const { data: searchData, loading: searchLoading, error: searchError, } = useSearchBookingItemsTemplateByName({ name: term, skip: !term.length });
    const handleAddTask = (formData) => {
        if (formData.hasError) {
            return;
        }
        configOperationalFlow({
            hotelId,
            bookingStepTemplateId: openedStepId,
            bookingItemsTemplateIds: formData.data.selectedTasksId,
        });
    };
    const handleSearchTermChange = (event) => {
        var _a;
        setTerm((_a = event === null || event === void 0 ? void 0 : event.currentTarget) === null || _a === void 0 ? void 0 : _a.value);
    };
    const renderItems = () => {
        var _a;
        let items = notSelectedBookingItems;
        if (term.length) {
            items = (_a = searchData === null || searchData === void 0 ? void 0 : searchData.searchBookingItemsTemplateByName) !== null && _a !== void 0 ? _a : [];
        }
        return (React.createElement(Column, { gap: Spacing.Medium }, items === null || items === void 0 ? void 0 : items.map((bookingItem) => {
            return (React.createElement(CheckboxField, { key: bookingItem.id, id: bookingItem.id }, limitString(bookingItem.name, 100)));
        })));
    };
    const hasError = term.length ? !!searchError : !!notSelectedBookingItemsError;
    const hasData = term.length
        ? !!((_a = searchData === null || searchData === void 0 ? void 0 : searchData.searchBookingItemsTemplateByName) === null || _a === void 0 ? void 0 : _a.length)
        : !!(notSelectedBookingItems === null || notSelectedBookingItems === void 0 ? void 0 : notSelectedBookingItems.length);
    const loading = term.length ? searchLoading : notSelectedBookingItemsLoading && !notSelectedBookingItems;
    const shouldRenderLoadMore = !term.length && (((_b = notSelectedBookingItemsData === null || notSelectedBookingItemsData === void 0 ? void 0 : notSelectedBookingItemsData.pageInfo) === null || _b === void 0 ? void 0 : _b.hasNextPage) || notSelectedBookingItemsLoading);
    return (React.createElement(Form, { onSubmit: handleAddTask },
        React.createElement(Modal, { ref: modalRef, title: strings.insertTask, small: true, onClose: onModalClose, footer: React.createElement(Row, { justifyContent: 'flex-end', gap: Spacing.Medium },
                React.createElement(Button, { variant: 'secondary', disabled: configOperationalFlowLoading, onClick: () => { var _a; return (_a = modalRef.current) === null || _a === void 0 ? void 0 : _a.close(); } }, strings.cancel),
                React.createElement(Button, { variant: 'primary', loading: configOperationalFlowLoading, type: 'submit' }, strings.add)) },
            React.createElement(Body, { bold: true }, strings.chooseTasks),
            React.createElement(Spacer, { "$height": Spacing.Medium }),
            React.createElement(SearchBar, { placeholder: strings.searchTasks, onChange: handleSearchTermChange }),
            React.createElement(Spacer, { "$height": Spacing.Medium }),
            React.createElement(LoadingState, { data: hasData, loading: loading, error: hasError },
                React.createElement(LoadingState.Shimmer, null,
                    React.createElement(Column, { gap: Spacing.Medium },
                        React.createElement(ListShimmer, null))),
                React.createElement(LoadingState.NoData, null,
                    React.createElement(Body, null, strings.noResults)),
                React.createElement(LoadingState.Error, null,
                    React.createElement(Body, null, strings.somethingWentWrong))),
            React.createElement(FormField, { name: 'selectedTasksId', validators: [Validators.Required(strings.atLeastOne)] }, renderItems()),
            shouldRenderLoadMore && (React.createElement(Row, { justifyContent: 'center' },
                React.createElement(Button, { loading: notSelectedBookingItemsLoading, variant: 'link', onClick: () => setPage((cur) => cur + 1) }, strings.loadMore))))));
};
