import styled, { css } from 'styled-components';
import { MaxContainerWidth, MediaQuery, Spacing } from '@atomic/obj.constants';
const flexCss = css `
  ${(props) => buildPropWhenDefined('align-items', props.alignItems)}
  ${(props) => buildPropWhenDefined('align-self', props.alignSelf)}
  ${(props) => buildPropWhenDefined('justify-content', props.justifyContent)}
  ${(props) => buildPropWhenDefined('gap', props.gap)}
  ${(props) => buildPropWhenDefined('flex', props.flex)}
  ${(props) => buildPropWhenDefined('flex-wrap', props.wrap)}
`;
export const Row = styled.div `
  ${flexCss}
  width: 100%;
  display: flex;
  flex-direction: ${(props) => (props.responsive ? (props.reverse ? 'column-reverse' : 'column') : 'row')};
  ${(props) => props.relative && 'position: relative;'};

  ${MediaQuery.md} {
    flex-direction: row;
  }
`;
export const Column = styled.div `
  ${flexCss}
  display: flex;
  flex-direction: ${(props) => (props.responsive ? (props.reverse ? 'row-reverse' : 'row') : 'column')};
  ${(props) => props.relative && 'position: relative;'};

  ${MediaQuery.md} {
    flex-direction: column;
  }
`;
export const Spacer = styled.div `
  flex-shrink: 0;
  ${(props) => { var _a, _b; return buildPropWhenDefined('flex-basis', (_b = (_a = props.$size) !== null && _a !== void 0 ? _a : props.$width) !== null && _b !== void 0 ? _b : props.$height); }}
  ${(props) => { var _a, _b; return buildPropWhenDefined('width', (_b = (_a = props.$size) !== null && _a !== void 0 ? _a : props.$width) !== null && _b !== void 0 ? _b : 0); }}
  ${(props) => { var _a, _b; return buildPropWhenDefined('height', (_b = (_a = props.$size) !== null && _a !== void 0 ? _a : props.$height) !== null && _b !== void 0 ? _b : 0); }}
`;
function buildPropWhenDefined(property, value) {
    if (!isResponsive(value)) {
        return buildPlainProperty(property, value);
    }
    return Object.entries(value).map(([key, responsiveValue]) => typeof responsiveValue === 'string' || typeof responsiveValue === 'number'
        ? `${MediaQuery[key]} { ${property}: ${responsiveValue}; }`
        : '');
}
function buildPlainProperty(property, value) {
    if (typeof value === 'string' || typeof value === 'number') {
        return `${property}: ${value};`;
    }
}
function isResponsive(value) {
    return (value === null || value === void 0 ? void 0 : value.xs) !== undefined;
}
export const Grid = styled.div `
  ${buildPropWhenDefined('max-width', MaxContainerWidth)}
  width: 100%;
  margin: 0 auto;
  padding: 0 ${Spacing.Medium};
`;
