export var ErrorType;
(function (ErrorType) {
    ErrorType["Authorization"] = "Authorization";
    ErrorType["Connection"] = "Connection";
    ErrorType["NotFound"] = "NotFound";
    ErrorType["Internal"] = "Internal";
    ErrorType["Timeout"] = "Timeout";
})(ErrorType || (ErrorType = {}));
export class AppError extends Error {
    constructor(type, message) {
        super(message);
        this.type = type !== null && type !== void 0 ? type : ErrorType.Internal;
        this.message = message;
    }
}
