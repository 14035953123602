import { useQuery } from '@app/core/graphql';
import { BookingDocument } from '@app/data/graphql/generated/booking.query';
export const useGetBooking = (params) => {
    var _a;
    const { data, loading, error } = useQuery(BookingDocument, {
        bookingId: +params.bookingId,
    }, {
        skip: !params.bookingId,
    });
    const response = data
        ? Object.assign(Object.assign({}, data), { booking: Object.assign(Object.assign({}, data.booking), { guests: sortGuestsByMain((_a = data.booking) === null || _a === void 0 ? void 0 : _a.guests) }) }) : null;
    return {
        data: response,
        loading,
        error,
    };
};
const sortGuestsByMain = (guests) => {
    return [...(guests !== null && guests !== void 0 ? guests : [])].sort((a, b) => (a.isMain ? -1 : b.isMain ? 1 : 0));
};
