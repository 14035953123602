import { gql } from '@apollo/client';
import { AttachmentFragmentDoc } from './attachment.fragment';
export const BookingAttachmentsDocument = gql `
  query BookingAttachments($bookingId: Int!) {
    bookingAttachments(bookingId: $bookingId) {
      ...Attachment
    }
  }
  ${AttachmentFragmentDoc}
`;
