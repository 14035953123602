import styled from 'styled-components';
import { disabledFieldCss, fieldBorderCss, fieldCss } from '@atomic/obj.form/field.component.styled';
export const TextAreaStyled = styled.textarea `
  ${fieldBorderCss}
  ${fieldCss}
  min-height: 100px;
  max-width: 100%;

  ${(props) => props.readOnly && disabledFieldCss}
`;
