import React from 'react';
import { Body } from '@atomic/atm.typography';
import { TextShimmerBoxStyled } from '@atomic/mol.shimmer/shimmer.component.style';
import { TD, TH, THead, TR, Table } from '@atomic/mol.table';
import { usersStrings } from '../users.string';
const strings = usersStrings.table;
export const UserTableShimmer = () => {
    return (React.createElement(Table, null,
        React.createElement(THead, null,
            React.createElement(TR, null,
                React.createElement(TH, null,
                    React.createElement(Body, { bold: true, variant: 'black' }, strings.name)),
                React.createElement(TH, null,
                    React.createElement(Body, { bold: true, variant: 'black' }, strings.email)),
                React.createElement(TH, null,
                    React.createElement(Body, { bold: true, variant: 'black' }, strings.role)),
                React.createElement(TH, null))),
        Array(7)
            .fill('')
            .map((_, index) => (React.createElement(TableRowShimmer, { key: index })))));
};
const TableRowShimmer = () => {
    return (React.createElement(TR, null,
        React.createElement(TD, null,
            React.createElement(TextShimmerBoxStyled, { height: '40px' })),
        React.createElement(TD, null,
            React.createElement(TextShimmerBoxStyled, { height: '40px' })),
        React.createElement(TD, null,
            React.createElement(TextShimmerBoxStyled, { height: '40px' })),
        React.createElement(TD, null,
            React.createElement(TextShimmerBoxStyled, { height: '40px', width: '70%' }))));
};
