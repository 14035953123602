import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { FlashMessage } from '@atomic/mol.flash-message/flash-message.component';
import { FlashMessageContainerDivStyled } from '@atomic/mol.flash-message-container/flash-message-container.component.style';
import { Grid } from '@atomic/obj.grid';
export var FlashMessageEvent;
(function (FlashMessageEvent) {
    FlashMessageEvent["Open"] = "FlashMessageOpen";
    FlashMessageEvent["Close"] = "FlashMessageClose";
})(FlashMessageEvent || (FlashMessageEvent = {}));
export const dispatchFlashMessage = (text, type = 'alert') => {
    const flashMessageEvent = new CustomEvent(FlashMessageEvent.Open, {
        detail: {
            type,
            text: text || ((!type || type === 'alert') && 'Algo deu errado'),
            date: new Date(),
        },
    });
    document.dispatchEvent(flashMessageEvent);
};
// TODO: replace with https://caniuse.com/?search=popover when there's support and polyfills
export const FlashMessageContainer = (props) => {
    const [data, setData] = useState();
    const onClose = () => {
        setData(null);
        const flashMessageEvent = new Event(FlashMessageEvent.Close);
        document.dispatchEvent(flashMessageEvent);
    };
    React.useEffect(() => {
        const flashMessageOpenHandler = function (event) {
            setData(event.detail);
        };
        const flashMessageCloseHandler = function () {
            setData(null);
        };
        const flashMessageEscKeyHandler = function (event) {
            if (event.key === 'Escape' && data) {
                event.preventDefault();
                setData(null);
            }
        };
        document.addEventListener(FlashMessageEvent.Open, flashMessageOpenHandler);
        document.addEventListener(FlashMessageEvent.Close, flashMessageCloseHandler);
        document.addEventListener('keydown', flashMessageEscKeyHandler);
        return function () {
            document.removeEventListener(FlashMessageEvent.Open, flashMessageOpenHandler);
            document.removeEventListener(FlashMessageEvent.Close, flashMessageCloseHandler);
            document.removeEventListener('keydown', flashMessageEscKeyHandler);
        };
    }, [data]);
    const flashMessageContent = !!data && (React.createElement(FlashMessageContainerDivStyled, null,
        React.createElement(Grid, null,
            React.createElement(FlashMessage, { onClose: onClose, key: data.date.toString(), type: data.type }, data.text))));
    return props.renderPortal
        ? createPortal(flashMessageContent, document === null || document === void 0 ? void 0 : document.getElementById('flash-message'))
        : flashMessageContent;
};
FlashMessageContainer.displayName = 'FlashMessageContainer';
