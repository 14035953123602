import React from 'react';
import { ChevronStyled, SidebarBlockStyled, SidebarStyled, TabStyled } from './sidebar.component.style';
export const Sidebar = (props) => {
    const [opened, setOpened] = React.useState(true);
    return (React.createElement(React.Fragment, null,
        React.createElement(SidebarBlockStyled, { opened: !props.collapsable || opened }),
        React.createElement(SidebarStyled, { opened: !props.collapsable || opened },
            props.collapsable && (React.createElement(TabStyled, { onClick: () => setOpened((cur) => !cur) },
                React.createElement(ChevronStyled, { variant: 'grayDark', opened: opened }))),
            props.children)));
};
