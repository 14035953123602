import { useMutation } from '@app/core/graphql/graphql-mutation.hooks';
import { UpdateBookingDocument, } from '@app/data/graphql';
import { dispatchFlashMessage } from '@atomic/mol.flash-message-container';
export function useUpdateBooking(params) {
    const [mutate, { data, error, loading }] = useMutation(UpdateBookingDocument, {
        onCompleted: (updateBookingResult) => { var _a; return (_a = params === null || params === void 0 ? void 0 : params.onCompleted) === null || _a === void 0 ? void 0 : _a.call(params, updateBookingResult); },
        onError: (requestError) => {
            var _a;
            dispatchFlashMessage(requestError.message);
            (_a = params === null || params === void 0 ? void 0 : params.onError) === null || _a === void 0 ? void 0 : _a.call(params, requestError);
        },
    });
    const updateBooking = (input) => {
        mutate({
            input,
        });
    };
    return {
        updateBooking,
        data,
        error,
        loading,
    };
}
