import React from 'react';
import { Body } from '@atomic/atm.typography';
import { TD, TH, THead, TR, Table } from '@atomic/mol.table';
import { Row } from '@atomic/obj.grid';
import { homeStrings } from '../home.strings';
import { formatCurrency, formatDate } from '@app/utils/string.utils';
const strings = homeStrings.drawer.paymentsTable;
export const PaymentsTable = (props) => {
    var _a;
    return (React.createElement(Table, null,
        React.createElement(THead, null,
            React.createElement(TR, null,
                React.createElement(TH, null,
                    React.createElement(Body, { bold: true }, strings.date)),
                React.createElement(TH, null,
                    React.createElement(Body, { bold: true }, strings.description)),
                React.createElement(TH, null,
                    React.createElement(Body, { bold: true }, strings.value)))), (_a = props.guestBillings) === null || _a === void 0 ? void 0 :
        _a.map((billing) => {
            return React.createElement(PaymentsTableRow, { key: billing.id, billing: billing });
        })));
};
const PaymentsTableRow = (props) => {
    var _a;
    return (React.createElement(TR, null,
        React.createElement(TD, null,
            React.createElement(Row, { alignItems: 'center' },
                React.createElement(Body, null, formatDate((_a = props.billing) === null || _a === void 0 ? void 0 : _a.expenseDate)))),
        React.createElement(TD, null,
            React.createElement(Body, null, props.billing.description)),
        React.createElement(TD, null,
            React.createElement(Body, null, formatCurrency(props.billing.valueInBRL)))));
};
