import { gql } from '@apollo/client';
import { PaginatedHotelsFragmentDoc } from './paginated-hotels.fragment';
export const HotelsDocument = gql `
  query Hotels($input: HotelsInput!, $filters: HotelsFilters) {
    hotels(input: $input, filters: $filters) {
      ...PaginatedHotels
    }
  }
  ${PaginatedHotelsFragmentDoc}
`;
