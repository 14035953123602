import { AppIcon } from '@app/components/atm.app-icon';
import { ContentWrapper } from '@app/components/atm.content-wrapper/content-wrapper.component';
import { ErrorPlaceholder } from '@app/components/mol.placeholder/error-placeholder.component';
import { useGetHotels } from '@app/domain/hotel/get-hotels.use-case';
import { Button } from '@atomic/atm.button';
import { PaginationComponent } from '@atomic/atm.pagination';
import { H1 } from '@atomic/atm.typography';
import { Spacing } from '@atomic/obj.constants';
import { Column, Grid, Row, Spacer } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import React from 'react';
import { HotelsTable } from './components/hotels-table.component';
import { HotelsRoutes } from './hotels.routes';
import { hotelStrings } from './hotels.strings';
import { HotelTableShimmer } from './components/hotels.page.shimmer';
import { useSearchParams } from 'react-router-dom';
import { PageParams } from '@app/utils/string.utils';
import { SearchBar } from '@app/components/atm.search-bar';
import { SelectField } from '@atomic/atm.select';
const INITIAL_PAGE = '1';
const HotelsPage = () => {
    var _a, _b, _c;
    const [filters, setFilters] = React.useState();
    const [params, setParams] = useSearchParams({ [PageParams]: INITIAL_PAGE });
    const { data, loading, error } = useGetHotels({
        page: params.get(PageParams),
        query: filters === null || filters === void 0 ? void 0 : filters.query,
        onlyActive: (_a = filters === null || filters === void 0 ? void 0 : filters.filters) === null || _a === void 0 ? void 0 : _a.onlyActive,
        onlyInactive: (_b = filters === null || filters === void 0 ? void 0 : filters.filters) === null || _b === void 0 ? void 0 : _b.onlyInactive,
    });
    const handleSearchTermChange = (event) => {
        const query = event.currentTarget.value;
        setFilters((prevFilters) => (Object.assign(Object.assign({}, prevFilters), { query })));
    };
    const handleStatusChange = (value) => {
        setFilters((prevFilters) => (Object.assign(Object.assign({}, prevFilters), { filters: {
                onlyActive: value === 'onlyActive',
                onlyInactive: value === 'onlyInactive',
            } })));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ContentWrapper, null,
            React.createElement(Row, { alignItems: 'center' },
                React.createElement(Column, { flex: 1, justifyContent: 'flex-start' },
                    React.createElement(H1, null, hotelStrings.hotelsEditing)),
                React.createElement(Column, null,
                    React.createElement(Button, { variant: 'primary', to: `${HotelsRoutes.Base}/${HotelsRoutes.RegisterHotel}` },
                        React.createElement(AppIcon.Add, { variant: 'white', size: 'large' }),
                        hotelStrings.registerNewHotel))),
            React.createElement(Spacer, { "$height": Spacing.Medium }),
            React.createElement(Row, { gap: Spacing.Medium },
                React.createElement(Column, { flex: 1 },
                    React.createElement(SearchBar, { onChange: handleSearchTermChange, placeholder: hotelStrings.searchBarPlaceholder })),
                React.createElement(Column, null,
                    React.createElement(SelectField, { onValueChange: handleStatusChange },
                        React.createElement("option", { value: 'onlyActive' }, hotelStrings.table.filters.active),
                        React.createElement("option", { value: 'onlyInactive' }, hotelStrings.table.filters.inactive)))),
            React.createElement(Spacer, { "$height": Spacing.Medium })),
        React.createElement(Spacer, { "$height": Spacing.Large }),
        React.createElement(Grid, null,
            React.createElement(LoadingState, { data: !!((_c = data === null || data === void 0 ? void 0 : data.nodes) === null || _c === void 0 ? void 0 : _c.length), loading: loading, error: !!error },
                React.createElement(LoadingState.Shimmer, null,
                    React.createElement(HotelTableShimmer, null)),
                React.createElement(LoadingState.NoData, null,
                    React.createElement(ErrorPlaceholder, { type: 'empty' })),
                React.createElement(LoadingState.Error, null,
                    React.createElement(ErrorPlaceholder, { type: 'notFound' })),
                (data === null || data === void 0 ? void 0 : data.nodes) && React.createElement(HotelsTable, { hotels: data === null || data === void 0 ? void 0 : data.nodes }),
                React.createElement(PaginationComponent, { current: data === null || data === void 0 ? void 0 : data.pageInfo.page, onPageChange: (page) => setParams({ page: page.toString() }), siblingCount: 2, total: data === null || data === void 0 ? void 0 : data.pageInfo.totalPages }),
                React.createElement(Spacer, { "$height": Spacing.XXLarge })))));
};
export default HotelsPage;
