import { useMutation } from '@app/core/graphql/graphql-mutation.hooks';
import { UpdateUserDocument, } from '@app/data/graphql/generated/update-user.mutation';
import { dispatchFlashMessage } from '@atomic/mol.flash-message-container/flash-message-container.component';
export function useUpdateUser(params) {
    const [updateUser, { error, loading }] = useMutation(UpdateUserDocument, {
        onCompleted: (updateUserResult) => { var _a; return (_a = params.onCompleted) === null || _a === void 0 ? void 0 : _a.call(params, updateUserResult); },
        onError: (requestError) => {
            var _a;
            dispatchFlashMessage(requestError.message);
            (_a = params.onError) === null || _a === void 0 ? void 0 : _a.call(params, requestError);
        },
    });
    return {
        updateUser,
        error,
        loading,
    };
}
