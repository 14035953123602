import React from 'react';
import { TextField } from '@atomic/atm.text-field';
import { Spacing } from '@atomic/obj.constants';
import { FormField, Validators } from '@atomic/obj.form';
import { Column, Row, Spacer } from '@atomic/obj.grid';
import { hotelStrings } from '../hotels.strings';
import { SelectField } from '@atomic/atm.select';
import { states } from '@app/utils/constant.utils';
const strings = hotelStrings.hotelForm;
var AddressFields;
(function (AddressFields) {
    AddressFields["addressNumber"] = "addressNumber";
    AddressFields["city"] = "city";
    AddressFields["complement"] = "complement";
    AddressFields["countryCode"] = "countryCode";
    AddressFields["district"] = "district";
    AddressFields["hotelId"] = "hotelId";
    AddressFields["postalCode"] = "postalCode";
    AddressFields["stateCode"] = "stateCode";
    AddressFields["street"] = "street";
})(AddressFields || (AddressFields = {}));
export const HotelAddressFormFields = (props) => {
    var _a, _b;
    const [postalCode, setPostalCode] = React.useState((_a = props.address) === null || _a === void 0 ? void 0 : _a.postalCode);
    const { address } = props;
    const handlePostalCodeChange = (value) => {
        setPostalCode(value);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(FormField, { label: strings.addressFields.street, name: `address.${AddressFields.street}`, initialValue: address === null || address === void 0 ? void 0 : address.street },
            React.createElement(TextField, { placeholder: strings.placeholder.street })),
        React.createElement(Spacer, { "$height": Spacing.XLarge }),
        React.createElement(Row, { gap: Spacing.Medium },
            React.createElement(Column, { flex: 1 },
                React.createElement(FormField, { label: strings.addressFields.postalCode, name: `address.${AddressFields.postalCode}`, initialValue: address === null || address === void 0 ? void 0 : address.postalCode, onValueChange: handlePostalCodeChange, validators: [!!(postalCode === null || postalCode === void 0 ? void 0 : postalCode.length) && Validators.ZipCodeRegex()].filter(Boolean) },
                    React.createElement(TextField, { placeholder: strings.placeholder.postalCode, variant: 'zip-code' }))),
            React.createElement(Column, { flex: 2 },
                React.createElement(FormField, { label: strings.addressFields.district, name: `address.${AddressFields.district}`, initialValue: address === null || address === void 0 ? void 0 : address.district },
                    React.createElement(TextField, { placeholder: strings.placeholder.district })))),
        React.createElement(Spacer, { "$height": Spacing.XLarge }),
        React.createElement(Row, { gap: Spacing.Medium },
            React.createElement(Column, { flex: 1 },
                React.createElement(FormField, { label: strings.addressFields.addressNumber, name: `address.${AddressFields.addressNumber}`, initialValue: address === null || address === void 0 ? void 0 : address.addressNumber },
                    React.createElement(TextField, { placeholder: strings.placeholder.addressNumber }))),
            React.createElement(Column, { flex: 2 },
                React.createElement(FormField, { label: strings.addressFields.complement, name: `address.${AddressFields.complement}`, initialValue: address === null || address === void 0 ? void 0 : address.complement },
                    React.createElement(TextField, { placeholder: strings.placeholder.complement })))),
        React.createElement(Spacer, { "$height": Spacing.XLarge }),
        React.createElement(Row, { gap: Spacing.Medium },
            React.createElement(Column, { flex: 1 },
                React.createElement(FormField, { label: strings.addressFields.stateCode, name: `address.${AddressFields.stateCode}`, initialValue: (_b = address === null || address === void 0 ? void 0 : address.stateCode) !== null && _b !== void 0 ? _b : null },
                    React.createElement(SelectField, null,
                        React.createElement("option", { value: null }),
                        states.map((option) => (React.createElement("option", { key: option.name, value: option.initials }, option.name)))))),
            React.createElement(Column, { flex: 2 },
                React.createElement(FormField, { label: strings.addressFields.city, name: `address.${AddressFields.city}`, initialValue: address === null || address === void 0 ? void 0 : address.city },
                    React.createElement(TextField, { placeholder: strings.placeholder.city })))),
        React.createElement(Spacer, { "$height": Spacing.XLarge })));
};
