import React from 'react';
import { AppIcon } from '@app/components/atm.app-icon';
import { FormShimmer } from '@app/components/mol.form-shimmer/form-shimmer.component';
import { ProgressBar } from '@app/components/mol.progress-bar/progress-bar.component';
import { useDeleteBookingAttachments } from '@app/domain/booking/delete-booking-attachment.use-case';
import { useGetBookingAttachments } from '@app/domain/booking/get-booking-attachments.use-case';
import { useUploadAttachments } from '@app/domain/booking/upload-booking-attachments.use-case';
import { ActivityIndicator } from '@atomic/atm.activity-indicator';
import { Button } from '@atomic/atm.button';
import { H4 } from '@atomic/atm.typography';
import { ClickableIcon } from '@atomic/obj.clickable';
import { Spacing } from '@atomic/obj.constants';
import { Row, Spacer } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { isPast } from 'date-fns';
import { homeStrings } from '../home.strings';
const strings = homeStrings.attachmentsSection;
export const AttachmentsSection = (props) => {
    var _a, _b, _c;
    const tryAgainAttachmentId = React.useRef(null);
    const fileInputRef = React.useRef(null);
    const { data, loading: attachmentsLoading, error: attachmentsError, refetch, } = useGetBookingAttachments({
        bookingId: props.bookingId,
        onCompleted: (result) => {
            if (tryAgainAttachmentId.current) {
                handleAttachmentClick(result.bookingAttachments.find((attachment) => attachment.id === tryAgainAttachmentId.current));
                tryAgainAttachmentId.current = null;
            }
        },
    });
    const { handleUploadAttachments, attachments, loading: uploadLoading, uploadProgress, } = useUploadAttachments({
        bookingId: props.bookingId,
        onCompleted: refetch,
    });
    const { deleteAttachment, deletingAttachments } = useDeleteBookingAttachments({ onCompleted: refetch });
    const handleAttachmentClick = (attachment) => {
        if (isPast(new Date(attachment.expiresAt))) {
            tryAgainAttachmentId.current = attachment.id;
            refetch();
            return;
        }
        window.open(attachment.signedUrl, '_blank');
    };
    const handleUpload = () => {
        var _a;
        (_a = fileInputRef.current) === null || _a === void 0 ? void 0 : _a.click();
    };
    const handleFileChange = (event) => {
        var _a;
        const selectedFiles = (_a = event.target) === null || _a === void 0 ? void 0 : _a.files;
        if (selectedFiles === null || selectedFiles === void 0 ? void 0 : selectedFiles.length) {
            handleUploadAttachments(Array.from(selectedFiles));
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(H4, { variant: 'black' }, strings.attachments),
        React.createElement(LoadingState, { data: !!((_a = data === null || data === void 0 ? void 0 : data.bookingAttachments) === null || _a === void 0 ? void 0 : _a.length), loading: attachmentsLoading, error: !!attachmentsError },
            React.createElement(LoadingState.Shimmer, null,
                React.createElement(FormShimmer, { rows: 3 })), (_b = data === null || data === void 0 ? void 0 : data.bookingAttachments) === null || _b === void 0 ? void 0 :
            _b.map((attachment) => (React.createElement(Row, { key: attachment.id, gap: Spacing.XSmall, alignItems: 'center' },
                React.createElement(AppIcon.Attachment, { variant: 'grayDark', size: 'large' }),
                React.createElement(Button, { variant: 'link', onClick: () => handleAttachmentClick(attachment) }, attachment.name),
                deletingAttachments.includes(attachment.id) ? (React.createElement(ActivityIndicator, { type: 'spinner' })) : (React.createElement(ClickableIcon, { onClick: () => deleteAttachment(attachment.id) },
                    React.createElement(AppIcon.Close, { variant: 'secondary', size: 'large' })))))),
            React.createElement(Spacer, { "$height": Spacing.Large })),
        uploadLoading && (React.createElement(React.Fragment, null,
            React.createElement(Spacer, { "$height": Spacing.Medium }),
            React.createElement(ProgressBar, { totalSteps: attachments === null || attachments === void 0 ? void 0 : attachments.length, currentStep: (_c = uploadProgress === null || uploadProgress === void 0 ? void 0 : uploadProgress.length) !== null && _c !== void 0 ? _c : 0 }),
            React.createElement(Spacer, { "$height": Spacing.Medium }))),
        React.createElement("input", { type: 'file', ref: fileInputRef, style: { display: 'none' }, onChange: handleFileChange, multiple: true }),
        React.createElement(Button, { variant: 'secondary', onClick: handleUpload, loading: uploadLoading },
            React.createElement(Row, { alignItems: 'center' },
                React.createElement(AppIcon.Add, { variant: 'grayDark', size: 'large' }),
                strings.addAttachments))));
};
