import { Color, Shadow, Spacing, ZIndex } from '@atomic/obj.constants';
import styled from 'styled-components';
export const AutocompleteContainer = styled.div `
  position: relative;
`;
export const AutocompleteList = styled.ul `
  position: absolute;
  top: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: ${Color.White};
  list-style: none;
  z-index: ${ZIndex.first};
  box-shadow: ${Shadow.Medium};
`;
export const AutocompleteListItem = styled.li `
  padding: ${Spacing.Medium};
  cursor: pointer;

  &:hover {
    background-color: ${Color.GrayXLight};
  }
`;
